import { combineReducers } from "redux";
import { authReducer } from "../auth";
import {httpRequestsOnLoadReducer} from "../http_requests_on_load";
import {httpRequestsOnSuccessReducer} from "../http_requests_on_success";
import {httpRequestsOnErrorsReducer} from "../http_requests_on_errors";
import { userReducer } from "../users";
import { listingReducer } from "../listings";
import {paymentReducer} from "../payment";
import {brokerReducer} from "../brokers";

export const appReducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  listings: listingReducer,
  payment: paymentReducer,
  broker: brokerReducer,

  httpOnLoad: httpRequestsOnLoadReducer,
  httpOnSuccess: httpRequestsOnSuccessReducer,
  httpOnError: httpRequestsOnErrorsReducer,
});
