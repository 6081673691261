import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha"
import {footerCoresStyle} from "./styles";
import {CloseModalButton, UserInput} from "components";
import {EmailValidator, FindLoad, FindSuccess, Images} from "utils";
import {authActions, httpRequestsOnSuccessActions} from "store";
import {CircularProgress} from "@mui/material";

export const SendMessage = ({onClose}) => {
    const classes = footerCoresStyle()
    const dispatch = useDispatch()
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const loader = FindLoad('SEND_MESSAGE')
    const success = FindSuccess('SEND_MESSAGE')
    const [disable, setDisable] = useState(true)

    function onChange(value) {
        setDisable(false)
    }

    useEffect(() => {
        if (success.length) {
            onClose();
            dispatch(httpRequestsOnSuccessActions.removeSuccess('SEND_MESSAGE'));
        }
    }, [success]);

    const handleChange = (evt) => {
        setInputs((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value,
        }));
        error === evt.target.name && setError('');
    };

    const sendMessage = () => {
        const emailErrorMsg = !EmailValidator.test(inputs.email) ? 'Email must be an email!' : '';
        if (inputs?.name && inputs.email && !emailErrorMsg !== 'Email must be an email!' && inputs?.message) {
            const info = {
                "name": inputs?.name,
                "email": inputs.email,
                "message": inputs?.message,
            }
            dispatch(authActions.sendMessage(info))
        } else {
            setError(
                !inputs?.name ? 'name' :
                    !inputs?.email ? 'email' :
                        emailErrorMsg ? emailErrorMsg :
                            !inputs?.message ? 'message' : ''
            )
        }
    }

    return (
        <div className={classes.sendMessageWrapper}>
            <CloseModalButton
                butnClassName={classes.closeButton}
                onCloseModal={onClose}
                butnLabel={<img src={Images.close} alt=""/>}
            />
            <p className={classes.title}>Send Message</p>
            <UserInput
                required={true}
                inputClassName={classes.inputStyles}
                inputLabel={'name'}
                inputType={'text'}
                inputName={'name'}
                inputValue={inputs.name}
                onInputChange={handleChange}
                inputError={error === 'name' && 'Input is not field'}
            />
            <UserInput
                inputClassName={classes.inputStyles}
                required={true}
                inputLabel={'Email Address'}
                inputType={'text'}
                inputName={'email'}
                inputValue={inputs.email}
                onInputChange={handleChange}
                inputError={
                    error === 'email' ? 'Input is not field' :
                        error === 'Email must be an email!' ? 'Email must be an email!' : ''
                }
            />
            <p className={classes.inputTitle}><span>*</span>Message</p>
            <textarea
                name='message'
                value={inputs?.message}
                className={classes.textAreaStyles}
                onChange={handleChange}
                style={error === 'message' ? {border: '1px solid #FF385C'} : {}}
            />
            <div style={{margin: '12px auto'}}>
                <ReCAPTCHA
                    sitekey={process?.env?.REACT_APP_RECAPTCHA_PKEY}
                    onChange={onChange}
                />
            </div>
            <button
                style={{background: disable ? '#E6E6E6' : '#FF385C'}}
                disabled={disable}
                onClick={sendMessage}
                className={classes.sendButton}
            >
                {loader?.length ?
                    <CircularProgress size={17} color={"inherit"}/>
                    :
                    'Send'
                }
            </button>
        </div>
    )
}