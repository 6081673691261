import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listingActions } from 'store';
import { NavBarActionsBox, NavBarFilteringBox, NavBarSearchBox } from './core';
import { navStyle } from './style';
import {initialCoordinates, ListingFiltersContext, ViewAsContext} from 'utils';

export const Nav = () => {
    const classes = navStyle();
    const dispatch = useDispatch();
    const { filters, mapCenter, zoom, handleZoomRequest, handleLatLngRequest } = useContext(ListingFiltersContext);
    const isMyListingsPage = window.location.pathname === '/my_listings';
    const { lat, lng, zoom: initialZoom } = initialCoordinates;
    const { view, handleViewChange } = useContext(ViewAsContext);


    useEffect(() => {
        const isMapSearch = mapCenter.lat !== lat || mapCenter.lng !== lng || zoom !== initialZoom;
        const selFilters = isMapSearch ? { ...filters, ...mapCenter, zoom } : { ...filters };
        if (isMyListingsPage) {
            dispatch(listingActions.getListingsByUserIdFilter(selFilters));
        } else {
            dispatch(listingActions.getListingsByFilters(selFilters));
        }
    }, [filters, isMyListingsPage, zoom, mapCenter, view]);

    return (
        <nav className={classes.navBarStyle}>
            <section className={classes.navBarContainerStyle}>
                <div className={classes.navBarUserActionBoxStyle}>
                    <div className={classes.filterAndActionsBoxStyle}>
                        <NavBarFilteringBox view={view}/>
                        <NavBarActionsBox />
                    </div>
                    <NavBarSearchBox />
                </div>
            </section>
        </nav>
    );
};
