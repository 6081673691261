import React from 'react';
import {howItWorksFragmentsStyles} from "./styles";
import {Svg} from "assets/images";

export const Step3 = () => {
    const classes = howItWorksFragmentsStyles();

    return (
        <div className={classes.stepsWrapper}>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        1
                    </div>
                    <div className={classes.stepDot}/>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 1
                    </h4>

                    <p className={classes.stepDesc}>
                        Quickly find a broker
                    </p>

                    <div style={{marginTop: '35px'}} className={classes.stepItemsDescWrapper}>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                            <p className={classes.stepItemsText}>
                                Start by visiting the homepage.
                            </p>
                        </div>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                Look for the
                                <span style={{margin:'0 5px'}} className={classes.stepItemsTextBold}>"Find Broker"</span>
                                button, which is located on the right side of the page.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        2
                    </div>
                    <div className={classes.stepDot}/>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 2
                    </h4>

                    <p style={{marginBottom:'13px'}} className={classes.stepSubTitle}>
                        Meet broker listings
                    </p>

                    <p style={{margin:'0'}} className={classes.stepDesc}>
                        After clicking the <span className={classes.stepItemsTextBold}>"Find Broker"</span> button, a pop-up will appear displaying a list of brokers. This list includes:
                    </p>

                    <div style={{marginTop: '35px'}} className={classes.stepItemsDescWrapper}>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsTextBold}>
                                Broker's name
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsTextBold}>
                                Phone number
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsTextBold}>
                                Company name
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsTextBold}>
                                Email address
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        3
                    </div>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 3
                    </h4>

                    <p className={classes.stepSubTitle}>
                        Contact a broker
                    </p>

                    <div style={{marginTop: '35px'}} className={classes.stepItemsDescWrapper}>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                            <p className={classes.stepItemsText}>
                                Browse through the list to find a broker who fits your needs based on their specialty, location, or company.
                            </p>
                        </div>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                Use the contact information provided (phone number or email address) to reach out
                                directly to the broker. You can inquire further about their services, request a meeting, or discuss your property needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};