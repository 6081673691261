import {
    createListing,
    getListings,
    editListing,
    removeListing,
    getListingById,
    getListingsByUserId,
    getListingsByFilters,
    getListingsByUserIdFilter, promote,
} from './listings.action';

export { listingReducer } from './listings.reducer';
export { watchListing } from './listings.saga';

export const listingActions = {
    createListing,
    getListings,
    editListing,
    removeListing,
    getListingById,
    getListingsByUserId,
    getListingsByFilters,
    getListingsByUserIdFilter,

    /** Promote */
    promote
    /** End */

};
