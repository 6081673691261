import { isNotEmpty } from "utils";

export const getPassErrorText = (val = "") => {
  if (isNotEmpty(val)) {
    return val.trim().length < 8
      ? "Minimum 8 characters"
      : !/\d/.test(val)
      ? "At least 1 number"
      : !/[A-Z]/.test(val)
      ? "At least 1 uppercase letter"
      : "";
  }
};
