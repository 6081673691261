import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from "utils";
import { smthWentWrongStyle } from './styles';

export const SmthWentWrong = () => {
    const classes = smthWentWrongStyle();

    return (
        <div className={classes.wentWrongBoxStyle}>
            <div className={classes.wentWrongBoxContainerStyle}>
                <div className={classes.wentWrongInfoBoxStyle}>
                    <img src={Images.smthWentWrong} alt="" />
                    <p className={classes.wentWrongTextStyle}> Whoops! Something went wrong </p>
                    <Link to="/" className={classes.homeLinkStyle}> Back to homepage </Link>
                </div>
            </div>
        </div>
    );
};
