import { makeStyles } from "@material-ui/core/styles";
import { Images, Colors } from "utils";

export const tooltipCoresStyle = makeStyles(
  () => ({
    // ** password validity style **
    passValidityContentStyle: {
      maxWidth: "300px",
      width: "100%",
      position: "absolute",
      top: "85px",
      left: "16px",
      zIndex: -1,
      backgroundColor: Colors.BackgroundWhite,
      boxShadow: "0px 0px 6px #00000029",
      height: "0px",
      padding: "0px",
      opacity: 0,
      transition:
        "height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease-in-out",
      "&.active": {
        height: "135px",
        padding: "16px",
        opacity: 1,
        zIndex: 1,
      },
    },
    tooltipArrowStyle: {
      width: "16px",
      height: "16px",
      position: "absolute",
      top: "-8px",
      left: "16px",
      transform: "rotate(45deg)",
      backgroundColor: Colors.BackgroundWhite,
      borderLeft: "1.5px solid rgba(0, 0, 0, 0.07)",
      borderTop: "1.5px solid rgba(0, 0, 0, 0.07)",
    },
    validityTitleStyle: {
      width: "100%",
      fontSize: "14px",
      fontWeight: 700,
      color: Colors.TextBlack,
    },
    validityListStyle: {
      width: "100%",
      marginTop: "16px",
      "& li": {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: 500,
        color: Colors.TextGray,
        "&::before": {
          content: "''",
          width: "18px",
          height: "18px",
          marginRight: "8px",
          backgroundImage: `url(${Images.invalidCheckmark})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        },
        "&.active::before": {
          backgroundImage: `url(${Images.validCheckmark})`,
        },
      },
      "& li:not(:last-of-type)": { marginBottom: "8px" },
    },
    // ** end **
  }),
  { index: 1 }
);
