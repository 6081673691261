import {
  createUser,
  getUsers,
  editUser,
  removeUser,
  getUserById,
} from "./users.action";

export { userReducer } from "./users.reducer";
export { watchUser } from "./users.saga";

export const userActions = {
  createUser,
  getUsers,
  editUser,
  removeUser,
  getUserById,
};
