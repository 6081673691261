import axios from "axios";

export const paymentAuthService = {
  createPaymentService: (body) => axios.patch("/billing", body, { auth: true }),

  getPaymentService: () => axios.get("/billing/pmtMethod", { auth: true }),

  getInvoicesService: () => axios.get("/billing/invoices", { auth: true }),

  deletePaymentService: ( ) => axios.delete(`/billing/card`, { auth: true }),

  impressionService: (id) => axios.post(`/listing/${id}/click`, {}, { auth: true }),
};
