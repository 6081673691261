import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const termsAndPrivacyStyle = makeStyles(
  () => ({
    termsAndPrivacyBoxStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    termsAndPrivacyBoxContainerStyle: {
      maxWidth: "1440px",
      width: "100%",
      padding: "48px 80px 40px",
      "@media(max-width: 1200px)": {
        paddingLeft: "70px",
        paddingRight: "70px",
      },
      "@media(max-width: 1024px)": {
        paddingTop: "40px",
        paddingBottom: "40px",
      },
      "@media(max-width: 768px)": {
        paddingLeft: "37px",
        paddingRight: "37px",
      },
      "@media(max-width: 600px)": { paddingTop: "24px" },
      "@media(max-width: 400px)": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    termsAndPrivacyTitle: {
      fontSize: "24px",
      fontWeight: 800,
      color: Colors.TextBlack,
    },
    termsAndPrivacyTextBoxStyle: {
      width: "100%",
      padding: "40px",
      marginTop: "48px",
      backgroundColor: Colors.BackgroundWhiteOff,
      borderRadius: "12px",
      "@media(max-width: 1024px)": { marginTop: "40px" },
      "@media(max-width: 600px)": {
        marginTop: "24px",
        padding: "24px",
      },
    },
    termsAndPrivacyTextStyle: {
      fontSize: "16px",
      fontWeight: 500,
      color: Colors.TextGray,
      lineHeight: "24px",
      "&:not(:last-of-type)": { marginBottom: "24px" },
    },
    termsAndPrivacyPStyle: {
      fontSize: "16px",
      fontWeight: 500,
      color: Colors.TextGray,
      lineHeight: "24px",
    },
  }),
  { index: 1 }
);
