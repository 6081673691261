import { Tooltip } from "@mui/material";
import { withStyles } from '@material-ui/core/styles';
import {useRef} from "react";

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: 396,
        // background: Colors.text,
        height: "auto",
        display: "flex",
        alignItems: "center",
        borderRadius: "6px",
        fontSize: "14px",
        // color: Colors.white,
        padding: "6px 16px",
    },
}))(Tooltip);

export const TextRow = ({ name, textWidth }) => {
    const text = (name ? name : 'Not Set') + '';
    const ref = useRef();
    return (
        <div style={{width:'100%'}} ref={ref}>
            {RenderText(ref?.current?.offsetWidth, text, textWidth)}
        </div>
    );
}

export const RenderText = (width, text, textWidth) => {
    if (text) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + text?.slice(0, count);
        return (
            <div style={{width:'100%'}} className="text-style">
                {text?.length > count ? (
                    <HtmlTooltip title={<p>{text}</p>} placement="top-start">
                        <p style={{ textOverflow: 'ellipsis' }}>{`${slicedText}...`}</p>
                    </HtmlTooltip>
                ) : (
                    <p>{text}</p>
                )}
            </div>
        );
    } else {
        return '...';
    }
};
