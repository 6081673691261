import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Loader,
    MyProfileWrapper,
    UserActionButton,
    UserInput,
    FilteringWithSearchBoxDropdown,
    MaskInput
} from 'components';
import {brokerActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from 'store';
import {EmailValidator, FindError, FindLoad, FindSuccess, getPhoneErrorText} from "utils";
import {userProfileStyle} from './styles';
import {AvatarContext} from "../../../utils/contexts/avatarContext";
import {inputStyle} from "../../../components/inputs/style";

const ACTION_TYPE = 'EDIT_BROKER'

export const BrokerProfile = () => {
    const user = useSelector((state) => state.users.userById);
    const dispatch = useDispatch();
    const isLoading = FindLoad('GET_USER_BY_ID');
    const editLoad = FindLoad(ACTION_TYPE);
    const userBackError = FindError(ACTION_TYPE);
    const userSuccess = FindSuccess(ACTION_TYPE);
    const avatarCtx = useContext(AvatarContext);
    const {avatar} = avatarCtx;
    const inputClasses = inputStyle();
    const classes = userProfileStyle();
    const [inputs, setInputs] = useState({});
    const [show, setShow] = useState(true)
    const [error, setError] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const errorMsg = 'This field must be not empty!';
    const phoneErrorMsg = getPhoneErrorText(inputs.phone);
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? 'Email must be an email!' : '';
    const phoneErrorText =
        error === 'phone' ? errorMsg : userBackError.length && userBackError[0].error[0] === 'phone must be a valid phone number' ? 'Phone must be a valid phone number' : '';
    const emailErrorText =
        error === 'email' ? errorMsg : userBackError.length && userBackError[0]?.error === 'User already exists' ? 'User already exists' :
            emailErrorMsg ? emailErrorMsg : '';

    useEffect(() => {
        if (userSuccess?.length) {
            setShow(true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }
    }, [userSuccess])

    useEffect(() => {
        if (user) {
            setInputs(user)
            setSelectedAddress(user?.countryOfService)
        }
    }, [user])

    const handleChange = (evt) => {
        setInputs((prevState) => ({...prevState, [evt.target.name]: evt.target.value,}));
        if (error === evt.target.name || error === phoneErrorMsg || error === emailErrorMsg || userBackError.length) {
            setError('');
        }
        if (userBackError.length) {
            dispatch(httpRequestsOnErrorsActions.removeError(userBackError.type));
        }
    };

    const handleCancel = () => {
        setShow(true)
        setError('')
        setInputs(user)
        setSelectedAddress(user?.countryOfService)
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE))
    }

    const handleSave = () => {
        // const phoneIsValid = !!inputs.phone && inputs.phone.trim().length >= 11 && !/[a-zA-Z]/g.test(inputs.phone);
        const emailIsValid = !!inputs.email && EmailValidator.test(inputs.email);
        const phone = inputs?.phone ? `${parseInt(inputs?.phone.replace(/[^0-9]/g, ''))}` : '';

        const userData = {
            firstName: inputs.firstName,
            lastName: inputs.lastName,
            phone: phone,
            email: inputs.email,
            company: inputs.company,
            licenseNumber: inputs.licenseNumber,
            countryOfService: selectedAddress,
            avatar: avatar,
            // aboutMe: inputs?.aboutMe ? inputs.aboutMe : ''
        };

        if(inputs?.aboutMe){
            userData.aboutMe = inputs?.aboutMe
        }

        if (inputs.firstName && inputs.lastName && emailIsValid && phone && inputs.company && selectedAddress && inputs.licenseNumber) {
            dispatch(brokerActions.editBroker(user?.id, userData));
        } else {
            setError(!inputs.firstName ? 'firstName' :
                !inputs.lastName ? 'lastName' :
                    !inputs.email ? 'email' :
                        !emailIsValid ? emailErrorMsg :
                            !inputs.phone ? 'phone' :
                                !phone ? phoneErrorMsg :
                                    !inputs.company ? 'company' :
                                        !selectedAddress ? 'countryOfService' :
                                            !inputs.licenseNumber ? 'licenseNumber' :
                                                !inputs.aboutMe ? 'aboutMe'
                                                    : 'Input is not field');
        }
    }

    const handleChangeCounty = (address) => {
        setSelectedAddress(address)
        setError('')
    }

    return isLoading?.length ? (
        <Loader/>
    ) : (
        <MyProfileWrapper user={user}>
            <div className={classes.myProfileStyle}>
                {show &&
                    <UserActionButton
                        butnClassName={classes.editUserButnStyle}
                        onUserAction={() => setShow(!show)}
                        butnLabel={'Edit'}
                    />
                }
                <div className={classes.userProfileInputsStyle}>
                    <div className={classes.brokerInputsWrapper}>
                        <div>
                            <UserInput
                                disabled={show}
                                inputClassName={classes.fullInput}
                                inputLabel={'First Name'}
                                inputType={'text'}
                                inputName={'firstName'}
                                inputValue={inputs.firstName}
                                onInputChange={handleChange}
                                inputError={error === 'firstName' && errorMsg}
                            />
                            <UserInput
                                disabled={show}
                                inputClassName={classes.fullInput}
                                inputLabel={'Last Name'}
                                inputType={'text'}
                                inputName={'lastName'}
                                inputValue={inputs.lastName}
                                onInputChange={handleChange}
                                inputError={error === 'lastName' && errorMsg}
                            />
                            <UserInput
                                disabled={show}
                                inputClassName={classes.fullInput}
                                inputLabel={'Company'}
                                inputType={'text'}
                                inputName={'company'}
                                inputValue={inputs.company}
                                onInputChange={handleChange}
                                inputError={error === 'company' && errorMsg}
                            />
                        </div>
                        <div>
                            <div style={{marginBottom: '8px', marginTop:'-4px'}}>
                                <MaskInput
                                    disables={show}
                                    name={'phone'}
                                    handleChange={handleChange}
                                    value={inputs?.phone}
                                    error={phoneErrorText}
                                    notRequired={true}
                                />
                            </div>
                            {/*<UserInput*/}
                            {/*    disabled={show}*/}
                            {/*    inputClassName={classes.fullInput}*/}
                            {/*    inputLabel={'Phone Number'}*/}
                            {/*    inputType={'number'}*/}
                            {/*    inputName={'phone'}*/}
                            {/*    inputValue={inputs.phone}*/}
                            {/*    onInputChange={handleChange}*/}
                            {/*    inputError={phoneErrorText}*/}
                            {/*/>*/}
                            <UserInput
                                disabled={show}
                                inputClassName={classes.fullInput}
                                inputLabel={'Email Address'}
                                inputType={'text'}
                                inputName={'email'}
                                inputValue={inputs.email}
                                onInputChange={handleChange}
                                inputError={emailErrorText}
                            />
                            <UserInput
                                disabled={show}
                                inputClassName={classes.fullInput}
                                inputLabel={'License Number'}
                                inputType={'number'}
                                inputName={'licenseNumber'}
                                inputValue={inputs.licenseNumber}
                                onInputChange={handleChange}
                            />
                        </div>
                    </div>

                    <div style={{width: '100%'}}>
                        <div style={{width: '100%'}}>

                            <div style={{margin: '-8px 0 0 0'}}>
                                <p className={classes.selectTitle} style={{margin: '4px 0 4px'}}>County of Service</p>
                                <FilteringWithSearchBoxDropdown
                                    disabled={show}
                                    searchType={'regions'}
                                    customClass={classes.customClass}
                                    error={error === 'countryOfService'}
                                    defaultSearchText={''}
                                    searchedAddress={selectedAddress}
                                    onRequest={(address) => handleChangeCounty(address)}
                                />
                                <div className={inputClasses.userInputErrorBoxStyle}>
                                    <p className={inputClasses.userInputErrorStyle}>{error === 'countryOfService' ? errorMsg : ''}</p>
                                </div>
                            </div>
                        </div>

                        <div style={{marginBottom: '24px'}}>
                            <p className={classes.selectTitle} style={{margin: '4px 0 0'}}>About Me</p>
                            <textarea
                                style={error === 'aboutMe' ? {border: '1px solid #FF385C'} : {}}
                                disabled={show}
                                className={classes.aboutMeArea}
                                value={inputs?.aboutMe}
                                name={'aboutMe'}
                                placeholder={'Write'}
                                onChange={handleChange}
                            />
                            <div className={inputClasses.userInputErrorBoxStyle}>
                                <p className={inputClasses.userInputErrorStyle}>{error === 'aboutMe' ? errorMsg : ''}</p>
                            </div>
                        </div>
                    </div>

                    {!show &&
                        <div className={classes.buttonsWrapper}>
                            <button
                                onClick={handleCancel}
                                className={classes.cancelBtn}
                            >Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                className={classes.saveBtn}
                            >
                                {editLoad?.length ?
                                    <Loader type={'mini'} color={'white'} position={'relative'}/>
                                    :
                                    'Save'
                                }
                            </button>
                        </div>
                    }
                </div>
            </div>
        </MyProfileWrapper>
    );
};
