import React from 'react';
import {Link} from 'react-router-dom';
import { getLimitedVal, Images } from 'utils';
import { headerCoresStyle } from './styles';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';

export const UserProfileMenu = ({
    onClickUserProfileMenu,
    userInfo,
    userProfileMenuIsShown,
    onHideUserProfileMenu,
    onHideMenu,
    menuIsShown,
}) => {
    const classes = headerCoresStyle();
    const userProfileName = `${userInfo?.firstName} ${userInfo?.lastName}`;
    const userProfileNameDisplay = menuIsShown ? getLimitedVal(userProfileName, 16) : getLimitedVal(userProfileName, 14);
    const dispatch = useDispatch();

    return (
        <>
            <div
                className={`${classes.userProfileMenuDropdownOverlayStyle} ${userProfileMenuIsShown ? '' : 'hide'}`}
                onClick={onHideUserProfileMenu}
            />
            <div className={`${classes.authButnStyle} ${!userInfo? 'authButnDontUser':''}`} onClick={onClickUserProfileMenu} title={userProfileName}>
                {userInfo ? (
                    <>
                        <div className={classes.myDropdownContainer}>
                            <div className={classes.userProfileStyle}>
                                <img src={Images.user} alt="user" />
                            </div>
                            <span className={menuIsShown ? 'active' : ''}>
                                {userProfileNameDisplay}
                            </span>
                            <div className={classes.dropdownArrowStyle}>
                                <img src={userProfileMenuIsShown ? Images.dropdownArrowBlack : Images.dropdownArrow} alt="dropdown arrow" />
                            </div>
                        </div>
                        <div className={`${classes.menuDropdownStyle} ${userProfileMenuIsShown ? 'show' : ''}`}>
                            <ul className={classes.userMenuListStyle}>
                                <li>
                                    <Link to="/my_profile" className="myProfile" onClick={onHideMenu}>
                                        My Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/my_listings" className="myListings" onClick={onHideMenu}>
                                        My Listings
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/promotions" className="promotions" onClick={onHideMenu}>
                                        Promotions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/billing" className="billings" onClick={onHideMenu}>
                                        Billing
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="*"
                                        className="logOut"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                            dispatch(authActions.logOut());
                                            onHideMenu();
                                        }}>
                                        Log Out
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </>
                ) : (
                    <div className={classes.userSignInStyle} onClick={onHideMenu}>
                        <div className={classes.userProfileStyle}>
                            <img src={Images.user} alt="user" />
                        </div>
                        <span className={classes.signInTextStyle}> sign in </span>
                    </div>
                )}
            </div>
        </>
    );
};
