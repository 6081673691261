import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const howItWorksFragmentsStyles = makeStyles(() => (
    {
        stepsWrapper: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
        },
        singleStepWrapper: {
            display: 'flex',
            gap: '24px',
        },
        stepCountAndDot: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px'
        },
        stepCount: {
            width: '48px',
            height: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '18px',
            fontWeight: '800',
            lineHeight: '28px',
            color: `${Colors.ThemePink}`,
            background: `${Colors.TextWhite}`,
            borderRadius: '8px',
            boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.10)',
            padding: '10px',
        },
        stepDot: {
            height: '100%',
            border: '1px dashed #757280',
        },
        stepTitleAndDesc: {
            display: 'flex',
            flexDirection: 'column',
        },
        stepTitle: {
            fontSize: '24px',
            fontWeight: '800',
            lineHeight: '36px',
            color: `${Colors.TextBlack2}`,
        },
        stepSubTitle: {
            fontSize: '18px',
            fontWeight: '800',
            lineHeight: '24px',
            color: `${Colors.TextBlack2}`,
            marginTop: '16px',
        },
        stepDesc: {
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            color: `${Colors.TextBlack2}`,
            marginTop: '16px',
        },
        stepItemsDescWrapper: {
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
        },
        stepItemsDesc: {
            display: 'flex',
            alignItems: 'start'
        },
        stepItemsSvg: {
            minWidth: '24px',
            minHeight: '24px',
            marginRight: '10px'
        },
        stepItemsText: {
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
        },
        stepItemsTextBold: {
            fontWeight: '600',
            whiteSpace: 'nowrap'
        },
        megaPhoneWrapper: {
            display: 'flex',
            alignItems: 'start',
            gap: '12px',
            color: `${Colors.TextBlack2}`,
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '27px',
            margin: '-60px 0 32px auto',

            '@media(max-width: 1279px)': {
                margin: '0 0 32px auto',
            },
        },

        megaPhone: {
            minWidth: '33px',
            minHeight: '33px'
        },
    }
))