import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CustomBreadcrumbs, Loader } from 'components';
import { httpRequestsOnSuccessActions, listingActions } from 'store';
import { CreateListingInputs } from './core';
import { SET_LISTING_BY_ID } from '../../../store/listings/listings.types';
import { listingInputsStyle } from './core/styles';
import {FindError, FindLoad, FindSuccess} from 'utils';

export const CreateListing = () => {
    const location = window.location;
    const params = useParams();
    const _id = params.id;

    const dispatch = useDispatch();
    const classes = listingInputsStyle();

    const isEditListingPage = location.pathname === `/my_listings/edit_listing-${_id}`;

    const listingById = useSelector((state) => state.listings.listingById);

    const loader = FindLoad('GET_LISTING_BY_ID');

    useEffect(() => {
        if (isEditListingPage) {
            dispatch(listingActions.getListingById(_id));
            return () => {
                dispatch({
                    type: SET_LISTING_BY_ID,
                    payload: { listingById: null },
                });
            };
        }
    }, [isEditListingPage, _id]);

    return loader.length ? (
        <Loader />
    ) : (
        <>
            <CustomBreadcrumbs breadcrumbsClassName={classes.bgColorStyle} />
            <div>
                <CreateListingInputs info={listingById} />
            </div>
        </>
    );
};
