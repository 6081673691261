import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import axios from 'axios';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Checkbox} from '@material-ui/core';
import {promotionStyle} from "../styles";
import {paymentActions} from "../../../store/payment";
import {paymentAuthService} from "../../../store/payment/payment.service";
import {Loader, PaymentStartEnd} from "../../../components";
// import { MinLoader, useModal } from 'components';
// import { PaymentActions, services } from 'store';
// import { MODAL_NAMES } from 'utils';

const cardElementOptions = {
    style: {
        base: {
            color: '#666',
            fontSize: '16px',
        },
        invalid: {
            color: '#fa755a',
            fontSize: '16px',
        },
    },
};

const CheckoutForm = ({handleClose, handlePay, edit, selectedItem}) => {
    const history = useHistory()
    const stripe = useStripe();
    const elements = useElements();
    const classes = promotionStyle()
    const dispatch = useDispatch()
    const [checkoutError, setCheckoutError] = useState();
    const [loader, setLoader] = useState(false);
    const [cardError, setCardError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        setCardError('');

        try {
            const paymentMethod = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement('card'),
            });

            await axios.patch('/billing', {'pmtMethod': paymentMethod?.paymentMethod?.id}, {auth: true}).then(() => {
                const inter = setInterval(() => {
                    dispatch(paymentActions.getPayment());
                    paymentAuthService.getPaymentService().then((res) => {
                        if (edit !== true) {
                            handlePay()
                        }
                        setLoader(false);
                        clearInterval(inter);
                    });
                }, 2000);
            }).catch((e) => {
                    setCardError(e?.data?.message);
                    setLoader(false);
                },
            );
        } catch (err) {
            setCheckoutError(err.message);
            setLoader(false);
        }
    };

    return (
        <div className='form-wrapper'>
            <form onSubmit={handleSubmit}>
                <div>
                    <p className={classes.cardNumberTitle}><span>*</span>Card Number</p>
                    <div className={classes.cardElement}>
                        <CardElement options={cardElementOptions}/>
                    </div>
                    {checkoutError && <span style={{color: 'red', fontSize: '12px'}}>{checkoutError}</span>}
                </div>
                {cardError && <p style={{color: '#fa755a', marginTop: '10px'}}>{
                    cardError === 'Can not create customer Error: Your card\'s security code is incorrect.' ? 'Your card\'s security code is incorrect.' :
                        cardError === 'Can not create customer Error: Your card has expired.' ? 'Your card has expired.' :
                            cardError === 'Can not create customer Error: An error occurred while processing your card. Try again in a little bit.' ? 'An error occurred while processing your card. Try again in a little bit.' :
                                cardError === 'Can not create customer Error: Your card was declined.' ? 'Your card was declined.' :
                                    cardError === 'This customer has no attached payment source or default payment method. Please consider adding a default payment method. For more information, visit https://stripe.com/docs/billing/subscriptions/payment-methods-setting#payment-method-priority.' ?
                                        'Input is not field' :
                                        cardError}
                </p>
                }
                {edit !== true &&
                    <>
                        <PaymentStartEnd
                            startDate={selectedItem?.promotion?.startDate}
                            endDate={selectedItem?.promotion?.endDate}
                            renewPromotion={selectedItem?.promotion?.status === "PROMOTED"}
                        />
                        <div className={classes.totalAmount}>
                            <p>Total Amount</p>
                            <span>$15</span>
                        </div>
                    </>
                }
                <div style={edit === true ? {marginTop: '32px'} : {}} className={classes.spaceBetween}>
                    {/*<button*/}
                    {/*    type='button'*/}
                    {/*    className={classes.cancel}*/}
                    {/*    onClick={ () => router.push("/")}*/}
                    {/*>*/}
                    {/*    Cancel*/}
                    {/*</button>*/}
                    <button type='submit' className={classes.pay}>
                        {loader ?
                            <Loader type={'mini'} color={'white'}/>
                            :
                            edit === true ? 'Save' : 'Pay'
                        }</button>
                </div>
            </form>
        </div>
    );
};

export default CheckoutForm;