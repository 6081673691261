import React from 'react';
import {Svg} from "assets/images";
import {howItWorksFragmentsStyles} from "./styles";

export const Step4 = () => {
    const classes = howItWorksFragmentsStyles();

    return (
        <>
            <div className={classes.megaPhoneWrapper}>
                <Svg.MegaphoneSvg className={classes.megaPhone}/>
                Promoting your listing can help you reach more potential buyers or renters by
                increasing its visibility on Croill. Here's how;
            </div>

            <div className={classes.stepsWrapper}>
                <div className={classes.singleStepWrapper}>
                    <div className={classes.stepCountAndDot}>
                        <div className={classes.stepCount}>
                            1
                        </div>
                        <div className={classes.stepDot}/>
                    </div>

                    <div className={classes.stepTitleAndDesc}>
                        <h4 className={classes.stepTitle}>
                            Step 1
                        </h4>

                        <p className={classes.stepDesc}>
                            <span className={classes.stepSubTitle}>Access your profile</span>
                        </p>

                        <div style={{marginTop: '20px'}} className={classes.stepItemsDescWrapper}>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                                <p className={classes.stepItemsText}>
                                    Go to the <span className={classes.stepItemsTextBold}>“Homepage”</span>.
                                </p>
                            </div>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                                <p className={classes.stepItemsText}>
                                    Click on your profile name at the top right corner of the page. This action will
                                    direct
                                    you to your profile and various personal sections.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.singleStepWrapper}>
                    <div className={classes.stepCountAndDot}>
                        <div className={classes.stepCount}>
                            2
                        </div>
                        <div className={classes.stepDot}/>
                    </div>

                    <div className={classes.stepTitleAndDesc}>
                        <h4 className={classes.stepTitle}>
                            Step 2
                        </h4>

                        <p className={classes.stepDesc} style={{marginBottom:'8px'}}>
                            Go to the <span style={{fontWeight:'800'}}>“Promotions”</span> section
                        </p>

                        <p style={{margin: '0'}} className={classes.stepDesc}>
                            Within your profile, locate and select the <span
                            className={classes.stepItemsTextBold}>"Promotions"</span> section.
                            This area allows you to manage the marketing of your listings.
                        </p>
                    </div>
                </div>
                <div className={classes.singleStepWrapper}>
                    <div className={classes.stepCountAndDot}>
                        <div className={classes.stepCount}>
                            3
                        </div>

                        <div className={classes.stepDot}/>
                    </div>

                    <div className={classes.stepTitleAndDesc}>
                        <h4 className={classes.stepTitle}>
                            Step 3
                        </h4>

                        <p className={classes.stepItemsText}>
                            Choose a listing to promote from the
                            <span className={classes.stepItemsTextBold}> "Non Promoted"</span>
                            section.
                        </p>

                        <div style={{marginTop: '20px'}} className={classes.stepItemsDescWrapper}>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                                <p className={classes.stepItemsText}>
                                    Identify the listing you want to improve from the
                                    <span style={{margin: '0 5px'}}
                                          className={classes.stepItemsTextBold}>"Non Promoted"</span>
                                    section.
                                </p>
                            </div>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                                <p className={classes.stepItemsText}>
                                    Click the
                                    <span className={classes.stepItemsTextBold} style={{margin: '0 5px'}}>
                                "Promote"
                                </span>
                                    button next to the listing you wish to push forward.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.singleStepWrapper}>
                    <div className={classes.stepCountAndDot}>
                        <div className={classes.stepCount}>
                            4
                        </div>
                        <div className={classes.stepDot}/>
                    </div>

                    <div className={classes.stepTitleAndDesc}>
                        <h4 className={classes.stepTitle}>
                            Step 4
                        </h4>

                        <p className={classes.stepSubTitle}>
                            Set up payment
                        </p>

                        <div style={{marginTop: '20px'}} className={classes.stepItemsDescWrapper}>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                                <p className={classes.stepItemsText}>
                                    Upon selecting <span className={classes.stepItemsTextBold}>"Promote"</span>, a payment window will appear:
                                </p>
                            </div>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                                <p className={classes.stepItemsText}>
                                    Fill in your card number to proceed with the payment.
                                </p>
                            </div>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                                <p className={classes.stepItemsText}>
                                    You'll also see the cost of the promotion, along with the start and end dates of the
                                    promotional period.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={classes.singleStepWrapper}>
                    <div className={classes.stepCountAndDot}>
                        <div className={classes.stepCount}>
                            5
                        </div>
                    </div>

                    <div className={classes.stepTitleAndDesc}>
                        <h4 className={classes.stepTitle}>
                            Step 5
                        </h4>

                        <p className={classes.stepSubTitle}>
                            Confirm and pay
                        </p>

                        <div style={{marginTop: '20px'}} className={classes.stepItemsDescWrapper}>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                                <p className={classes.stepItemsText}>
                                    Double-check all the entered details to ensure accuracy.
                                </p>
                            </div>
                            <div className={classes.stepItemsDesc}>
                                <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                                <p className={classes.stepItemsText}>
                                    Click the <span className={classes.stepItemsTextBold}>"Pay"</span> button to finalize your promotion.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

