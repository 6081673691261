import React, { useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import { ListingsCardItem, CustomMarker } from './core';
import { listingsCardStyle } from './style';
import {getValDependsOnLength, Images, ViewAsContext, FindLoad, ListingFiltersContext} from 'utils';
import { Loader } from 'components';

const defaultMapOptions = {
    fullscreenControl: false,
    keyboardShortcuts: false,
};

const GOOGLE_MAPS_API_KEY = '';

export const ListingsCard = ({ listingList }) => {
    const classes = listingsCardStyle();
    const listingFilterLoader = FindLoad('GET_LISTINGS_BY_FILTERS');
    const userListingFilterLoader = FindLoad('GET_LISTINGS_BY_USER_ID_FILTER');
    const { view } = useContext(ViewAsContext);
    const { mapCenter, zoom, handleLatLngRequest, handleZoomRequest } = useContext(ListingFiltersContext);
    const listingsCardClassName =
        view === 'gallery'
            ? classes.listingsCardStyle
            : view === 'list'
            ? classes.listingsCardListViewStyle
            : view === 'map'
            ? classes.listingsCardMapViewStyle
            : '';



    return (
        <div className={classes.listingsCardBoxStyle}>
            <div className={classes.listingsCardBoxContainerStyle}>
                {(!!listingFilterLoader.length || !!userListingFilterLoader.length) && view !== 'map' ? (
                    <Loader />
                ) : !listingList.length && view !== 'map' ? (
                    <div className={classes.noListingsFoundStyle}>
                        <div className={classes.noListingsFoundContainerStyle}>
                            <div className={classes.errorImageStyle}>
                                <img src={Images.noListingsFound} alt="" />
                            </div>
                            <div className={classes.errorMsgContainerStyle}>
                                <h2 className={classes.errorMsgTitleStyle}>Whoops! No listings found</h2>
                                <p className={classes.errorMsgTextStyle}> Please try again. </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={listingsCardClassName}>
                        {view === 'map' ? (
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                                zoom={zoom}
                                center={{ lat: parseFloat(mapCenter.lat), lng: parseFloat(mapCenter.lng) }}
                                options={defaultMapOptions}
                                onDragEnd={(mapProps) => {
                                    handleLatLngRequest(mapProps.center.lat().toString(), mapProps.center.lng().toString());
                                }}
                                onZoomAnimationEnd={(zoomInteger) => handleZoomRequest(zoomInteger)}>
                                {listingList.map((listing, index) => (
                                    <CustomMarker key={index} lat={listing.address.lat} lng={listing.address.lng} listing={listing} />
                                ))}
                            </GoogleMapReact>
                        ) : (
                            listingList.map((listing, index) => {
                                const { address, price, size, mainImage } = listing;
                                const { street } = address;
                                const listingPrice = getValDependsOnLength(price);
                                const listingMinSize = getValDependsOnLength(size.start ? size.start : '');
                                const listingMaxSize = getValDependsOnLength(size.end && size.end !== '0' ? size.end : '');

                                // if( listing?.promotion?.status === PROMOTED) {
                                    return (
                                        <ListingsCardItem
                                            key={index}
                                            listingId={listing._id}
                                            mainImage={mainImage?.url}
                                            address={address}
                                            street={street}
                                            price={listingPrice}
                                            size={{minSize: listingMinSize, maxSize: listingMaxSize}}
                                            item={listing}
                                        />
                                    );
                                // }
                            })
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
