import React from 'react';
import {howItWorksFragmentsStyles} from "./styles";
import {Svg} from "assets/images";

export const Step2 = () => {
    const classes = howItWorksFragmentsStyles();

    return (
        <div className={classes.stepsWrapper}>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        1
                    </div>
                    <div className={classes.stepDot}/>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 1
                    </h4>

                    <p className={classes.stepDesc}>
                        Log in to your account and click <span className={classes.stepItemsTextBold}>"Create Listing"</span>. This will take you to the listing creation
                        page.
                    </p>
                </div>
            </div>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        2
                    </div>
                    <div className={classes.stepDot}/>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 2
                    </h4>

                    <p className={classes.stepSubTitle}>
                        Fill out required information
                    </p>

                    <p className={classes.stepDesc}>
                        Begin by filling out the information about your listing. Here are the fields you’ll need to
                        complete:
                    </p>

                    <div style={{marginTop: '35px'}} className={classes.stepItemsDescWrapper}>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                           Property type:
                                            </span>
                                Choose from retail, office, industrial, or other.
                            </p>
                        </div>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                               Address:
                                            </span>
                                Provide the full address of the property.
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                       Size range:
                                            </span>
                                Specify the size of the property in square feet or other applicable units.
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                              Price per sq. ft.:
                                            </span>
                                Set the price per square foot or your preferred pricing unit.
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                         Listing number:
                                            </span>
                                Auto-Generate a unique number to your listing for easy reference.
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                        Lease type:
                                            </span>
                                Select from gross, NNN, or other lease types.
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                    Zoning:
                                            </span>
                                Include the zoning information relevant to your property.
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                 Lot size:
                                            </span>
                                Specify the size of the lot
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                     Parking:
                                            </span>
                                Detail the parking options available
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                     Description:
                                            </span>
                                Write a compelling description that highlights key features and advantages of the
                                property.
                            </p>
                        </div>

                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                   Listing images:
                                            </span>
                                Upload high-quality images to showcase the property. Ensure the photos are well-lit and
                                cover various angles and key areas.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        3
                    </div>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 3
                    </h4>

                    <p className={classes.stepSubTitle}>
                        Review and publish your listing
                    </p>

                    <div style={{marginTop: '35px'}} className={classes.stepItemsDescWrapper}>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                            <p className={classes.stepItemsText}>
                                Review all the information you’ve entered for completeness. This is important to
                                provide possible clients get a clear picture of what’s being offered.
                            </p>
                        </div>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                When you’re satisfied, click the “Create Listing” button to publish your
                                listing on Croill.
                            </p>
                        </div>
                    </div>

                    <p className={classes.stepDesc}>
                        Your property is now active on Croill and available for potential buyers and tenants to view.
                    </p>
                </div>
            </div>
        </div>
    );
};