import React, { useContext } from "react";
import { Logo, CloseModalButton } from "components";
import { wrappersStyle } from "./style";
import { Images, RegisterContext } from "utils";

export const RegisterModalWrapper = ({ onClose, children }) => {
  const classes = wrappersStyle();

  const registerCtx = useContext(RegisterContext);
  const { modalContent } = registerCtx;

  return (
    <div className={classes.registerWrapperStyle}>
      <CloseModalButton
        onCloseModal={onClose}
        butnLabel={<img src={Images.registerModalClose} alt="" />}
        butnClassName={classes.closeModalButnStyle}
      />
      {modalContent === "profilePicture" ||
        modalContent === "changePassword" ||
        (modalContent === "deleteAccount" ||
        modalContent === "deleteListing" ? null : (
          <div className={classes.wrapperLeftSideStyle}>
            {/*<Logo logoClassName={classes.logoInModalStyle} />*/}
            {/*<div className={classes.wrapperImageStyle}>*/}
            {/*  <img*/}
            {/*      style={{width:'232px', height:'392px'}}*/}
            {/*      src={Images.signUpRectangle} alt="" />*/}
            {/*</div>*/}
          </div>
        ))}
      <div
          style={modalContent === 'signUp' ? {overflow:'auto'} : {}}
          className={classes.wrapperRightSideStyle}>{children}</div>
    </div>
  );
};
