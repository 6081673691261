import { Images, Colors } from 'utils';
import { makeStyles } from '@material-ui/core/styles';

export const commonStyle = makeStyles(
    () => ({
        // ** general info style **
        propTypeBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: '24px',
            '@media(max-width: 600px)': { flexWrap: 'wrap' },
        },
        propertyTypeStyle: {
            width: '100%',
            '&.active': {
                marginRight: '24px',
                marginBottom: '9px',
                '@media(max-width: 600px)': { marginRight: '0' },
            },
        },
        requiredLabelStyle: {
            marginBottom: '4px',
            fontSize: '16px',
            fontWeight: 700,
            color: Colors.TextBlack,
            textTransform: 'capitalize',
            display: 'flex',
            '&::before': {
                content: "'*'",
                color: '#FF385C',
            },
        },
        errorStyle: {
            fontSize: '12px',
            fontWeight: 500,
            color: '#FF385C',
            padding: '4px 16px',
        },
        listingInputStyle: {
            maxWidth: '100%',
            '&:not(:last-of-type)': { marginBottom: '24px' },
            '& > label > input': {
                width: '100%',
                height: '40px',
            },
        },
        sizeRangeStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: '24px',
            '& > div': { marginBottom: '0px !important' },
            '& > div:last-of-type': { marginLeft: '24px' },
        },
        // ** end **

        // ** property details **
        leaseTypeAndNPriceStyle: {
            width: '100%',
            display: 'flex',
            marginBottom: '24px',
            '@media(max-width: 600px)': {
                flexWrap: 'wrap',
            },
        },
        leaseTypeStyle: {
            width: '100%',
            '&.active': {
                marginRight: '24px',
                '@media(max-width: 600px)': {
                    marginRight: '0',
                    marginBottom: '24px',
                },
            },
        },
        labelStyle: {
            marginBottom: '4px',
            fontSize: '16px',
            fontWeight: 700,
            color: Colors.TextBlack,
            textTransform: 'capitalize',
        },
        zoningAndLotSizeStyle: {
            width: '100%',
            display: 'flex',
            '& > div:last-of-type': { marginLeft: '24px' },
            '@media(max-width: 600px)': {
                flexWrap: 'wrap',
                '& > div:last-of-type': {
                    marginLeft: '0px',
                    marginBottom: '24px',
                },
            },
        },
        // ** end **

        // ** contact info style **
        userInfoStyle: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '26px',
        },
        userProfileImageStyle: {
            width: '52px',
            height: '52px',
            borderRadius: '50%',
            objectFit: 'cover',
        },
        userFullNameStyle: {
            fontSize: '20px',
            fontWeight: 600,
            color: '#3377CC',
            textTransform: 'capitalize',
            marginLeft: '26px',
        },
        profileInfoBox: {
            maxWidth: '100%',
            '&:not(:last-of-type)': { marginBottom: '24px' },
        },
        profileLabelStyle: {
            marginBottom: '4px',
            fontSize: '16px',
            fontWeight: 700,
            color: Colors.TextBlack,
            textTransform: 'capitalize',
            display: 'flex',
        },
        profileInputStyle: {
            width: '100%',
            height: '40px',
            padding: '10px 16px',
            borderRadius: '8px',
            backgroundColor: Colors.BackgroundWhiteOff,
        },
        infoStyle: {
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextBlack,
            '&.active': { color: '#757280' },
        },
        // ** end **

        // ** description style **
        descInfoStyle: {
            padding: '4px 16px',
            fontSize: '12px',
            fontWeight: 500,
            color: Colors.TextGray,
        },
        richInputStyle: {
            '& .RichTextEditor__root___2QXK-': {
                width: '100% !important',
                height: '190px !important',
                backgroundColor: '#FFFFFF !important',
                border: '1px solid #757280B3 !important',
                borderRadius: '8px !important',
            },
            '&.error .RichTextEditor__root___2QXK-': {
                borderColor: '#FF385C !important',
            },
            '& .RichTextEditor__paragraph___3NTf9': {
                margin: '0 !important',
            },
            '& .EditorToolbar__root___3_Aqz': {
                backgroundColor: '#F4F4F4 !important',
                borderRadius: '7px 7px 0 0 !important',
                padding: '8px 0px !important',
                display: 'flex !important',
                flexWrap: 'wrap !important',
                alignItems: 'baseline !important',
                margin: '0 !important',
            },
            '& .ButtonGroup__root___3lEAn': {
                borderRight: '1px solid #757280 !important',
                margin: '0 !important',
                height: '24px !important',
            },
            '& .ButtonWrap__root___1EO_R button': {
                border: 'none',
                outline: 'none',
                background: 'none',
                marginLeft: '16px',
                opacity: 0.6,
            },
            '& .ButtonWrap__root___1EO_R button.IconButton__isActive___2Ey8p': {
                opacity: 1,
            },
            '& .RichTextEditor__editor___1QqIU .public-DraftEditor-content, .RichTextEditor__editor___1QqIU .public-DraftEditorPlaceholder-root':
                {
                    width: '100% !important',
                    height: '105px !important',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    padding: '16px !important',
                    fontSize: '14px !important',
                    fontWeight: 500,
                    lineHeight: '20px',
                    color: '#2B273CB3 !important',
                },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderBottomStyle: 'none !important',
            },
        },
        listingImagesStyle: { width: '100%' },
        imageBoxStyle: {
            maxWidth: '302px',
            width: '100%',
            height: '346px',
            backgroundColor: '#FFFFFF1A',
            border: '1px dashed #75728080',
            borderRadius: '12px',
            margin: '-34px auto 0px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > div > img': {
                width: '50px',
                height: '50px',
                margin: '72px 0px 40px',
            },
            '@media(max-width: 600px)': { marginTop: '-18px' },
        },
        imageInfoStyle: {
            fontSize: '18px',
            fontWeight: 600,
            color: Colors.TextGray,
        },
        fileUploadLabelStyle: {
            display: 'block',
            fontSize: '14px',
            fontWeight: 500,
            color: '#3377CC',
            textDecoration: 'underline',
            margin: '99px 0px 40px',
            cursor: 'pointer',
            '& input': { display: 'none' },
        },
        listingImagesContainerStyle: { position: 'relative' },
        fileUploaderStyle: {
            width: '178px',
            height: '40px',
            backgroundColor: '#3377CC',
            borderRadius: '8px',
            fontSize: '16px',
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: Colors.TextWhite,
            '& input': { display: 'none' },
            position: 'absolute',
            top: '-48px',
            right: '4px',
            cursor: 'pointer',
            zIndex: 999,
            '@media(max-width: 600px)': {
                top: '-64px',
                right: 'unset',
            },
        },
        dragInfoStyle: {
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextGray,
            margin: '-24px 0px 40px',
            '@media(max-width: 600px)': {
                marginTop: '58px',
            },
        },
        coverImageStyle: {
            width: '302px',
            height: '346px',
            flexShrink: '0',
            borderRadius: '12px',
            overflow: 'hidden',
            position: 'relative',
            marginRight: '24px',
            '& > img, & > embed': {
                width: '302px',
                height: '346px',
                objectFit: 'cover',
                '@media(max-width: 1024px)': { width: '548px' },
                '@media(max-width: 730px)': {
                    width: '295px',
                    height: '338px',
                },
            },
            '@media(max-width: 1024px)': {
                marginBottom: '24px',
                marginRight: '0px',
                width: '548px',
            },
            '@media(max-width: 730px)': {
                width: '295px',
                height: '338px',
            },
        },
        coverButnStyle: {
            position: 'absolute',
            top: '12px',
            left: '12px',
            zIndex: 999,
            maxWidth: '143px',
            backgroundColor: Colors.BackgroundWhite,
            border: 'none',
            color: '#0273BB',
        },
        imagesContainerStyle: {
            width: '100%',
            display: 'flex',
            '@media(max-width: 1024px)': {
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        restImagesStyle: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '24px',
            '@media(max-width: 1024px)': { justifyContent: 'center' },
            '@media(max-width: 730px)': { gap: '17px' },
        },
        uploaderStyle: {
            width: '200px',
            height: '161px',
            backgroundColor: '#FFFFFF1A',
            border: '1px dashed #75728080',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '& img': {
                width: '24px',
                height: '24px',
            },
            '& input': { display: 'none' },
            '@media(max-width: 1024px)': {
                width: '262px',
                height: '222px',
            },
            '@media(max-width: 730px)': {
                width: '139px',
                height: '118px',
            },
        },
        restImageStyle: {
            position: 'relative',
            borderRadius: '12px',
            overflow: 'hidden',
            width: '200px',
            height: '161px',
            '@media(max-width: 1024px)': {
                width: '262px',
                height: '222px',
            },
            '@media(max-width: 730px)': {
                width: '139px',
                height: '118px',
            },
            '& div.fileOverlay': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
            },
            '& > img, & > embed': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            },
        },
        closerButnStyle: {
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 999,
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            cursor: 'pointer',
        },
        closerStyle: {
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 999,
            width: '24px',
            height: '24px',
            backgroundImage: `url(${Images.closeImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            cursor: 'pointer',
        },
        // ** end **
    }),
    { index: 1 }
);
