import {Images, Colors} from "utils";
import {makeStyles} from "@material-ui/styles";

export const userProfileStyle = makeStyles(() => ({
        myProfileStyle: {position: "relative"},
        fullInput:{
            '& input':{
                width:'100% !important',

                "@media(min-width: 1280px)": {
                    width:'400px !important',
                }
            },
        },
        editUserButnStyle: {
            position: "absolute",
            top: "-48px",
            right: "0px",
            fontSize: "16px",
            fontWeight: 600,
            color: "#3377CC",
            display: "flex",
            alignItems: "center",
            "&::before": {
                content: "''",
                width: "24px",
                height: "24px",
                marginRight: "4px",
                backgroundImage: `url(${Images.edit})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
            },
        },
        userProfileInputsStyle: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
        },
        profileInfoBox: {
            maxWidth: "400px",
            width: "100%",
            marginRight: "32px",
            marginBottom: "24px",
            "@media(max-width: 1024px)": {
                maxWidth: "100%",
                marginRight: "0px",
            },
        },
        profileLabelStyle: {
            fontSize: "16px",
            fontWeight: 700,
            color: Colors.TextBlack,
            marginBottom: "4px",
        },
        profileInputStyle: {
            width: "100%",
            height: "40px",
            padding: "10px 16px",
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: "8px",
        },
        infoStyle: {
            fontSize: "14px",
            fontWeight: 600,
            color: Colors.TextBlack,
        },
        notSetStyle: {
            fontSize: "14px",
            fontWeight: 500,
            color: Colors.TextGray,
        },

        buttonsWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
        },
        cancelBtn: {
            width: '188px',
            height: '40px',
            background: 'rgba(117, 114, 128, 0.5)',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF',
            border: 'none',
            marginRight: '24px',
        },
        saveBtn: {
            width: '188px',
            height: '40px',
            background: '#0073BB',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF',
            border: 'none',
        },
        // sectionContent:{
        //     '@media(min-width: 320px)': {
        //         marginRight:'0',
        //     },
        //     '@media(min-width: 1025px)': {
        //         marginRight:'32px',
        //     },
        // },
        inputsWrapper:{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '@media(min-width: 320px)': {
                flexDirection:'column',
            },
            '@media(min-width: 1280px)': {
                flexDirection:'row',
            },

            '& .MuiOutlinedInput-root':{
                width: '100%!important',
                '@media(min-width: 1280px)': {
                    width: '400px!important',
                },
            },

            '& input':{
                width: '100%!important',

                '@media(min-width: 1280px)': {
                    width: '400px!important',
                },
            },
        },
        brokerInputsWrapper:{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '@media(min-width: 320px)': {
                flexDirection:'column',
            },
            '@media(min-width: 1280px)': {
                flexDirection:'row',
            }
        },
        selectTitle: {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
            margin: '0 0 4px'
        },
        selectInputWrapper: {
            width: '100%',
            height: '40px',
            background: '#F4F4F4',
            borderRadius: '8px',
            padding: '0 16px',
        },
        selectInput: {
            border: 'none',
            background: 'transparent',
            outline: 'none',
            height: '40px',
            width: '100%',
        },
        aboutMeArea: {
            border: '1px solid transparent',
            padding:'10px 16px',
            width: '100%',
            height: '168px',
            background: '#F4F4F4',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: Colors.TextGray,
            resize:'none',
            outline: 'none',
            '&:focus': {
                backgroundColor: Colors.BackgroundWhite,
                border: '1px solid #0073BB',
            },
        },

        customClass: {
            width: '100%',
            position: 'relative',
            marginTop: '4px',
            '& input': {
                borderRadius: '8px',
                height: '40px',
                padding:'0 16px',
            }
        },
    }),
    {index: 1}
);
