import React, {useEffect, useState, useContext} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {SendButton, UserInput, FilteringWithSearchBoxDropdown, MaskInput} from "components";
import {userRegistrationCoreStyles} from "./styles";
import {
    EmailValidator,
    FindError,
    FindLoad,
    FindSuccess,
    getPassErrorText,
    getPhoneErrorText,
    isNotEmpty,
    RegisterContext
} from "utils";
import {brokerActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from "store";
import {inputStyle} from "../../../../components/inputs/style";

export const BrokerSignUp = ({setAccountType}) => {
    const classes = userRegistrationCoreStyles();
    const inputClasses = inputStyle();
    const dispatch = useDispatch();
    const errorMsg = 'This field must be not empty!';
    const [inputs, setInputs] = useState({});
    const phoneErrorMsg = inputs?.phone ? getPhoneErrorText(inputs?.phone) : null;
    const emailErrorMsg = inputs?.email ? !EmailValidator.test(inputs?.email) ? 'Not valid email' : '' : '';
    const passwordErrorMsg = getPassErrorText(inputs.password);
    const [error, setError] = useState('');
    const userLoader = FindLoad('CREATE_BROKER');
    const userBackError = FindError('CREATE_BROKER');
    const userSuccess = FindSuccess('CREATE_BROKER');
    const [selectedAddress, setSelectedAddress] = useState('');
    const emailErrorText =
        error === 'email' ? errorMsg :
            emailErrorMsg ? emailErrorMsg :
                userBackError?.length && userBackError[0]?.error === 'Broker already exists' ? 'Broker already exists'
                    : '';
    const phoneErrorText =
        error === 'phone' ? errorMsg :
            phoneErrorMsg ? phoneErrorMsg :
                userBackError.length && userBackError[0].error[0] === 'phone must be a valid phone number' ? 'Phone must be a valid phone number' : '';
    const passwordErrorText = error === 'password' ? errorMsg : error === passwordErrorMsg ? passwordErrorMsg : '';
    const hasMinSixChars = isNotEmpty(inputs.password) && inputs.password.trim().length >= 8;
    const hasOneNum = isNotEmpty(inputs.password) && /\d/.test(inputs.password);
    const hasOneUppercase = isNotEmpty(inputs.password) && /[A-Z]/.test(inputs.password);
    const registerCtx = useContext(RegisterContext);
    const {handleModalContent} = registerCtx;



    useEffect(() => {
        if (userSuccess.length) {
            setAccountType('')
            handleModalContent('welcome');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(userSuccess.type));
        }
    }, [userSuccess]);

    const handleChange = (evt) => {
        setInputs((prevState) => ({...prevState, [evt.target.name]: evt.target.value,}));
        if (
            error === evt.target.name ||
            error === phoneErrorMsg ||
            error === emailErrorMsg ||
            error === passwordErrorMsg ||
            userBackError.length
        ) {
            setError('');
        }
        if (userBackError.length) {
            dispatch(httpRequestsOnErrorsActions.removeError(userBackError.type));
        }
    };

    const handleBrokerDataSend = () => {
        const passwordIsValid = !!inputs.password && hasMinSixChars && hasOneNum && hasOneUppercase;
        const emailIsValid = !!inputs.email && EmailValidator.test(inputs.email);
        const phone = inputs?.phone ? `${parseInt(inputs?.phone.replace(/[^0-9]/g, ''))}` : '';

        const userData = {
            firstName: inputs.firstName,
            lastName: inputs.lastName,
            email: inputs.email,
            phone: phone,
            company: inputs.company,
            countryOfService: selectedAddress,
            password: inputs.password,
            licenseNumber: inputs.licenseNumber,
        };

        if (inputs.firstName && inputs.lastName && emailIsValid && phone && inputs.company && selectedAddress && passwordIsValid && inputs.licenseNumber) {
            dispatch(brokerActions.createBroker(userData));
        } else {
            setError(!inputs.firstName ? 'firstName' :
                !inputs.lastName ? 'lastName' :
                    !inputs.email ? 'email' :
                        !emailIsValid ? emailErrorMsg :
                            !phone ? 'phone' :
                                !inputs.company ? 'company' :
                                    !selectedAddress ? 'countryOfService' :
                                        !inputs.password ? 'password' :
                                            !inputs.licenseNumber ? 'licenseNumber' :
                                                !passwordIsValid ? passwordErrorMsg
                                                    : 'Input is not field');
        }
    };

    const handleChangeCounty = (address) => {
        setSelectedAddress(address)
        setError('')
    }

    return (
        <div>
            <div className={classes.brokerInputsWrapperResponse}>
                <div className={classes.brokerInputsFirstPart}>
                    <UserInput
                        required={true}
                        inputClassName={classes.fullInput}
                        inputLabel={'First Name'}
                        inputType={'text'}
                        inputName={'firstName'}
                        inputValue={inputs.firstName}
                        onInputChange={handleChange}
                        inputError={error === 'firstName' && errorMsg}
                    />
                    <UserInput
                        required={true}
                        inputClassName={classes.fullInput}
                        inputLabel={'Last Name'}
                        inputType={'text'}
                        inputName={'lastName'}
                        inputValue={inputs.lastName}
                        onInputChange={handleChange}
                        inputError={error === 'lastName' && errorMsg}
                    />
                    <UserInput
                        required={true}
                        inputClassName={classes.fullInput}
                        inputLabel={'Email Address'}
                        inputType={'text'}
                        inputName={'email'}
                        inputValue={inputs.email}
                        onInputChange={handleChange}
                        inputError={emailErrorText}
                    />

                    <MaskInput
                        name={'phone'}
                        handleChange={handleChange}
                        value={inputs.phone}
                        error={phoneErrorText}
                    />
                </div>
                <div>
                    <UserInput
                        required={true}
                        inputClassName={classes.fullInput}
                        inputLabel={'Company'}
                        inputType={'text'}
                        inputName={'company'}
                        inputValue={inputs.company}
                        onInputChange={handleChange}
                        inputError={error === 'company' && errorMsg}
                    />
                    <div style={{margin: '-8px 0 8px 0'}}>
                        <p className={classes.inputTitle}><span>*</span> County of Service</p>
                        <FilteringWithSearchBoxDropdown
                            searchType={'regions'}
                            customClass={classes.customClass}
                            error={error === 'countryOfService'}
                            defaultSearchText={''}
                            searchedAddress={selectedAddress}
                            onRequest={(address) => handleChangeCounty(address)}
                        />
                        <div className={inputClasses.userInputErrorBoxStyle}>
                            <p className={inputClasses.userInputErrorStyle}>{error === 'countryOfService' ? errorMsg : ''}</p>
                        </div>
                    </div>
                    <UserInput
                        required={true}
                        inputClassName={classes.fullInput}
                        inputLabel={'Password'}
                        inputType={'password'}
                        inputName={'password'}
                        inputValue={inputs.password}
                        onInputChange={handleChange}
                        passwordInput={isNotEmpty(inputs.password)}
                        areaLabel={'create'}
                        inputError={passwordErrorText}
                        hasMinSixChars={hasMinSixChars}
                        hasOneNum={hasOneNum}
                        hasOneUppercase={hasOneUppercase}
                    />
                    <div style={{marginTop:'11x'}}>
                    <UserInput
                        required={true}
                        inputClassName={classes.fullInput}
                        inputLabel={'License Number'}
                        inputType={'text'}
                        inputName={'licenseNumber'}
                        inputValue={inputs.licenseNumber}
                        onInputChange={handleChange}
                        inputError={error === 'licenseNumber' && errorMsg}
                    />
                    </div>
                </div>
            </div>
            <>

                <div className={classes.signUpWrapper}>
                    <SendButton
                        butnClassName={classes.signUpButnStyle}
                        butnType={'button'}
                        butnDisabled={false}
                        butnSendingText={'sign up'}
                        loader={!!userLoader.length}
                        onClickButn={handleBrokerDataSend}
                    />
                </div>
                <p style={{maxWidth: '100%'}} className={classes.signUpAgreementStyle}>
                    By signing up, you agree to Croil's
                    <Link to="/termsAndConditions"
                        // onClick={closeModal}
                    >
                        Terms of Use
                    </Link>
                    and
                    <Link to="/privacyPolicy"
                        // onClick={closeModal}
                    >
                        Privacy Policy
                    </Link>
                </p>
            </>
        </div>
    )
}