import {promotionStyle} from "../../pages/promotion/styles";
import moment from "moment";

export const PaymentStartEnd = ({ startDate,endDate, renewPromotion }) => {
    const classes = promotionStyle()
    const newMount = moment().add(1, 'months').calendar()
    const endDayRenew = endDate ? moment(endDate).add(1, 'months').calendar() : ''
    const startDay = startDate ? moment(startDate).format('MM.DD.YYYY') : moment().format('MM.DD.YYYY')

    return(
        <div>
            <div className={classes.whiteWrapper} style={{flexDirection: 'column', marginTop: '24px'}}>
                <p className={classes.titleAndItem}>Price: <span>$15.00</span></p>
                <div className={classes.alignCenter} style={{justifyContent: 'space-between', marginTop: '16px'}}>
                    <p className={classes.titleAndItem}>Start Date:
                        <span>{renewPromotion === true ? startDay : moment().format('MM.DD.YYYY') }</span>
                    </p>
                    <p className={classes.titleAndItem}>End Date:
                        <span>{renewPromotion === true ? moment(endDayRenew).format('MM.DD.YYYY')  : moment(newMount).format('MM.DD.YYYY')}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}