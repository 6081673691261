import React, { useState, useEffect } from 'react';
import { ListingInputsWrapper } from 'components';
import { commonStyle } from './styles';
import { RichInput } from './richInput';
import RichTextEditorEdit from './richInputEdit';

export const Description = ({ info, inputs, onPass }) => {
    const classes = commonStyle();

    const [text, setText] = useState(info ? info.description : '');
    const [newText, setNewText] = useState('');

    useEffect(() => onPass(text, newText), [text, newText]);

    return (
        <ListingInputsWrapper inputsTitle={'Description'}>
            <p className={classes.labelStyle}> Description </p>
            <div className={classes.richInputStyle}>
                {info ? (
                    <RichTextEditorEdit text={text} onChange={(ev) => setNewText(ev)} />
                ) : (
                    <RichInput value={text} onChange={(ev) => setText(ev)} />
                )}
            </div>
            <span className={classes.descInfoStyle}> Max 500 characters </span>
        </ListingInputsWrapper>
    );
};
