export const CREATE_BROKER = "CREATE_BROKER";

export const GET_BROKERS = "GET_BROKERS";
export const GET_BROKERS_SUCCESS = "GET_BROKERS_SUCCESS";

export const EDIT_BROKER = "EDIT_BROKER";

export const DELETE_BROKER = "DELETE_BROKER";

export const GET_BROKER_BY_ID = "GET_BROKER_BY_ID";
export const GET_BROKER_BY_ID_SUCCESS = "GET_BROKER_BY_ID_SUCCESS";

export const GET_BROKER_MY_PROFILE = "GET_BROKER_MY_PROFILE";
export const GET_BROKER_MY_PROFILE_SUCCESS = "GET_BROKER_MY_PROFILE_SUCCESS";