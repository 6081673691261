import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'utils';

export const listingsCardStyle = makeStyles(
    () => ({
        listingsCardBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        listingsCardBoxContainerStyle: {
            maxWidth: '1440px',
            width: '100%',
            padding: '0px 80px 128px',
            '@media(max-width: 1200px)': {
                paddingLeft: '70px',
                paddingRight: '70px',
            },
            '@media(max-width: 768px)': {
                paddingLeft: '37px',
                paddingRight: '37px',
            },
            '@media(max-width: 400px)': {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        // ** gallery view style **
        listingsCardStyle: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            '@media(max-width: 1100px)': {
                justifyContent: 'center',
            },
            '@media(max-width: 795px)': {
                justifyContent: 'space-between',
            },
            '@media(max-width: 768px)': {
                justifyContent: 'center',
                flexDirection: 'column',
            },
            '@media(max-width: 375px)': {
                justifyContent: 'space-between',
            },
        },
        // ** end **

        // ** list view style **
        listingsCardListViewStyle: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media(max-width: 1180px)': {
                justifyContent: 'center',
            },
        },
        // ** end **

        // ** map view style **
        listingsCardMapViewStyle: {
            width: '100%',
            height: '753px',
            borderRadius: '16px',
            overflow: 'hidden',
            '@media(max-width: 1200px)': { height: '769px' },
            '@media(max-width: 650px)': { height: '440px' },
        },
        // ** end **

        // ** not found error style **
        noListingsFoundStyle: {
            width: '100%',
            margin: '100px 0',
            textAlign: 'center',
            '@media(max-width: 768px)': { margin: '80px 0' },
            '@media(max-width: 530px)': { margin: '60px 0' },
        },
        noListingsFoundContainerStyle: { width: '100%' },
        errorImageStyle: {
            maxWidth: '260px',
            width: '100%',
            margin: '40px auto',
            '& img': { width: '100%' },
        },
        errorMsgContainerStyle: { width: '100%' },
        errorMsgTitleStyle: {
            fontSize: '32px',
            fontWeight: 800,
            color: Colors.TextBlack,
            '@media(max-width: 530px)': { fontSize: '24px' },
            '@media(max-width: 360px)': { fontSize: '21px' },
        },
        errorMsgTextStyle: {
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextBlack,
            lineHeight: '68px',
        },
        // ** end **
    }),
    { index: 1 }
);
