import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        color: "black",
        height: '100%',
        padding: 26,
    },
    title: {
        margin: '10px 0',
        fountSize: '24px',
        fontWeight: 700,
    },
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    invoiceInfo: {
        backgroundColor: '#F5F7F9',
        width: '235px',
        height: 'auto',
        borderLeft: '1px solid red',
        padding: '8px 8px 8px 22px',
    },
    invoiceInfoTitle: {
        fontSize: '10px',
        display: 'flex',
        fontWeight: 400,
        marginBottom: '8px',
        color: '#324557',
    },
    firstColumn: {
        flexDirection: 'column',
        width: '50%',
        fontSize: '12px',
        color: '#324557',
        fontWeight: 400
    },
    bill: {
        fontWeight: '700',
        fontSize: '14px',
        color: '#324557',
        marginBottom: '8px',
    },
    to: {
        fontWeight: '600',
        fontSize: '12px',
        color: '#324557',
        marginBottom: '8px',
    },
    info: {
        fontWeight: '400',
        fontSize: '11px',
        color: '#324557',
    },
    tableHead: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '35px',
        backgroundColor: '#41586D',
        height: '31px',
        fontWeight: '400',
        fontSize: '10px',
        color: '#FFFFFF',
        padding: '8px 16px',
    },
    tableBody: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#F5F7F9',
        height: '31px',
        fontWeight: '400',
        fontSize: '10px',
        color: '#41586D',
        padding: '8px 16px',
    },
    information: {
        color: 'black',
        fontWeight: '600',
        fontSize: '10px',
    },
    amountTitle: {
        fontWeight: '400',
        fontSize: '10px',
        color: '#324557',
    },
    amountTitleBold: {
        fontWeight: '700',
        fontSize: '10px',
        color: '#324557',
    }
});

function BasicDocument(invoice) {
    const item = invoice?.invoice

    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.title}>
                        <Text>Invoice</Text>
                    </View>
                    <View style={styles.invoiceInfo}>
                        <Text style={styles.invoiceInfoTitle}>Invoice Number:
                            <Text style={styles.information}>{item?.invoiceNumber}</Text>
                        </Text>
                        <Text style={styles.invoiceInfoTitle}>Date of issue:
                            <Text style={styles.information}> {moment(item?.createdAt).format('MM.DD.YYYY')}</Text>
                        </Text>
                        <Text style={styles.invoiceInfoTitle}>Date due:
                            <Text style={styles.information}>{moment(item?.createdAt).format('MM.DD.YYYY')}</Text>
                        </Text>
                    </View>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: '16px'}}>
                        <View style={styles.firstColumn}>
                            <Text>Croill</Text>
                            <Text style={{margin: '2px 0'}}>345 Park Avenue</Text>
                            <Text style={{margin: '2px 0'}}>San Francisco CA 95110-2704</Text>
                            <Text>United States</Text>
                        </View>

                        <View style={{flexDirection: 'column', width: '50%'}}>
                            <Text style={styles.bill}>Billed to</Text>
                            <Text style={styles.to}>{item?.email}</Text>
                            {/*<Text style={styles.info}>2972 Westheimer Rd. Santa Ana, Illinois 85486 </Text>*/}
                        </View>
                    </View>

                    <View style={styles.tableHead}>
                        <Text style={{width: '70%'}}>Description</Text>
                        <Text style={{width: '20%'}}>Amount</Text>
                        <Text style={{width: '10%'}}>Total</Text>
                    </View>
                    <View style={styles.tableBody}>
                        <Text style={{width: '70%'}}>{item?.description}</Text>
                        <Text style={{width: '20%'}}>$15.00</Text>
                        <Text style={{width: '10%'}}>$15.00</Text>
                    </View>

                    <View style={{flexDirection: 'row', justifyContent: 'space-between',}}>
                        <View style={{width: '60%'}}/>
                        <View style={{
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            marginTop: '16px',
                            width: '40%'
                        }}>
                            <View style={{
                                flexDirection: 'row',
                                width: '83%',
                                justifyContent: 'space-between',
                                margin: '0 0 20px 0'
                            }}>
                                <Text style={styles.amountTitle}>Total</Text>
                                <Text style={styles.amountTitle}>$15.00</Text>
                            </View>
                            <View style={{flexDirection: 'row', width: '83%', justifyContent: 'space-between',}}>
                                <Text style={styles.amountTitleBold}>Amount due</Text>
                                <Text style={styles.amountTitleBold}>$15.00</Text>
                            </View>

                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
        ;
}

export default BasicDocument;