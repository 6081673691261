import React from "react";
import {useSelector} from "react-redux";
import {Invoices, PaymentMethod, billingStyles} from "./core";

export const BillingFragments = ({}) => {
    const classes = billingStyles()
    const payment = useSelector((state) => state.payment.myPayment);
    const invoices = useSelector((state) => state.payment.invoices);

    return (
        <div className={classes.billingFragmentsWrapper}>
            <PaymentMethod
                payment={payment}
            />
            <Invoices
                invoices={invoices}
            />
        </div>
    )
}