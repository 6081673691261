import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { withRouter } from "react-router-dom";
import { hideParamsId, makeCapitalize } from "utils";
import { customBreadcrumbsStyle } from "./styles";

export const CustomBreadcrumbs = withRouter(
  ({ history, location, breadcrumbsClassName }) => {
    const classes = customBreadcrumbsStyle();

    const pathnames = location.pathname
      .split("/")
      .filter((pathname) => pathname);

    return (
      <div
        className={`${classes.customBreadcrumbsStyle} ${breadcrumbsClassName}`}
      >
        <div className={classes.breadcrumbsContainerStyle}>
          <Breadcrumbs
            className={classes.breadcrumbsStyle}
            aria-label="breadcrumb"
          >
            {pathnames.length === 0 ? (
              <Typography className={classes.childStyle}> Home page </Typography>
            ) : (
              <Link
                className={classes.parentStyle}
                onClick={() => history.push("/")}
              >
                Home page
              </Link>
            )}
            {pathnames.map((pathname, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;

              return isLast ? (
                <Typography key={index} className={classes.childStyle}>
                  {makeCapitalize(hideParamsId(pathname))}
                </Typography>
              ) : (
                <Link
                  key={index}
                  className={classes.parentStyle}
                  onClick={() => history.push(routeTo)}
                >
                  {makeCapitalize(hideParamsId(pathname))}
                </Link>
              );
            })}
          </Breadcrumbs>
        </div>
      </div>
    );
  }
);
