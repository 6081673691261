import { Images, Colors } from 'utils';
import { makeStyles } from '@material-ui/core/styles';

export const listingDetailsStyle = makeStyles(
    () => ({
        listingDetailsBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        listingDetailsBoxContainerStyle: {
            maxWidth: '1440px',
            width: '100%',
            padding: '0px 80px 128px',
            '@media(max-width: 1200px)': {
                paddingLeft: '70px',
                paddingRight: '70px',
            },
            '@media(max-width: 768px)': {
                paddingLeft: '37px',
                paddingRight: '37px',
            },
            '@media(max-width: 400px)': {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        listingDetailsTitleStyle: {
            fontSize: '20px',
            fontWeight: 700,
            color: Colors.TextBlack,
        },
        propertyDetailsStyle: {
            width: '100%',
            marginTop: '44px',
            marginBottom: '80px',
            '@media(max-width: 1130px)': {
                marginTop: '64px',
                marginBottom: '64px',
            },
            '@media(max-width: 500px)': {
                marginTop: '48px',
                marginBottom: '48px',
            },
        },
        detailsListStyle: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: '32px',
            '@media(max-width: 1320px)': {
                justifyContent: 'space-between',
            },
            '& li': {
                marginRight: '25px',
                marginBottom: '16px',
                maxWidth: '400px',
                width: '100%',
                padding: '12px 16px',
                border: '1px solid #7572804D',
                backgroundColor: Colors.BackgroundWhite,
                borderRadius: '4px',
                '@media(max-width: 1440px)': {
                    maxWidth: '380px',
                },
                '@media(max-width: 1380px)': {
                    maxWidth: '360px',
                },
                '@media(max-width: 1320px)': {
                    maxWidth: '460px',
                    marginRight: 0,
                },
                '@media(max-width: 1110px)': {
                    maxWidth: '400px',
                },
                '@media(max-width: 990px)': {
                    maxWidth: '100%',
                    marginRight: 0,
                },
            },
        },
        propDetailTextStyle: {
            fontSize: '16px',
            fontWeight: 600,
            color: Colors.TextBlack,
            display: 'flex',
            alignItems: 'center',
            '&:before': {
                content: "''",
                display: 'block',
                width: '24px',
                height: '24px',
                marginRight: '8px',
                backgroundImage: `url(${Images.checkmark})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
        },
        propDetailStyle: {
            fontWeight: 500,
            color: Colors.TextGray,
            marginLeft: '8px',
        },
        contactDetailsStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            '@media(max-width: 1130px)': {
                flexWrap: 'wrap',
                justifyContent: 'center',
            },
        },
        decriptionBoxStyle: {
            maxWidth: '628px',
            width: '100%',
            marginRight: '25px',
            '@media(max-width: 1130px)': {
                maxWidth: '100%',
                marginRight: 0,
            },
        },
        listingDescriptionStyle: {
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextGray,
            lineHeight: '24px',
            marginTop: '16px',
        },
        locationBoxStyle: {
            maxWidth: '465px',
            width: '100%',
            '@media(max-width: 1130px)': {
                maxWidth: '100%',
                marginTop: '64px',
            },
        },
        googleMapBoxStyle: {
            height: '240px',
            borderRadius: '8px',
            overflow: 'hidden',
            marginTop: '16px',
            marginBottom: '24px',
            '@media(max-width: 1130px)': {
                height: '340px',
            },
            '@media(max-width: 768px)': {
                height: '240px',
            },
            '@media(max-width: 500px)': {
                height: '178px',
            },
        },
        pinMarkerStyle: {
            display: 'flex',
            width: '150px',
            '& img': {
                width: '32px',
                height: '32px',
            },
        },
        titleAndAddressMarkerStyle: {
            maxWidth: '150px',
            width: '100%',
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.ThemePink,
            lineHeight: '12px',
        },
        locationLinkStyle: {
            textDecoration: 'none',
            fontSize: '16px',
            fontWeight: 500,
            color: '#3377CC',
            display: 'inline-flex',
            alignItems: 'center',
            '&:before': {
                content: "''",
                display: 'block',
                width: '24px',
                height: '24px',
                marginRight: '8px',
                backgroundImage: `url(${Images.address})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
            },
        },
    }),
    { index: 1 }
);
