export const routerInfo = () => {
    return [
        {
            text: 'About Us',
            href: '/about-us',
        },
        {
            text: 'How It Works?',
            href: '/howItWorks',
        },
        {
            text: 'Listings',
            href: '/listings',
        },
    ]
}