import React, { useState, useEffect } from 'react';
import { userRegistrationCoreStyles } from './styles';
import { FindError, FindLoad, FindSuccess, getPassErrorText, isNotEmpty } from 'utils';
import { SaveOrCancelButton, UserInput } from 'components';
import { useDispatch } from 'react-redux';
import {authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions} from 'store';

export const ChangePassword = ({ onClose }) => {
    const classes = userRegistrationCoreStyles();
    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [error, setError] = useState('');

    const userBackError = FindError('CHANGE_PASSWORD_REQUEST');
    const loader = FindLoad('CHANGE_PASSWORD_REQUEST');
    const success = FindSuccess('CHANGE_PASSWORD_REQUEST');

    useEffect(() => {
        if (success.length) {
            onClose();
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CHANGE_PASSWORD_REQUEST'));
        }
    }, [success]);

    const errorMsg = 'This field must be not empty!';
    const newPasswordErrorMsg = getPassErrorText(inputs.newPassword);
    const confirmNewPasswordErrorMsg = inputs.confirmNewPassword !== inputs.newPassword ? 'Passwords do not match!' : '';
    const currentPasswordErrorText =
        error === 'currentPassword'
            ? errorMsg
            : userBackError.length && userBackError[0].error === 'user password does not match'
            ? 'The password you’ve entered is incorrect'
            : '';
    const newPasswordErrorText = error === 'newPassword' ? errorMsg : error === newPasswordErrorMsg ? newPasswordErrorMsg : '';
    const confirmNewPasswordErrorText =
        error === 'confirmNewPassword' ? errorMsg : error === confirmNewPasswordErrorMsg ? confirmNewPasswordErrorMsg : '';

    const hasMinSixChars = isNotEmpty(inputs.newPassword) && inputs.newPassword.trim().length >= 8;
    const hasOneNum = isNotEmpty(inputs.newPassword) && /\d/.test(inputs.newPassword);
    const hasOneUppercase = isNotEmpty(inputs.newPassword) && /[A-Z]/.test(inputs.newPassword);

    const handleChange = (evt) => {
        setInputs((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value,
        }));
        if(userBackError?.length){
            dispatch(httpRequestsOnErrorsActions.removeError('CHANGE_PASSWORD_REQUEST'))
        }
        if (error === evt.target.name || error === newPasswordErrorMsg || error === confirmNewPasswordErrorMsg) {
            setError('');
        }
    };

    const handlePasswordChange = () => {
        const changedPasswordData = {
            password: inputs.currentPassword,
            newPassword: inputs.newPassword,
            confirmation: inputs.confirmNewPassword,
        };
        const newPasswordIsValid = inputs.newPassword && hasMinSixChars && hasOneNum && hasOneUppercase;
        const confirmNewPasswordIsValid = inputs.confirmNewPassword && inputs.confirmNewPassword === inputs.newPassword;
        const errorText = !inputs.currentPassword
            ? 'currentPassword'
            : !inputs.newPassword
            ? 'newPassword'
            : !newPasswordIsValid
            ? newPasswordErrorMsg
            : !inputs.confirmNewPassword
            ? 'confirmNewPassword'
            : !confirmNewPasswordIsValid
            ? confirmNewPasswordErrorMsg
            : 'Input is not field';
        if (newPasswordIsValid && confirmNewPasswordIsValid) {
            dispatch(authActions.changePassword(changedPasswordData));
        } else {
            setError(errorText);
        }
    };

    return (
        <div className={classes.changePassStyle}>
            <UserInput
                required={true}
                inputClassName={'inModal'}
                inputLabel={'Current Password'}
                inputType={'password'}
                inputName={'currentPassword'}
                inputValue={inputs.currentPassword}
                onInputChange={handleChange}
                inputError={currentPasswordErrorText}
                passwordInput={isNotEmpty(inputs.currentPassword)}
            />
            <UserInput
                required={true}
                inputClassName={'inModal'}
                inputLabel={'New Password'}
                inputType={'password'}
                inputName={'newPassword'}
                inputValue={inputs.newPassword}
                onInputChange={handleChange}
                inputError={newPasswordErrorText}
                passwordInput={isNotEmpty(inputs.newPassword)}
                areaLabel={'create'}
                hasMinSixChars={hasMinSixChars}
                hasOneNum={hasOneNum}
                hasOneUppercase={hasOneUppercase}
            />
            <UserInput
                required={true}
                inputClassName={'inModal'}
                inputLabel={'Confirm New Password'}
                inputType={'password'}
                inputName={'confirmNewPassword'}
                inputValue={inputs.confirmNewPassword}
                onInputChange={handleChange}
                inputError={confirmNewPasswordErrorText}
                passwordInput={isNotEmpty(inputs.confirmNewPassword)}
            />
            <SaveOrCancelButton
                butnClassName={classes.changePasswordButnStyle}
                butnSavingText={'Save'}
                butnCancelling={'Cancel'}
                onClearAll={onClose}
                onSaveData={handlePasswordChange}
                loader={!!loader.length}
            />
        </div>
    );
};
