import arrowIcon from "./down-arrow.png";
import aboutUs from './aboutUs.png'
import whoWeAre from './whoWeAre.png'
import whyChooseImg from './whyChooseImg.png'

import {ReactComponent as CorrectSvg} from "./svg/CorrectSvg.svg";
import {ReactComponent as ArrowRightSvg} from "./svg/ArrowRightSvg.svg";
import {ReactComponent as ProfileSvg} from "./svg/ProfileSvg.svg";
import {ReactComponent as ListSvg} from "./svg/ListSvg.svg";
import {ReactComponent as AgentSvg} from "./svg/AgentSvg.svg";
import {ReactComponent as MegaphoneSvg} from "./svg/MegaphoneSvg.svg";
import {ReactComponent as DownRightArrow} from "./svg/DownRightArrow.svg";

export const Images = {
  // Name:Value
  arrowIcon: arrowIcon,
  aboutUs,
  whoWeAre,
  whyChooseImg
};

export const Svg = {
  CorrectSvg,
  ArrowRightSvg,
  ProfileSvg,
  ListSvg,
  AgentSvg,
  MegaphoneSvg,
  DownRightArrow
}