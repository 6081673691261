import { makeStyles } from "@material-ui/core/styles";

export const modalContentsStyle = makeStyles(
  () => ({
    filteringFromToInModalStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "8px",
      "& input": {
        maxWidth: "202px",
        width: "100%",
      },
      "& span": { margin: "0 16px" },
    },
    filteringInModalStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "16px",
      flexWrap: "wrap",
      "@media(max-width: 768px)": {
        maxWidth: "280px",
      },
    },
  }),
  { index: 1 }
);
