export { initAxiosInterceptors } from './axios';
export { FindError } from './findError';
export { FindSuccess } from './findSuccess';
export { FindLoad } from './findLoad';
export { makeCapitalize } from './makeCapitalize';
export { isNotEmpty } from './isNotEmpty';
export { getValDependsOnLength } from './getValDependsOnLength';
export { getValTillTenDig } from './getValTillTenDig';
export { getLimitedVal } from './getLimitedVal';
export { handleCreatedAtDate } from './handleCreatedAtDate';
export { formatPhoneNumber } from './fomatPhoneNumber';
export { hideParamsId } from './hideParamsId';
export { getPassErrorText } from './getPassErrorText';
export { getPhoneErrorText } from './getPhoneErrorText';
export { ToastSuccess } from './toastSuccess';
export { ToastFail } from './toastFail';
export { getDataValue } from './getDataValue';
export { getAddressText } from './getAddressText';
export { useWidth } from './useWidth';
export { handleFileUpload } from './handleFileUpload';
export { hasURL } from './hasURL';
export { TextRow } from './textResizer'



export const PROMOTED = 'PROMOTED'
export const NON_PROMOTED = 'NON_PROMOTED'
