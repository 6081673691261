import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";


export const howItWorksStyles = makeStyles(() => ({
    howItWorksPage: {
        width: '100%',
        padding: '89px 0 138px',

        '@media(max-width: 1279px)': {
            padding: '60px 0 89px',
        },
        '@media(max-width: 768px)': {
            padding: '48px 0 61px',
        },
    },
    howItWorksContainer: {
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',
        padding: '0px 80px 0',
        '@media(max-width: 1279px)': {
            padding: '0px 33px 0',
        },
        '@media(max-width: 768px)': {
            padding: '0px 14px 0',
        },
    },

    howItWorksTitle: {
        color: `${Colors.TextBlack2}`,
        fontSize: '36px',
        fontWeight: '800',
        lineHeight: '60px',
        marginBottom: '58px',

        '@media(max-width: 1279px)': {
            marginBottom: '36px',
        },
    },

    howItWorksContentWrapper: {
        display: "flex",
        gap: '116px',

        '@media(max-width: 1279px)': {
            flexDirection: 'column',
            gap: '36px',
        },
    },
    tabsWrapper: {
        width: '100%',
        maxWidth: '417px',
        display: "flex",
        flexDirection: 'column',
        gap: '28px'
    },
    tab: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        borderRadius: '8px',
        background: `${Colors.TextWhite}`,
        cursor: 'pointer',
        padding: '16px 24px',
        boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.10)',
        '&:hover': {
            background: `${Colors.ThemePink}`,

            '& div': {
                background: `${Colors.TextWhite}`,
                '& svg': {
                    fill: 'red',
                    '& path': {
                        fill: 'red'
                    }
                }
            },
            '& p': {
                color: `${Colors.TextWhite}`,
            }
        },
    },
    activeTab: {
        background: `${Colors.ThemePink}`,

        '& div': {
            background: `${Colors.TextWhite}`,
            '& svg': {
                '& path': {
                    fill: 'red'
                }
            }
        },
        '& p': {
            color: `${Colors.TextWhite}`,
        }
    },
    svgWrapper: {
        width: '24px',
        height: '24px',
        display: 'inline-flex',
        borderRadius: '4px',
        padding: '3px',
        background: `${Colors.ThemePink}`
    },
    tabSvg: {
        width: '100%',
        height: '100%',

        '& path': {
            fill: `${Colors.TextWhite}`
        }
    },
    text: {
        color: `${Colors.TextBlack}`,
        fontSize: '18px',
        fontWeight: '800',
        lineHeight: '28px',
    },


}))