import React, {useEffect} from 'react';
import {listingDetailsStyle} from './styles';
import {PhotosAndInfoBox} from './core';
import {Images, makeCapitalize, getAddressText, NON_PROMOTED, PROMOTED} from 'utils';
import GoogleMapReact from 'google-map-react';
import ReactHtmlParser from 'react-html-parser';
import {useDispatch} from "react-redux";
import {paymentActions} from "store";

const Marker = ({children}) => <>{children}</>;

const defaultMapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    keyboardShortcuts: false,
};

export const ListingDetails = ({details}) => {
    const dispatch = useDispatch()
    const classes = listingDetailsStyle();
    const {
        _id,
        address,
        createdAt,
        description,
        leaseType,
        nPrice,
        number,
        parking,
        price,
        propertyType,
        size,
        user,
        zoning,
        lotSize,
        capRate,
        images,
        mainImage,
    } = details ? details : {};
    const {lat, lng} = address ? address : {};
    const {start: sizeStart, end: sizeEnd} = size ? size : {};
    const {firstName, middleName, lastName, phone, email, avatar} = user ? user : {};
    const currentUser = JSON.parse(localStorage.getItem('userInfo'));

    const PROPERTY_DETAILS = [
        {
            detailText: 'Listing Number:',
            detail: number === 0 ? number + '' : number,
        },
        {detailText: 'Property Type:', detail: makeCapitalize(propertyType)},
        {detailText: 'Zoning:', detail: zoning},
        {detailText: 'Parking:', detail: parking},
        {detailText: 'Lot Size:', detail: lotSize ? `${lotSize} sq.ft.` : ''},
        {detailText: 'Lease Type:', detail: leaseType === 'NNN' ? leaseType : makeCapitalize(leaseType)},
        {detailText: 'NNN Price:', detail: nPrice ? `$${nPrice}` : ''},
        {detailText: 'Cap Rate:', detail: capRate ? `${capRate}%` : ''},
    ];
    const filteredDetails = PROPERTY_DETAILS.filter((dtl) => dtl.detail);
    const LISTING_LOCATION_URL = `https://maps.google.com/?q=${lat},${lng}&ll=${lat},${lng}&z=4&t=m`;
    const addressDisplay = getAddressText(address);
    const addressMapView = addressDisplay ? addressDisplay.split(', ').reverse().join(', ') : '';

    useEffect(() => {
        if (details && details?.user?._id !== currentUser?.id && details?.promotion?.status === PROMOTED) {
            dispatch(paymentActions.impression(details?._id))
        }
    }, [details])

    return (
        <div className={classes.listingDetailsBoxStyle}>
            <div className={classes.listingDetailsBoxContainerStyle}>
                <PhotosAndInfoBox
                    listingId={_id}
                    addressDisplay={addressDisplay}
                    price={price}
                    size={{sizeStart, sizeEnd}}
                    user={{firstName, middleName, lastName, phone, email, avatar}}
                    createdAt={createdAt}
                    listingLocationUrl={LISTING_LOCATION_URL}
                    listingImages={images}
                    mainImage={mainImage}
                />
                <div className={classes.propertyDetailsStyle}>
                    {filteredDetails.length !== 0 && (
                        <>
                            <h3 className={classes.listingDetailsTitleStyle}> Property Details </h3>
                            <ul className={classes.detailsListStyle}>
                                {filteredDetails.map((propDetail, index) => (
                                    <li key={index}>
                                        <p className={classes.propDetailTextStyle}>
                                            {propDetail.detailText}
                                            <em className={classes.propDetailStyle}>{propDetail.detail}</em>
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
                <div className={classes.contactDetailsStyle}>
                    {description && description !== '<p><br></p>' && (
                        <div className={classes.decriptionBoxStyle}>
                            <h3 className={classes.listingDetailsTitleStyle}>Description</h3>
                            <p className={classes.listingDescriptionStyle}>{ReactHtmlParser(description)}</p>
                        </div>
                    )}
                    <div className={classes.locationBoxStyle}>
                        <h3 className={classes.listingDetailsTitleStyle}> Location </h3>
                        <div className={classes.googleMapBoxStyle}>
                            <GoogleMapReact defaultZoom={6} defaultCenter={{lat, lng}} options={defaultMapOptions}>
                                <Marker lat={lat} lng={lng}>
                                    <div className={classes.pinMarkerStyle}>
                                        <img src={Images.pin} alt=""/>
                                        <span className={classes.titleAndAddressMarkerStyle}>{addressMapView}</span>
                                    </div>
                                </Marker>
                            </GoogleMapReact>
                        </div>
                        <a href={LISTING_LOCATION_URL} target="_blank" rel="noreferrer noopener"
                           className={classes.locationLinkStyle}>
                            {addressDisplay}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
