import {Route, Switch, Redirect} from 'react-router-dom';
import {
    Home,
    ListingDetailsPage,
    Listings,
    PrivacyPolicy,
    TermsAndConditions,
    UserListings,
    PageNotFound,
    SmthWentWrong,
    Promotion,
    Billing,
    Brokers,
    BrokerInfo,
    AboutUs,
    HowItWorks,
} from 'pages';
import {CreateListing, UserRegistration, ListingGallery, UserProfile, ResetPassword, BrokerProfile} from 'fragments';

const createListingPaths = ['/create_listing', '/my_listings/create_listing', '/my_listings/edit_listing-:id'];
const listingDetailsPaths = ['/listings/listing_details-:id', '/my_listings/listing_details-:id'];
const listingGalleryPaths = ['/listings/listing_details-:id/gallery', '/my_listings/listing_details-:id/gallery'];
const usrType = localStorage.getItem('userType')
export const Router = () => {
    const Token = localStorage.getItem('access-token');

    return (
        <div>
            <Switch>

            </Switch>

            {!Token ? (
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/resetPassword" exact component={ResetPassword}/>

                    <Route path="/brokers" exact component={Brokers}/>
                    <Route path="/broker/:id" exact component={BrokerInfo}/>

                    <Route path="/listings" exact component={Listings}/>
                    <Route path="/listings/listing_details-:id" exact component={ListingDetailsPage}/>
                    <Route path="/listings/listing_details-:id/gallery" exact component={ListingGallery}/>

                    <Route path="/termsAndConditions" exact component={TermsAndConditions}/>
                    <Route path="/privacyPolicy" exact component={PrivacyPolicy}/>

                    <Route path="/about-us" exact component={AboutUs}/>
                    <Route path="/howItWorks" exact component={HowItWorks}/>

                    <Redirect to={'/'}/>
                </Switch>
            ) : (
                <Switch>
                    <Route path="/" exact component={Home}/>

                    <Route path="/edit_user-:id" exact component={UserRegistration}/>
                    <Route path="/my_profile" exact component={usrType === 'USER' ? UserProfile : BrokerProfile}/>

                    <Route path="/brokers" exact component={Brokers}/>
                    <Route path="/broker/:id" exact component={BrokerInfo}/>

                    <Route path="/listings" exact component={Listings}/>
                    <Route path={createListingPaths} exact component={CreateListing}/>
                    <Route path={listingDetailsPaths} exact component={ListingDetailsPage}/>
                    <Route path={listingGalleryPaths} exact component={ListingGallery}/>
                    <Route path="/my_listings" exact component={UserListings}/>
                    <Route path="/promotions" exact component={Promotion}/>

                    <Route path="/termsAndConditions" exact component={TermsAndConditions}/>
                    <Route path="/privacyPolicy" exact component={PrivacyPolicy}/>
                    <Route path="/billing" exact component={Billing}/>

                    <Route path="/403" exact component={SmthWentWrong}/>
                    <Route path="/404" exact component={PageNotFound}/>

                    <Route path="/about-us" exact component={AboutUs}/>
                    <Route path="/howItWorks" exact component={HowItWorks}/>

                    <Redirect from="*" to={'/404'}/>
                </Switch>
            )}
        </div>
    );
};
