import {
    CREATE_LISTING,
    EDIT_LISTING,
    GET_LISTINGS,
    GET_LISTINGS_BY_USER_ID,
    GET_LISTING_BY_ID,
    REMOVE_LISTING,
    GET_LISTINGS_BY_FILTERS,
    GET_LISTINGS_BY_USER_ID_FILTER, PROMOTE_LISTING,
} from './listings.types';

export const createListing = (body) => {
    return {
        type: CREATE_LISTING,
        payload: { body },
    };
};

export const getListings = () => {
    return {
        type: GET_LISTINGS,
    };
};

export const editListing = (id, body) => {
    return {
        type: EDIT_LISTING,
        payload: { id, body },
    };
};

export const removeListing = (id) => {
    return {
        type: REMOVE_LISTING,
        payload: { id },
    };
};

export const getListingById = (id) => {
    return {
        type: GET_LISTING_BY_ID,
        payload: { id },
    };
};

export const getListingsByUserId = () => {
    return {
        type: GET_LISTINGS_BY_USER_ID,
    };
};

export const getListingsByUserIdFilter = (filters, loader) => {
    return {
        type: GET_LISTINGS_BY_USER_ID_FILTER,
        payload: { filters, loader },
    };
};

export const getListingsByFilters = (filters) => {
    return {
        type: GET_LISTINGS_BY_FILTERS,
        payload: { filters },
    };
};

/** Promote */
export const promote = ( id, body, type ) => {
    return {
        type: PROMOTE_LISTING,
        payload: { id, body, type }
    }
}
/** End */
