import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import { getAddressText, getLimitedVal, getValDependsOnLength, Images, makeCapitalize } from 'utils';
import { listingsCardItemStyle } from './style';

export const CustomMarker = ({ listing }) => {
    const classes = listingsCardItemStyle();
    const [isShown, setIsShown] = useState(false);

    const { address, mainImage, propertyType, price } = listing;
    const { street } = address;
    const addressDisplay = getAddressText(address);

    const history = useHistory();

    const isMyListingsPage = window.location.pathname === '/my_listings';

    const showMoreDetails = () => {
        if (isMyListingsPage) {
            history.push(`/my_listings/listing_details-${listing._id}`);
        } else {

            const pageEvent = {
                event: "VIEW_DETAILS",
                list_id: listing._id,
            };
            window?.dataLayer.push(pageEvent);

            history.push(`/listings/listing_details-${listing._id}`);
        }
    };

    return (
        <div className={`${classes.markerStyle} ${isShown ? 'active' : ''}`}>
            <div className={classes.pinStyle} onClick={() => setIsShown((prevState) => !prevState)} />
            {isShown && (
                <div className={classes.markerContentStyle} onClick={showMoreDetails}>
                    <div className={classes.listingImageStyle}>
                        <img src={mainImage ? mainImage.url : Images.building} alt="main listing" />
                        {propertyType !== 'OTHER' && <p>{makeCapitalize(propertyType)}</p>}
                    </div>
                    <div className={classes.listingDetailsStyle}>
                        {!!street ? (
                            <>
                                <h5>{getLimitedVal(street, 15)}</h5>
                                <h6>{getLimitedVal(addressDisplay, 20)}</h6>
                            </>
                        ) : (
                            <h5>{getLimitedVal(addressDisplay, 30)}</h5>
                        )}
                        {!!price && (
                            <p>
                                {`$${getValDependsOnLength(price)}`} <em> {'SF/YR'} </em>
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
