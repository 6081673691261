import {promotedStyles} from "./core";
import {PromotedCard} from "./core/promotedCard";
import {PromotedCardTablet} from "./core/promotedCardTablet";
import {PromotedCardMobile} from "./core/promotedCardMobile";

export const Promoted = ({userListings, handleRepromote}) => {
    const classes = promotedStyles()

    return (
        <div>
            <div className={classes.promotedCardWrapper}>
                <div className={classes.promotedDesktop}>
                    <div className={classes.promotedHead}>
                        <div style={{width: '40%'}}>Listing</div>
                        <div style={{width: '12%'}}>Start Date</div>
                        <div style={{width: '12%'}}>End Date</div>
                        <div style={{width: '12%'}}>Impressions</div>
                        <div style={{width: '12%'}}>Price</div>
                        <div style={{width: '12%'}}>Action</div>
                    </div>
                    <div className={classes.promotedCardBody}>
                        {userListings?.map((i, j) => (
                            <PromotedCard
                                key={j}
                                item={i}
                                handleRepromote={() => handleRepromote(i?._id, i)}
                            />
                        ))}
                    </div>
                </div>

                <div className={classes.promotedTablet}>
                    <div className={classes.promotedHead}>
                        <div style={{width: '80%'}}>Listing</div>
                        <div style={{width: '20%'}}>Action</div>
                    </div>
                    <div className={classes.promotedCardTabletBody}>
                        {userListings?.map((i, j) => (
                            <PromotedCardTablet
                                key={j}
                                item={i}
                                handleRepromote={() => handleRepromote(i?._id, i)}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className={classes.promotedCardMobile}>
                {userListings?.map((i, j) => (
                    <PromotedCardMobile
                        key={j}
                        item={i}
                        handleRepromote={() => handleRepromote(i?._id)}
                    />
                ))}
            </div>

        </div>
    )
}