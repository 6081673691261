import {useState, useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {FilteringCheckboxInModal, FilteringFromToInModal, FiltersModalWrapper} from "components";
import {
    enumValues,
    FindLoad,
    FindSuccess,
    getValTillTenDig,
    Images,
    initialCoordinates,
    isNotEmpty,
    ListingFiltersContext
} from "utils";
import {navBarCoresStyle} from "../../menuBar/nav/core/style";
import {httpRequestsOnSuccessActions} from "store";

export const AdvancedSearch = ({ handleModalClose, handleClearAll }) => {
    const listingFiltersCtx = useContext(ListingFiltersContext);
    const {filters, selectedPropType, handleFiltersRequest, reselAllFilters, propTypes, setPropTypes,
        filteredMinPrice, setFilteredMinPrice,
        filteredMaxPrice, setFilteredMaxPrice,
        filteredMinSize, setFilteredMinSize,
        filteredMaxSize, setFilteredMaxSize,
        handleLatLngRequest, handleZoomRequest,
    } = listingFiltersCtx;
    const [filteredNewest, setFilteredNewest] = useState('');
    const [advancedFilters, setAdvancedFilters] = useState(filters ? { ...filters} : {});
    const [filtersInModal, setFiltersInModal] = useState({});
    const classes = navBarCoresStyle();
    const dispatch = useDispatch();
    const history = useHistory()
    const modalClass = navBarCoresStyle();
    const filteredPropTypes = propTypes.filter((propType) => propType?.isChecked).map((propType) => propType.label.toUpperCase());

    const passSelectedPropTypes = (selectedOption) => {
        setPropTypes(
            propTypes.map((propType) => {
                if (propType.label === selectedOption.label) {
                    return selectedOption;
                }
                return propType;
            })
        );
    };

    useEffect(() => {
        if (selectedPropType) {
            const selPropType = {label: selectedPropType, isChecked: true};
            handleSelectedPropType(selPropType, setAdvancedFilters);
        }
    }, [selectedPropType]);

    const handleSelectedPropType = (selectedOption, resetFilters) => {
        passSelectedPropTypes(selectedOption);
        resetFilters((prevState) => ({...prevState}));
    };

    const handleSelectedMinPrice = (minPrice, resetFilters) => {
        setFilteredMinPrice(getValTillTenDig(minPrice));
        resetFilters((prevState) => ({
            ...prevState,
            priceStart: getValTillTenDig(minPrice),
        }));
    };

    const handleSelectedMaxPrice = (maxPrice, resetFilters) => {
        setFilteredMaxPrice(getValTillTenDig(maxPrice));
        resetFilters((prevState) => ({
            ...prevState,
            priceEnd: getValTillTenDig(maxPrice),
        }));
    };

    const handleSelectedMinSize = (minSize, resetFilters) => {
        setFilteredMinSize(getValTillTenDig(minSize));
        resetFilters((prevState) => ({
            ...prevState,
            sizeStart: getValTillTenDig(minSize),
        }));
    };

    const handleSelectedMaxSize = (maxSize, resetFilters) => {
        setFilteredMaxSize(getValTillTenDig(maxSize));
        resetFilters((prevState) => ({
            ...prevState,
            sizeEnd: getValTillTenDig(maxSize),
        }));
    };

    const clearAllSelectedFilters = (resetFilters) => {
        setFilteredNewest('');
        setFilteredMinPrice('');
        setFilteredMaxPrice('');
        setFilteredMinSize('');
        setFilteredMaxSize('');
        setPropTypes(enumValues.DEFAULT_PROPERTY_TYPES);
        setAdvancedFilters({})
        setFiltersInModal({})
        reselAllFilters()
        handleClearAll && handleClearAll()


        handleLatLngRequest(initialCoordinates.lat, initialCoordinates.lng);
        handleZoomRequest(initialCoordinates.zoom);
    };

    const handleSelectedFilters = (selFilters) => {
        delete selFilters[isNotEmpty(filteredNewest.label) ? '' : 'filterSort'];
        delete selFilters[filteredPropTypes.length !== 0 ? '' : 'propertyType'];
        delete selFilters[isNotEmpty(filteredMinPrice) ? '' : 'priceStart'];
        delete selFilters[isNotEmpty(filteredMaxPrice) ? '' : 'priceEnd'];
        delete selFilters[isNotEmpty(filteredMinSize) ? '' : 'sizeStart'];
        delete selFilters[isNotEmpty(filteredMaxSize) ? '' : 'sizeEnd'];
        handleFiltersRequest(selFilters);
    };

    const listingFilterLoader = FindLoad('GET_LISTINGS_BY_FILTERS');
    const userListingFilterLoader = FindLoad('GET_LISTINGS_BY_USER_ID_FILTER');
    const isLoading = !!listingFilterLoader.length || !!userListingFilterLoader.length;

    const listingFilterSuccess = FindSuccess('GET_LISTINGS_BY_FILTERS');
    const userListingFilterSuccess = FindSuccess('GET_LISTINGS_BY_USER_ID_FILTER');

    useEffect(() => {
        if (listingFilterSuccess.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('GET_LISTINGS_BY_FILTERS'));
        }
        if (userListingFilterSuccess.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('GET_LISTINGS_BY_USER_ID_FILTER'));
        }
    }, [listingFilterSuccess, userListingFilterSuccess]);

    const handleSubmitFilters = () => {
        const chosenFiltersInModal = {
            ...advancedFilters,
            ...filtersInModal,
            propertyType: filteredPropTypes,
        };
        handleSelectedFilters(chosenFiltersInModal);
        const link = history?.location?.pathname === "/my_listings" ? "/my_listings" : '/listings'
        history.push(link);
    };

    return (
        <div>
            <FiltersModalWrapper
                onClose={handleModalClose}
                filterTitle={'More Filters'}
                isLoading={isLoading}
                submitHandler={handleSubmitFilters}
                clearAllHandler={() => clearAllSelectedFilters(setFiltersInModal)}
                onDesktop={true}
            >
                <button
                    style={{width: '145px', marginRight: '16px'}}
                    type="button"
                    className={classes.forLeaseButton}
                    // onClick={handleModalClose}
                >
                    <img src={Images.forLease} alt=""/>
                    <span> For lease </span>
                </button>

                <div style={{display: 'block'}} className={`${modalClass.sortByBoxStyle} sortByBox fromToBox`}>
                    <h6 className={classes.sortByTitleStyle}> Property Type </h6>
                    <FilteringCheckboxInModal
                        dropdownOptions={propTypes}
                        onPass={(propType) => handleSelectedPropType(propType, setFiltersInModal)}
                    />
                </div>
                <div style={{display: 'block'}} className={`${modalClass.sortByBoxStyle} sortByBox fromToBox`}>

                <h6 className={classes.sortByTitleStyle}> Price </h6>
                    <FilteringFromToInModal
                        passSelectedMin={(minPrice) => handleSelectedMinPrice(minPrice, setFiltersInModal)}
                        passSelectedMax={(maxPrice) => handleSelectedMaxPrice(maxPrice, setFiltersInModal)}
                        selectedMin={filteredMinPrice}
                        selectedMax={filteredMaxPrice}
                        minInputPlaceholder={'Min $/SF/YR'}
                        maxInputPlaceholder={'Max $/SF/YR'}
                    />
                </div>
                <div style={{display: 'block'}} className={`${modalClass.sortByBoxStyle} sortByBox fromToBox`}>
                    <h6 className={classes.sortByTitleStyle}> Size </h6>
                    <FilteringFromToInModal
                        passSelectedMin={(minSize) => handleSelectedMinSize(minSize, setFiltersInModal)}
                        passSelectedMax={(maxSize) => handleSelectedMaxSize(maxSize, setFiltersInModal)}
                        selectedMin={filteredMinSize}
                        selectedMax={filteredMaxSize}
                        minInputPlaceholder={'Min SF'}
                        maxInputPlaceholder={'Max SF'}
                    />
                </div>
            </FiltersModalWrapper>
        </div>
    )
}