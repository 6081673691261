export const hasURL = (images = []) => {
    if (!Array.isArray(images)) return;

    let hasURL = false;

    for (const image of images) {
        if (!!image.url) {
            hasURL = true;
        } else {
            hasURL = false;
        }
    }

    return hasURL;
};
