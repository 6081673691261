import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const footerCoresStyle = makeStyles(() => ({

    sendMessageWrapper: {
        position: 'fixed',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 99999,
        maxWidth: '500px',
        width: '96%',
        maxHeight: '650px',
        height: '100%',
        overflow: 'auto',
        paddingTop: '16px',
        backgroundColor: Colors.BackgroundWhite,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 12px',
        '@media(min-width: 320px)': {
            padding: '24px 12px',
        },
        '@media(min-width: 768px)': {
            padding: '24px',
        },
    },

    closeButton: {
        left: 'auto',
        right: '16px',
    },

    title: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2B273C',
        marginBottom: '32px',
    },

    textAreaStyles: {
        width: '100%',
        height: '169px',
        background: '#F4F4F4',
        borderRadius: '8px',
        border: '1px solid transparent',
        resize: 'none',
        padding: '10px 16px',
        outline: 'none',

        '&:focus': {
            backgroundColor: Colors.BackgroundWhite,
            border: '1px solid #0073BB',
        },

    },

    inputStyles: {
        maxWidth: '100%',
        '&:not(:last-of-type)': {
            marginBottom: '24px'
        },
        '& > label > input': {
            width: '100%',
        },
    },

    inputTitle: {
        // marginTop: '24px',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        marginBottom: '4px',
        '& span': {
            color: '#FF385C',
        }
    },

    sendButton: {
        width: '100%',
        height: '48px',
        background: '#FF385C',
        borderRadius: '8px',
        // marginTop: '38px',
        border: 'none',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    }


}), {index: 1});
