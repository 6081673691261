import React, { useState } from 'react';

export const ViewAsContext = React.createContext({
    view: '',
    handleViewChange: () => {},
});

export const ViewAsContextProvider = ({ children }) => {
    const [view, setView] = useState('gallery');

    const handleViewChange = (viewName) => setView(viewName);

    const context = { view, handleViewChange };

    return <ViewAsContext.Provider value={context}>{children}</ViewAsContext.Provider>;
};
