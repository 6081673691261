import {
  logOut,
  logIn,
  getRecoveryLink,
  resetPassword,
  changePassword, sendMessage,
} from "./auth.action";
export { authReducer } from "./auth.reducer";
export { watchAuth } from "./auth.saga";

export const authActions = {
  /**Log In, Register, Log Out */
  logIn,
  logOut,
  getRecoveryLink,
  resetPassword,
  changePassword,
  /** End */

  /**Contact Form */
  sendMessage,
  /** End */
};
