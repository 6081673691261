import {
  LOG_IN,
  LOG_OUT,
  GET_RECOVERY_LINK,
  RESET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST, SEND_MESSAGE,
} from "./auth.types";

export const logIn = (body) => {
  return {
    type: LOG_IN,
    payload: { body },
  };
};

export const logOut = () => {
  return {
    type: LOG_OUT,
  };
};

export const getRecoveryLink = (email) => {
  return {
    type: GET_RECOVERY_LINK,
    payload: { email },
  };
};

export const resetPassword = (passwords) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: { passwords },
  };
};

export const changePassword = (passwords) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: { passwords },
  };
};

/** Contact Form */
export const sendMessage = ( info ) => {
  return {
    type: SEND_MESSAGE,
    payload: info,
  };
};
