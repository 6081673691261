import React from 'react';
import { ListingInputsWrapper, UserInput, UserInputsDropdown } from 'components';
import { commonStyle } from './styles';

export const PropertyDetails = ({ info, inputs, dropdownErrorMsg, error, handleChange, handleSelLeaseTypePass, selLeaseType }) => {
    const classes = commonStyle();

    return (
        <ListingInputsWrapper inputsTitle={'Property Details'}>
            <div className={classes.profileInfoBox}>
                <h6 className={classes.profileLabelStyle}>Listing Number</h6>
                <div className={classes.profileInputStyle}>
                    <span className={`${classes.infoStyle} ${!info ? 'active' : ''}`}>{info ? info.number : 'Auto-generated'}</span>
                </div>
            </div>
            <div className={classes.leaseTypeAndNPriceStyle}>
                <div
                    className={`${classes.leaseTypeStyle} ${selLeaseType === 'NNN' || (info && info.leaseType === 'NNN') ? 'active' : ''}`}>
                    <p className={classes.requiredLabelStyle}> Lease Type </p>
                    <UserInputsDropdown
                        dropdownOptions={['Gross', 'NNN', 'Other']}
                        onPass={(selected) => handleSelLeaseTypePass(selected)}
                        selected={selLeaseType}
                        dropdownTitle={'Select Type'}
                        dropdownError={error === 'leaseType'}
                    />
                    <p className={classes.errorStyle}>{error === 'leaseType' && dropdownErrorMsg}</p>
                </div>
                {selLeaseType === 'NNN' && (
                    <UserInput
                        inputClassName={classes.listingInputStyle}
                        inputLabel={'NNN Price (sq. ft.)'}
                        inputType={'number'}
                        inputName={'nPrice'}
                        inputValue={inputs.nPrice}
                        onInputChange={handleChange}
                    />
                )}
            </div>
            <div className={classes.zoningAndLotSizeStyle}>
                <UserInput
                    inputClassName={classes.listingInputStyle}
                    inputLabel={'Zoning'}
                    inputType={'text'}
                    inputName={'zoning'}
                    inputValue={inputs.zoning}
                    onInputChange={handleChange}
                />
                <UserInput
                    inputClassName={classes.listingInputStyle}
                    inputLabel={'Lot Size'}
                    inputType={'number'}
                    inputName={'lotSize'}
                    inputValue={inputs.lotSize}
                    onInputChange={handleChange}
                />
            </div>
            <UserInput
                inputClassName={classes.listingInputStyle}
                inputLabel={'Parking'}
                inputType={'text'}
                inputName={'parking'}
                inputValue={inputs.parking}
                onInputChange={handleChange}
            />
        </ListingInputsWrapper>
    );
};
