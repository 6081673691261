export const getValDependsOnLength = (val = "") => {
  const strVal = val.toString();
  switch (strVal.length) {
    case 4:
      return `${strVal.slice(0, 1)}K`;
    case 5:
      return `${strVal.slice(0, 2)}K`;
    case 6:
      return `${strVal.slice(0, 3)}K`;
    case 7:
      return `${strVal.slice(0, 1)}M`;
    case 8:
      return `${strVal.slice(0, 2)}M`;
    case 9:
      return `${strVal.slice(0, 3)}M`;
    case 10:
      return `${strVal.slice(0, 1)}B`;
    default:
      return strVal;
  }
};
