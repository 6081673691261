import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "./globalColors";

export const useGlobalText = makeStyles(
  () => ({
    title: {
      fontSize: "24px",
      fontWeight: "700",
      color: "black",
      position: "relative",
      display: "flex",
      justifyContent: "flex-end",
      "& span": {
        fontSize: "24px",
        fontWeight: "700",
        color: "black",
        margin: "0 8px",
      },
    },

    smallText: {
      fontSize: "18px",
      fontWeight: "600",
      color: Colors.TextSecondary,
    },

    smallSwitchText: {
      fontSize: "14px",
      color: Colors.TextSecondary,
    },

    nameEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "100px",
      textTransform: "uppercase",
      "@media (min-width: 1919px)": {
        width: "150px",
      },
    },

    addressEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "200px",
      "@media (min-width: 1919px)": {
        width: "250px",
      },
    },
    desc: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "200px",
      "@media (min-width: 1919px)": {
        width: "250px",
      },
    },

    emailEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "150px",
      "@media (min-width: 1919px)": {
        width: "200px",
      },
    },
  }),
  { index: 1 }
);
