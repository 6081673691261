import React from 'react';
import { Link } from "react-router-dom";
import { pageNotFoundStyle } from './styles';
import { Images } from "utils";

export const PageNotFound = () => {
    const classes = pageNotFoundStyle();

    return (
        <div className={classes.notFoundBoxStyle}>
            <div className={classes.notFoundBoxContainerStyle}>
                <div className={classes.notFoundInfoBoxStyle}>
                    <img src={Images.pageNotFound} alt="" />
                    <p className={classes.notFoundTextStyle}> Whoops! Page not found </p>
                    <Link to="/" className={classes.homeLinkStyle}> Back to homepage </Link>
                </div>
            </div>
        </div>
    );
};
