import React, { useState } from 'react';
import { Images } from 'utils';
import { dropdownStyle } from './style';

export const UserInputsDropdown = ({ dropdownOptions = [], onPass, selected, dropdownTitle, dropdownError }) => {
    const [dropdownIsShown, setDropdownIsShown] = useState(false);

    const classes = dropdownStyle();

    const chooseOptionHandler = (option) => {
        onPass(option);
        setDropdownIsShown(false);
    };

    return (
        <>
            {dropdownIsShown && <div className={classes.dropdownOverlayStyle} onClick={() => setDropdownIsShown(false)} />}
            <div
                className={`${classes.dropdownBoxStyle} listing ${dropdownIsShown || selected ? 'active' : ''} ${
                    dropdownError ? 'error' : ''
                }`}>
                <div className={`${classes.showDropdownBoxStyle} listing`} onClick={() => setDropdownIsShown((prevState) => !prevState)}>
                    <h6 className={`${classes.dropdownSelectedStyle} listing`}>{selected ? selected : dropdownTitle}</h6>
                    <img src={dropdownIsShown ? Images.dropdownArrowBlack : Images.dropdownArrow} alt="" />
                </div>
                {dropdownIsShown && (
                    <div className={`${classes.dropdownStyle} listing`}>
                        {dropdownOptions.map((option, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${classes.dropdownOptionsStyle} listing`}
                                    onClick={() => chooseOptionHandler(option)}>
                                    <span
                                        className={`${classes.dropdownOptionTitleStyle} listing ${
                                            option === selected ? 'selectedTitle' : ''
                                        }`}>
                                        {option}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};
