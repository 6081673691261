import { makeStyles } from "@material-ui/styles";
import { Colors } from "utils";

export const listingInputsStyle = makeStyles(
  () => ({
    createListingBoxStyle: {
      backgroundColor: Colors.BackgroundWhiteOff,
      display: "flex !important",
      justifyContent: "center !important",
    },
    createListingBoxContainer: {
      maxWidth: "1440px",
      width: "100%",
      padding: "0px 80px",
      display: "flex",
      flexDirection: "column",

      "@media(max-width: 1200px)": {
        paddingLeft: "70px",
        paddingRight: "70px",
      },
      "@media(max-width: 768px)": {
        paddingLeft: "37px",
        paddingRight: "37px",
      },
      "@media(max-width: 400px)": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    createListingTitle: {
      fontSize: "24px",
      fontWeight: 800,
      color: Colors.TextBlack,
      textTransform: "capitalize",
      marginBottom: "48px",
    },
    createListingStyle: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(528px, 1fr))",
      gap: "24px",
      backgroundColor: "none !important",
      boxShadow: "none !important",
      "& > div:last-of-type": { width: "204%" },
      "@media(max-width: 1250px)": {
        "& > div:last-of-type": { width: "100%" },
      },
      "@media(max-width: 600px)": {
        gridTemplateColumns: "repeat(auto-fill, minmax(343px, 1fr))",
      },
    },
    listingInputsActionStyle: {
      width: "100%",
      backgroundColor: Colors.BackgroundWhite,
      padding: "22px",
      marginTop: "24px",
      marginBottom: "24px",
      borderRadius: "12px",
      boxShadow: "0px 0px 6px #00000029",
      "& button": { width: "161px" },
    },
    bgColorStyle: { backgroundColor: Colors.BackgroundWhiteOff },
  }),
  { index: 1 }
);
