import axios from "axios";

export const authService = {
    createBrokerService: (body) => axios.post("/brokers", body),

    getBrokersService: () => axios.get("/brokers", {auth: false}),

    editBrokerService: (id, body) => axios.patch(`/brokers`, body, {auth: true}),

    deleteUserService: (id) => axios.delete(`/brokers/${id}`, {auth: true}),

    getBrokerByIdService: (id) => axios.get(`/brokers/${id}`, {auth: false}),

    getBrokerProfileService: (id) => axios.get(`/brokers/myProfile`, {auth: true}),
};
