import React from 'react';
import {aboutUsFragments} from "./styles";

export const CardRed = ({title, description}) => {
    const classes = aboutUsFragments()
    return (
        <div className={classes.cardRedWrapper}>
            <h3 className={classes.cardRedTitle}>
                {title}
            </h3>

            <p className={classes.cardRedDescription}>
                {description}
            </p>
        </div>
    );
};

