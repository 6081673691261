import {BrokersFragmentStyles} from "./styles";
import {TextRow} from "../../../utils";


export const GrayItem = ({ icon, title, subtitle, right}) => {
    const classes = BrokersFragmentStyles()

    return(
        <div style={right === 0 ? {marginRight: 0} : {}} className={classes.grayItem}>
            <img src={icon} alt="icon"/>
            <p className={classes.grayItemTitle}>{title}</p>
            <p className={classes.grayItemSbTitle}>
                <TextRow name={subtitle} textWidth={10}/>
            </p>
        </div>
    )
}