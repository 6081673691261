import React, { useState, useContext, useEffect } from 'react';
import { userRegistrationCoreStyles } from './styles';
import { SendButton, UserActionButton, UserInput } from 'components';
import { useDispatch } from 'react-redux';
import { EmailValidator, FindError, FindLoad, FindSuccess, isNotEmpty, RegisterContext } from 'utils';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

export const UserSignIn = ({ closeModal }) => {
    const classes = userRegistrationCoreStyles();

    const registerCtx = useContext(RegisterContext);
    const { handleModalContent } = registerCtx;

    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');

    const userSignInLoader = FindLoad('LOG_IN');
    const userBackError = FindError('LOG_IN');
    const userSignInSuccess = FindSuccess('LOG_IN');

    const errorMsg = 'This field must be not empty!';
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? 'Email must be an email!' : '';

    const emailErrorText =
        error === 'email'
            ? errorMsg
            : userBackError.length && userBackError[0].error === 'User with this email was not found'
            ? 'The email you entered isn’t connected to an account'
            : error === emailErrorMsg
            ? emailErrorMsg
            : '';

    const passwordErrorText =
        error === 'password'
            ? errorMsg
            : userBackError.length && userBackError[0].error === 'user password does not match'
            ? 'The password you’ve entered is incorrect'
            : '';

    const handleChange = (evt) => {
        setInputs((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value,
        }));

        if (error === evt.target.name || error === emailErrorMsg) {
            setError('');
        }

        if (userBackError.length) {
            dispatch(httpRequestsOnErrorsActions.removeError('LOG_IN'));
        }
    };

    const handleSignIn = () => {
        const signInData = {
            email: inputs.email,
            password: inputs.password,
        };

        const emailIsValid = inputs.email && EmailValidator.test(inputs.email);
        const errorText = !inputs.email ? 'email' : !emailIsValid ? emailErrorMsg : !inputs.password ? 'password' : 'Input is not field';

        if (emailIsValid && inputs.password) {
            dispatch(authActions.logIn(signInData));
        } else {
            setError(errorText);
        }
    };

    useEffect(() => {
        if (userSignInSuccess.length) {
            closeModal();
            dispatch(httpRequestsOnSuccessActions.removeSuccess('LOG_IN'));
        }
    }, [userSignInSuccess]);

    return (
        <div>
            <UserInput
                required={true}
                inputClassName={'inModal'}
                inputLabel={'Email Address'}
                inputType={'text'}
                inputName={'email'}
                inputValue={inputs.email}
                onInputChange={handleChange}
                inputError={emailErrorText}
            />

            <UserInput
                required={true}
                inputClassName={'inModal'}
                inputLabel={'Password'}
                inputType={'password'}
                inputName={'password'}
                inputValue={inputs.password}
                onInputChange={handleChange}
                inputError={passwordErrorText}
                passwordInput={isNotEmpty(inputs.password)}
            />
            <div className={classes.signInForgotBoxStyle}>
                <SendButton
                    butnClassName={classes.signInButnStyle}
                    butnType={'button'}
                    onClickButn={handleSignIn}
                    butnDisabled={false}
                    butnSendingText={'sign in'}
                    loader={!!userSignInLoader.length}
                />
                <UserActionButton
                    butnClassName={classes.goToForgotPassButnStyle}
                    onUserAction={() => handleModalContent('forgotPass')}
                    butnLabel={'Forgot Password?'}
                />
            </div>
            <div className={classes.signUpPartBoxStyle}>
                <span> Not on Croil yet? </span>
                <UserActionButton
                    butnClassName={classes.goToSignUpButnStyle}
                    onUserAction={() => handleModalContent('signUp')}
                    butnLabel={'Sign Up'}
                />
            </div>
        </div>
    );
};
