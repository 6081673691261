import React, { useState, useContext } from 'react';
import { Images, ListingFiltersContext } from 'utils';
import { dropdownStyle } from './style';
import { CheckBoxInput } from 'components';

export const FilteringCheckBoxDropdown = ({
    dropdownBoxClassName,
    dropdownClassName,
    pointsBoxClassName,
    dropdownOptions = [],
    isInModal,
    selectedCheckBoxes = [],
    selectedOption = '',
    dropdownTitle,
    onPass,
    isHomePage,
}) => {
    const [dropdownIsShown, setDropdownIsShown] = useState(false);

    const classes = dropdownStyle();
    const listingFiltersCtx = useContext(ListingFiltersContext);
    const { handlePropTypeSelected } = listingFiltersCtx;

    return (
        <>
            {dropdownIsShown && <div className={classes.dropdownOverlayStyle} onClick={() => setDropdownIsShown(false)} />}
            <div className={`${classes.dropdownBoxStyle} ${dropdownBoxClassName} ${isInModal ? '' : 'checkBoxInModal'}`}>
                <div className={classes.showDropdownBoxStyle} onClick={() => setDropdownIsShown((prevState) => !prevState)}>
                    <h6 className={`${classes.dropdownSelectedStyle} ${selectedOption ? 'active' : ''}`}>
                        {selectedOption ? selectedOption : dropdownTitle}
                    </h6>
                    {dropdownIsShown ? (
                        <img src={Images.dropdownArrowBlack} alt="dropdown arrow black" className={'dropArrow'} />
                    ) : (
                        <img src={Images.dropdownArrow} alt="dropdown arrow" className={'dropArrow'} />
                    )}
                </div>
                {selectedCheckBoxes.length !== 0 && (
                    <div className={`${classes.checkBoxesQuantityStyle} ${pointsBoxClassName}`}>
                        <span> {selectedCheckBoxes.length} </span>
                    </div>
                )}
                {dropdownIsShown && (
                    <div className={`${classes.dropdownStyle} ${dropdownClassName}`}>
                        {dropdownOptions.map((option, index) => {
                            return isHomePage ? (
                                <div
                                    key={index}
                                    className={classes.dropdownOptionsStyle}
                                    onClick={() => {
                                        handlePropTypeSelected(option.label);
                                        setDropdownIsShown(false);
                                    }}>
                                    <span
                                        className={`${classes.dropdownOptionTitleStyle}
                                            ${option.label === selectedOption && 'selectedTitle'}`}>
                                        {option.label}
                                    </span>
                                </div>
                            ) : (
                                <CheckBoxInput
                                    key={index}
                                    inputId={index}
                                    inputChecked={option.isChecked}
                                    onInputChange={(evt) => {
                                        onPass({
                                            ...option,
                                            isChecked: evt.target.checked,
                                        });
                                    }}
                                    inputLabel={option.label}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};
