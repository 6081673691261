import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const promotionStyle = makeStyles(() => ({
    tabButtonsWrapper: {
        marginBottom: '24px',
        width: '307px',
        height: '40px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(138, 138, 138, 0.16)',
        borderRadius: '8px',
        display: 'flex',
        padding: '4px',
        alignItems: 'center',
        '& button': {
            border: 'none',
            width: '166px',
            height: '32px',
            borderRadius: '4px',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            background: 'transparent',
        }
    },

    paymentModal: {
        position: 'fixed',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 99999,
        maxWidth: '465px',
        width: '90%',
        maxHeight: '500px',
        height: 'auto',
        overflow: 'auto',
        paddingTop: '16px',
        backgroundColor: Colors.BackgroundWhite,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        '& button': {
            background: 'transparent',
            border: 'none',

        }
    },

    modalTitle: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2B273C',
    },
    modalSubTitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        margin: '15px 0 24px',
    },
    whiteWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        height: 'auto',
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '22px'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    titleAndItem: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        '& span': {
            color: '#757280',
        },
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& button': {
            // width: '188px',
            height: '40px',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF',
            border: 'none',
        }
    },
    cancel: {
        background: 'rgba(117, 114, 128, 0.5)',
    },
    pay: {
        background: '#FE385C',
        // marginLeft:'20px',
        width:'100%',
    },

    spaceBetweenRenew: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& button': {
            width: '188px',
            height: '40px',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF',
            border: 'none',
        }
    },

    payButton: {
        background: '#FE385C',
        marginLeft:'20px',
        width: '188px',
        height: '40px',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        border: 'none',
    },
    cardNumber: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        marginLeft: '17px',
    },
    totalAmount: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px',
        '& p': {
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '28px',
            color: '#757280',
        },
        '& span': {
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '28px',
            color: '#2B273C',
        },
    },
    cardElement: {
        background: '#F4F4F4',
        padding: '12px 16px',
        border: 'none',
        borderRadius: '8px',
        width: '100%',
        height: '48px',
    },
    cardNumberTitle: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        margin: '24px 0 4px',
        '& span': {
            color: '#FE385C',
        },
    },
    doneWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    doneTitle: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2B273C',
        margin: '24px 0 16px',
    },
    doneSubtitle: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#2B273C',
        marginBottom: '24px'
    },
    doneButton: {
        width: '192px',
        height: '40px',
        background: '#FE385C',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        border: 'none'
    },
    noListWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        marginTop:'70px',
    },
    noList:{
        width: '100%',
        maxWidth:'362px',
        height: '100%',
    },
    noListTitle: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        textAlign: 'center',
        color: '#2B273C',
        margin: '24px 0 16px 0'
    },
    noListSubTitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#2B273C',
    },
}))