import React from 'react';
import {aboutUsFragments} from "./styles";
import {Svg} from "assets/images";

export const CardWithSvg = ({description}) => {
    const classes = aboutUsFragments()

    return (
        <div className={classes.cardWithSvgWrapper}>
            <Svg.CorrectSvg className={classes.cardWithSvgSvg}/>

            <p className={classes.cardWithSvgDescription}>
                {description}
            </p>
        </div>
    );
};

