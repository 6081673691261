import { Colors } from 'utils';
import { makeStyles } from '@material-ui/core/styles';

export const dropdownStyle = makeStyles(
    () => ({
        dropdownOverlayStyle: {
            width: '100%',
            height: '100vh',
            position: 'fixed',
            zIndex: 1,
            top: 0,
            left: 0,
            '&.overlayInModal': {
                maxWidth: '500px',
                width: '100%',
                maxHeight: '391px',
                height: '100%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                '@media(max-width: 1200px)': { maxHeight: '587px' },
                '@media(max-width: 768px)': { maxWidth: '375px' },
            },
        },
        dropdownBoxStyle: {
            position: 'relative',
            backgroundColor: Colors.BackgroundWhite,
            border: '1px solid #757280',
            borderRadius: '20px',
            '&.listing': {
                backgroundColor: Colors.BackgroundWhiteOff,
                borderRadius: '8px',
                border: '1px solid transparent',
                '&.active': {
                    backgroundColor: Colors.BackgroundWhite,
                    boxShadow: '0px 0px 6px #0073BB29',
                    border: '1px solid #0073BB',
                },
                '&.error': {
                    backgroundColor: Colors.BackgroundWhite,
                    boxShadow: '0px 0px 6px #FF385C29',
                    border: '1px solid #FF385C',
                },
            },
            '&.fromToDropdown': {
                '@media(max-width: 1440px)': {
                    display: 'none',
                },
            },
            '&.newestInModal, &.checkBoxInModal': {
                '@media(max-width: 1200px)': {
                    display: 'none',
                },
            },
            '&.boxInModal': {
                maxWidth: '372px',
                width: '100%',
                borderRadius: '8px',
                '@media(max-width: 768px)': {
                    maxWidth: '247px',
                },
            },
        },
        showDropdownBoxStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '10px 16px 10px 24px',
            '&.listing': {
                justifyContent: 'space-between',
                padding: '10px 16px',
                height: '38px',
            },
            '& .dropArrow, & .dropClear': {
                marginLeft: '16px',
            },
            '&.selected': {
                paddingRight: '8px',
            },
            '&.showBoxInModal': {
                justifyContent: 'space-between',
            },
        },
        dropTitleBoxClassName: {
            display: 'flex',
            alignItems: 'center',
        },
        dropdownSelectedStyle: {
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextBlack,
            '&.listing': { color: Colors.TextGray },
            '&.active': { color: '#757280' },
        },
        dropdownOptionIconClassName: {
            width: '18px',
            height: '18px',
            marginLeft: '8px',
            '& img': { width: '100%' },
        },
        clearSelectedButnStyle: {
            border: 'none',
            outline: 'none',
            backgroundColor: 'inherit',
            display: 'flex',
        },
        dropdownStyle: {
            position: 'absolute',
            left: 0,
            width: '250px',
            backgroundColor: Colors.BackgroundWhite,
            boxShadow: '0px 0px 6px #00000029',
            borderRadius: '12px',
            paddingTop: '12px',
            paddingBottom: '12px',
            top: '48px',
            zIndex: 999,
            '&.listing': {
                width: '100%',
                borderRadius: '8px',
                top: '42px',
            },
            '&.dropdownInModal': {
                width: '372px',
                '@media(max-width: 768px)': {
                    maxWidth: '247px',
                },
            },
            '&.filteringFromTo': {
                width: '376px',
                padding: '24px',
                top: '48px',
                transition: 'all 0.1s linear',
                display: 'flex',
                alignItems: 'center',
                '& span': {
                    fontSize: '16px',
                    fontWeight: 500,
                    color: Colors.TextBlack,
                    marginLeft: '16px',
                    marginRight: '16px',
                },
                '& input': {
                    maxWidth: '140px',
                    width: '100%',
                },
            },
        },
        dropdownInModalStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '16px',
            flexWrap: 'wrap',
            '@media(max-width: 768px)': {
                maxWidth: '280px',
            },
        },
        dropdownOptionsStyle: {
            transition: 'all 0.1s linear',
            cursor: 'default',
            padding: '10px 16px',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: Colors.BackgroundWhiteOff,
            },
            zIndex: 9999,
        },
        dropdownOptionTitleStyle: {
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextGray,
            '&.selectedTitle': {
                color: '#3377CC',
            },
        },
        checkBoxesQuantityStyle: {
            position: 'absolute',
            top: 0,
            right: '-10px',
            width: '24px',
            height: '24px',
            backgroundColor: '#2B273C',
            border: '2px solid #FAFAFA',
            borderRadius: '50%',
            textAlign: 'center',
            '& span': {
                fontSize: '10px',
                fontWeight: 500,
                color: Colors.TextWhite,
            },
        },
        searchContainerStyle: {
            position: 'relative',
            '@media(max-width: 650px)': {
                marginTop: '24px',
                width: '100%',
            },
        },

        searchBoxStyle: {
            padding: '8px 8px 8px 19px',
            width: '230px',
            height: '40px',
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: '24px',
            border: '1px solid transparent',
            display: 'flex',
            alignItems: 'center',
            transition: 'all 0.2s linear',
            '&.focused': {
                width: '380px',
                border: '1px solid #0073BB',
                backgroundColor: Colors.BackgroundWhite,
                boxShadow: '0px 0px 6px #0073BB29',
            },
            '@media(max-width: 650px)': {
                width: '100%',
                '&.focused': { width: '100%' },
            },
        },

        searchBoxStyleCustom: {
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: '8px',
            border: '1px solid transparent',
            '&.focused': {
                width: '100%',
                borderRadius: '8px',
                border: '1px solid #0073BB',
                backgroundColor: Colors.BackgroundWhite,
            },
            '@media(max-width: 650px)': {
                width: '100%',
                '&.focused': { width: '100%' },
            },
        },

        searchBoxStyleCustomError: {
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: '8px',
            border: '1px solid red',
            '&.focused': {
                width: '100%',
                borderRadius: '8px',
                border: '1px solid #0073BB',
                backgroundColor: Colors.BackgroundWhite,
            },
            '@media(max-width: 650px)': {
                width: '100%',
                '&.focused': { width: '100%' },
            },
        },
        searchIconStyle: {
            display: 'flex',
            paddingRight: '11px',
            cursor: 'text',
        },
    }),
    { index: 1 }
);
