import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {brokerActions} from "store";
import {BrokersFragment} from "fragments";
import {FindLoad} from "utils";
import {Loader} from "components";

export const Brokers = ({}) => {
    const dispatch = useDispatch()
    const brokers = useSelector((state) => state.broker.brokerList);
    const loader = FindLoad('GET_BROKERS')

    useEffect(() => {
        dispatch(brokerActions.getBrokers())
    }, [])

    if (loader?.length) {
        return <div style={{minHeight: '100vh'}}><Loader/></div>
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Commercial Real Estate Brokers | Retail Leasing</title>
                <meta name="description"
                      content="A commercial realtor or broker can help you navigate the complex process of buying or selling commercial property in los Angeles and Orange County."
                />
            </Helmet>
            <BrokersFragment
                brokers={brokers}
            />
        </div>
    )
}