import {GET_BROKER_BY_ID_SUCCESS, GET_BROKER_MY_PROFILE_SUCCESS, GET_BROKERS_SUCCESS} from "./brokers.types";

const initialState = {
    brokerList: null,
    brokerById: null,
    brokerProfile: null
};

export const brokerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BROKERS_SUCCESS:
            return {
                ...state,
                brokerList: action.payload,
            };
        case GET_BROKER_BY_ID_SUCCESS:
            return {
                ...state,
                brokerById: action.payload,
            };

        case GET_BROKER_MY_PROFILE_SUCCESS:
            return {
                ...state,
                brokerProfile: action.payload,
            };
        default:
            return state;
    }
};
