import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/styles/reset.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import {
  initAxiosInterceptors,
  ListingFiltersContextProvider,
  RegisterContextProvider,
  ViewAsContextProvider,
} from "./utils";
import { AvatarContextProvider } from "utils/contexts/avatarContext";

initAxiosInterceptors();
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <RegisterContextProvider>
          <ViewAsContextProvider>
            <ListingFiltersContextProvider>
              <AvatarContextProvider>
                <App />
              </AvatarContextProvider>
            </ListingFiltersContextProvider>
          </ViewAsContextProvider>
        </RegisterContextProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// serviceWorker.register()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
