import { Images } from 'utils';

export const enumValues = {
    NEWEST_OPTIONS: [
        { id: '0', label: 'Newest', filterSort: 'createdAt' },
        { id: '1', label: 'Oldest', filterSort: '-createdAt' },
        {
            id: '2',
            label: 'Price',
            filterSort: 'price',
            icon: Images.downUpArrows,
            iconSelected: Images.downUpArrowsBlue,
        },
        {
            id: '3',
            label: 'Price',
            filterSort: '-price',
            icon: Images.upDownArrows,
            iconSelected: Images.upDownArrowsBlue,
        },
        {
            id: '4',
            label: 'Size',
            filterSort: 'size',
            icon: Images.downUpArrows,
            iconSelected: Images.downUpArrowsBlue,
        },
        {
            id: '5',
            label: 'Size',
            filterSort: '-size',
            icon: Images.upDownArrows,
            iconSelected: Images.upDownArrowsBlue,
        },
    ],
    PROPERTY_TYPES: [
        { label: 'Industrial Estate', value:'INDUSTRIAL',
            isChecked: false,
            image: Images.retail,
            description:'Discover commercial industrial spaces for lease by owner in Los Angeles. Our listings offer ' +
                'quick, efficient solutions.'
        },
        { label: 'Office Space', value:'OFFICE', isChecked: false, image: Images.officeIcon,
            description:'Elevate your business with our modern commercial office spaces for lease, featuring' +
                ' modern workspaces for every kind of venture.'
        },
        { label: 'Retail Space', value:'RETAIL', isChecked: false, image: Images.rectangleIcon,
            description:"Expand your retail potential with prime commercial properties for \n" +
                "lease. Find high-visibility, strategic spaces in the US, offered by \n" +
                "owners for optimal exposure."
        },
        { label: 'Other',value:'OTHER', isChecked: false },
    ],
    DEFAULT_PROPERTY_TYPES: [
        { label: 'Industrial Estate', value:'INDUSTRIAL',isChecked: false, image: Images.retail,
            description:'Discover commercial industrial spaces for lease by owner in Los Angeles. Our listings offer quick, efficient solutions.'
        },
        { label: 'Office Space', value:'OFFICE', isChecked: false, image: Images.officeIcon,
            description:'Elevate your business with our modern commercial office spaces for lease, featuring modern workspaces for every kind of venture.'
        },
        { label: 'Retail Space', value:'RETAIL', isChecked: false, image: Images.rectangleIcon,
            description:"Expand your retail potential with prime commercial properties for \n" +
                "lease. Find high-visibility, strategic spaces in the US, offered by \n" +
                "owners for optimal exposure."
        },
        { label: 'Other',value:'OTHER', isChecked: false },
    ],
};
