import {makeStyles} from '@material-ui/core/styles';
import {Colors} from 'utils';

export const homeFragmentStyles = makeStyles(
    () => ({
        homeFragmentStyle: {
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            '& > div': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
            '& > div > div': {
                // maxWidth: '1440px',
                width: '100%',
                // '@media(max-width: 1200px)': {
                //     paddingLeft: '70px',
                //     paddingRight: '70px',
                // },
                // '@media(max-width: 768px)': {
                //     paddingLeft: '37px',
                //     paddingRight: '37px',
                // },
                // '@media(max-width: 400px)': {
                //     paddingLeft: '16px',
                //     paddingRight: '16px',
                // },
            },
        },
        firstBoxStyle: {
            position: 'relative',

        },
        grayWrapperStyle: {
            display: 'flex',
            flexDirection: 'column',
            background: 'linear-gradient(180deg, #FAFAFA 0%, #FFFFFF 45.31%, rgba(245, 245, 245, 0) 90.79%, rgba(244, 244, 244, 0) 100%)',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            marginTop: '60px',

            // '@media(max-width: 1200px)': {
            //     paddingLeft: '70px',
            //     paddingRight: '70px',
            // },
            // '@media(max-width: 768px)': {
            //     paddingLeft: '37px',
            //     paddingRight: '37px',
            // },
            // '@media(max-width: 400px)': {
            //     paddingLeft: '16px',
            //     paddingRight: '16px',
            // },
        },
        firstBoxContainerStyle: {
            // padding: '32px 80px 112px',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
            '& > img': {
                // '@media(max-width: 768px)': { width: '200px' },
            },
        },
        homePageHeroStyle: {
            width: '100%',
            objectFit: 'cover',
            height: '75vh',
            '@media(min-width: 320px)': {
                height: '50vh',
            },
            '@media(min-width: 768px)': {
                height: '60vh',
            },
            '@media(min-width: 1279px)': {
                height: '75vh',
            },
        },
        homeTitleStyle: {
            fontWeight: 800,
            color: Colors.TextWhite,
            position: 'absolute',
            textAlign: 'center',
            textShadow: '0px 2px 2px rgba(0, 0, 0, 0.7)',
            marginTop: '87px',
            fontSize: '24px',
            lineHeight: '36px',
            padding: '0 16px ',

            '@media(min-width: 320px)': {
                marginTop: '90px',
            },
            '@media(min-width: 768px)': {
                marginTop: '150px',
                fontSize: '32px',
                lineHeight: '40px',
            },
            '@media(min-width: 1279px)': {
                marginTop: '200px',
                fontSize: '40px',
                lineHeight: '60px',
            },

            '& img': {
                marginTop: '8px',
                marginRight: '8px',
                '@media(max-width: 768px)': {
                    marginTop: '0px',
                },
            },

        },
        homeSubtitleStyle: {
            maxWidth: '628px',
            width: '100%',
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextGray,
            lineHeight: '24px',
            textAlign: 'center',
        },
        searchListingBoxStyle: {
            marginTop: '24px',
            // position: 'absolute',
            // bottom: '0px',
            // left: '50%',
            // transform: 'translate(-50%, 50%)',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            // backgroundColor: Colors.BackgroundWhite,
            // boxShadow: '0px 2px 8px #00000029',
            // borderRadius: '12px',
            // padding: '24px',
            '@media(max-width: 768px)': {
                flexDirection: 'column',
                alignItems: 'start',
            },
        },

        searchButtonsWrapper: {
            display: 'flex',
            '@media(min-width: 320px)': {
                flexDirection: 'column',
            },
            '@media(min-width: 768px)': {
                flexDirection: 'row'
            }
        },

        searchListingWrapperStyle: {
            display: 'flex',
            '@media(min-width: 320px)': {
                flexDirection: 'column',
            },
            '@media(min-width: 1279px)': {
                flexDirection: 'row'
            }

        },

        advancedWrapper: {
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        },

        secondBoxContainerStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '176px 80px 128px',
            '@media(max-width: 1380px)': {
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '120px 80px 80px',
            },
            '@media(max-width: 768px)': {
                flexDirection: 'column-reverse',
                alignItems: 'flex-start',
            },
        },
        advanced: {
            display: 'flex',
            alignItems: 'center',
            background: 'transparent',
            border: 'none',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#2B273C',
            marginTop: '14px',

            '& img': {
                marginLeft: '8px'
            }

        },
        citySearchBoxStyle: {
            display: 'flex',
            alignItems: 'center',
        },
        homePageSearchBoxStyle: {
            margin: '0px 12px',
            '& > div > div': {
                width: '453px',
                height: '48px',
                backgroundColor: Colors.BackgroundWhite,
                borderRadius: '6px',
                border: '1px solid #9E9CA6',
                padding: '0px 16px',
                '&.focused': {
                    border: '1px solid #757280B3',
                    boxShadow: 'unset',
                    width: '453px',
                    '@media(max-width: 1024px)': {width: '316px'},
                    '@media(max-width: 768px)': {width: '243px'},
                },
                '& > button': {display: 'none'},
                '@media(max-width: 1024px)': {width: '316px'},
                '@media(max-width: 768px)': {width: '243px'},
            },
            '@media(max-width: 768px)': {marginLeft: '0px'},
            '@media(max-width: 650px)': {marginTop: '0px !important'},
        },
        searchBoxDropdownStyle: {
            width: '453px !important',
            top: '80px !important',
            '@media(max-width: 1024px)': {width: '316px !important'},
            '@media(max-width: 768px)': {
                width: '243px !important',
                top: '60px !important',
            },
        },
        searchAddressButnStyle: {
            padding: '0 24px 0 16px',
            width: '165px',
            height: '48px',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.ThemePink,
            border: 'none',
            transition: 'background-color 0.2s linear',
            '&:hover': {backgroundColor: '#d52f4c'},
            '@media(max-width: 1024px)': {
                width: '56px',
                justifyContent: 'space-between',
            },
            '& span': {
                fontSize: '16px',
                fontWeight: 500,
                color: Colors.TextWhite,
                '@media(max-width: 1024px)': {display: 'none'},
            },
        },
        forLeaseButton: {
            padding: '0 24px 0 16px',
            width: '129px',
            height: '48px',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: Colors.ThemePink,
            border: 'none',
            transition: 'background-color 0.2s linear',
            // '&:hover': {backgroundColor: '#d52f4c'},
            // '@media(max-width: 1024px)': {width: '56px'},
            '& span': {
                fontSize: '16px',
                fontWeight: 500,
                color: Colors.TextWhite,
            },
            '@media(max-width: 1279px)': {
                marginBottom: '12px',

            },
        },
        imagesBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > img': {
                marginRight: '40px',
                '@media(max-width: 768px)': {
                    marginBottom: '32px',
                    marginRight: '0px',
                    width: '200px',
                },
            },
            marginRight: '125px',
            '@media(max-width: 1380px)': {
                marginRight: '0px',
                marginBottom: '40px',
            },
            '@media(max-width: 768px)': {
                marginBottom: '0px',
                marginTop: '32px',
                flexDirection: 'column',
            },
        },
        offersListStyle: {
            width: '240px',
            '@media(max-width: 768px)': {alignSelf: 'flex-start'},
            '& li': {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            },
            '& li:not(:last-of-type)': {
                marginBottom: '65px',
                '@media(max-width: 768px)': {marginBottom: '24px'},
            },
            '& li:nth-child(2)': {
                justifyContent: 'flex-end',
                '@media(max-width: 768px)': {justifyContent: 'flex-start'},
            },
            '& li span': {
                fontSize: '18px',
                fontWeight: 600,
                color: Colors.TextBlack,
            },
            '& li div': {
                width: '40px',
                height: '40px',
                borderRadius: '8px',
                marginRight: '16px',
                backgroundColor: '#FF385C',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        havePropBoxStyle: {
            maxWidth: '628px',
            width: '100%',
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: '12px',
            padding: '40px',
        },
        havePropTitle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
            marginBottom: '16px',
        },
        havePropText: {
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextGray,
            lineHeight: '24px',
        },
        thirdBoxStyle: {
            maxWidth: '1440px',
            padding: '0 16px',

            '@media(min-width: 320px)': {
                padding: '0 16px',
            },
            '@media(min-width: 768px)': {
                padding: '0 32px',
            },
            '@media(min-width: 1279px)': {
                padding: '0 60px',
            },
        },
        thirdBoxContainerStyle: {
            // marginTop:'70px'
            // padding: '70px 80px 40px',
            '@media(min-width: 320px)': {
                marginTop: '32px'
            },
            '@media(min-width: 768px)': {
                marginTop: '80px'
            },
            '@media(min-width: 1279px)': {
                marginTop: '70px'
            },
        },
        createListingTitleStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
            textAlign: 'center',
            '& em': {color: '#FF385C'},

            '@media(min-width: 320px)': {
                textAlign: 'start',
                marginBottom: '32px',
            },
            '@media(min-width: 768px)': {
                textAlign: 'start',
                marginBottom: '40px',
            },
            '@media(min-width: 1279px)': {
                textAlign: 'center',
                marginBottom: '70px',
            },
        },
        yourFreeListingInfoStyle: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
            gap: '40px',
            '@media(min-width: 320px)': {
                gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
            },
            '@media(min-width: 1279px)': {
                gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
            },

        },
        fourthBoxContainerStyle: {
            // padding: '128px 0 128px 80px',
            marginTop: '128px',
            paddingRight: '0 !important',

            '@media(min-width: 320px)': {
                marginTop: '70px',
                paddingLeft: '16px',
            },
            '@media(min-width: 768px)': {
                marginTop: '80px',
                paddingLeft: '32px',
            },
            '@media(min-width: 1279px)': {
                marginTop: '128px',
                paddingLeft: '60px',
            },
        },
        browseCategoriesTitleStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
            marginBottom: '40px',
        },
        chooseCategoriesDesc: {
            fontSize: '18px',
            fontWeight: 400,
            color: Colors.TextGray,
            marginBottom: '30px'
        },
        categoryBoxStyle: {
            width: '400px ',
            height: '501px',
            marginRight: '40px !important',
            borderRadius: '16px',
            overflow: 'hidden',
            backgroundColor: Colors.BackgroundWhite,
            boxShadow: '0px 0px 6px #00000029',
            display: "flex",
            flexDirection: 'column',

            '& > img': {
                width: '400px',
                height: '260px',
                objectFit: 'cover',
                // marginBottom: '40px',
                '@media(max-width: 760px)': {
                    width: '254px',
                    height: '180px',
                },
            },
            '@media(max-width: 760px)': {
                width: '255px',
                height: '415px',
            },
        },

        titleDescriptionWrapper: {
            width: '100%',
            height:'100%',
            padding: '24px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between'
        },
        viewMoreButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '44px',
            border: '1px solid #FF385C',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FF385C',
            background: 'transparent',
            marginTop: '10px',

            '& img': {
                marginLeft: '10px',
            }
        },
        categoryTitleStyle: {
            fontSize: '24px',
            fontWeight: 768,
            color: Colors.TextBlack,
            marginBottom: '10px',
            // marginLeft: '24px',
        },
        propertyDescription: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            color: Colors.TextGray,
        },
        fifthBoxContainerStyle: {
            paddingRight: '0 !important',

            '@media(min-width: 320px)': {
                paddingLeft: '16px',
                margin: '0 0 70px 0',
            },
            '@media(min-width: 768px)': {
                paddingLeft: '32px',
                margin: '0 0 80px 0',
            },
            '@media(min-width: 1279px)': {
                paddingLeft: '60px',
                margin: '0 0 128px 0',
            },
        },
        latestListingsBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '40px',
            paddingRight: '80px',
            '@media(max-width: 1200px)': {paddingRight: '70px'},
            '@media(max-width: 768px)': {paddingRight: '37px'},
            '@media(max-width: 400px)': {paddingRight: '16px'},
        },
        latestListingsTitleStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
        },
        viewAllLinkStyle: {
            fontSize: '16px',
            fontWeight: 600,
            color: '#FF385C',
            background: 'transparent',
            border: 'none',
        },
        latestListingsStyle: {
            width: '100%',
            display: 'flex !important',
            alignItems: 'center',
        },
        listingsCardItemStyle: {
            '@media(max-width: 767px)': {
                width: '302px !important',
                height: '346px',
            },
            '& > div > img': {
                width: '302px',
                height: '259px',
            },
        },

        sliderWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '@media(min-width: 320px)': {
                margin: '0 0 70px 0',
            },
            '@media(min-width: 768px)': {
                margin: '0 0 80px 0',
            },
            '@media(min-width: 1279px)': {
                margin: '0 0 128px 0',
            },

            '& .slider-list': {
                marginBottom: '25px!important',
            },
        },

        cards: {
            '@media (min-width: 320px)': {
                marginRight: '16px',
            },
            '@media (min-width: 768px)': {
                marginRight: '16px',
            },
            '@media (min-width: 1240px)': {
                marginRight: '19px',
            },
            '@media (min-width: 1920px)': {
                marginRight: '20px',
            },
        },

        homPageDropdownBoxStyle: {
            width: '300px',
            borderRadius: '6px !important',
            border: '1px solid #757280B3',
            backgroundColor: Colors.BackgroundWhite,
            '& > div': {
                padding: '13px 16px',
                justifyContent: 'space-between',
                '& > h6': {
                    fontSize: '16px',
                    color: '#757280B3',
                },
            },
            '@media(max-width: 1200px)': {display: 'block !important'},
            '@media(max-width: 1024px)': {width: '200px'},
            '@media(max-width: 768px)': {
                width: '311px',
                marginBottom: '16px',
            },
        },

        homPageDropdownStyle: {
            width: '300px !important',
            top: '80px !important',
            padding: '8px 0px !important',
            borderRadius: '6px !important',
            '& > label > div': {display: 'none !important'},
            '& > label > span': {fontSize: '16px !important'},
            '@media(max-width: 1024px)': {width: '200px !important'},
            '@media(max-width: 768px)': {
                width: '311px !important',
                top: '60px !important',
            },
        },
        pointsBoxStyle: {display: 'none'},

        fAQTitle: {
            fontSize: 32,
            color: `${Colors.TextBlack}`,
            fontWeight: 800,
            textAlign: 'center',
        },
        accordionWrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '15px',
            padding: '72px 0 172px',

            '@media(max-width: 1279px)': {
                padding: '32px 0 72px',
            },
        },
        accordion: {
            width: '100%',
            maxWidth: '780px',
            borderRadius: '8px !important',
            boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.10) !important',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                transform: 'rotate(90deg) !important'
            },
            '&::before': {
                height: '0px !important'
            }
        },


        accordionTitle: {
            color: `${Colors.TextBlack2}`,
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '27px',
        },

        accordionDescription: {
            color: `${Colors.TextBlack2}`,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
        },
    }),
    {index: 1}
);
