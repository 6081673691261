import React, { useContext, useState } from "react";
import {
  CreateUserInputs,
  ForgotPassword,
  Welcome,
  UserSignIn,
  CheckEmail,
} from "./core";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  Loader,
  MyProfileWrapper,
  UserRegistrationWrapper,
} from "components";
import { RegisterContext } from "utils";

export const UserRegistration = ({ onClose }) => {
  const params = useParams();

  const [userEmail, setUserEmail] = useState();
  const triggerUserEmail = (email) => setUserEmail(email);

  const httpOnLoad = useSelector((state) => state.httpOnLoad);
  // const isLoading = httpOnLoad.length && httpOnLoad[0] === "GET_USER_BY_ID";
  // const isEditUserPage = window.location.pathname === `/edit_user-${params.id}`;
  // const userById = useSelector((state) => state.users.userById);

  const registerCtx = useContext(RegisterContext);
  const { modalContent } = registerCtx;

  // isEditUserPage ? (
  //   isLoading ? (
  //     <Loader />
  //   ) : (
  //     <MyProfileWrapper user={userById}>
  //       <CreateUserInputs info={userById} />
  //     </MyProfileWrapper>
  //   )
  // ) :

  return modalContent === "signUp" ? (
    <UserRegistrationWrapper title={"Sign up to Croil"}>
      <CreateUserInputs closeModal={onClose} />
    </UserRegistrationWrapper>
  ) : modalContent === "forgotPass" ? (
    <UserRegistrationWrapper title={"Forgot your password?"}>
      <ForgotPassword onTrigger={triggerUserEmail} />
    </UserRegistrationWrapper>
  ) : modalContent === "checkEmail" ? (
    <UserRegistrationWrapper title={"Please Check your Email"}>
      <CheckEmail userEmail={userEmail} closeModal={onClose} />
    </UserRegistrationWrapper>
  ) : modalContent === "welcome" ? (
    <UserRegistrationWrapper title={"Welcome to Croil!"}>
      <Welcome closeModal={onClose} />
    </UserRegistrationWrapper>
  ) : modalContent === "signIn" ? (
    <UserRegistrationWrapper title={"Sign in to Croil"}>
      <UserSignIn closeModal={onClose} />
    </UserRegistrationWrapper>
  ) : null;
};
