import { makeStyles } from "@material-ui/core/styles";

export const customBreadcrumbsStyle = makeStyles(
  () => ({
    customBreadcrumbsStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    breadcrumbsContainerStyle: {
      maxWidth: "1440px",
      width: "100%",
      padding: "50px 80px 0px",
      "@media(max-width: 1200px)": {
        paddingLeft: "70px",
        paddingRight: "70px",
      },
      "@media(max-width: 768px)": {
        paddingLeft: "37px",
        paddingRight: "37px",
      },
      "@media(max-width: 400px)": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    breadcrumbsStyle: {
      marginBottom: "32px !important",
      "@media(max-width: 705px)": {
        marginBottom: "24px !important",
      },
    },
    parentStyle: {
      fontSize: "16px !important",
      fontWeight: "500 !important",
      color: "#757280 !important",
      textDecoration: "none !important",
      cursor: "pointer",
    },
    childStyle: {
      fontSize: "16px !important",
      fontWeight: "500 !important",
      color: "#2B273C !important",
      cursor: "default",
    },
  }),
  { index: 1 }
);
