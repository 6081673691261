import React, { useEffect, useState } from 'react';
import { SimpleTooltip } from 'components';
import { inputStyle } from './style';
import { PassValidityContent } from '../tooltip/core';

export const UserInput = ({
    required,
    inputClassName,
    inputLabel,
    inputType,
    inputName,
    inputValue = '',
    onInputChange,
    inputPlaceholder,
    inputError,
    charCounterIsShown,
    passwordInput,
    areaLabel,
    hasMinSixChars,
    hasOneNum,
    hasOneUppercase,
                              disabled,
}) => {
    const classes = inputStyle();

    const [passwordIsShown, setPasswordIsShown] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const passwordIsValid = hasMinSixChars && hasOneNum && hasOneUppercase;

    useEffect(() => {
        const timerId = setTimeout(() => setIsValid(passwordIsValid), 1000);
        return () => clearTimeout(timerId);
    }, [passwordIsValid]);

    return (
        <div className={`${classes.userInputBoxStyle} ${inputClassName}`}>
            <label className={classes.userInputLabelStyle}>
                <p className={required ? 'required' : ''}> {inputLabel} </p>
                <input
                    disabled={disabled}
                    type={passwordIsShown ? 'text' : inputType}
                    className={`${classes.userInputStyle} ${inputError ? 'error' : ''}`}
                    name={inputName}
                    value={inputValue}
                    onChange={onInputChange}
                    onWheel={(evt) => inputType === 'number' && evt.target.blur()}
                    autoComplete="off"
                    placeholder={inputPlaceholder}
                />
                {passwordInput && (
                    <i
                        className={`${classes.eyeIconStyle} ${passwordIsShown ? 'show' : ''}`}
                        onClick={() => setPasswordIsShown((prevState) => !prevState)}
                    />
                )}
            </label>
            {passwordInput &&
                <SimpleTooltip
                    title={'password validity'}
                    content={
                        <>
                            <PassValidityContent
                                isShown={passwordInput && areaLabel === 'create' && !isValid}
                                hasMinSixChars={hasMinSixChars}
                                hasOneNum={hasOneNum}
                                hasOneUppercase={hasOneUppercase}
                            />
                        </>
                    }
                />
            }
            <div className={classes.userInputErrorBoxStyle}>
                <p className={classes.userInputErrorStyle}>{inputError}</p>
                {charCounterIsShown && <p className={classes.charCounterStyle}> {`${inputValue.length}/50`} </p>}
            </div>
        </div>
    );
};
