import React from "react";
import { tooltipCoresStyle } from "./styles";

export const PassValidityContent = ({
  isShown,
  hasMinSixChars,
  hasOneNum,
  hasOneUppercase,
}) => {
  const classes = tooltipCoresStyle();

  return (
    <div
      className={`${classes.passValidityContentStyle} ${
        isShown ? "active" : ""
      }`}
    >
      <div className={classes.tooltipArrowStyle} />
      <h6 className={classes.validityTitleStyle}>
        Your password must contain:
      </h6>
      <ul className={classes.validityListStyle}>
        <li className={hasMinSixChars ? "active" : ""}>Minimum 8 characters</li>
        <li className={hasOneNum ? "active" : ""}>At least 1 number</li>
        <li className={hasOneUppercase ? "active" : ""}>
          At least 1 uppercase letter
        </li>
      </ul>
    </div>
  );
};
