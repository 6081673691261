import React, { useEffect, useState } from 'react';
import { userRegistrationCoreStyles } from './styles';
import { FindLoad, getPassErrorText, isNotEmpty } from 'utils';
import { Logo, SendButton, UserInput } from 'components';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';

export const ResetPassword = () => {
    const classes = userRegistrationCoreStyles();
    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({
        newPassword: '',
        confirmation: '',
    });
    const [error, setError] = useState('');
    const [resetToken, setResetToken] = useState('');

    const resetPasswordLoader = FindLoad('RESET_PASSWORD_REQUEST');

    useEffect(() => {
        const link = window.location.search;
        if (link.search('resetToken=') !== -1) {
            const pos = link.slice(link.search('resetToken='));
            const resetToken = pos.substring(11);
            setResetToken(resetToken);
        }
    }, []);

    const errorMsg = 'This field must be not empty!';
    const newPasswordErrorMsg = getPassErrorText(inputs.newPassword);
    const confirmNewPasswordErrorMsg = inputs.confirmNewPassword !== inputs.newPassword ? 'Passwords do not match!' : '';
    const newPasswordErrorText = error === 'newPassword' ? errorMsg : error === newPasswordErrorMsg ? newPasswordErrorMsg : '';
    const confirmNewPasswordErrorText =
        error === 'confirmNewPassword' ? errorMsg : error === confirmNewPasswordErrorMsg ? confirmNewPasswordErrorMsg : '';

    const hasMinSixChars = isNotEmpty(inputs.newPassword) && inputs.newPassword.trim().length >= 8;
    const hasOneNum = isNotEmpty(inputs.newPassword) && /\d/.test(inputs.newPassword);
    const hasOneUppercase = isNotEmpty(inputs.newPassword) && /[A-Z]/.test(inputs.newPassword);

    const handleChange = (evt) => {
        setInputs((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value,
        }));
        if (error === evt.target.name || error === newPasswordErrorMsg || error === confirmNewPasswordErrorMsg) {
            setError('');
        }
    };

    const handlePasswordReset = () => {
        const newPasswordData = {
            newPassword: inputs.newPassword,
            confirmation: inputs.confirmNewPassword,
            token: resetToken,
        };
        const newPasswordIsValid = inputs.newPassword && hasMinSixChars && hasOneNum && hasOneUppercase;
        const confirmNewPasswordIsValid = inputs.confirmNewPassword && inputs.confirmNewPassword === inputs.newPassword;
        const errorText = !inputs.newPassword
            ? 'newPassword'
            : !newPasswordIsValid
            ? newPasswordErrorMsg
            : !inputs.confirmNewPassword
            ? 'confirmNewPassword'
            : !confirmNewPasswordIsValid
            ? confirmNewPasswordErrorMsg
            : 'Input is not field';
        if (newPasswordIsValid && confirmNewPasswordIsValid) {
            dispatch(authActions.resetPassword(newPasswordData));
        } else {
            setError(errorText);
        }
    };

    return (
        <div className={classes.resetPasswordBoxStyle}>
            <div className={classes.resetPassContainerStyle}>
                <Logo />
                <div className={classes.resetPassFormStyle}>
                    <h2 className={classes.registerTitleStyle}> Reset Password </h2>
                    <h6 className={classes.registerInfoStyle}>Please enter a new password below to reset password.</h6>
                    <div>
                        <UserInput
                            required={true}
                            inputClassName={'inModal'}
                            inputLabel={'New Password'}
                            inputType={'password'}
                            inputName={'newPassword'}
                            inputValue={inputs.newPassword}
                            onInputChange={handleChange}
                            inputError={newPasswordErrorText}
                            passwordInput={isNotEmpty(inputs.newPassword)}
                            areaLabel={'create'}
                            hasMinSixChars={hasMinSixChars}
                            hasOneNum={hasOneNum}
                            hasOneUppercase={hasOneUppercase}
                        />
                        <UserInput
                            required={true}
                            inputClassName={'inModal'}
                            inputLabel={'Confirm New Password'}
                            inputType={'password'}
                            inputName={'confirmNewPassword'}
                            inputValue={inputs.confirmNewPassword}
                            onInputChange={handleChange}
                            inputError={confirmNewPasswordErrorText}
                            passwordInput={isNotEmpty(inputs.confirmNewPassword)}
                        />
                        <SendButton
                            butnClassName={classes.resetPassButnStyle}
                            butnType={'button'}
                            butnDisabled={false}
                            butnSendingText={'Sign In'}
                            loader={!!resetPasswordLoader.length}
                            onClickButn={handlePasswordReset}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
