import {call, put, takeLatest} from 'redux-saga/effects';
import {paymentAuthService} from './payment.service';
import {authActions} from '..';
import {httpRequestsOnErrorsActions} from '../http_requests_on_errors';
import {httpRequestsOnLoadActions} from '../http_requests_on_load';
import {httpRequestsOnSuccessActions} from '../http_requests_on_success';
import {
    CREATE_PAYMENT,
    DELETE_PAYMENT,
    GET_INVOICES,
    GET_INVOICES_SUCCESS,
    GET_PAYMENT,
    GET_PAYMENT_SUCCESS, IMPRESSION_REQUEST
} from "./payment.types";

/** Payment Actions  */

function* createPaymentSaga({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(paymentAuthService.createPaymentService, payload.body);
        yield put({
            type: GET_PAYMENT,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getPaymentSaga({type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(paymentAuthService.getPaymentService);
        yield put({
            type: GET_PAYMENT_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getInvoicesSaga({type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(paymentAuthService.getInvoicesService);
        yield put({
            type: GET_INVOICES_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteInvoicesSaga({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(paymentAuthService.deletePaymentService);
        yield put({
            type: GET_PAYMENT,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* impressionRequestSaga({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(paymentAuthService.impressionService, payload.id);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/** End */

export const watchPayment = function* watchPaymentSaga() {
    /** Payment Actions  */
    yield takeLatest(CREATE_PAYMENT, createPaymentSaga);
    yield takeLatest(GET_PAYMENT, getPaymentSaga);
    yield takeLatest(GET_INVOICES, getInvoicesSaga);
    yield takeLatest(DELETE_PAYMENT, deleteInvoicesSaga);
    yield takeLatest(IMPRESSION_REQUEST, impressionRequestSaga);
    /** End  */
};
