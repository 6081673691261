import React from "react";
import {HomeFragment} from "fragments";
import {Helmet} from "react-helmet";

export const Home = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Commercial Real Estate & Offices for Lease</title>
                <meta name="description"
                      content="Commercial Real Estate available for lease. Search easily for offices, retail and industrial properties in Los Angeles and Orange County."
                />
            </Helmet>
            <HomeFragment/>
        </div>
    );
};
