import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const billingStyles = makeStyles(() => ({
    billingFragmentsWrapper: {
        display: 'flex',
        margin: '0 auto',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    whiteWrapper: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '12px',
        padding: '24px',
        marginTop: '24px',
        width: '100%',
        maxWidth: '897px',
        height: 'auto',
        '@media(min-width: 320px)': {
            padding: '16px',
        },
        '@media(min-width: 768px)': {
            padding: '24px',
        },
    },
    changePayment: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        margin: '16px 0'
    },
    paymentCardWrapper: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '4px',
        padding: '16px',
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '@media(min-width: 320px)': {
            flexDirection: 'column'
        },
        '@media(min-width: 768px)': {
            flexDirection: 'row'
        },
    },
    cardInfo: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    cardNumberYear: {
        marginLeft: '16px',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '@media(min-width: 320px)': {
            justifyContent: 'flex-end',
            marginTop: '24px',
        },
        '@media(min-width: 768px)': {
            width: 'auto',
            marginTop: '0'
        },
        '& button': {
            border: 'none',
            background: 'transparent',
            alignItems: 'center',
            display: 'flex',
            '& p': {
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '24px',
            },
            '& img': {
                marginRight: '6px',
            },
        },
    },
    edit: {
        color: '#0073BB',
        marginRight: '10px',
    },
    delete: {
        color: '#FE385C',
    },
    invoicesHead: {
        height: '50px',
        padding: '15px 16px',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2B273C',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '24px 0 8px',
    },
    invoicesBody: {
        borderRadius: '4px',
        marginBottom: '8px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#757280',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        padding: '14px 16px',
        alignItems: 'center',
    },
    actionButtonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& button': {
            border: 'none',
            background: 'transparent',
        }
    },
    paymentModal: {
        position: 'fixed',
        bottom: '50%',
        right: '50%',
        transform: 'translate(50%, 50%)',
        zIndex: 99999,
        maxWidth: '464px',
        width: '90%',
        maxHeight: '500px',
        height: 'auto',
        overflow: 'auto',
        paddingTop: '16px',
        backgroundColor: Colors.BackgroundWhite,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        '& button': {
            background: 'transparent',
            border: 'none',

        }
    },
    deleteTitle: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2B273C',
    },
    deleteSubTitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
        margin: '32px 0'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    desktopView: {
        height:'300px',
        overflow:'auto',
        padding:'0 6px',
        '@media(min-width: 320px)': {
            display: 'none',
        },
        '@media(min-width: 768px)': {
            display: 'block',
        },
    },
    mobileView: {
        height:'420px',
        overflow:'auto',
        padding:'0 6px',
        '@media(min-width: 320px)': {
            display: 'block',
        },
        '@media(min-width: 768px)': {
            display: 'none',
        },
    },
    textAndDescription: {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2B273C',
        marginBottom: '12px',
        '& span': {
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#757280',
        },
    },
    noInvoicesYet: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#757280',
        height: '200px',
    },
    addCardWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    addButton: {
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FE385C',
        cursor:'pointer',
        '& img':{
            marginRight:'8px'
        },
    },
    pdfReader:{
        width:'100%',
        height:'100%',
        position:'fixed',
        background:'rgb(50, 54, 57)',
        top:'0',
        zIndex:999999
    },
    closeFiltersModalStyle:{
        width:'30px',
        height:'30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:'white',
        borderRadius:'40px',
        border:'none',
    }

}))