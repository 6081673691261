import { SendButton } from "components";
import React from "react";
import { useHistory } from "react-router";
import { userRegistrationCoreStyles } from "./styles";

export const Welcome = ({ closeModal }) => {
  const classes = userRegistrationCoreStyles();

  const history = useHistory();

  return (
    <>
      <h6 className={classes.registerInfoStyle}>
        You are all set up. Now you're ready to start your journey within Croil.
      </h6>
      <SendButton
        butnClassName={classes.doneButnStyle}
        butnType={"button"}
        butnDisabled={false}
        butnSendingText={"Done"}
        onClickButn={() => {
          history.push("/");
          closeModal();
        }}
      />
    </>
  );
};
