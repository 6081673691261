import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const buttonsStyle = makeStyles(
    () => ({
        viewMoreButnStyle: {
            paddingTop: "8px",
            paddingBottom: "8px",
            border: "1px solid #FE385C",
            borderRadius: "8px",
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "center",
        },
        saveOrCancelButnStyle: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& button": {
                border: "none",
                paddingTop: "8px",
                paddingBottom: "8px",
                borderRadius: "8px",
                fontWeight: 500,
                width: "156px",
                textAlign: "center",
            },
        },
        saveButnStyle: {
            backgroundColor: "#FF385C",
            color: Colors.TextWhite,
            fontSize: "14px",
            transition: "background-color 0.2s linear",
            "&.delete": {backgroundColor: "#FF385C !important"},
            "&:hover": {backgroundColor: "#d52f4c"},
        },
        sendButnStyle: {
            padding: "8px",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            color: Colors.TextWhite,
            textTransform: "capitalize",
            borderRadius: "8px",
            backgroundColor: "#FF385C",
            border: "none",
            outline: "none",
            transition: "background-color 0.2s linear",
            "&:hover": {backgroundColor: "#d52f4c"},
        },
        cancelButnStyle: {
            backgroundColor: "inherit",
            textDecoration: "underline",
            fontSize: "16px",
        },
        closeModalButnStyle: {
            border: "none",
            backgroundColor: "inherit",
            position: "absolute",
            top: "16px",
            left: "16px",
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "@media(max-width: 860px)": {left: "60px"},
        },
        userActionButnStyle: {
            border: "none",
            outline: "none",
            backgroundColor: "inherit",
            padding: 0,
            "&:hover": {textDecoration: "underline"},
        },
        alignCenter: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
        },
        cancelButton: {
            width: '188px',
            height: '40px',
            background: 'rgba(117, 114, 128, 0.5)',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF',
            border:'none',
        },
        createButton: {
            width: '188px',
            height: '40px',
            background: '#FF0331',
            borderRadius: '8px',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#FFFFFF',
            border:'none',
        },
    }),
    {index: 1}
);
