import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const homeFragmentCoreStyle = makeStyles(
    () => ({
        // ** free listing style **
        yourFreeListingStyle: {


            '@media(min-width: 320px)': {
                // width:'300px',
                width: '100%',
            },
            '@media(min-width: 768px)': {
                width: '100%',
            },
            // '@media(min-width: 1279px)': {
            //     marginTop:'70px'
            // },
        },
        subtitleStyle: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
        },
        iconStyle: {
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: "#FF385C1A",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "16px",
        },
        titleStyle: {
            fontSize: "18px",
            fontWeight: 800,
            color: Colors.TextBlack,
        },
        textStyle: {
            fontSize: "16px",
            fontWeight: 500,
            color: Colors.TextGray,
            lineHeight: "24px",
        },
        // ** end **
    }),
    {index: 1}
);
