import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './users.service';
import { authActions } from '..';
import { CREATE_USER, EDIT_USER, GET_USERS, GET_USER_BY_ID, REMOVE_USER, SET_USERS, SET_USER_BY_ID } from './users.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

function* createUser({ type, payload }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        let res = yield call(authService.createUserService, payload.body);
        const data = {
            email: payload.body.email,
            password: payload.body.password,
        };

        const pageEvent = {
            event: "SIGN_UP",
            user_id: res.data?.id,
            userType: "BASIC"
        };
        window?.dataLayer.push(pageEvent);

        yield put(authActions.logIn(data));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getUsers({ type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const { data } = yield call(authService.getUsersService);
        yield put({
            type: SET_USERS,
            payload: { users: data },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* editUser({ type, payload }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.editUserService, payload.id, payload.body);
        yield put({
            type: SET_USER_BY_ID,
            payload: { userById: res?.data },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* removeUser({ type, payload }) {
    const userType = localStorage.getItem('userType')
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.removeUserService, payload.id, userType);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        localStorage.removeItem('access-token');
        localStorage.removeItem('reset-token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userType');
        window.location.replace('/');
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getUserById({ type, payload }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    if(payload?.load !== 'noLoad'){
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const { data } = yield call(authService.getUserByIdService, payload?.id);

        yield put({
            type: SET_USER_BY_ID,
            payload: { userById: data },
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

export const watchUser = function* watchUserSaga() {
    yield takeLatest(CREATE_USER, createUser);
    yield takeLatest(GET_USERS, getUsers);
    yield takeLatest(EDIT_USER, editUser);
    yield takeLatest(REMOVE_USER, removeUser);
    yield takeLatest(GET_USER_BY_ID, getUserById);
};
