import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Helmet} from "react-helmet";
import {ListingsCard, Nav} from 'fragments';
import {CustomBreadcrumbs, Loader} from 'components';
import {FindLoad} from 'utils';

export const Listings = () => {
    const listings = useSelector((state) => state.listings.listingList);
    const loader = FindLoad('GET_LISTINGS');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    if (loader?.length) {
        return <div style={{minHeight: '100vh'}}><Loader/></div>
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Browse Commercial Properties for Lease</title>
                <meta name="description"
                      content="Find offices, industrial spaces, retail places for rent on croill.com. Discover many types of commercial rentals that suit your needs in Los Aneles and Orang County."
                />
            </Helmet>
            <CustomBreadcrumbs/>
            <Nav/>
            <ListingsCard listingList={listings}/>
        </div>
    );
};
