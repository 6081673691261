
import React from "react";
import InputMask from "react-input-mask";
import {TextField} from "@material-ui/core";
import {inputStyle} from "./style";

export const MaskInput = ({name, label, value, disables, handleChange, info, error, mask, styles, notRequired}) => {
    const classes = inputStyle();

    return(
        <div
            className={classes.simpleInput}
            style={{display: 'flex', flexDirection: 'column', width: '100%', ...styles}}
        >

            <p className={classes.titleClass}>{notRequired ? '' : <span>*</span>}Phone Number</p>
            <InputMask
                mask={mask ? mask : "(999) 999-9999"}
                value={value}
                disabled={disables}
                onChange={handleChange}
            >
                {() => <TextField
                    style={{border:error ?  '1px solid #FF385C' : '1px solid #F4F4F4', borderRadius:'8px'} }
                    disabled={disables}
                    variant={'outlined'}
                    label={label}
                    name={name}
                    className={ classes.inputTextField}
                    error={error}
                />
                }
            </InputMask>
            <div className={classes.errorMessage} style={{position:'relative'}}>
                <p>{error}</p>
                {/*<ErrMessage text={error}/>*/}
            </div>
        </div>
    )
}