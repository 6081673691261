import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const listingsStyle = makeStyles(() => ({
    userListingsBoxStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    userListingsBoxContainerStyle: {
      maxWidth: "1440px",
      width: "100%",
      padding: "50px 80px 33px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media(max-width: 1200px)": {
        paddingLeft: "70px",
        paddingRight: "70px",
      },
      "@media(max-width: 768px)": {
        paddingLeft: "37px",
        paddingRight: "37px",
      },
      "@media(max-width: 400px)": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    myListingsTitleStyle: {
      fontSize: "24px",
      fontWeight: 800,
      color: Colors.TextBlack,
    },
    createListingButnStyle: {
      width: "161px",
      border: "none",
      backgroundColor: "#FF385C",
      color: Colors.TextWhite,
      transition: "background-color 0.2s linear",
      "&:hover": { backgroundColor: "#d52f4c" },
    },
  }),
  { index: 1 }
);
