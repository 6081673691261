import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {FindLoad, getValTillTenDig, makeCapitalize, getDataValue, handleFileUpload, hasURL, FindError} from 'utils';
import {SaveOrCancelButton} from 'components';
import {listingActions} from 'store';
import {listingInputsStyle} from './styles';
import {ContactInfo, Description, GeneralInfo, ListingImages, PropertyDetails} from './common';

export const CreateListingInputs = ({info}) => {
    const classes = listingInputsStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const backError = FindError(info ? 'EDIT_LISTING' : 'CREATE_LISTING');
    const createListingLoader = FindLoad('CREATE_LISTING');
    const editListingLoader = FindLoad('EDIT_LISTING');
    const backMessage = backError?.length && backError.find((i) => i?.error === 'Unable to verify the address')
    const [inputs, setInputs] = useState(
        info
            ? {
                ...info,
                ...info.size,
                address: info.address.formattedAddress,
            }
            : {}
    );
    const [error, setError] = useState('');
    const [selectedPropertyType, setSelectedPropertyType] = useState(info ? makeCapitalize(inputs.propertyType) : '');
    const [selectedLeaseType, setSelectedLeaseType] = useState(
        info ? (inputs.leaseType === 'NNN' ? inputs.leaseType : makeCapitalize(inputs.leaseType)) : ''
    );
    const [chosenImages, setChosenImages] = useState([]);
    const [chosenMainImage, setChosenMainImage] = useState();
    const [chosenImagesToAdd, setChosenImagesToAdd] = useState();
    const [chosenImagesToRemove, setChosenImagesToRemove] = useState();
    const [loaderUpload, setLoaderUpload] = useState(false);
    const errorMsg = 'This field must be not empty!';
    const dropdownErrorMsg = 'One of the options must be chosen!';

    const handleChange = (evt) => {
        const evtTargetValue = evt.target.name === 'propertyTypeDescription' ? getValTillTenDig(evt.target.value, 50) : evt.target.value;
        setInputs((prevState) => ({
            ...prevState,
            [evt.target.name]: evtTargetValue,
        }));
        error === evt.target.name && setError('');
    };

    const handleTextChange = (text, newText) => {
        setInputs((prevState) => ({
            ...prevState,
            description: newText ? getValTillTenDig(newText, 500) : getValTillTenDig(text, 500),
        }));
    };

    const sendListingData = () => {
        const listingFormIsValid =
            !!selectedPropertyType &&
            (selectedPropertyType === 'Other' ? !!inputs.propertyTypeDescription : true) &&
            !!inputs.address &&
            !!selectedLeaseType;

        const errorText = !selectedPropertyType
            ? 'propertyType'
            : selectedPropertyType === 'Other' && !inputs.propertyTypeDescription
                ? 'propertyTypeDescription'
                : !inputs.address
                    ? 'address'
                    : !selectedLeaseType
                        ? 'leaseType'
                        : 'Input is not field';

        const listingData = {
            propertyType: selectedPropertyType.toUpperCase(),
            propertyTypeDescription: getDataValue(inputs.propertyTypeDescription),
            size: {
                start: getDataValue(+inputs.start),
                end: getDataValue(+inputs.end),
            },
            zoning: getDataValue(inputs.zoning),
            lotSize: getDataValue(+inputs.lotSize),
            parking: getDataValue(inputs.parking),
            price: getDataValue(+inputs.price),
            leaseType: selectedLeaseType.toUpperCase(),
            nPrice: getDataValue(+inputs.nPrice),
            description: getDataValue(inputs.description),
            mainImage: chosenMainImage,
            images: chosenImages,
            address: getDataValue(inputs.address),
        };

        if (listingFormIsValid) {
            if (info) {
                delete listingData.images;

                const listingDataEdit = {
                    ...listingData,
                    imagesToAdd: !!chosenImagesToAdd?.length ? chosenImagesToAdd : undefined,
                    imagesToRemove: !!chosenImagesToRemove?.length ? chosenImagesToRemove : undefined,
                };

                if (!!chosenImagesToAdd?.length && !hasURL(chosenImagesToAdd)) {
                    setLoaderUpload(true);

                    if (!!chosenMainImage && !chosenMainImage.url) {
                        Promise.all([handleFileUpload(chosenMainImage), handleFileUpload(chosenImagesToAdd)]).then((response) => {
                            setLoaderUpload(false);

                            const uploadedMainImage = response[0].data;
                            const uploadedImagesToAdd = response[1].data;

                            const listingDataEditWithFileDTO = {
                                ...listingDataEdit,
                                mainImage: uploadedMainImage,
                                imagesToAdd: uploadedImagesToAdd,
                            };

                            dispatch(listingActions.editListing(info._id, listingDataEditWithFileDTO));
                        });
                    } else {
                        handleFileUpload(chosenImagesToAdd).then((response) => {
                            setLoaderUpload(false);

                            const uploadedImagesToAdd = response.data;

                            const listingDataEditWithFileDTO = {
                                ...listingDataEdit,
                                imagesToAdd: uploadedImagesToAdd,
                            };

                            dispatch(listingActions.editListing(info._id, listingDataEditWithFileDTO));
                        });
                    }
                } else {
                    dispatch(listingActions.editListing(info._id, listingDataEdit));
                }
            } else {
                if (!!chosenImages.length && !hasURL(chosenImages)) {
                    setLoaderUpload(true);

                    if (!!chosenMainImage && !chosenMainImage.url) {
                        Promise.all([handleFileUpload(chosenMainImage), handleFileUpload(chosenImages)]).then((response) => {
                            setLoaderUpload(false);

                            const uploadedMainImage = response[0].data;
                            const uploadedImages = response[1].data;

                            const listingDataWithFileDTO = {
                                ...listingData,
                                mainImage: uploadedMainImage,
                                images: uploadedImages,
                            };

                            dispatch(listingActions.createListing(listingDataWithFileDTO));
                        });
                    } else {
                        handleFileUpload(chosenImages).then((response) => {
                            setLoaderUpload(false);

                            const uploadedImages = response.data;

                            const listingDataWithFileDTO = {
                                ...listingData,
                                images: uploadedImages,
                            };

                            dispatch(listingActions.createListing(listingDataWithFileDTO));
                        });
                    }
                } else {
                    dispatch(listingActions.createListing(listingData));
                }
            }
        } else {
            setError(errorText);
        }
    };

    return (
        <div className={classes.createListingBoxStyle}>
            <div className={classes.createListingBoxContainer}>
                <p className={classes.createListingTitle}> {info ? 'Edit Listing' : 'Create Listing'} </p>
                <div className={classes.createListingStyle}>
                    <GeneralInfo
                        handleSelPropTypePass={(selected) => {
                            setSelectedPropertyType(selected);
                            setError('');
                        }}
                        selPropType={selectedPropertyType}
                        error={error}
                        dropdownErrorMsg={dropdownErrorMsg}
                        info={info}
                        inputs={inputs}
                        handleChange={handleChange}
                        errorMsg={errorMsg}
                        backMessage={backMessage}
                    />
                    <PropertyDetails
                        info={info}
                        inputs={inputs}
                        dropdownErrorMsg={dropdownErrorMsg}
                        error={error}
                        handleChange={handleChange}
                        handleSelLeaseTypePass={(selected) => {
                            setSelectedLeaseType(selected);
                            setError('');
                        }}
                        selLeaseType={selectedLeaseType}
                    />
                    <ContactInfo/>
                    <Description info={info} inputs={inputs} onPass={handleTextChange}/>
                    <ListingImages
                        info={info}
                        handleImagesPass={(images) => setChosenImages(images)}
                        handleMainImagePass={(mainImage) => setChosenMainImage(mainImage)}
                        handleImagesToAddPass={(imagesToAdd) => setChosenImagesToAdd(imagesToAdd)}
                        handleImagesToRemovePass={(imagesToRemove) => setChosenImagesToRemove(imagesToRemove)}
                    />
                </div>
                <SaveOrCancelButton
                    butnClassName={classes.listingInputsActionStyle}
                    loader={loaderUpload || !!createListingLoader.length || !!editListingLoader.length}
                    butnCancelling={'Cancel'}
                    butnSavingText={info ? 'Save Changes' : 'Create Listing'}
                    onSaveData={sendListingData}
                    onClearAll={() => history.push(`${info ? '/my_listings' : '/'}`)}
                />
            </div>
        </div>
    );
};
