import {useState} from "react";
import moment from "moment";
import {globalStyles} from "components";
import {Images} from "utils";
import {billingStyles} from "./styles";
import BasicDocument from "../../../components/wrappers/pdf";

export const Invoices = ({invoices}) => {
    const classes = billingStyles()
    const globalClasses = globalStyles()
    const [show, setShow] = useState(null)

    return (
        <>
            <div className={classes.whiteWrapper}>
                <p className={globalClasses.title}>Invoices</p>
                <div className={classes.desktopView}>
                    {invoices?.length ?
                        <>
                            <div className={classes.invoicesHead}>
                                <p style={{width: '55%'}}>Description</p>
                                <p style={{width: '15%'}}>Date Paid</p>
                                <p style={{width: '15%'}}>Amount</p>
                                <p style={{width: '15%'}}>Action</p>
                            </div>
                            {invoices.map((i, j) => (
                                <div key={j} className={classes.invoicesBody}>
                                    <p style={{width: '55%'}}>{i?.description ? i?.description : 'Not Set'}</p>
                                    <p style={{width: '15%'}}>{moment(i?.createdAt).format("ll")}</p>
                                    <p style={{width: '15%'}}>$15.00</p>
                                    <div className={classes.actionButtonsWrapper} style={{width: '15%'}}>
                                        <button onClick={() => setShow(i)}>
                                            <img src={Images.showInvoice} alt=""/>
                                        </button>
                                        {/*<button onClick={() => setShow(i)}>*/}
                                        {/*    <img src={Images.download} alt=""/>*/}
                                        {/*</button>*/}
                                    </div>
                                </div>
                            ))}
                        </>
                        :
                        <div className={classes.noInvoicesYet}>
                            <p>No Invoices yet</p>
                        </div>
                    }
                </div>

                <div className={classes.mobileView}>
                    {invoices?.length ?
                        invoices.map((i, j) => (
                            <div key={j} className={classes.whiteWrapper}>
                                <p className={classes.textAndDescription}>
                                    Description:&nbsp;
                                    <span>{i?.description ? i?.description : 'Not Set'}</span>
                                </p>
                                <p className={classes.textAndDescription}>
                                    Date Paid:&nbsp;
                                    <span>{moment(i?.createdAt).format("ll")}</span>
                                </p>
                                <p className={classes.textAndDescription}>
                                    Amount:&nbsp;
                                    <span>$15</span>
                                </p>
                                <div className={classes.actionButtonsWrapper}
                                     style={{width: '100%', justifyContent: 'flex-end'}}
                                >
                                    <button onClick={() => setShow(i)}>
                                        <img src={Images.showInvoice} alt=""/>
                                    </button>
                                    {/*<button onClick={() => setShow(i)}>*/}
                                    {/*    <img src={Images.download} alt=""/>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        ))
                        :
                        <div className={classes.noInvoicesYet}>
                            <p>No Invoices yet</p>
                        </div>
                    }
                </div>
            </div>

            {show &&
                <div className={classes.pdfReader}>
                    <div style={{padding: '12px 20px'}}>
                        <button
                            className={classes.closeFiltersModalStyle}
                            onClick={() => setShow(null)}>
                            <img src={Images.close} alt=""/>
                        </button>
                    </div>
                    <BasicDocument invoice={show}/>
                </div>
            }
        </>
    )
}