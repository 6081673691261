import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { listingActions } from 'store';
import { CustomBreadcrumbs, Loader } from 'components';
import { SET_LISTING_BY_ID } from 'store/listings/listings.types';
import { ListingDetails } from 'fragments';
import {Helmet} from "react-helmet";

export const ListingDetailsPage = () => {
    const params = useParams();
    const _id = params.id;

    const dispatch = useDispatch();

    const httpOnLoad = useSelector((state) => state.httpOnLoad);
    const isLoading = httpOnLoad.length && httpOnLoad[0] === 'GET_LISTING_BY_ID';
    const listingById = useSelector((state) => state.listings.listingById);

    useEffect(() => {
        dispatch(listingActions.getListingById(_id));

        return () => {
            dispatch({
                type: SET_LISTING_BY_ID,
                payload: { listingById: null },
            });
        };
    }, [_id]);


    return isLoading ? (
        <Loader />
    ) : (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title> {`${listingById?.address ? `${listingById?.address?.formattedAddress} |` : ''} Croill`}</title>
                <meta name="description"
                      content={`${listingById?.address?  listingById?.address?.formattedAddress : ''} This commercial property is available for lease.`}
                />
            </Helmet>

            <CustomBreadcrumbs />
            <ListingDetails details={listingById}/>
        </>
    );
};
