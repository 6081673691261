import React from 'react';
import { logoStyle } from './styles';

export const Logo = ({ logoClassName, onClickLogo }) => {
    const classes = logoStyle();

    return (
        <div className={`${classes.logoStyle} ${logoClassName}`} onClick={onClickLogo}>
            <h1> {'croill'} </h1>
        </div>
    );
};
