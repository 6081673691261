import {Images, Colors} from 'utils';
import {makeStyles} from '@material-ui/core/styles';

export const userRegistrationCoreStyles = makeStyles(
    () => ({
        // ** user sign-up|edit style **
        createUserStyle: {
            '&.edit': {
                display: 'flex',
                '& > div:last-of-type': {
                    marginLeft: '32px',
                    '@media(max-width: 1024px)': {marginLeft: '0px'},
                },
                '@media(max-width: 1024px)': {
                    flexDirection: 'column',
                    '& label input': {width: '100%'},
                },
            },
            '& > .hide': {display: 'none'},
        },
        continueButnStyle: {
            width: '138px',
            '@media(max-width: 500px)': {width: '100%'},
        },
        optionErrorText: {
            height: '10px',
            color: '#D03325',
            marginBottom: '10px',
            fontWeight: 400,
            fontSize: '12px',
        },
        signUpButnStyle: {
            width: '192px',
            '@media(max-width: 500px)': {width: '295px'},
        },
        signUpAgreementStyle: {
            maxWidth: '400px',
            width: '100%',
            marginTop: '32px',
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextGray,
            lineHeight: '24px',
            '& a': {
                fontWeight: 600,
                color: Colors.TextBlack,
                textDecoration: 'none',
                margin: '0px 4px',
            },
            '@media(max-width: 500px)': {maxWidth: '295px'},
            '@media(max-width: 350px)': {maxWidth: '265px'},
        },
        // ** end **

        // ** user sign-in style **
        signInForgotBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '32px',
        },
        signInButnStyle: {width: '118px'},
        goToForgotPassButnStyle: {
            fontSize: '14px',
            fontWeight: 500,
            color: '#3377CC',
        },
        signUpPartBoxStyle: {
            width: '100%',
            textAlign: 'center',
            '& > span': {
                fontSize: '14px',
                fontWeight: 500,
                color: Colors.TextGray,
            },
        },
        goToSignUpButnStyle: {
            fontSize: '14px',
            fontWeight: 600,
            color: Colors.TextBlack,
        },
        // ** end **

        // ** forgot password style **
        registerInfoStyle: {
            maxWidth: '400px',
            width: '100%',
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextGray,
            lineHeight: '24px',
            marginBottom: '32px',
            '@media(max-width: 500px)': {maxWidth: '295px'},
            '@media(max-width: 350px)': {maxWidth: '265px'},
        },
        sendEmailButnStyle: {
            width: '220px',
            '@media(max-width: 500px)': {width: '295px'},
            '@media(max-width: 350px)': {width: '265px'},
        },
        // ** end **

        // ** check email style */
        userEmailStyle: {
            color: '#3377CC',
            textDecoration: 'none',
            margin: '0px 4px',
            display: 'inline-block',
        },
        // ** end */

        // reset password style **
        resetPasswordBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.BackgroundWhiteOff,
        },
        resetPassContainerStyle: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '159px 0px 232px',
            '@media(max-width: 1200px)': {
                margin: '101px 0px 92px',
            },
        },
        resetPassFormStyle: {
            backgroundColor: Colors.BackgroundWhite,
            padding: '40px',
            marginTop: '32px',
            boxShadow: '0px 0px 6px #00000029',
            borderRadius: '12px',
            '@media(max-width: 500px)': {padding: '24px', marginTop: '24px'},
        },
        registerTitleStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
            marginBottom: '32px',
            '&.welcome': {
                marginTop: '40px',
                '@media(max-width: 500px)': {marginTop: '32px'},
            },
        },
        resetPassButnStyle: {
            width: '118px',
            '@media(max-width: 500px)': {width: '295px'},
        },
        // ** end **

        // ** welcome style **
        doneButnStyle: {
            width: '192px',
            marginBottom: '40px',
            '@media(max-width: 500px)': {
                width: '295px',
                marginBottom: '32px',
            },
        },
        // ** end **

        // ** edit user style **
        saveOrCancelEditStyle: {
            marginTop: '44px',
            '& button': {
                maxWidth: '188px',
                fontSize: '16px',
                fontWeight: 500,
                color: Colors.TextWhite,
                textDecoration: 'none',
                '@media(max-width: 600px)': {maxWidth: '139px'},
            },
            '& button:first-of-type': {backgroundColor: '#75728080'},
            '& button:last-of-type': {backgroundColor: '#3377CC'},
            '@media(max-width: 1024px)': {
                marginTop: '0px',
                marginBottom: '32px',
            },
        },
        // ** end **

        // ** change password style **
        changePassStyle: {marginTop: '32px'},
        changePasswordButnStyle: {
            '& > button': {
                width: '188px',
                height: '40px',
                '&:first-of-type': {
                    textDecoration: 'none',
                    backgroundColor: '#75728080',
                    color: Colors.TextWhite,
                    marginRight: '24px',
                },
                '&:last-of-type': {backgroundColor: '#3377CC'},
            },
        },

        choseText: {
            marginBottom: '32px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
            maxWidth: '400px',
        },

        accountTypeImageText: {
            display: 'flex',
            alignItems: 'center'
        },

        accountTypeItem: {
            width: '100%',
            maxWidth: '478px',
            height: '92px',
            background: '#F4F4F4',
            borderRadius: '16px',
            padding: '18px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            border: '1px solid #F4F4F4',
            cursor: 'pointer',
            '@media(min-width: 320px)': {
                padding: '18px 0px 18px 14px',
            },
            '@media(min-width: 768px)': {
                padding: '18px 16px',
            },
            // '@media(min-width: 1280px)': {},

        },

        iconStyle: {
            width: '56px',
            height: '56px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255, 56, 92, 0.1)',
            borderRadius: '8px',
        },

        selectTypeTexts: {
            marginLeft: '16px',

            '@media(min-width: 320px)': {
                marginLeft: '10px',
            },
            '@media(min-width: 768px)': {
                marginLeft: '16px',
            },
        },

        title: {
            fontWeight: 800,
            fontSize: '18px',
            lineHeight: '28px',
            color: '#2B273C',
            marginBottom: '10px'
        },

        description: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#2B273C',
        },

        brokerInputsWrapper: {
            display: 'flex',
        },

        brokerInputsFirstPart: {
            marginRight: '13px',

            '@media(min-width: 320px)': {
                marginRight: '0',
            },
            '@media(min-width: 1120px)': {
                marginRight: '13px',
            },
        },

        selectTitle: {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
        },

        selectInput: {
            width: '100%',
            height: '40px',
            border: 'none',
            background: '#F4F4F4',
            borderRadius: '8px',
            padding: '0 12px',
        },

        brokerInputsWrapperResponse: {
            display: 'flex',
            flexDirection: 'row',
            '@media(min-width: 320px)': {
                flexDirection: 'column',
            },
            '@media(min-width: 1120px)': {
                flexDirection: 'row',
            },
        },
        fullInput: {
            '& input': {
                width: '100% !important',

                "@media(min-width: 1280px)": {
                    width: '400px !important',
                }
            },
        },

        customClass: {
            width: '100%',
            position: 'relative',
            marginTop: '4px',
            '& input': {
                borderRadius: '8px',
                height: '40px',
                padding:'0 16px',
            }
        },

        inputTitle: {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
            '& span': {
                color: '#FF385C'
            }
        },

        signUpWrapper:{
            width:'100%',
            display:'flex',
            justifyContent:'flex-end',
        }
        // ** end **
    }),
    {index: 1},
);
