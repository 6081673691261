import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const mainStyle = makeStyles(
  () => ({
    mainStyle: {
      width: "100%",
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
      "&.bgColor": { backgroundColor: Colors.BackgroundWhiteOff },
    },
    mainContainerStyle: {
      width: "100%",
    },
  }),
  { index: 1 }
);
