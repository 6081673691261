import React from "react";
import { buttonsStyle } from "./style";

export const UserActionButton = ({
  butnClassName,
  onUserAction,
  butnLabel,
}) => {
  const classes = buttonsStyle();

  return (
    <button
      type="button"
      className={`${classes.userActionButnStyle} ${butnClassName}`}
      onClick={onUserAction}
    >
      {butnLabel}
    </button>
  );
};
