import React, { useEffect } from 'react';
import { Router } from 'root';
import { mainStyle } from './style';

export const Main = () => {
    const classes = mainStyle();

    const scrollToTop = () => window.scrollTo(0, 0);
    useEffect(scrollToTop, []);

    const isResetPasswordPage = window.location.pathname === '/resetPassword';

    return (
        <main className={`${classes.mainStyle} ${isResetPasswordPage ? 'bgColor' : ''}`}>
            <section className={classes.mainContainerStyle}>
                <Router />
            </section>
        </main>
    );
};
