import React from 'react';
import { inputStyle } from './style';

export const FromToInput = ({ inputValue, onInputChange, inputPlaceholder }) => {
    const classes = inputStyle();

    return (
        <input
            type="number"
            className={classes.fromToInputStyle}
            value={inputValue}
            onChange={onInputChange}
            onWheel={(evt) => evt.target.blur()}
            placeholder={inputPlaceholder}
        />
    );
};
