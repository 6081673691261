import React, {useState, useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {UserInput, SendButton, MaskInput} from 'components';
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, userActions} from 'store';
import {
    FindLoad,
    FindError,
    isNotEmpty,
    getPassErrorText,
    EmailValidator,
    getPhoneErrorText,
    FindSuccess,
    RegisterContext,
    Images,
} from 'utils';
import {userRegistrationCoreStyles} from './styles';
import {Link} from 'react-router-dom';
import {Radio} from "@mui/material";
import {BrokerSignUp} from "./brokerSignUp";

export const CreateUserInputs = ({closeModal}) => {
    const classes = userRegistrationCoreStyles();
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');
    const [accountType, setAccountType] = useState('');
    const [isLastStep, setIsLastStep] = useState(false);
    const userLoader = FindLoad('CREATE_USER');
    const userBackError = FindError('CREATE_USER');
    const userSuccess = FindSuccess('CREATE_USER');
    const errorMsg = 'This field must be not empty!';
    const phoneErrorMsg = getPhoneErrorText(inputs.phone);
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? 'Email must be an email!' : '';
    const passwordErrorMsg = getPassErrorText(inputs.password);
    const phoneErrorText =
        error === 'phone' ? errorMsg :
            userBackError.length && userBackError[0].error[0] === 'phone must be a valid phone number' ? 'Phone must be a valid phone number' : '';
    const emailErrorText =
        error === 'email' ? errorMsg
            : userBackError.length && userBackError[0]?.error === 'User already exists' ? 'User already exists'
                : '';
    const passwordErrorText = error === 'password' ? errorMsg : error === passwordErrorMsg ? passwordErrorMsg : '';
    const hasMinSixChars = isNotEmpty(inputs.password) && inputs.password.trim().length >= 8;
    const hasOneNum = isNotEmpty(inputs.password) && /\d/.test(inputs.password);
    const hasOneUppercase = isNotEmpty(inputs.password) && /[A-Z]/.test(inputs.password);
    const registerCtx = useContext(RegisterContext);
    const {handleModalContent} = registerCtx;

    useEffect(() => {
        if (userSuccess.length) {
            handleModalContent('welcome');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(userSuccess.type));
        }
    }, [userSuccess]);

    const handleChange = (evt) => {
        setInputs((prevState) => ({...prevState, [evt.target.name]: evt.target.value}));
        if (
            error === evt.target.name ||
            error === phoneErrorMsg ||
            error === emailErrorMsg ||
            error === passwordErrorMsg ||
            userBackError.length
        ) {
            setError('');
        }
        if (userBackError.length) {
            dispatch(httpRequestsOnErrorsActions.removeError(userBackError.type));
        }
    };

    const handleStepContinue = () => {
        if (accountType) {
            setError('')
            setIsLastStep(true)
        } else {
            setError('selectType')
        }
    };

    const handleUserDataSend = () => {
        const passwordIsValid = !!inputs.password && hasMinSixChars && hasOneNum && hasOneUppercase;
        const emailIsValid = !!inputs.email && EmailValidator.test(inputs.email);
        const phone = inputs?.phone ? `${parseInt(inputs?.phone.replace(/[^0-9]/g, ''))}` : '';

        const errorText =
            !inputs.firstName ? 'firstName' :
                !inputs.lastName ? 'lastName' :
                    !phone ? 'phone' :
                        !inputs.email ? 'email' :
                            !inputs.password ? 'password' :
                                !passwordIsValid ? passwordErrorMsg
                                    : 'Input is not field';

        const userData = {
            firstName: inputs.firstName,
            lastName: inputs.lastName,
            phone: phone,
            email: inputs.email,
            password: inputs.password,
        };

        if (!!inputs.firstName && !!inputs.lastName && phone && emailIsValid && passwordIsValid) {
            dispatch(userActions.createUser(userData));
        } else {
            setError(errorText);
        }
    };

    const handeSetType = (ev) => {
        if (error === 'selectType') {
            setError('')
        }
        setAccountType(ev)
    }

    return (
        <div>

            <div className={`${classes.createUserStyle}`}>
                {accountType === 'BASIC' ?
                    <div className={isLastStep ? '' : 'hide'}>
                        <UserInput
                            required={true}
                            inputClassName={'inModal'}
                            inputLabel={'First Name'}
                            inputType={'text'}
                            inputName={'firstName'}
                            inputValue={inputs.firstName}
                            onInputChange={handleChange}
                            inputError={error === 'firstName' && errorMsg}
                        />
                        <UserInput
                            required={true}
                            inputClassName={'inModal'}
                            inputLabel={'Last Name'}
                            inputType={'text'}
                            inputName={'lastName'}
                            inputValue={inputs.lastName}
                            onInputChange={handleChange}
                            inputError={error === 'lastName' && errorMsg}
                        />
                        <div style={{marginBottom: '12px'}}>
                            <MaskInput
                                name={'phone'}
                                handleChange={handleChange}
                                value={inputs.phone}
                                error={phoneErrorText}
                            />
                        </div>
                        <UserInput
                            required={true}
                            inputClassName={'inModal'}
                            inputLabel={'Email Address'}
                            inputType={'text'}
                            inputName={'email'}
                            inputValue={inputs.email}
                            onInputChange={handleChange}
                            inputError={emailErrorText}
                        />
                        <UserInput
                            required={true}
                            inputClassName={!isLastStep ? 'hide' : 'inModal'}
                            inputLabel={'Password'}
                            inputType={'password'}
                            inputName={'password'}
                            inputValue={inputs.password}
                            onInputChange={handleChange}
                            passwordInput={isNotEmpty(inputs.password)}
                            areaLabel={'create'}
                            inputError={passwordErrorText}
                            hasMinSixChars={hasMinSixChars}
                            hasOneNum={hasOneNum}
                            hasOneUppercase={hasOneUppercase}
                        />
                    </div>
                    :
                    accountType === 'BROKER' && isLastStep &&
                    <BrokerSignUp setAccountType={setAccountType}/>
                }
                <div className={!isLastStep ? '' : 'hide'}>
                    <>
                        <p className={classes.choseText}>You are all set up. Now you're ready to start your
                            journey
                            within
                            Croil.</p>

                        <div
                            onClick={(e) => handeSetType('BASIC')}
                            style={accountType === 'BASIC' ? {
                                    border: '1px solid #FF385C',
                                    background: '#FFF5F7'
                                } :
                                error === 'selectType' ? {borderColor: '#D03325'} :
                                    {}}
                            className={classes.accountTypeItem}
                        >
                            <div className={classes.accountTypeImageText}>
                                <div className={classes.iconStyle}>
                                    <img src={Images.brokerIcon} alt={''}/>
                                </div>
                                <div className={classes.selectTypeTexts}>
                                    <p className={classes.title}>Basic Account</p>
                                    <p className={classes.description}>Post and sell your property</p>
                                </div>
                            </div>
                            <div>
                                <Radio
                                    style={{color: '#FF385C'}}
                                    checked={accountType === 'BASIC'}
                                    onClick={(e) => handeSetType('BASIC')}
                                    value="BASIC"
                                />
                            </div>
                        </div>
                        <div
                            onClick={(e) => handeSetType('BROKER')}
                            style={accountType === 'BROKER' ? {
                                    border: '1px solid #FF385C',
                                    background: '#FFF5F7'
                                } :
                                error === 'selectType' ? {borderColor: '#D03325'} :
                                    {}}
                            className={classes.accountTypeItem}
                        >
                            <div className={classes.accountTypeImageText}>
                                <div className={classes.iconStyle}>
                                    <img src={Images.basicUser} alt={''}/>
                                </div>
                                <div className={classes.selectTypeTexts}>
                                    <p className={classes.title}>Broker</p>
                                    <p className={classes.description}>Post and sell your property</p>
                                </div>
                            </div>
                            <div>
                                <Radio
                                    style={{color: '#FF385C'}}
                                    checked={accountType === 'BROKER'}
                                    onClick={(e) => handeSetType('BROKER')}
                                    value="BROKER"
                                />
                            </div>
                        </div>
                    </>
                    <p className={classes.optionErrorText}>{error === 'selectType' ? 'Select one of options' : ''}</p>
                </div>


                {!isLastStep && (
                    <SendButton
                        butnClassName={classes.continueButnStyle}
                        butnType={'button'}
                        butnDisabled={false}
                        butnSendingText={'Next Step'}
                        onClickButn={handleStepContinue}
                    />
                )}
                {isLastStep && accountType !== 'BROKER' && (
                    <>
                        <SendButton
                            butnClassName={classes.signUpButnStyle}
                            butnType={'button'}
                            butnDisabled={false}
                            butnSendingText={'sign up'}
                            loader={!!userLoader.length}
                            onClickButn={handleUserDataSend}
                        />
                        <p className={classes.signUpAgreementStyle}>
                            By signing up, you agree to Croil's
                            <Link to="/termsAndConditions" onClick={closeModal}>
                                Terms of Use
                            </Link>
                            and
                            <Link to="/privacyPolicy" onClick={closeModal}>
                                Privacy Policy
                            </Link>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
};
