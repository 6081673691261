import axios from "axios";
const usrType = localStorage.getItem('userType')

export const authService = {
  createUserService: (body) => axios.post("/user", body),

  getUsersService: () => axios.get("/user", { auth: true }),

  editUserService: (id, body) => axios.patch(`/user/${id}`, body, { auth: true }),

  removeUserService: (id, userType) => axios.delete(userType === 'BROKER' ? `/brokers` : `/user/${id}`, { auth: true }),

  getUserByIdService: (id) => axios.get(usrType === 'BROKER' ? '/brokers/myProfile' : `/user/${id}`, { auth: true }),
};