import React, { useContext, useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactFileReader from 'react-file-reader';
import { UserActionButton, SimpleModal, RegisterModalWrapper } from 'components';
import { wrappersStyle } from './style';
import { handleFileUpload, Images, RegisterContext} from 'utils';
import { UserRegistrationWrapper } from '.';
import { ChangePassword } from 'fragments';
import { AvatarContext } from 'utils/contexts/avatarContext';
import { withRouter } from 'react-router';
import {brokerActions, httpRequestsOnErrorsActions, userActions} from "store";

import {CircularProgress} from "@mui/material";

export const MyProfileWrapper = withRouter(({ location, children,  }) => {
    const classes = wrappersStyle();
    const usrType = localStorage.getItem('userType')

    const user = useSelector((state) => state.users.userById);
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loaderUpload, setLoaderUpload] = useState(false);
    const [profilePicture, setProfilePicture] = useState(user?.avatar ? user.avatar : undefined);
    const userFullName = `${user?.firstName} ${user?.lastName}`;
    const registerCtx = useContext(RegisterContext);
    const { modalContent, handleModalContent } = registerCtx;
    const avatarCtx = useContext(AvatarContext);
    const { handleAvatarChange } = avatarCtx;


    const _profilePictureURL =
        // profilePicture ? profilePicture === user?.avatar ?
                profilePicture?.url ? profilePicture?.url : Images.userProfile;

    // useEffect(() => {
    //     handleAvatarChange(profilePicture);
    // }, [profilePicture]);

    useEffect(() => {
        if(user?.avatar){
            setProfilePicture(user?.avatar)
        }
    },[user])

    const handleFileChange = async (file) => {
        const _avatarSizeLimit = 2097152;
        if (file[0]?.size > _avatarSizeLimit) {
            dispatch(httpRequestsOnErrorsActions.appendError('IMAGE_SIZE'))
            setError(true);
            return;
        }else{
            setError(false);
            dispatch(httpRequestsOnErrorsActions.removeError('IMAGE_SIZE'))
        }

        setLoaderUpload(true);
       await handleFileUpload(file[0]).then((response) => {
           setLoaderUpload(false);
            const uploadedAvatar = response.data;
            const userData = {
                ...user,
                avatar: uploadedAvatar,
            };

           setProfilePicture( uploadedAvatar )
           if(usrType === 'USER') {
               dispatch(userActions.editUser(user?.id, userData));
           }else{
               dispatch(brokerActions.editBroker(user?.id, {
                   ...userData,
                   avatar: uploadedAvatar,
                   aboutMe: user?.aboutMe ? user?.aboutMe : '.',
               }));
           }
        }).catch(() => {
           setLoaderUpload(false);
       });
        if (open) {
            setOpen(false);
        }
    };

    const handleAvatarRemove = () => {
        const userData = {
            ...user,
            avatar: null,
        };

        if(usrType === 'USER') {
            dispatch(userActions.editUser(user?.id, userData));
        }else{
            dispatch(brokerActions.editBroker(user?.id, {
                ...userData,
                avatar: null,
                aboutMe: user?.aboutMe ? user?.aboutMe : '.',
            }));
        }
        setProfilePicture(undefined);
        setOpen(false);
    };

    return (
        <>
            <div className={classes.myProfileBoxStyle}>
                <div className={classes.myProfileBoxContainerStyle}>
                    <div className={classes.myProfileWrapperStyle}>
                        <div style={{width:'100%'}}>
                            <h1 className={classes.myProfileTitleStyle}> My Profile </h1>
                            <div className={classes.wrapperContentStyle}>
                                <div className={classes.userInfoStyle}>
                                    <div className={classes.userProfilePictureContentStyle}>
                                        <div className={classes.userProfileBoxStyle}>
                                            {loaderUpload ?
                                                <CircularProgress
                                                    style={{
                                                        width: "78px",
                                                        height: "78px",
                                                        position: 'relative',
                                                        left: 0,
                                                        right: 0,
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        color: '#FE385C',
                                                    }}
                                                />
                                                :
                                                <img src={_profilePictureURL} alt="avatar"/>
                                            }
                                        </div>
                                        { (
                                            <label
                                                className="editAvatarIcon"
                                                onClick={() => {
                                                    if (!profilePicture) return;
                                                    setOpen(true);
                                                    handleModalContent('profilePicture');
                                                }}>
                                                {!profilePicture && (
                                                    <ReactFileReader handleFiles={handleFileChange}>
                                                        <></>
                                                    </ReactFileReader>
                                                )}
                                            </label>
                                        )}
                                    </div>
                                    <h3 className={classes.userFullNameStyle}>{userFullName}</h3>
                                </div>
                                {children}
                            </div>
                            <div className={classes.myProfileActionsStyle}>
                                <UserActionButton
                                    butnClassName={classes.changePassButnStyle}
                                    onUserAction={() => {
                                        setOpen(true);
                                        handleModalContent('changePassword');
                                    }}
                                    butnLabel={'Change Password'}
                                />
                                <UserActionButton
                                    butnClassName={classes.delAccountButnStyle}
                                    onUserAction={() => {
                                        setOpen(true);
                                        handleModalContent('deleteAccount');
                                    }}
                                    butnLabel={'Delete Account'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SimpleModal
                openDefault={open}
                handleOpenClose={() => setOpen(false)}
                content={
                    <RegisterModalWrapper onClose={() => setOpen(false)}>
                        {modalContent === 'profilePicture' ? (
                            <UserRegistrationWrapper
                                avatarURL={_profilePictureURL}
                                user={user}
                                title={'Profile Picture'}
                                onChangeAvatar={handleFileChange}
                                onRemoveAvatar={handleAvatarRemove}
                                loader={loaderUpload}
                            >
                                <p className={classes.profilePictureDescStyle}>
                                    Profile picture helps people recognize you and builds trust in the platform.
                                </p>
                            </UserRegistrationWrapper>
                        ) : modalContent === 'changePassword' ? (
                            <UserRegistrationWrapper title={'Change Password'}>
                                <p className={classes.descriptionStyle}>Use strong password to keep your account secure.</p>
                                <ChangePassword onClose={() => setOpen(false)} />
                            </UserRegistrationWrapper>
                        ) : modalContent === 'deleteAccount' ? (
                            <UserRegistrationWrapper user={user} onClose={() => setOpen(false)} title={'Delete Account'}>
                                <p className={classes.descriptionStyle}>
                                    Are you sure you want to delete your account? If you delete your account, you'll permanently lose your
                                    profile.
                                </p>
                            </UserRegistrationWrapper>
                        ) : null}
                    </RegisterModalWrapper>
                }
            />
        </>
    );
});
