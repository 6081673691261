import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './auth.service';
import {
    CHANGE_PASSWORD_REQUEST,
    GET_RECOVERY_LINK,
    LOG_IN,
    LOG_OUT,
    RESET_PASSWORD_REQUEST,
    SEND_MESSAGE
} from './auth.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

/** Log in, Log Out */

function* logIn({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.logInService, payload.body);
        localStorage.setItem('access-token', res.data.token);
        localStorage.setItem('userType', res.data.userType);



        const response = yield call(authService.getMyProfileByTokenService,  res.data.userType);
        localStorage.setItem('userInfo', JSON.stringify(response.data));

        const pageEvent = {
            event: "SIGN_IN",
            user_id: response.data?.id,
            userType: res.data.userType
        };
        window?.dataLayer.push(pageEvent);

        window.location.replace('/');
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* logOut() {
    try {
        yield call(authService.logOutService);
        localStorage.removeItem('access-token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userType');
        window.location.replace('/');
    } catch (err) {
        localStorage.removeItem('access-token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userType');
        window.location.replace('/');
    }
}

function* getLink({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.getLinkService, payload.email);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        // window.location.replace("/resetPassword");
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* resetPassword({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.resetPassService, payload.passwords);
        localStorage.setItem('access-token', res.data.token);
        localStorage.setItem('userType', res.data.userType);
        const response = yield call(authService.getMyProfileByTokenService, res.data.userType );
        localStorage.setItem('userInfo', JSON.stringify(response.data));
        window.location.replace('/');
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* changePassword({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.changePasswordService, payload.passwords);
        localStorage.setItem('access-token', res.data.accessToken);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** Contact Form */
function* sendMessage({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(authService.sendMessageService, payload);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End */


export const watchAuth = function* watchUserAuth() {
    /** Log in, Log Out */
    yield takeLatest(LOG_IN, logIn);
    yield takeLatest(LOG_OUT, logOut);
    yield takeLatest(GET_RECOVERY_LINK, getLink);
    yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
    /** End */

    /** Contact Form */
    yield takeLatest(SEND_MESSAGE, sendMessage);
    /** End */

};
