import React from "react";
import { inputStyle } from "./style";

export const CheckBoxInput = ({
  inputId,
  inputChecked,
  onInputChange,
  inputLabel,
}) => {
  const classes = inputStyle();

  const isHomePage = window.location.pathname === "/";

  return (
    <label
      htmlFor={inputId}
      className={`${classes.checkBoxLabelStyle} ${
        isHomePage ? "homPageLabel" : ""
      }`}
    >
      <input
        type="checkbox"
        id={inputId}
        checked={inputChecked}
        onChange={onInputChange}
      />
      <div className={classes.inputCheckBoxStyle} />
      <span className={classes.inputLabelStyle}>{inputLabel}</span>
    </label>
  );
};
