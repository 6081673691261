import React from "react";
import { buttonsStyle } from "./style";

export const CloseModalButton = ({
  onCloseModal,
  butnLabel,
  butnClassName,
}) => {
  const classes = buttonsStyle();
  return (
    <button
      type="button"
      className={`${classes.closeModalButnStyle} ${butnClassName}`}
      onClick={onCloseModal}
    >
      {butnLabel}
    </button>
  );
};
