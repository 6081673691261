import React, { useState, useEffect } from 'react';
import { ListingInputsWrapper, ViewMoreButton } from 'components';
import { commonStyle } from './styles';
import { Images } from 'utils';
import ReactFileReader from 'react-file-reader';

export const ListingImages = ({ info, handleImagesPass, handleMainImagePass, handleImagesToAddPass, handleImagesToRemovePass }) => {
    const classes = commonStyle();

    const [error, setError] = useState(false);
    const [images, setImages] = useState(info ? info.images : []);
    const [imagesToAdd, setImagesToAdd] = useState([]);
    const [imagesToRemove, setImagesToRemove] = useState([]);
    const [mainImage, setMainImage] = useState(info ? info.mainImage : undefined);

    const _mainImageURL = mainImage
        ? info?.images.includes(mainImage) || mainImage === info?.mainImage
            ? mainImage?.url
            : URL.createObjectURL(mainImage)
        : '';

    const uniqueImages = [...new Map(images.map((image) => [image.url ? image.url : image.name, image])).values()];
    const uniqueImagesToAdd = [...new Map(imagesToAdd.map((image) => [image.name, image])).values()];

    useEffect(() => {
        if (!mainImage) {
            setMainImage(images[0]);
        }

        if (!images.length) {
            setMainImage(undefined);
        }

        if (info) {
            handleImagesToAddPass(uniqueImagesToAdd);
            handleImagesToRemovePass(imagesToRemove);
        }

        handleImagesPass(uniqueImages);
        handleMainImagePass(mainImage);
    }, [images, mainImage]);

    const handleImagesChange = (images) => {
        const _imageSizeLimit = 2097152;

        for (const image of images) {
            if (image.size > _imageSizeLimit) {
                setError(true);
                return;
            }
        }

        if (info) {
            setImagesToAdd((prevState) => prevState.concat(Array.from(images)));
        }

        setError(false);
        setImages((prevState) => prevState.concat(Array.from(images)));
    };

    const handleImagesRemove = (evt, uniqueImage, uniqueImageArray, imagesAlreadyExist) => {
        evt.stopPropagation();

        if (imagesAlreadyExist) {
            setImagesToRemove((prevState) => [...prevState, uniqueImage]);
            setImages(() => uniqueImageArray.filter((image) => image.url !== uniqueImage.url));

            if (mainImage.url === uniqueImage.url && uniqueImage === uniqueImageArray[0]) {
                setMainImage(uniqueImageArray[1]);
            } else if (mainImage.url === uniqueImage.url && uniqueImage !== uniqueImageArray[0]) {
                setMainImage(uniqueImageArray[0]);
            }
        } else {
            setImages(() => uniqueImageArray.filter((image) => image.name !== uniqueImage.name));

            if (info) {
                setImagesToAdd(() => uniqueImageArray.filter((image) => image.name !== uniqueImage.name));
            }

            if (mainImage.name === uniqueImage.name && uniqueImage === uniqueImageArray[0]) {
                setMainImage(uniqueImageArray[1]);
            } else if (mainImage.name === uniqueImage.name && uniqueImage !== uniqueImageArray[0]) {
                setMainImage(uniqueImageArray[0]);
            }
        }
    };

    return (
        <ListingInputsWrapper inputsTitle={'Listing Images'}>
            <div className={classes.listingImagesStyle}>
                {!uniqueImages.length ? (
                    <div className={classes.imageBoxStyle}>
                        <div>
                            <img src={Images.emptyImage} alt="" />
                            <h6 className={classes.imageInfoStyle}>Drag your photos here</h6>
                            <label className={classes.fileUploadLabelStyle}>
                                Upload from your device
                                <ReactFileReader multipleFiles={true} handleFiles={handleImagesChange}>
                                    <></>
                                </ReactFileReader>
                            </label>
                        </div>
                    </div>
                ) : (
                    <div className={classes.listingImagesContainerStyle}>
                        <label className={classes.fileUploaderStyle}>
                            Upload Image
                            <ReactFileReader multipleFiles={true} handleFiles={handleImagesChange}>
                                <></>
                            </ReactFileReader>
                        </label>
                        <h6 className={classes.dragInfoStyle}> Drag to reorder </h6>
                        <div className={classes.imagesContainerStyle}>
                            <div className={classes.coverImageStyle}>
                                <img src={_mainImageURL} alt="main" />
                                <ViewMoreButton butnViewingText={'Cover Image'} butnClassName={classes.coverButnStyle} />
                                <div className={classes.closerButnStyle} onClick={() => setMainImage(images[0])}>
                                    <img src={Images.closeImage} alt="" />
                                </div>
                            </div>
                            <div className={classes.restImagesStyle}>
                                <label className={classes.uploaderStyle}>
                                    <img src={Images.emptyImage} alt="" />
                                    <ReactFileReader multipleFiles={true} handleFiles={handleImagesChange}>
                                        <></>
                                    </ReactFileReader>
                                </label>
                                {uniqueImages.map((uniqueImage, index, uniqueImageArray) => {
                                    const _imagesAlreadyExist = info?.images.includes(uniqueImage);

                                    const _uniqueImageURL = _imagesAlreadyExist ? uniqueImage.url : URL.createObjectURL(uniqueImage);

                                    return (
                                        <div key={index} className={classes.restImageStyle}>
                                            <div className="fileOverlay" onClick={() => setMainImage(uniqueImage)} />
                                            <img src={_uniqueImageURL} alt={uniqueImage.name} />
                                            <div
                                                className={classes.closerStyle}
                                                onClick={(evt) =>
                                                    handleImagesRemove(evt, uniqueImage, uniqueImageArray, _imagesAlreadyExist)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ListingInputsWrapper>
    );
};
