import React, {useState, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {userActions} from 'store';
import {Logo, RegisterModalWrapper, SimpleModal, ViewMoreButton} from 'components';
import {UserRegistration} from 'fragments';
import {UserProfileMenu} from './core';
import {RegisterContext} from 'utils';
import {routerInfo} from "root";
import {headerStyle} from './style';


export const Header = () => {
    const classes = headerStyle();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [menuIsShown, setMenuIsShown] = useState(false);
    const [currentPage, setCurrentPage] = useState('')
    const isResetPasswordPage = window.location.pathname === '/resetPassword';
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    useEffect(() => {
        if (userInfo) {
            dispatch(userActions.getUserById(userInfo.id));
        }
    }, []);

    const user = useSelector((state) => state.users.userById);

    const registerCtx = useContext(RegisterContext);
    const {handleModalContent} = registerCtx;

    const handleModalClose = () => {
        setIsOpen(false);
        handleModalContent('signIn');
    };
    const handleUserProfileMenu = () => {
        if (userInfo) {
            setIsShown((prevState) => !prevState);
        } else {
            setIsOpen(true);
        }
    };

    return (
        <>
            <header className={`${classes.headerStyle} ${isResetPasswordPage ? 'boxShadow' : ''}`}>
                <section className={classes.headerContainerStyle}>
                    <Logo logoClassName={classes.headerLogoStyle} onClickLogo={() => {
                        history.push('/')
                        setCurrentPage('')
                    }}/>
                    <div
                        className={`${classes.menuIconStyle} ${menuIsShown ? 'active' : ''}`}
                        onClick={() => setMenuIsShown((prevState) => !prevState)}>
                        <div>
                            <span/>
                            <span/>
                        </div>
                    </div>
                    {menuIsShown && <div className={classes.menuOverlayStyle} onClick={() => setMenuIsShown(false)}/>}
                    <div className={`${classes.userProfileActionsBoxStyle} ${menuIsShown ? 'show' : ''}`}>
                        <div className={classes.menuList}>
                            {routerInfo().map(({text, href}, i) => (
                                <p
                                    key={i}
                                    onClick={() => {
                                        history.push(href)
                                        setMenuIsShown(false)
                                        setCurrentPage(href)
                                    }}
                                    className={`${classes.menuItem} ${currentPage === href ? 'active-page' : ''}`}
                                >
                                    {text}
                                </p>
                            ))}
                        </div>

                        <div className={`${classes.buttonsInfoBlock} ${userInfo ? 'row-revers' : ''}`}>
                            <div>
                                <ViewMoreButton
                                    butnClassName={`${classes.createListingButnStyle} ${userInfo ? 'signedIn' : ''}`}
                                    butnViewingText={'Create Listing'}
                                    onView={() => {
                                        if (userInfo) {
                                            window.location.replace('/create_listing');
                                            setMenuIsShown(false);
                                        } else {
                                            setIsOpen(true);
                                            setMenuIsShown(false);
                                        }
                                    }}
                                />

                                <ViewMoreButton
                                    butnClassName={
                                        `${classes.findListingButnStyle}  ${userInfo ? 'signedIn' : ''}`
                                    }
                                    butnViewingText={'Find Broker'}
                                    onView={() => {
                                        const pageEvent = {
                                            event: "FIND_BROKER",
                                            type: 'FIND_BROKER'
                                        };
                                        window?.dataLayer.push(pageEvent);
                                        history.push('/brokers');
                                    }}

                                    // onView={() => {
                                    //     history.push('/brokers');
                                    //     // if (userInfo) {
                                    //     //     history.push('/brokers');
                                    //     //     setMenuIsShown(false);
                                    //     // } else {
                                    //     //     setIsOpen(true);
                                    //     //     setMenuIsShown(false);
                                    //     // }
                                    //    }}
                                />
                            </div>


                            <UserProfileMenu
                                onClickUserProfileMenu={handleUserProfileMenu}
                                userInfo={userInfo}
                                userProfileMenuIsShown={isShown}
                                onHideUserProfileMenu={() => setIsShown(false)}
                                onHideMenu={() => setMenuIsShown(false)}
                                menuIsShown={menuIsShown}
                            />
                        </div>


                    </div>
                </section>
            </header>
            <SimpleModal
                openDefault={isOpen}
                handleOpenClose={handleModalClose}
                content={
                    <RegisterModalWrapper onClose={handleModalClose}>
                        <UserRegistration onClose={handleModalClose}/>
                    </RegisterModalWrapper>
                }
            />
        </>
    );
};
