import React from "react";

export const ViewAsButton = ({
  butnClassName,
  viewAsHandler,
  butnViewAsLabel,
}) => {
  return (
    <button type="button" className={butnClassName} onClick={viewAsHandler}>
      {butnViewAsLabel}
    </button>
  );
};
