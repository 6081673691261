export const getAddressText = (address) => {
    if (!address) return;
    const { street, neighborhood, city, state, country, zip } = address;
    const addressComponents = [];
    const addressKeys = [zip, country, state, city, neighborhood, street];
    for (let i = 0; i < addressKeys.length; i++) {
        if (addressKeys[i]) addressComponents.push(addressKeys[i]);
    }
    let addressDisplay = '';
    for (let i = 0; i < addressComponents.length; i++) {
        if (i === 0) {
            addressDisplay += addressComponents[i];
        } else {
            addressDisplay += `, ${addressComponents[i]}`;
        }
    }
    return addressDisplay;
};
