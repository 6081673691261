import {NotPromotedCard, promotedStyles} from "./core";
import {Fragment} from "react";
import {NON_PROMOTED} from "utils";

export const NonPromoted = ({handlePromote, payment, userListings}) => {
    const classes = promotedStyles()

    return (
        <div>

            <div className={classes.cardsWrapper}>
                {userListings?.length ? userListings?.map((item, k) => (
                    // item?.promotion?.status === NON_PROMOTED &&
                        <Fragment key={k}>
                            <NotPromotedCard
                                item={item}
                                handlePromote={handlePromote}
                            />
                        </Fragment>
                    ))
                    : ''
                }
            </div>
        </div>
    )
}