import React, { useEffect } from "react";
import { menuBarStyle } from "./style";
import { Header, Main, Footer } from "fragments";
import { ToastSuccess, ToastFail } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  httpRequestsOnSuccessActions,
  httpRequestsOnErrorsActions,
} from "store";
import { Toast } from "components";

export const MenuBar = () => {
  const classes = menuBarStyle();
  const dispatch = useDispatch();
  const { httpOnError, httpOnSuccess } = useSelector((state) => ({
    httpOnLoad: state.httpOnLoad,
    httpOnError: state.httpOnError,
    httpOnSuccess: state.httpOnSuccess,
  }));

  const success = httpOnSuccess.length && httpOnSuccess[0].type;
  const error = httpOnError.length && httpOnError[0].type;
  const toastSuccess = ToastSuccess(success);
  const toastFail = ToastFail(error);

  useEffect(() => {
    if (toastSuccess) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
    }
  }, [toastSuccess]);

  useEffect(() => {
    if (toastFail) {
      dispatch(httpRequestsOnErrorsActions.removeError(error));
    }
  }, [toastFail]);

  return (
    <div className={classes.menuBarContainerStyle}>
      <Header />
      <Main />
      <Footer />
      <Toast
        type={toastSuccess ? "success" : toastFail ? "error" : ""}
        text={toastSuccess ? toastSuccess : toastFail ? toastFail : ""}
        info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ""}
      />
    </div>
  );
};
