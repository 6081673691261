import { Images } from 'utils';
import React from 'react';
import { SaveOrCancelButton } from 'components';
import { wrappersStyle } from './style';
import { CloseModalButton } from 'components';

export const FiltersModalWrapper = ({ onClose, children, submitHandler, clearAllHandler, isLoading = false,onDesktop }) => {
    const classes = wrappersStyle();
    return (
        <div>
            <div style={onDesktop ? {maxHeight:'580px'} : {}} className={classes.filtersModalWrapperStyle}>
                <div className={classes.titleBoxStyle}>
                    <CloseModalButton
                        butnClassName={classes.closeFiltersModalStyle}
                        onCloseModal={onClose}
                        butnLabel={<img src={Images.close} alt="" />}
                    />
                    {/*<h2 className={classes.moreFiltersTitleStyle}> More Filters </h2>*/}
                    <h2
                        style={onDesktop ? {display:'block'} : {}}
                        className={classes.filtersTitleStyle}> Advanced Search </h2>
                </div>
                <div className={classes.wrapperChildrenBoxStyle}>{children}</div>
                <div className={classes.wrapperActionsStyle}>
                    <SaveOrCancelButton
                        loader={isLoading}
                        butnSavingText={'Show Results'}
                        butnCancelling={'Clear all'}
                        onSaveData={submitHandler}
                        onClearAll={clearAllHandler}
                    />
                </div>
            </div>
        </div>
    );
};
