import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Carousel from 'nuka-carousel';
import {useHistory} from 'react-router';
import {homeFragmentStyles} from './styles';
import {enumValues, getValDependsOnLength, Images, ListingFiltersContext, useWidth} from 'utils';
import {FilteringCheckBoxDropdown, FilteringWithSearchBoxDropdown, SimpleModal,} from 'components';
import {AdvancedSearch, YourFreeListing} from './core';
import {listingActions} from 'store';
import {ListingsCardItem} from 'fragments';
import {navBarCoresStyle} from "../menuBar/nav/core/style";
import {Faqs} from "./core/faqs";

export const HomeFragment = () => {
    const classes = homeFragmentStyles();
    const dispatch = useDispatch();
    const width = useWidth();
    const listings = useSelector((state) => state.listings.listingList);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false)

    useEffect(() => {
        dispatch(listingActions.getListings());
    }, []);

    const handleModalClose = () => {
        setIsOpen(!isOpen)
    }

    const categoriesSize =
        width < 450
            ? 1.2
            : width >= 450 && width < 500
                ? 1.3
                : width >= 500 && width < 580
                    ? 1.5
                    : width >= 580 && width < 660
                        ? 1.8
                        : width >= 660 && width < 690
                            ? 2.1
                            : width >= 690 && width < 761
                                ? 2.2
                                : width >= 761 && width < 820
                                    ? 1.4
                                    : width >= 820 && width < 900
                                        ? 1.6
                                        : width >= 900 && width < 1000
                                            ? 1.8
                                            : width >= 1000 && width < 1050
                                                ? 2
                                                : width >= 1050 && width < 1089
                                                    ? 2.1
                                                    : width >= 1089 && width < 1149
                                                        ? 2.2
                                                        : width >= 1149 && width < 1279
                                                            ? 2.3
                                                            : width >= 1279 && width < 1330
                                                                ? 2.5
                                                                : width >= 1330 && width < 1430
                                                                    ? 2.7
                                                                    : width >= 1430
                                                                        ? 3
                                                                        : 1;

    const listingsSize =
        width >= 320 && width < 450
            ? 1.1
            : width >= 450 && width < 480
                ? 1.2
                : width >= 480 && width < 580
                    ? 1.4
                    : width >= 580 && width < 710
                        ? 1.7
                        : width >= 710 && width < 740
                            ? 2.1
                            : width >= 740 && width < 800
                                ? 2.2
                                : width >= 800 && width < 989
                                    ? 2.4
                                    : width >= 989 && width < 1049
                                        ? 2.6
                                        : width >= 1049 && width < 1209
                                            ? 3.2
                                            : width >= 1209 && width < 1330
                                                ? 3.4
                                                : width >= 1330 && width < 1430
                                                    ? 3.5
                                                    : width >= 1430
                                                        ? 4
                                                        : 1;

    const history = useHistory();
    const isHomePage = window.location.pathname === '/';
    const modalClass = navBarCoresStyle();

    const listingFiltersCtx = useContext(ListingFiltersContext);
    const {
        handleAddressRequest,
        handlePropTypeSelected,
        selectedPropType,
        reselAllFilters,
        setPropTypes
    } = listingFiltersCtx;

    const viewAll = () => {
        reselAllFilters()
        window.location.replace("/listings")
    }

    return (
        <div className={classes.homeFragmentStyle}>
            <div className={classes.firstBoxStyle}>
                <div className={classes.firstBoxContainerStyle}>
                    <h1 className={classes.homeTitleStyle}>
                        {/*<img src={Images.diamond} alt="" />*/}
                        The Next Gen Commercial Real Estate <br/>Leasing Platform
                    </h1>
                    <img className={classes.homePageHeroStyle} src={Images.homePageHero} alt=""/>

                    {/*<h6 className={classes.homeSubtitleStyle}>*/}
                    {/*    Croill is designed ground up to take the frustration of leasing industrial, commercial or retail real estate away*/}
                    {/*    from the leasees and leasors.*/}
                    {/*</h6>*/}


                    <div className={classes.searchListingBoxStyle}>
                        <div className={classes.searchListingWrapperStyle}>
                            <div
                                // disabled={true}
                                style={{width: '145px', marginRight: '16px', cursor: ''}}
                                // type="button"
                                className={classes.forLeaseButton}
                                // onClick={handleModalClose}
                            >
                                <img src={Images.forLease} alt=""/>
                                <span> For lease </span>
                            </div>

                            {/*<div className={classes.propTypeBoxStyle}>*/}

                            <div className={classes.searchButtonsWrapper}>

                                <FilteringCheckBoxDropdown
                                    dropdownBoxClassName={classes.homPageDropdownBoxStyle}
                                    dropdownClassName={classes.homPageDropdownStyle}
                                    pointsBoxClassName={classes.pointsBoxStyle}
                                    dropdownOptions={enumValues.PROPERTY_TYPES}
                                    selectedOption={selectedPropType}
                                    dropdownTitle={'Property Type'}
                                    isHomePage={isHomePage}
                                />
                                {/*</div>*/}
                                <div className={classes.citySearchBoxStyle}>
                                    <FilteringWithSearchBoxDropdown
                                        searchBoxClassName={classes.homePageSearchBoxStyle}
                                        dropdownClassName={classes.searchBoxDropdownStyle}
                                        defaultSearchText={'City'}
                                        searchedAddress={selectedAddress}
                                        onRequest={(address) => setSelectedAddress(address)}
                                    />
                                    <button
                                        type="button"
                                        className={classes.searchAddressButnStyle}
                                        onClick={() => {
                                            history.push('/listings');
                                            handleAddressRequest(selectedAddress);
                                        }}>
                                        <img src={Images.searchCity} alt=""/>
                                        <span> Search </span>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className={classes.advancedWrapper}>
                            <button onClick={() => setOpenSearch(!openSearch)} className={classes.advanced}>
                                <p>Advanced search</p>
                                <img src={Images.arrowBlackRight} alt={''}/>
                            </button>
                        </div>

                    </div>


                </div>


            </div>
            {/*<div style={{maxWidth:'1440px'}} className={classes.secondBoxStyle}>*/}
            {/*    <div className={classes.secondBoxContainerStyle}>*/}
            {/*        <div className={classes.imagesBoxStyle}>*/}
            {/*            <img src={Images.usersImage} alt="" />*/}
            {/*            <ul className={classes.offersListStyle}>*/}
            {/*                <li>*/}
            {/*                    <div>*/}
            {/*                        <img src={Images.listingList} alt="" />*/}
            {/*                    </div>*/}
            {/*                    <span> Unlimited Listings </span>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <div>*/}
            {/*                        <img src={Images.users} alt="" />*/}
            {/*                    </div>*/}
            {/*                    <span> 100's of Users/Day </span>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <div>*/}
            {/*                        <img src={Images.freeAccess} alt="" />*/}
            {/*                    </div>*/}
            {/*                    <span> Free Access </span>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        <div className={classes.havePropBoxStyle}>*/}
            {/*            <h3 className={classes.havePropTitle}>Have a property you want to lease out?</h3>*/}
            {/*            <p className={classes.havePropText}>*/}
            {/*                You can use Croill to add your listings for your potential leasees to see at not cost. Add as many listings as*/}
            {/*                you need with ease using our advanced platform. Keep in mind to fill out as much information about your listing*/}
            {/*                as possible to attrack more users.*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div
                className={classes.grayWrapperStyle}
                // style={{background: 'linear-gradient(180deg, #FAFAFA 0%, #FFFFFF 45.31%, rgba(245, 245, 245, 0) 90.79%, rgba(244, 244, 244, 0) 100%)'}}
            >
                <div className={classes.thirdBoxStyle}>
                    <div className={classes.thirdBoxContainerStyle}>
                        <h2 className={classes.createListingTitleStyle}>
                            3 steps to create <em> FREE </em> commercial property listings
                        </h2>
                        <div className={classes.yourFreeListingInfoStyle}>
                            <YourFreeListing
                                icon={Images.account}
                                title={'Create An Account'}
                                text={'Creating an account takes two minutes. Your account links your listings directly to you.'}
                            />
                            <YourFreeListing
                                icon={Images.property}
                                title={'List Your Property'}
                                text={
                                    'Once you created your free account, you can start adding your listings in Croill. Simply click the "Create Listing" button and fill out the form.'
                                }
                            />
                            <YourFreeListing
                                icon={Images.management}
                                title={'Manage Your Property Listing'}
                                text={
                                    'Once you created your first listings, you are now ready to manage them. You can see all of your listings in "My Listings" navigation item from the user menu.'
                                }
                            />
                        </div>
                    </div>
                </div>
                <div style={{maxWidth: '1440px'}} className={classes.fourthBoxStyle}>
                    <div className={classes.fourthBoxContainerStyle}>
                        <h4 className={classes.browseCategoriesTitleStyle}>Choose Categories</h4>
                        <p className={classes.chooseCategoriesDesc}>
                            Discover your perfect business space, near you in popular spots. Explore the ideal space
                            for your business journey!
                        </p>
                        <div className={classes.sliderWrapper}>
                            {width >= 1440 ? (
                                enumValues.PROPERTY_TYPES.slice(0, 3).map((propType, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={classes.cards}
                                        >
                                            <div className={classes.categoryBoxStyle}>
                                                <img src={propType.image} alt="industrial"/>
                                                <div className={classes.titleDescriptionWrapper}>
                                                    <h5 className={classes.categoryTitleStyle}>{propType.label}</h5>
                                                    <p className={classes.propertyDescription}>{propType?.description}</p>
                                                    <button
                                                        onClick={() => {
                                                            setPropTypes(enumValues.DEFAULT_PROPERTY_TYPES)
                                                            reselAllFilters()
                                                            handlePropTypeSelected(propType.label);
                                                            history.push('/listings');
                                                        }}
                                                        className={classes.viewMoreButton}
                                                    >
                                                        <p> View More</p>
                                                        <img src={Images.arrowRightRed} alt=""/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <Carousel
                                    slidesToShow={categoriesSize}
                                    slidesToScroll={1}
                                    style={{outline: 'none'}}
                                    renderBottomCenterControls={() => false}
                                    renderCenterLeftControls={() => false}
                                    renderCenterRightControls={() => false}>
                                    {enumValues.PROPERTY_TYPES.slice(0, 3).map((propType, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={classes.cards}
                                            >
                                                <div className={classes.categoryBoxStyle}>
                                                    <img src={propType.image} alt="industrial"/>
                                                    <div className={classes.titleDescriptionWrapper}>
                                                        <h5 className={classes.categoryTitleStyle}>{propType.label}</h5>
                                                        <p className={classes.propertyDescription}>{propType?.description}</p>
                                                        <button
                                                            onClick={() => {
                                                                setPropTypes(enumValues.DEFAULT_PROPERTY_TYPES)
                                                                reselAllFilters()
                                                                handlePropTypeSelected(propType.label);
                                                                history.push('/listings');
                                                            }}
                                                            className={classes.viewMoreButton}
                                                        >
                                                            <p> View More</p>
                                                            <img src={Images.arrowRightRed} alt=""/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Carousel>
                            )}
                        </div>
                    </div>
                </div>

                {listings && listings.length !== 0 && (
                    <div style={{maxWidth: '1440px'}} className={classes.fifthBoxStyle}>
                        <div className={classes.fifthBoxContainerStyle}>
                            <div className={classes.latestListingsBoxStyle}>
                                <h4 className={classes.latestListingsTitleStyle}>Latest Listings</h4>

                                <button onClick={viewAll} className={classes.viewAllLinkStyle}>
                                    View All
                                </button>
                            </div>
                            <div className={classes.sliderWrapper}>
                                {width >= 1440 ? (
                                    listings.slice(0, 4).map((listing, index) => {
                                        const {address, price, size, mainImage} = listing;
                                        const {street} = address;
                                        const listingPrice = getValDependsOnLength(price);
                                        const listingMinSize = getValDependsOnLength(size.start ? size.start : '');
                                        const listingMaxSize = getValDependsOnLength(size.end ? size.end : '');

                                        return (
                                            <ListingsCardItem
                                                key={index}
                                                listingId={listing._id}
                                                mainImage={mainImage?.url}
                                                street={street}
                                                address={address}
                                                price={listingPrice}
                                                size={{minSize: listingMinSize, maxSize: listingMaxSize}}
                                                item={listing}
                                            />
                                        );
                                    })
                                ) : (
                                    <Carousel
                                        slidesToShow={listingsSize}
                                        slidesToScroll={1}
                                        style={{outline: 'none'}}
                                        renderBottomCenterControls={() => false}
                                        renderCenterLeftControls={() => false}
                                        renderCenterRightControls={() => false}>
                                        {listings.map((listing, index) => {
                                            const {address, price, size, mainImage} = listing;
                                            const {street} = address;
                                            const listingPrice = getValDependsOnLength(price);
                                            const listingMinSize = getValDependsOnLength(size.start ? size.start : '');
                                            const listingMaxSize = getValDependsOnLength(size.end ? size.end : '');

                                            return (
                                                <ListingsCardItem
                                                    key={index}
                                                    listingId={listing._id}
                                                    mainImage={mainImage?.url}
                                                    street={street}
                                                    address={address}
                                                    price={listingPrice}
                                                    size={{minSize: listingMinSize, maxSize: listingMaxSize}}
                                                    item={listing}
                                                />
                                            );
                                        })}
                                    </Carousel>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <Faqs/>

            </div>

            <SimpleModal
                openDefault={openSearch}
                handleOpenClose={() => setOpenSearch(false)}
                backdropCustom={modalClass.filterOverlayStyle}
                content={
                    <AdvancedSearch
                        handleModalClose={() => setOpenSearch(false)}
                    />
                }
            />
        </div>
    );
};
