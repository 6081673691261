import {BrokersFragmentStyles} from "./styles";


export const WhiteSpace = ({ children }) => {
    const classes = BrokersFragmentStyles()

    return(
        <div className={classes.whiteSpaceWrapper}>
            {children}
        </div>
    )
}