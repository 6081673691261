import {Images, Colors} from 'utils';
import {makeStyles} from '@material-ui/core/styles';
import * as url from "url";

export const wrappersStyle = makeStyles(
    () => ({
        // ** filters modal wrapper style **
        '@keyframes bottomUp': {
            '0%': {
                maxHeight: '0px',
            },
            '100%': {
                maxHeight: '587px',
            },
        },
        filtersModalWrapperStyle: {
            position: 'fixed',
            bottom: '50%',
            right: '50%',
            transform: 'translate(50%, 50%)',
            zIndex: 99999,
            maxWidth: '500px',
            width: '100%',
            maxHeight: '391px',
            height: '100%',
            overflow: 'auto',
            paddingTop: '16px',
            backgroundColor: Colors.BackgroundWhite,
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            '@media(max-width: 1200px)': {
                maxHeight: '587px',
            },
            '@media(max-width: 768px)': {
                maxWidth: '375px',
            },
            '@media(max-width: 375px)': {
                bottom: 0,
                right: 0,
                transform: 'translate(0%, 0%)',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                animation: '$bottomUp 0.2s linear',
            },
        },
        titleBoxStyle: {
            padding: '0px 16px 15px',
            textAlign: 'center',
            borderBottom: '1px solid #7572804D',
        },
        moreFiltersTitleStyle: {
            fontSize: '24px',
            fontWeight: 600,
            color: Colors.TextBlack,
            '@media(max-width: 1200px)': {
                display: 'none',
            },
        },
        filtersTitleStyle: {
            fontSize: '24px',
            fontWeight: 600,
            color: Colors.TextBlack,
            display: 'none',
            '@media(max-width: 1200px)': {
                display: 'block',
            },
        },
        wrapperChildrenBoxStyle: {
            flexGrow: 1,
        },
        wrapperActionsStyle: {
            flexShrink: 0,
            padding: '22px 24px',
            backgroundColor: Colors.BackgroundWhiteOff,
        },
        // ** end **

        // ** register modal wrapper style **
        registerWrapperStyle: {
            position: 'relative',
            overflow: 'hidden',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: 'auto',
            zIndex: 999,
            backgroundColor: Colors.BackgroundWhite,
            borderRadius: '12px',
            display: 'flex',
        },
        closeModalButnStyle: {
            left: 'unset',
            right: '16px',
            '@media(max-width: 500px)': {
                top: '8px',
                right: '8px',
            },
        },
        closeFiltersModalStyle: {
            right: '16px',
            left: 'auto',
        },
        wrapperLeftSideStyle: {
            width: '232px',
            // height: '404px',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            // padding: '24px 32px 32px',
            // ../assets/images/signUpRectangle.png
            backgroundImage: `url(${Images.signUpRectangle})`,
            // background: url('../assets/images/signUpRectangle.png'),
            backgroundColor: '#F4EAEE',
            '@media(max-width: 1120px)': {display: 'none'},
        },
        logoInModalStyle: {
            marginBottom: '11px',
            marginLeft: '-8px',
            '& > h1': {fontSize: '20px'},
            '&::after': {
                width: '8px',
                height: '8px',
            },
        },
        wrapperImageStyle: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        wrapperRightSideStyle: {
            padding: '32px',
            backgroundColor: 'inherit',
            maxHeight: '90vh',
            '@media(max-width: 600px)': {width: '343px', padding: '16px'},
            '@media(max-width: 500px)': {padding: '16px'},
        },
        // ** end **

        // ** user registration wrapper style **
        registerTitleStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
            marginBottom: '32px',
            '&.welcome': {
                marginTop: '40px',
                '@media(max-width: 500px)': {marginTop: '32px'},
            },
            '&.profilePicture': {
                textAlign: 'center',
                marginBottom: '16px',
            },
            '&.changeDelete': {marginBottom: '16px'},
        },
        // ** end **

        // ** listing inputs wrapper style **
        listingInputWrapperStyle: {
            backgroundColor: Colors.BackgroundWhite,
            boxShadow: '0px 0px 6px #00000029',
            borderRadius: '12px',
        },
        wrapperContainerStyle: {
            padding: '40px 40px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media(max-width: 768px)': {
                display: 'none',
            },
            '&.tabletView': {
                display: 'none',
                '@media(max-width: 768px)': {
                    display: 'flex',
                    paddingBottom: '40px',
                    cursor: 'pointer',
                },
                '@media(max-width: 600px)': {
                    paddingBottom: '24px',
                },
            },
            '@media(max-width: 600px)': {
                padding: '24px 24px 0px',
            },
        },
        wrapperTitleStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: '#3377CC',
            textTransform: 'capitalize',
        },
        childrenContainerStyle: {
            padding: '40px',
            width: '100%',
            '@media(max-width: 768px)': {display: 'none'},
            '&.tabletView': {
                display: 'none',
                '@media(max-width: 768px)': {
                    display: 'block',
                    padding: '0px 40px',
                    '&.active': {paddingBottom: '40px'},
                },
                '@media(max-width: 600px)': {
                    padding: '0px 24px',
                    '&.active': {paddingBottom: '24px'},
                },
            },
        },
        // ** end **

        // ** my profile wrapper style **
        userInfoStyle: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '36px',
        },
        userProfilePictureContentStyle: {
            position: 'relative',
            width: '78px',
            height: '78px',
            border: '4px solid #FFFFFF',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
            borderRadius: '40px',
            '& > label.editAvatarIcon': {
                display: 'block',
                width: '28px',
                height: '28px',
                backgroundImage: `url(${Images.addPhoto})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                position: 'absolute',
                bottom: '-2px',
                right: '-8px',
                cursor: 'pointer',
            },
        },
        userProfileBoxStyle: {
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // '& > input': { display: 'none' },
            '& embed, & img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                border: `0.1px solid ${Colors.BackgroundWhite}`,
                borderRadius: '50%',
            },
        },
        userProfileImageStyle: {
            width: '78px',
            height: '78px',
            objectFit: 'cover',
        },
        userFullNameStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
            textTransform: 'capitalize',
            marginLeft: '34px',
            "@media(min-width: 320px)": {
                marginLeft: '16px',
            },
            "@media(min-width: 768px)": {
                marginLeft: '34px',
            },
        },
        myProfileBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: Colors.BackgroundWhiteOff,
        },
        myProfileBoxContainerStyle: {
            maxWidth: '1440px',
            width: '100%',
            padding: '148px 80px 180px',

            '@media(min-width: 320px)': {
                padding:'24px 16px',
            },
            '@media(min-width: 768px)': {
                padding:'57px 152px',
            },
            '@media(min-width: 1440px)': {
                padding:'148px 272px',
            },
        },
        myProfileWrapperStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        myProfileTitleStyle: {
            fontSize: '24px',
            fontWeight: 800,
            color: Colors.TextBlack,
            marginBottom: '40px',
            '@media(max-width: 600px)': {marginBottom: '24px'},
        },
        wrapperContentStyle: {
            // width: '936px',
            padding: '32px',
            paddingBottom: '24px',
            backgroundColor: Colors.BackgroundWhite,
            borderRadius: '12px',
            boxShadow: '0px 0px 6px #00000029',
            // '@media(max-width: 1024px)': {width: '464px'},
            // '@media(max-width: 600px)': {
                width: '100%',
                // padding: '24px',
            // },
        },
        myProfileActionsStyle: {
            marginTop: '25px',
            paddingRight: '32px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media(max-width: 600px)': {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
            '& button': {
                fontSize: '16px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                    content: "''",
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                },
            },
        },
        changePassButnStyle: {
            marginRight: '40px',
            color: '#3377CC',
            '&::before': {backgroundImage: `url(${Images.password})`},
            '@media(max-width: 600px)': {
                marginRight: '0px',
                marginBottom: '17px',
            },
        },
        delAccountButnStyle: {
            color: '#FF385C',
            '&::before': {backgroundImage: `url(${Images.deletee})`},
        },
        // ** end **

        // ** my profile modals style **
        userRegistrationStyle: {
            '& > div:last-of-type > div': {marginTop: '24px'},
            '& > div:last-of-type > div > button': {
                width: '188px',
                height: '40px',
                '&:first-of-type': {
                    textDecoration: 'none',
                    backgroundColor: '#75728080',
                    color: Colors.TextWhite,
                    marginRight: '24px',
                },
                '&:last-of-type': {backgroundColor: '#3377CC'},
            },
        },
        profilePictureStyle: {
            width: '112px',
            height: '112px',
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto 28px',
            '& embed, & img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                border: `0.1px solid ${Colors.BackgroundWhite}`,
                borderRadius: '50%',
            },
        },
        profilePictureDescStyle: {
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextGray,
            maxWidth: '400px',
            width: '100%',
            textAlign: 'center',
            lineHeight: '24px',
        },
        descriptionStyle: {
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextGray,
            maxWidth: '400px',
            width: '100%',
            lineHeight: '24px',
        },
        pictureActionsStyle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        removeButnStyle: {
            maxWidth: '188px',
            backgroundColor: '#75728080 !important',
        },
        changeAvatarStyle: {
            cursor: 'pointer',
            maxWidth: '188px',
            borderRadius: '8px',
            width: '100%',
            height: '40px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextWhite,
            backgroundColor: '#3377CC',
        },
        // ** end **
    }),
    {index: 1}
);


export const globalStyles = makeStyles(() => ({
    promotionWrapperBackground: {
        background: '#F4F4F4',
    },
    promotionWrapper: {
        height: '100%',
        minHeight: '100vh',
        maxWidth: '1440px',
        margin: '0 auto',
        padding: '18px 16px 64px 16px',

        '@media(min-width: 320px)': {
            padding: '18px 16px 64px 16px',
        },
        '@media(min-width: 768px)': {
            padding: '32px 70px 80px 70px',
        },
        '@media(min-width: 1280px)': {
            padding: '48px 80px 80px 80px',
        },
    },
    title: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2B273C',
    },
    subTitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        margin: '12px 0 24px',
        '@media(min-width: 320px)': {
            margin: '16px 0 16px',
        },
        '@media(min-width: 768px)': {
            margin: '12px 0 24px',
        },
    },

}))