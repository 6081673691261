import React, { useContext } from 'react';
import { FilteringWithSearchBoxDropdown } from 'components';
import { ListingFiltersContext } from 'utils';

export const NavBarSearchBox = () => {
    const listingFiltersCtx = useContext(ListingFiltersContext);
    const { address, handleAddressRequest } = listingFiltersCtx;

    return (
        <FilteringWithSearchBoxDropdown
            defaultSearchText={'Search by Address'}
            searchedAddress={address}
            searchIcon
            onRequest={(address) => handleAddressRequest(address)}
        />
    );
};
