import {GET_INVOICES_SUCCESS, GET_PAYMENT_SUCCESS} from "./payment.types";

const initialState = {
    myPayment: null,
    invoices: null,
};

export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_PAYMENT_SUCCESS:
            return {
                ...state,
                myPayment: action.payload,
            };

        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
            };

        default:
            return state;
    }
};
