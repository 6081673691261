import {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {Images} from "utils";
import {GrayItem, BrokersFragmentStyles} from "./core";
import {RenderText} from "../../utils/hooks/textResizer";

export const BrokersFragment = ({brokers}) => {
    const classes = BrokersFragmentStyles()
    const history = useHistory()
    const [mapList, setMapList] = useState([])

    useEffect(() => {
        if (brokers?.brokers) {
            setMapList(brokers?.brokers)
        }
    }, [brokers])

    const handleRoute = (e, id) => {
        history.push(`broker/${id}`)
    }

    const handleFind = (e) => {
        const list = brokers?.brokers.filter((el) => el?.countryOfService.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)
        setMapList(list)
    }

     const TextRow = ({ name, textWidth }) => {
        const text = (name ? name : 'Not Set') + '';
        const ref = useRef();
        return (
            <div style={{width:'100%'}} ref={ref}>
                {RenderText(ref?.current?.offsetWidth ? ref?.current?.offsetWidth : 190, text, textWidth)}
            </div>
        );
    }

    const phoneText = window.innerWidth > 1279 && window.innerWidth < 1320 ? 'Phone:' : 'Phone Number:'

    return (
        <div className={classes.brokersFragmentWrapper}>
            <p className={classes.title}>Find Broker</p>
            <div className={classes.findWrapper}>
                <img src={Images.searchRed} alt="search"/>
                <input
                    onChange={handleFind}
                    placeholder='Search by County'
                    className={classes.searchInput}
                    type="text"
                />
            </div>
            <div className={'broker-wrapper'}>
                {brokers?.brokers?.length ?
                    <>
                        {mapList?.length ?
                            mapList?.map((i, k) => (
                                <div onClick={(e) => handleRoute(e, i?.id)} key={k} className={classes.brokerItem}>
                                    <div className={classes.brokerInfo}>
                                        <img
                                            className={classes.brokerListAvatar}
                                            src={i?.avatar?.url ? i?.avatar?.url : Images.userProfile} alt="userIcon"/>
                                        <div className={classes.textRow}>
                                            <TextRow name={`${i?.firstName} ${i?.lastName}`} textWidth={11}/>
                                        </div>
                                    </div>
                                    <div className={classes.grayItemsWrapper}>
                                        <GrayItem
                                            icon={Images.buildings}
                                            title={'Company:'}
                                            subtitle={i?.company}
                                        />
                                        <GrayItem
                                            icon={Images.callCalling}
                                            title={phoneText}
                                            subtitle={i?.phone}
                                        />
                                        <GrayItem
                                            right={0}
                                            icon={Images.redSms}
                                            title={'Email:'}
                                            subtitle={i?.email}
                                        />
                                    </div>
                                </div>
                            ))
                            :
                            <div className={classes.noBrokersYet}>
                                <img src={Images.searchBroker} alt='icon'/>
                                <p>Search by County to <br/>
                                    Find a Broker</p>
                            </div>
                        }
                    </>
                    :
                    <div className={classes.noBrokersYet}>
                        <img src={Images.searchBroker} alt='icon'/>
                        <p>No Brokers Yet</p>
                    </div>
                }
            </div>
        </div>
    )
}