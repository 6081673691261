import { makeStyles } from "@material-ui/core/styles";

export const menuBarStyle = makeStyles(
  () => ({
    menuBarContainerStyle: {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
  }),
  { index: 1 }
);
