import React, { useState } from 'react';

export const AvatarContext = React.createContext({
    avatar: undefined,
    handleAvatarChange: () => {},
});

export const AvatarContextProvider = ({ children }) => {
    const [avatar, setAvatar] = useState();

    const handleAvatarChange = (avatar) => setAvatar(avatar);

    return <AvatarContext.Provider value={{ avatar, handleAvatarChange }}>{children}</AvatarContext.Provider>;
};
