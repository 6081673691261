import { RegisterContext } from "utils";
import React, { useContext } from "react";
import { buttonsStyle } from "./style";
import { CircularProgress } from "@mui/material";

export const SaveOrCancelButton = ({
  butnClassName,
  loader = false,
  butnSavingText,
  butnCancelling,
  onClearAll,
  onSaveData,
}) => {
  const classes = buttonsStyle();

  const registerCtx = useContext(RegisterContext);
  const { modalContent } = registerCtx;

  return (
    <div className={`${classes.saveOrCancelButnStyle} ${butnClassName}`}>
      <button
        type="button"
        className={classes.cancelButnStyle}
        onClick={onClearAll}
      >
        {butnCancelling}
      </button>
      <button
        type="button"
        className={`${classes.saveButnStyle} ${
          modalContent === "deleteAccount" ? "delete" : ""
        }`}
        onClick={onSaveData}
      >
        {loader ? (
          <CircularProgress size={17} color={"inherit"} />
        ) : (
          butnSavingText
        )}
      </button>
    </div>
  );
};
