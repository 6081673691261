import {
  CREATE_USER,
  GET_USERS,
  EDIT_USER,
  REMOVE_USER,
  GET_USER_BY_ID,
} from "./users.types";

export const createUser = (body) => {
  return {
    type: CREATE_USER,
    payload: { body },
  };
};

export const getUsers = () => {
  return {
    type: GET_USERS,
  };
};

export const editUser = (id, body) => {
  return {
    type: EDIT_USER,
    payload: { id, body },
  };
};

export const removeUser = (id) => {
  return {
    type: REMOVE_USER,
    payload: { id },
  };
};

export const getUserById = (id) => {
  return {
    type: GET_USER_BY_ID,
    payload: { id },
  };
};
