import React, { useState, useEffect, useContext } from 'react';
import { userRegistrationCoreStyles } from './styles';
import { EmailValidator, FindError, FindLoad, FindSuccess, RegisterContext } from 'utils';
import { SendButton, UserInput } from 'components';
import { useDispatch } from 'react-redux';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';

export const ForgotPassword = ({ onTrigger }) => {
    const classes = userRegistrationCoreStyles();
    const dispatch = useDispatch();

    const [inputs, setInputs] = useState({});
    const [error, setError] = useState('');

    const sendingEmailLoader = FindLoad('GET_RECOVERY_LINK');
    const userBackError = FindError('GET_RECOVERY_LINK');
    const sendingEmailSuccess = FindSuccess('GET_RECOVERY_LINK');

    const registerCtx = useContext(RegisterContext);
    const { handleModalContent } = registerCtx;

    useEffect(() => {
        if (sendingEmailSuccess.length) {
            handleModalContent('checkEmail');
            dispatch(httpRequestsOnSuccessActions.removeSuccess('GET_RECOVERY_LINK'));
        }
    }, [sendingEmailSuccess]);

    const errorMsg = 'This field must be not empty!';
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? 'Email must be an email!' : '';
    const emailErrorText =
        error === 'email' ? errorMsg : userBackError.length && userBackError[0].error === 'User already exists' ? 'User already exists'
            : userBackError.length && userBackError[0].error === 'User with this email was not found'
            ? 'The email you entered isn’t connected to an account'
            : error === emailErrorMsg
            ? emailErrorMsg
            : '';

    const handleChange = (evt) => {
        setInputs((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value,
        }));
        if (error === evt.target.name || error === emailErrorMsg || userBackError.length) {
            setError('');
        }
        if (userBackError.length) {
            dispatch(httpRequestsOnErrorsActions.removeError('GET_RECOVERY_LINK'));
        }
    };

    const handleEmailSend = () => {
        const emailIsValid = inputs.email && EmailValidator.test(inputs.email);
        const errorText = !inputs.email ? 'email' : !emailIsValid ? emailErrorMsg : 'Input is not field';
        if (emailIsValid) {
            dispatch(authActions.getRecoveryLink(inputs.email));
            onTrigger(inputs.email);
        } else {
            setError(errorText);
        }
    };

    return (
        <>
            <h6 className={classes.registerInfoStyle}>
                Please enter your email address and we'll send you a recovery email to reset your password.
            </h6>
            <div>
                <UserInput
                    required={true}
                    inputClassName={'inModal'}
                    inputLabel={'Email Address'}
                    inputType={'text'}
                    inputName={'email'}
                    inputValue={inputs.email}
                    onInputChange={handleChange}
                    inputError={emailErrorText}
                />
                <SendButton
                    butnClassName={classes.sendEmailButnStyle}
                    butnType={'button'}
                    butnDisabled={false}
                    butnSendingText={'Get Recovery Email'}
                    loader={!!sendingEmailLoader.length}
                    onClickButn={handleEmailSend}
                />
            </div>
        </>
    );
};
