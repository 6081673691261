import {billingStyles} from "./styles";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../../../pages/promotion/core/checkoutForm";
import {loadStripe} from "@stripe/stripe-js";


// const stripePromise = loadStripe('pk_test_51MfratLAkwdqYCSYf1eRsihPMam37GmLHUIXsgZXXYxlO3kS1XX4o6ptNACFivptJUezorigv5QL4W5nIQHFgVtr00tXNFUnCr');
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PKEY}`);

export const EditCard = ({onClose, handleCreate}) => {
    const classes = billingStyles()

    return (
        <div>
            <p className={classes.deleteTitle}>Payment Method</p>
            <Elements stripe={stripePromise}>
                <CheckoutForm edit={true}/>
            </Elements>
        </div>
    )
}