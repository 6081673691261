import { fork } from "redux-saga/effects";
import { watchAuth } from "../auth";
import { watchListing } from "../listings";
import { watchUser } from "../users";
import {watchPayment} from "../payment";
import {watchBroker} from "../brokers";

/** Combined Sagas */
export const appSaga = function* startForman() {
  yield fork(watchAuth);
  yield fork(watchUser);
  yield fork(watchListing);
  yield fork(watchBroker);
  yield fork(watchPayment);
};