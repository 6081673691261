import {call, put, takeLatest} from 'redux-saga/effects';
import {authService} from './brokers.service';
import {authActions} from '..';
import {httpRequestsOnErrorsActions} from '../http_requests_on_errors';
import {httpRequestsOnLoadActions} from '../http_requests_on_load';
import {httpRequestsOnSuccessActions} from '../http_requests_on_success';
import {
    CREATE_BROKER,
    DELETE_BROKER,
    EDIT_BROKER,
    GET_BROKER_BY_ID,
    GET_BROKER_BY_ID_SUCCESS, GET_BROKER_MY_PROFILE, GET_BROKER_MY_PROFILE_SUCCESS,
    GET_BROKERS,
    GET_BROKERS_SUCCESS,
} from "./brokers.types";
import {GET_USER_BY_ID} from "../users/users.types";

function* createBroker({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        let res = yield call(authService.createBrokerService, payload.body);
        const data = {
            email: payload.body.email,
            password: payload.body.password,
        };
        const pageEvent = {
            event: "SIGN_UP",
            user_id: res.data?.id,
            userType: "BROKER"
        };
        window?.dataLayer.push(pageEvent);

        yield put(authActions.logIn(data));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getBrokers({type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const {data} = yield call(authService.getBrokersService);
        yield put({
            type: GET_BROKERS_SUCCESS,
            payload: data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* editBroker({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.editBrokerService, payload.id, payload.body);
        yield put({
            type: GET_USER_BY_ID,
            payload:{ load:'noLoad' }
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* deleteUser({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.deleteUserService, payload.id);
        localStorage.removeItem('access-token');
        localStorage.removeItem('reset-token');
        localStorage.removeItem('userInfo');
        window.location.replace('/');
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getBrokerById({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const {data} = yield call(authService.getBrokerByIdService, payload.id);
        yield put({
            type: GET_BROKER_BY_ID_SUCCESS,
            payload: data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getBrokerProfile({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const {data} = yield call(authService.getBrokerProfileService, payload.id);
        yield put({
            type: GET_BROKER_MY_PROFILE_SUCCESS,
            payload: data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

export const watchBroker = function* watchBrokerSaga() {
    yield takeLatest(CREATE_BROKER, createBroker);
    yield takeLatest(GET_BROKERS, getBrokers);
    yield takeLatest(EDIT_BROKER, editBroker);
    yield takeLatest(DELETE_BROKER, deleteUser);
    yield takeLatest(GET_BROKER_BY_ID, getBrokerById);
    yield takeLatest(GET_BROKER_MY_PROFILE, getBrokerProfile);
};
