import React from "react";
import RichTextEditor from "react-rte";

export class RichInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(),
    };
  }

  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.setState({ value: value });
      value.toString("html");
      this.props.onChange(value.toString("html"));
    }
  };

  render() {
    const toolbarConfig = {
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "BLOCK_ALIGNMENT_BUTTONS",
        "LINK_BUTTONS",
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
      ],
      BLOCK_ALIGNMENT_BUTTONS: [
        { label: "AL", style: "ALIGN_LEFT" },
        { label: "AC", style: "ALIGN_CENTER" },
        { label: "AR", style: "ALIGN_RIGHT" },
      ],
      LINK_BUTTONS: [
        {
          label: "Link!",
          style: "unstyled",
          className: "rte-button rte-button-link",
        },
        {
          label: "Remove link",
          style: "unstyled",
          className: "rte-button rte-button-link-remove",
        },
      ],
    };

    return (
      <RichTextEditor
        disabled={this.props.disableLabels}
        placeholder={"Type here..."}
        toolbarConfig={toolbarConfig}
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}
