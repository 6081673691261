import React from "react";
import {CircularProgress} from "@mui/material";
import {loaderStyle} from "./style";
import {Colors} from "utils";

export const Loader = ({type, color}) => {
    const classes = loaderStyle();
    return (
        <div  style={ type ==='mini' ? {height:'auto'} : {}} className={classes.loaderBoxStyle}>
            <CircularProgress
                style={{
                    width: type === 'mini' ? '20px' : "100px",
                    height: type === 'mini' ? '20px' : "100px",
                    position: type === 'mini' ? 'relative' : "absolute",
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    color: color ? color : Colors.ThemePink,
                }}
            />
        </div>
    );
};
