import {makeStyles} from '@material-ui/core/styles';
import {Colors} from 'utils';

export const listingsCardItemStyle = makeStyles(
    () => ({
        // * gallery view styles *
        cardItemStyle: {
            position: 'relative',
            width: '292px !important',
            height: '336px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: Colors.BackgroundWhite,
            boxShadow: '0px 0px 6px #00000029',
            borderRadius: '16px',
            overflow: 'hidden',
            marginRight: '24px',
            marginBottom: '24px',
            cursor: 'pointer',
            '&:hover .cardOverlay': {
                backgroundColor: '#00000066',
                zIndex: 9,
            },
            '@media(min-width: 769px)': {
                cursor: 'default',
                '&:hover .cardInfo': {height: '131px'},
                '&:hover .viewDetailsButn': {
                    opacity: 1,
                    transition: 'opacity 0.1s linear',
                },
            },
            '@media(max-width: 795px)': {marginRight: '0px'},
        },
        cardImageBoxStyle: {
            width: '100%',
            height: '259px',
            overflow: 'hidden',
            '& img': {
                width: '100%',
                objectFit: 'cover',
                height: '259px',
            },
        },
        cardItemOverlayStyle: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -9,
            width: '100%',
            height: '100%',
            backgroundColor: '#0000001A',
            transition: 'background-color 0.1s linear',
        },
        cardItemInfoStyle: {
            width: '100%',
            height: '87px',
            padding: '12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: Colors.BackgroundWhite,
            transition: 'height 0.2s linear',
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 9,
        },
        infoBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        formattedAddressBoxStyle: {
            cursor: 'default',
            // marginTop: '8px',
        },
        formattedAddressBoxListViewStyle: {cursor: 'default'},
        cardTitleStyle: {
            fontSize: '16px',
            fontWeight: 600,
            color: Colors.ThemeBlue,
        },
        cardAddressStyle: {
            maxWidth: '130px',
            fontSize: '12px',
            fontWeight: 500,
            color: Colors.ThemeBlue,
            marginTop: '8px',
        },
        cardPriceStyle: {
            fontSize: '16px',
            fontWeight: 600,
            color: Colors.TextBlack,
            textAlign: 'right',
            '& em': {
                fontSize: '12px',
                fontWeight: 500,
                color: Colors.TextGray,
            },
        },
        cardSizeStyle: {
            marginTop: '8px',
            padding: '4px 8px',
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextGray,
            height: '28px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        viewCardDetailsButnStyle: {
            backgroundColor: Colors.ThemePink,
            color: Colors.TextWhite,
            width: '268px',
            opacity: 0,
            transition: 'background-color 0.1s linear',
            '&:hover': {backgroundColor: '#d52f4c'},
        },
        moreBoxStyle: {
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        moreStyle: {
            cursor: 'pointer',
            marginBottom: '4px',
        },
        buildingStyle: {
            width: '302px',
            height: '259px !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.BackgroundWhiteOff,
        },
        // * end *

        // * list view styles *
        cardItemListViewStyle: {
            position: 'relative',
            maxWidth: '628px',
            width: '100%',
            padding: '8px',
            display: 'flex',
            marginBottom: '24px',
            alignItems: 'center',
            backgroundColor: Colors.BackgroundWhite,
            boxShadow: '0px 0px 6px #00000029',
            borderRadius: '16px',
            '@media(max-width: 1440px)': {
                maxWidth: '608px',
            },
            '@media(max-width: 1400px)': {
                maxWidth: '588px',
            },
            '@media(max-width: 1360px)': {
                maxWidth: '548px',
            },
            '@media(max-width: 1280px)': {
                maxWidth: '508px',
            },
            '@media(max-width: 1180px)': {
                maxWidth: '628px',
            },
        },
        cardImageBoxListViewStyle: {
            width: '102px',
            height: '102px',
            flexShrink: 0,
            display: 'flex',
            marginRight: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            '@media(min-width: 320px)': {
                width: '76px',
                height: '76px',
            },
            '@media(min-width: 767px)': {
                width: '102px',
                height: '102px',
            },
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '16px',
            },
        },
        cardItemOverlayListViewStyle: {display: 'none'},
        cardItemInfoListViewStyle: {
            height: '78px',
            position: 'relative',
            flexGrow: 1,
            paddingLeft: '8px',
            paddingRight: '8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        infoBoxListViewStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        cardTitleListViewStyle: {
            fontSize: '16px',
            fontWeight: 600,
            color: Colors.ThemeBlue,
            '@media(max-width: 605px)': {fontSize: '14px'},
        },
        cardPriceListViewStyle: {
            fontSize: '16px',
            fontWeight: 600,
            color: Colors.TextBlack,
            textAlign: 'right',
            '&.margin': {
                marginRight: '50px',
                marginTop: '8px',
            },
            '& em': {
                fontSize: '12px',
                fontWeight: 500,
                color: Colors.TextGray,
            },
            '@media(max-width: 605px)': {fontSize: '14px'},
        },
        cardSizeListViewStyle: {
            position: 'absolute',
            left: '8px',
            bottom: 0,
            '@media(max-width: 605px)': {
                backgroundColor: 'inherit',
                paddingLeft: '0px',
                justifyContent: 'flex-start',
            },
        },
        viewCardDetailsButnListViewStyle: {
            width: '150px',
            backgroundColor: 'inherit',
            color: Colors.ThemePink,
            alignSelf: 'flex-end',
            '&:hover': {
                backgroundColor: Colors.ThemePink,
                color: Colors.TextWhite,
                transition: 'background-color 0.1s linear',
            },
            '@media(max-width: 605px)': {
                width: '100px',
                padding:'0',
                border: 'none',
                textAlign: 'right',
                paddingBottom: '0px',
                textDecoration: 'underline',
                '&:hover': {
                    backgroundColor: 'inherit',
                    color: Colors.ThemePink,
                },
            },
        },
        priceAndMoreStyle: {
            display: 'flex',
            height: '20px',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        moreBoxListViewStyle: {
            position: 'absolute',
            top: '16px',
            right: '18px',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        moreListViewStyle: {
            cursor: 'pointer',
            boxShadow: '0px 0px 6px #00000029',
            borderRadius: '50%',
            marginBottom: '4px',
            width: '32px',
            height: '32px',
        },
        buildingListViewStyle: {
            width: '102px',
            height: '102px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: '16px',
            '@media(min-width: 320px)': {
                width: '76px',
                height: '76px',
            },
            '@media(min-width: 767px)': {
                width: '102px',
                height: '102px',
            }

        },
        // * end *

        moreOverlayStyle: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -9,
        },
        moreActionsBoxStyle: {
            width: '140px',
            borderRadius: '12px',
            boxShadow: '0px 0px 6px #00000029',
            backgroundColor: Colors.BackgroundWhite,
            padding: '12px 0',
        },
        moreActionsListStyle: {
            '& li': {
                padding: '10px 16px',
                fontSize: '14px',
                fontWeight: 500,
                color: Colors.TextGray,
                backgroundColor: 'inherit',
                transition: 'background-color 0.2s linear',
                '&:hover': {backgroundColor: Colors.BackgroundWhiteOff},
                cursor: 'default',
            },
        },

        // * Custom Marker Style *
        markerStyle: {
            position: 'relative',
            '&:hover $pinStyle': {
                borderColor: '#1593C14D',
                '&::before': {backgroundColor: '#1593C1'},
            },
            '&.active $pinStyle': {
                borderColor: '#FF385C33',
                '&::before': {backgroundColor: '#FF385C'},
            },
        },
        pinStyle: {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            border: '5px solid #3377CC33',
            position: 'relative',
            transition: 'all 0.2s ease-in-out',
            '&::before': {
                content: "''",
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: '#3377CC',
            },
        },
        markerContentStyle: {
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate(8%, -88%)',
            width: '264px',
            height: '93px',
            overflow: 'hidden',
            boxShadow: '0px 3px 6px #00000029',
            borderRadius: '8px',
            backgroundColor: Colors.BackgroundWhite,
            display: 'flex',
        },
        listingImageStyle: {
            width: '89px',
            position: 'relative',
            '& > img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            },
            '& > p': {
                width: '100%',
                position: 'absolute',
                bottom: 0,
                left: 0,
                textAlign: 'center',
                backgroundColor: '#F4F4F4E3',
                fontSize: '12px',
                fontWeight: 600,
                color: Colors.TextBlack,
            },
        },
        listingDetailsStyle: {
            padding: '8px 16px',
            width: '175px',
            '& > h5': {
                fontSize: '14px',
                fontWeight: 600,
                color: Colors.ThemeBlue,
            },
            '& > h6': {
                fontSize: '12px',
                fontWeight: 500,
                color: Colors.ThemeBlue,
            },
            '& > p': {
                fontSize: '14px',
                fontWeight: 600,
                color: Colors.TextBlack,
                marginTop: '16px',
                textAlign: 'right',
                '& > em': {
                    fontSize: '12px',
                    fontWeight: 500,
                    color: Colors.TextGray,
                },
            },
        },
        promoted: {
            width: '112px',
            height: '36px',
            background: '#FBEBED',
            borderRadius: '16px',
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#FE385C',
            position:'absolute',
            left: 8,
            top: 8,
            '& img':{
                marginRight:'8px',
            },
        },
        promotedList: {
            width: '26px',
            height: '26px',
            background: '#FBEBED',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent:'center',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#FE385C',
            position:'absolute',
            left: 16,
            top: 16,
        },
        // * end *
    }),
    {index: 1}
);
