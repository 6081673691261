import React from "react";
import { homeFragmentCoreStyle } from "./styles";

export const YourFreeListing = ({ icon, title, text }) => {
  const classes = homeFragmentCoreStyle();

  return (
    <div className={classes.yourFreeListingStyle}>
      <div className={classes.subtitleStyle}>
        <div className={classes.iconStyle}>
          <img src={icon} alt="" />
        </div>
        <h4 className={classes.titleStyle}> {title} </h4>
      </div>
      <p className={classes.textStyle}> {text} </p>
    </div>
  );
};
