import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Loader, MaskInput, MyProfileWrapper, UserActionButton, UserInput} from 'components';
import {userProfileStyle} from './styles';
import {httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, userActions} from 'store';
import {EmailValidator, FindError, FindLoad, FindSuccess, getPhoneErrorText} from "utils";
import {AvatarContext} from "../../../utils/contexts/avatarContext";

export const UserProfile = () => {
    const dispatch = useDispatch();
    const isLoading = FindLoad('GET_USER_BY_ID');
    const editLoad = FindLoad('EDIT_USER');
    const userBackError = FindError('EDIT_USER');
    const userSuccess = FindSuccess('EDIT_USER');
    const avatarCtx = useContext(AvatarContext);
    const {avatar} = avatarCtx;
    const user = useSelector((state) => state.users.userById);
    const classes = userProfileStyle();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const [inputs, setInputs] = useState({});
    const [show, setShow] = useState(true)
    const [error, setError] = useState('');
    const [loaderUpload, setLoaderUpload] = useState(false);
    const errorMsg = 'This field must be not empty!';
    const phoneErrorMsg = getPhoneErrorText(inputs.phone);
    const emailErrorMsg = !EmailValidator.test(inputs.email) ? 'Email must be an email!' : '';
    const phoneErrorText = error === 'phone' ? errorMsg :
        userBackError.length && userBackError[0].error[0] === 'phone must be a valid phone number' ? 'Phone must be a valid phone number' : '';
    const emailErrorText = error === 'email' ? errorMsg :
        userBackError.length && userBackError[0]?.error === 'User already exists' ? 'User already exists' : '';

    useEffect(() => {
        dispatch(userActions.getUserById(userInfo.id));
    }, []);

    useEffect(() => {
        if (userSuccess?.length) {
            setShow(true)
            dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_USER'))
        }
    }, [userSuccess])

    useEffect(() => {
        if (user) {
            setInputs(user)
        }
    }, [user])

    const handleChange = (evt) => {
        setInputs((prevState) => ({...prevState, [evt.target.name]: evt.target.value,}));
        if (error === evt.target.name || error === phoneErrorMsg || error === emailErrorMsg || userBackError.length) {
            setError('');
        }
        if (userBackError.length) {
            dispatch(httpRequestsOnErrorsActions.removeError(userBackError.type));
        }
    };

    const handeSetShow = () => {
        setShow(true)
        setInputs(user)
    }

    const handleSave = () => {
        const phoneIsValid = !!inputs.phone && inputs.phone.trim().length >= 11 && !/[a-zA-Z]/g.test(inputs.phone);
        const emailIsValid = !!inputs.email && EmailValidator.test(inputs.email);
        const phone = inputs?.phone ? `${parseInt(inputs?.phone.replace(/[^0-9]/g, ''))}` : '';

        const userData = {
            firstName: inputs.firstName,
            lastName: inputs.lastName,
            phone: phone,
            email: inputs.email,
            avatar: avatar,
        };

        if (phone && emailIsValid && !!inputs.firstName && !!inputs.lastName) {
            // if (!!avatar && !avatar.url) {
            //     setLoaderUpload(true);
            //
            //     handleFileUpload(avatar).then((response) => {
            //         setLoaderUpload(false);
            //         const uploadedAvatar = response.data;
            //         const userDataWithAvatar = {
            //             ...userData,
            //             avatar: uploadedAvatar,
            //         };
            //
            //         dispatch(userActions.editUser(user?.id, userDataWithAvatar));
            //     });
            // } else {
            dispatch(userActions.editUser(user?.id, userData));
            // }
        } else {
            setError(!inputs.phone ? 'phone' :
                !phone ? phoneErrorMsg :
                    !inputs.email ? 'email' :
                        !emailIsValid ? emailErrorMsg :
                            !inputs.firstName ? 'firstName' :
                                !inputs.lastName ? 'lastName' : 'Input is not field');
        }
    }

    return isLoading?.length ? (
        <Loader/>
    ) : (
        <MyProfileWrapper user={user}>
            <div className={classes.myProfileStyle}>
                {show &&
                    <UserActionButton
                        butnClassName={classes.editUserButnStyle}
                        onUserAction={() => setShow(!show)}
                        butnLabel={'Edit'}
                    />
                }
                <div className={classes.userProfileInputsStyle}>
                    <div className={classes.inputsWrapper}>
                        <div className={classes.sectionContent}>
                            <UserInput
                                disabled={show}
                                inputClassName={'edit'}
                                inputLabel={'First Name'}
                                inputType={'text'}
                                inputName={'firstName'}
                                inputValue={inputs.firstName}
                                onInputChange={handleChange}
                                inputError={error === 'firstName' && errorMsg}
                            />
                            <UserInput
                                disabled={show}
                                inputClassName={'edit'}
                                inputLabel={'Last Name'}
                                inputType={'text'}
                                inputName={'lastName'}
                                inputValue={inputs.lastName}
                                onInputChange={handleChange}
                                inputError={error === 'lastName' && errorMsg}
                            />
                        </div>
                        <div>
                            <div style={{marginBottom: '24px', marginTop:'-4px'}}>
                                <MaskInput
                                    disables={show}
                                    name={'phone'}
                                    handleChange={handleChange}
                                    value={inputs?.phone}
                                    error={phoneErrorText}
                                    notRequired={true}
                                />
                            </div>

                            {/*<UserInput*/}
                            {/*    disabled={show}*/}
                            {/*    inputClassName={'edit'}*/}
                            {/*    inputLabel={'Phone Number'}*/}
                            {/*    inputType={'number'}*/}
                            {/*    inputName={'phone'}*/}
                            {/*    inputValue={inputs.phone}*/}
                            {/*    onInputChange={handleChange}*/}
                            {/*    inputError={phoneErrorText}*/}
                            {/*/>*/}
                            <UserInput
                                disabled={show}
                                inputClassName={'edit'}
                                inputLabel={'Email Address'}
                                inputType={'text'}
                                inputName={'email'}
                                inputValue={inputs.email}
                                onInputChange={handleChange}
                                inputError={emailErrorText}
                            />
                        </div>
                    </div>
                    {!show &&
                        <div className={classes.buttonsWrapper}>
                            <button
                                onClick={handeSetShow}
                                className={classes.cancelBtn}
                            >Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                className={classes.saveBtn}
                            >
                                {editLoad?.length ?
                                    <Loader type={'mini'} color={'white'} position={'relative'}/>
                                    :
                                    'Save'
                                }
                            </button>
                        </div>
                    }
                </div>
            </div>
        </MyProfileWrapper>
    );
};
