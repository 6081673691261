import React from "react";
import { CheckBoxInput } from "components";
import { modalContentsStyle } from "./styles";

export const FilteringCheckboxInModal = ({ dropdownOptions = [], onPass }) => {
  const classes = modalContentsStyle();
  return (
    <div className={classes.filteringInModalStyle}>
      {dropdownOptions.map((option, index) => {
        return (
          <CheckBoxInput
            key={index}
            inputId={index}
            inputChecked={option.isChecked}
            onInputChange={(evt) => {
              onPass({
                ...option,
                isChecked: evt.target.checked,
              });
            }}
            inputLabel={option.label}
          />
        );
      })}
    </div>
  );
};
