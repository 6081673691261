import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const pageNotFoundStyle = makeStyles(
  () => ({
    notFoundBoxStyle: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    notFoundBoxContainerStyle: {
      maxWidth: "1440px",
      width: "100%",
      height: "100%",
      padding: "274px 80px",
      "@media(max-width: 1200px)": { padding: "278px 70px" },
      "@media(max-width: 768px)": { padding: "186px 37px" },
      "@media(max-width: 400px)": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    notFoundInfoBoxStyle: {
      textAlign: "center",
      "& img": {
        "@media(max-width: 600px)": {
          width: "250px",
          height: "150px",
        },
      },
    },
    notFoundTextStyle: {
      fontSize: "32px",
      fontWeight: 800,
      color: Colors.TextBlack,
      margin: "48px 0 24px",
      lineHeight: "30px",
      "@media(max-width: 600px)": {
        fontSize: "24px",
        margin: "32px 0 16px",
      },
    },
    homeLinkStyle: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#3377CC",
      textDecoration: "none",
      transition: "text-decoration 0.2s linear",
      "&:hover": { textDecoration: "underline" },
    },
  }),
  { index: 1 }
);
