import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {BrokerInfoFragment} from "fragments";
import {Loader} from "components";
import {FindLoad} from "utils";
import {brokerActions} from "store";
import {Helmet} from "react-helmet";

export const BrokerInfo = ({}) => {
    const loader = FindLoad('GET_BROKER_BY_ID')
    const param = useParams()
    const dispatch = useDispatch()
    const brokerById = useSelector((state) => state.broker.brokerById);

    useEffect(() => {
        if (param?.id) {
            dispatch(brokerActions.getBrokerById(param?.id))
        }
    }, [])

    if (loader?.length) {
        return <div style={{minHeight: '100vh'}}><Loader/></div>
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title> {`${brokerById?.firstName ? brokerById?.firstName : ''} ${brokerById?.lastName ? brokerById?.lastName : ''} | Croill`}</title>
                <meta name="description"
                      content={`${brokerById?.firstName ? brokerById?.firstName : ''} ${brokerById?.lastName ? brokerById?.lastName : ''}. Check out 'Current Broker Name's professional profile and available commercial real estate listings.`}
                />
            </Helmet>
            <BrokerInfoFragment
                info={brokerById}
            />
        </div>
    )
}