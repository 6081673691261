import React, { useState } from 'react';
import { getValDependsOnLength, Images, isNotEmpty } from 'utils';
import { dropdownStyle } from './style';
import { FromToInput } from 'components';

export const FilteringFromToDropdown = ({
    isInModal,
    passSelectedMin,
    passSelectedMax,
    selectedMin,
    selectedMax,
    dropdownTitle,
    onClearSelected,
    measureUnit,
    minInputPlaceholder,
    maxInputPlaceholder,
}) => {
    const [dropdownIsShown, setDropdownIsShown] = useState(false);
    const classes = dropdownStyle();

    const minValueIsNotEmpty = isNotEmpty(selectedMin);
    const maxValueIsNotEmpty = isNotEmpty(selectedMax);
    const selectedMinValue = getValDependsOnLength(selectedMin);
    const selectedMaxValue = getValDependsOnLength(selectedMax);

    const selectedValueWithUnit =
        minValueIsNotEmpty && maxValueIsNotEmpty
            ? measureUnit === '$'
                ? `$${selectedMinValue} - ${selectedMaxValue}`
                : `${selectedMinValue} - ${selectedMaxValue} SF`
            : minValueIsNotEmpty
            ? measureUnit === '$'
                ? `Over $${selectedMinValue}`
                : `Over ${selectedMinValue} SF`
            : maxValueIsNotEmpty
            ? measureUnit === '$'
                ? `Under $${selectedMaxValue}`
                : `Under ${selectedMaxValue} SF`
            : dropdownTitle;

    const selectFieldIsNotEmpty = (minValueIsNotEmpty && maxValueIsNotEmpty) || minValueIsNotEmpty || maxValueIsNotEmpty;

    return (
        <>
            {dropdownIsShown && <div className={classes.dropdownOverlayStyle} onClick={() => setDropdownIsShown(false)} />}
            <div className={`${classes.dropdownBoxStyle} ${isInModal ? '' : 'fromToDropdown'}`}>
                <div
                    className={`${classes.showDropdownBoxStyle} ${selectedMin || selectedMax ? 'selected' : ''}`}
                    onClick={() => setDropdownIsShown((prevState) => !prevState)}>
                    <h6 className={classes.dropdownSelectedStyle}> {selectedValueWithUnit} </h6>
                    {selectFieldIsNotEmpty ? (
                        <button
                            type="button"
                            className={`${classes.clearSelectedButnStyle} dropClear`}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                onClearSelected();
                                setDropdownIsShown(false);
                            }}>
                            <img src={Images.clear} alt="clear" />
                        </button>
                    ) : dropdownIsShown ? (
                        <img src={Images.dropdownArrowBlack} alt="dropdown arrow black" className={'dropArrow'} />
                    ) : (
                        <img src={Images.dropdownArrow} alt="dropdown arrow" className={'dropArrow'} />
                    )}
                </div>
                {dropdownIsShown && (
                    <div className={`${classes.dropdownStyle} filteringFromTo`}>
                        <FromToInput
                            inputValue={selectedMin ? selectedMin : ''}
                            onInputChange={(evt) => passSelectedMin(evt.target.value)}
                            inputPlaceholder={minInputPlaceholder}
                        />
                        <span> to </span>
                        <FromToInput
                            inputValue={selectedMax ? selectedMax : ''}
                            onInputChange={(evt) => passSelectedMax(evt.target.value)}
                            inputPlaceholder={maxInputPlaceholder}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
