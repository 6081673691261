import { makeStyles } from '@material-ui/core/styles';

export const loaderStyle = makeStyles(
    () => ({
        loaderBoxStyle: {
            width: '100%',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
    { index: 1 }
);
