import {promotedStyles} from "./styles";
import {Images, TextRow} from "utils";
import moment from "moment";

export const PromotedCardMobile = ({item, key, handleRepromote}) => {
    const classes = promotedStyles()

    return (
        <div key={key} className={classes.promotedCardMobileWrapper}>
            <div className={classes.spaceBetween}>
                <div className={classes.alignItem}>
                    <img className={classes.promotedCardIcon} src={ item?.mainImage?.url ? item?.mainImage?.url : Images.building} alt="icon"/>
                    <p className={classes.promotedCardIconTitle}>
                        {item?.address?.formattedAddress}
                        {/*<TextRow name={item?.address?.formattedAddress}/>*/}
                    </p>
                </div>

            </div>

            <div className={classes.alignItem} style={{height: '36px', marginTop:'16px'}}>
                <img src={Images.timerStart} alt="icon"/>
                <p className={classes.promotedCardIconTitle}><span
                    className={classes.boldTitle}>Start Date: </span>{moment(item?.promotion?.startDate).format('MM.DD.YYYY')}</p>
            </div>
            <div className={classes.alignItem} style={{height: '36px'}}>
                <img src={Images.timerPause} alt="icon"/>
                <p className={classes.promotedCardIconTitle}><span
                    className={classes.boldTitle}>End Date: </span>{moment(item?.promotion?.endDate).format('MM.DD.YYYY')}</p>
            </div>
            <div className={classes.alignItem} style={{height: '36px'}}>
                <img src={Images.statusUp} alt="icon"/>
                <p className={classes.promotedCardIconTitle}><span
                    className={classes.boldTitle}>Impressions: </span> {item?.promotion?.views ? item?.promotion?.views  : 0}</p>
            </div>
            <div className={classes.alignItem} style={{height: '36px'}}>
                <img src={Images.dollarSquare} alt="icon"/>
                <p className={classes.promotedCardIconTitle}><span
                    className={classes.boldTitle}>Price:</span> $15.00</p>
            </div>


            <div>
                <button onClick={handleRepromote} className={classes.repromoteMobile}>Repromote</button>
            </div>
        </div>
    )
}