import {promotedStyles} from "./styles";
import {Images, TextRow} from "utils";
import moment from "moment";

export const PromotedCard = ({key, item, handleRepromote}) => {
    const classes = promotedStyles()

    return (
        <div key={key} className={classes.promotedCard}>
            <div className={classes.alignItem} style={{width: '40%'}}>
                <img className={classes.promotedCardIcon}
                     src={item?.mainImage?.url ? item?.mainImage?.url : Images.building}
                     alt="icon"/>
                <p className={classes.promotedCardIconTitle}>
                    <TextRow name={item?.address?.formattedAddress}/>
                </p>
            </div>
            <div className={classes.alignItem} style={{width: '12%'}}>
                <img src={Images.timerStart} alt="icon"/>
                <p className={classes.promotedCardIconTitle}>
                    {moment(item?.promotion?.startDate).format('MM.DD.YYYY')}
                </p>
            </div>
            <div className={classes.alignItem} style={{width: '12%'}}>
                <img src={Images.timerPause} alt="icon"/>
                <p className={classes.promotedCardIconTitle}>
                    {moment(item?.promotion?.endDate).format('MM.DD.YYYY')}
                </p>
            </div>
            <div className={classes.alignItem} style={{width: '12%'}}>
                <img src={Images.statusUp} alt="icon"/>
                <p className={classes.promotedCardIconTitle}> {item?.promotion?.views ? item?.promotion?.views  : 0}</p>
            </div>
            <div style={{width: '12%'}}>
                <p style={{marginLeft: 0}} className={classes.promotedCardIconTitle}>$15.00</p>
            </div>
            <div style={{width: '12%'}}>
                <button
                    onClick={handleRepromote}
                    className={classes.repromote}>Repromote
                </button>
            </div>
        </div>
    )
}