import React from 'react';
import {ListingInputsWrapper, UserInput, UserInputsDropdown} from 'components';
import {commonStyle} from './styles';

export const GeneralInfo = ({
                                handleSelPropTypePass,
                                selPropType,
                                error,
                                dropdownErrorMsg,
                                info,
                                inputs,
                                handleChange,
                                errorMsg,
                                backMessage,
                            }) => {
    const classes = commonStyle();

    return (
        <ListingInputsWrapper inputsTitle={'General Info'}>
            <div className={classes.propTypeBoxStyle}>
                <div className={`${classes.propertyTypeStyle} ${selPropType === 'Other' ? 'active' : ''}`}>
                    <p className={classes.requiredLabelStyle}> Property Type </p>
                    <UserInputsDropdown
                        dropdownOptions={['Retail', 'Office', 'Industrial', 'Other']}
                        onPass={(selected) => handleSelPropTypePass(selected)}
                        selected={selPropType}
                        dropdownTitle={'Select Type'}
                        dropdownError={error === 'propertyType'}
                    />
                    <p className={classes.errorStyle}>{error === 'propertyType' && dropdownErrorMsg}</p>
                </div>
                {selPropType === 'Other' && (
                    <UserInput
                        inputLabel={'Enter type'}
                        inputClassName={classes.listingInputStyle}
                        inputType={'text'}
                        inputName={'propertyTypeDescription'}
                        inputPlaceholder={'Enter property type'}
                        inputValue={inputs.propertyTypeDescription}
                        onInputChange={handleChange}
                        inputError={error === 'propertyTypeDescription' && errorMsg}
                        charCounterIsShown={!!inputs.propertyTypeDescription}
                    />
                )}
            </div>
            <UserInput
                inputClassName={classes.listingInputStyle}
                inputLabel={'Address'}
                inputType={'text'}
                required={true}
                inputName={'address'}
                inputValue={inputs.address}
                onInputChange={handleChange}
                inputError={error === 'address' ? errorMsg  : backMessage ? 'Unable to verify the address' : ''}
            />
            <div className={classes.sizeRangeStyle}>
                <UserInput
                    inputClassName={classes.listingInputStyle}
                    inputLabel={'Size Range'}
                    inputType={'number'}
                    inputName={'start'}
                    inputValue={inputs.start}
                    onInputChange={handleChange}
                    inputPlaceholder={'From'}
                />
                <UserInput
                    inputClassName={classes.listingInputStyle}
                    inputType={'number'}
                    inputName={'end'}
                    inputValue={inputs.end}
                    onInputChange={handleChange}
                    inputPlaceholder={'To'}
                />
            </div>
            <UserInput
                inputClassName={classes.listingInputStyle}
                inputLabel={'Price (sq. ft.)'}
                inputType={'number'}
                inputName={'price'}
                inputValue={inputs.price}
                onInputChange={handleChange}
            />
        </ListingInputsWrapper>
    );
};
