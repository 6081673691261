import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {paymentActions, listingActions, httpRequestsOnSuccessActions} from "store";
import {globalStyles, Loader, SimpleModal} from "components";
import {FindLoad, FindSuccess, Images, NON_PROMOTED, PROMOTED} from "utils";
import {promotionStyle} from "./styles";
import {NonPromoted, Promoted} from "../../fragments/promotions";
import {RenewPromotion, PaymentMethod, DoneModal, NoList} from "./core";

const activeButtonStyle = {
    background: '#FE385C',
    color: '#FFFFFF',
    fontWeight: 600,
}

export const Promotion = () => {
    const dispatch = useDispatch()
    const classes = promotionStyle()
    const globalClasses = globalStyles()
    const loader = FindLoad('GET_LISTINGS_BY_USER_ID_FILTER')
    const success = FindSuccess('PROMOTE_LISTING')
    const [activeTab, setActiveTab] = useState('nonPromoted')
    const [open, setOpen] = useState('')
    const [selectedItem, setSelectedItem] = useState('')
    const [selectedId, setSelectedId] = useState('')
    const payment = useSelector((state) => state.payment.myPayment);
    const userListings = useSelector((state) => state.listings.listingsByUserId);
    const filteredListNon = userListings?.length ? userListings.filter((i) => i?.promotion?.status === NON_PROMOTED) : []
    const filteredListPromoted = userListings?.length ? userListings.filter((i) => i?.promotion?.status === PROMOTED) : []

    useEffect(() => {
        if (success?.length) {
            setOpen('')
            setSelectedItem('')
            dispatch(httpRequestsOnSuccessActions.removeSuccess('PROMOTE_LISTING'))
        }
    }, [success])

    useEffect(() => {
        dispatch(paymentActions.getPayment())
        handleGetListing(NON_PROMOTED)
    }, [])

    const handleGetListing = (type, load) => {
        const filters = {
            promotionStatus: type,
        }
        const loader = load ? 'noLoad' : ''
        dispatch(listingActions.getListingsByUserIdFilter(filters, loader));
    }

    const handlePay = () => {
        const reqType = activeTab === 'nonPromoted' ? NON_PROMOTED : PROMOTED
        if(selectedItem){ setOpen('RenewPromotion')}
        dispatch(listingActions.promote(selectedId, {promote: true}, reqType))
    }

    const handlePromote = (id) => {
        setOpen('PaymentMethod')
        setSelectedId(id)
        setSelectedItem('')
    }

    const handleRePromote = (id, item) => {
        const selectedType = payment?.type ? 'RenewPromotion' : 'PaymentMethod'
        setOpen(selectedType)
        setSelectedId(id)
        setSelectedItem(item)
    }

    const handleSetActiveTab = (type) => {
        setActiveTab(type)
        const reqType = type === 'nonPromoted' ? NON_PROMOTED : PROMOTED
        handleGetListing(reqType)
    }

    return (
        <div className={globalClasses.promotionWrapperBackground}>
            <div className={globalClasses.promotionWrapper}>
                <p className={globalClasses.title}>Promotions</p>
                <p className={globalClasses.subTitle}>You can promote a listing for 4 weeks. Price $15.00</p>
                <div className={classes.tabButtonsWrapper}>
                    <button
                        onClick={() => handleSetActiveTab('nonPromoted')}
                        style={activeTab === 'nonPromoted' ? {...activeButtonStyle} : {}}
                    >
                        Non Promoted
                    </button>
                    <button
                        onClick={() => handleSetActiveTab('promoted')}
                        style={activeTab === 'promoted' ? {...activeButtonStyle} : {}}
                    >
                        Promoted
                    </button>
                </div>
                {loader?.length ?
                    <Loader/>
                    :
                    <>
                        {
                            activeTab === 'nonPromoted' ?
                                <>
                                    {filteredListNon?.length ?
                                        <NonPromoted
                                            handlePromote={handlePromote}
                                            payment={payment}
                                            userListings={filteredListNon}
                                        />
                                        :
                                        <NoList listType={activeTab}/>
                                    }
                                </>
                                :
                                <>
                                    {filteredListPromoted?.length ?
                                        <Promoted
                                            handleRepromote={(id, item) => handleRePromote(id, item)}
                                            userListings={filteredListPromoted}
                                        />
                                        :
                                        <NoList listType={activeTab}/>
                                    }
                                </>
                        }

                    </>
                }
            </div>
            <SimpleModal
                openDefault={!!open}
                handleOpenClose={() => setOpen('')}
                content={
                    <div
                        style={open === 'success' ? {maxWidth: '300px'} : {}}
                        className={classes.paymentModal}>
                        <div className={classes.flexEnd}>
                            <button onClick={() => setOpen('')}>
                                <img src={Images.close} alt=""/>
                            </button>
                        </div>
                        {open === 'RenewPromotion' ?
                            <RenewPromotion
                                payment={payment}
                                handleClose={() => setOpen('')}
                                handlePay={handlePay}
                                item={selectedItem}
                            />
                            :
                            open === 'PaymentMethod' ?
                                <PaymentMethod
                                    selectedId={selectedId}
                                    payment={payment}
                                    handleClose={() => setOpen(null)}
                                    handleOpenSuccess={() => setOpen('success')}
                                    handlePay={handlePay}
                                    selectedItem={selectedItem}
                                />
                                :
                                open === 'success' ?
                                    <DoneModal
                                        handleClose={() => setOpen('')}
                                    />
                                    :
                                    ''
                        }
                    </div>
                }
            />
        </div>
    )
}