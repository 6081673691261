import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const screensStyle = makeStyles(
  () => ({
    // ** toastr style **
    toastStyle: {

      "& .Toastify .Toastify__toast": {
        width: "auto",
        padding: "19px 16px",
      },
    },
    toastWrapper: {
      display: "flex",
      "& p": {
        fontSize: "16px",
        fontWeight: 500,
        color: Colors.TextBlack,
        lineHeight: "20px",
      },
    },
    toastIconStyle: {
      marginRight: "16px",
      width: "28px",
      height: "28px",
      "& img": { width: "100%" },
    },
    // ** end **
  }),
  { index: 1 }
);
