import {
    CREATE_BROKER,
    DELETE_BROKER,
    EDIT_BROKER,
    GET_BROKER_BY_ID,
    GET_BROKER_MY_PROFILE,
    GET_BROKERS
} from "./brokers.types";


export const createBroker = (body) => {
    return {
        type: CREATE_BROKER,
        payload: {body},
    };
};

export const getBrokers = () => {
    return {
        type: GET_BROKERS,
    };
};

export const editBroker = (id, body) => {
    return {
        type: EDIT_BROKER,
        payload: {id, body},
    };
};

export const deleteBroker = (id) => {
    return {
        type: DELETE_BROKER,
        payload: {id},
    };
};

export const getBrokerById = (id) => {
    return {
        type: GET_BROKER_BY_ID,
        payload: {id},
    };
};

export const getBrokerProfile= () => {
    return {
        type: GET_BROKER_MY_PROFILE,
    };
};
