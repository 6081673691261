import {Images} from "utils";
import {promotionStyle} from "../styles";


export const NoList = ({listType}) => {
    const classes = promotionStyle()
    const title = listType === 'nonPromoted' ? 'No listings yet' : 'No promoted listings yet'
    const subtitle = listType === 'nonPromoted' ? 'This page is waiting for you to fill it with your promoted listings.'
        :
        'This page is waiting for you to fill it with your listing items'

    return (
        <div className={classes.noListWrapper}>
            <div className={classes.noList}>
                <img src={Images.noPromoted} alt={'icon'}/>
                <p className={classes.noListTitle}>{title}</p>
                <p className={classes.noListSubTitle}>{subtitle}</p>
            </div>
        </div>
    )
}