import {makeStyles} from '@material-ui/core/styles';
import {Images, Colors} from 'utils';

export const inputStyle = makeStyles(
    () => ({
        // ** user input style **
        userInputBoxStyle: {
            position: 'relative',
            width: '100%',
            marginBottom: '8px',
            '&.inModal': {marginBottom: '16px'},
            '&.edit': {marginBottom: '24px'},
            '&.hide': {display: 'none'},
        },
        userInputLabelStyle: {
            '& > p': {
                marginBottom: '4px',
                fontSize: '16px',
                fontWeight: 700,
                color: Colors.TextBlack,
                display: 'flex',
                '&.required::before': {
                    content: "'*'",
                    color: '#FF385C',
                },
            },
        },
        userInputStyle: {
            outline: 'none',
            width: '400px',
            padding: '10px 16px',
            borderRadius: '8px',
            backgroundColor: Colors.BackgroundWhiteOff,
            border: '1px solid transparent',
            '&.listing': {width: '100%'},
            '&:focus': {
                backgroundColor: Colors.BackgroundWhite,
                border: '1px solid #0073BB',
            },
            '&.error': {
                backgroundColor: Colors.BackgroundWhite,
                border: '1px solid #FF385C',
            },
            '@media(max-width: 600px)': {width: '100%'},
            '@media(max-width: 500px)': {width: '295px'},
            '@media(max-width: 350px)': {width: '265px'},
        },
        eyeIconStyle: {
            position: 'absolute',
            top: '32px',
            right: '16px',
            zIndex: 999,
            display: 'block',
            width: '18px',
            height: '18px',
            backgroundImage: `url(${Images.eyeOffIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            cursor: 'pointer',
            '&.show': {
                backgroundImage: `url(${Images.eyeOnIcon})`,
            },
        },
        userInputErrorBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '4px 16px 0',
            minHeight: '17px',
            '& > p': {
                fontSize: '12px',
                fontWeight: 500,
            },
        },
        userInputErrorStyle: {color: '#FF385C'},
        charCounterStyle: {color: '#2B273C'},
        // ** end **

        // ** from-to input style **
        fromToInputStyle: {
            outline: 'none',
            padding: '8px',
            backgroundColor: Colors.BackgroundWhite,
            border: '1px solid #75728080',
            borderRadius: '8px',
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextGray,
        },
        // * end **

        // ** check-box input style **
        checkBoxLabelStyle: {
            display: 'flex',
            alignItems: 'center',
            transition: 'all 0.1s linear',
            cursor: 'default',
            padding: '10px 16px',
            '& input': {display: 'none'},
            '& input:checked + $inputCheckBoxStyle': {
                backgroundImage: `url(${Images.checkedCheckmark})`,
            },
            '&.homPageLabel input:checked ~ $inputLabelStyle': {
                color: Colors.ThemeBlue,
            },
            '&:hover': {backgroundColor: Colors.BackgroundWhiteOff},
        },
        inputCheckBoxStyle: {
            transition: 'background-image 0.2s linear',
            width: '18px',
            height: '18px',
            backgroundImage: `url(${Images.uncheckedCheckmark})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            marginRight: '8px',
        },
        inputLabelStyle: {
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextGray,
            '&.selectedTitle': {
                color: '#3377CC',
            },
        },
        // ** end **


        inputTextField: {
            width: '100%',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                // borderColor: '#F4F4F4',
                borderRadius: '8px',
            },
            '& .MuiOutlinedInput-root': {
                height: '40px',
                border: 'none',
                borderRadius: '8px',
                background: '#F4F4F4'
            },
            '& .MuiInputLabel-outlined': {
                marginTop: '-3px'
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.75)'
            },
            '& .MuiSelect-select.MuiSelect-select': {
                background: 'none',
                border: 'none',
            },
        },

        selectTitle: {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
            marginBottom: '4px',
        },
        selectWrapper: {
            width: '100%',
            height: '40px',
            background: '#F4F4F4',
            borderRadius: '8px',
            padding: '0 12px',
            border: '1px solid #F4F4F4',
        },
        select: {
            background: 'transparent',
            outline: 'none',
            border: 'none',
            height: '40px',
            width: '100%',
        },
        errorText: {
            fontWeight: '500',
            fontSize: '12px',
            color: '#FF385C',
            padding: '4px 16px 0',
            minHeight: '17px',
        },
        titleClass: {
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
            '& span': {
                color: '#FE385C',
            },
        },
        simpleInput: {},
        errorMessage: {
            fontSize: '12px',
            fontWeight: '500',
            color: '#FF385C',
            padding: '4px 16px 0',
            minHeight: '17px',
        }
    }),
    {index: 1}
);
