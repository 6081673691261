import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {FindLoad, FindSuccess} from "utils";
import {httpRequestsOnSuccessActions, paymentActions} from "store";
import {billingStyles} from "./styles";
import {CreateCancelButtons} from "components";

const ACTION_TYPE = 'DELETE_PAYMENT'
export const DeleteCard = ({onClose}) => {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const classes = billingStyles()
    const dispatch = useDispatch()
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)

    const handleDelete = () => {
        dispatch(paymentActions.deletePayment(user?.id))
    }

    useEffect(() => {
        if (success?.length) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }
    }, [success])

    return (
        <div>
            <p className={classes.deleteTitle}>Delete Card?</p>
            <p className={classes.deleteSubTitle}>Deleting your card you can’t promoted your listing item. You must add
                new card.</p>
            <CreateCancelButtons
                cancelText={'Cancel'}
                createText={'Delete'}
                handleCancel={onClose}
                handleCreate={handleDelete}
                loader={loader}
            />
        </div>
    )
}