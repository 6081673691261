import React, { useState } from "react";
import { Images } from "utils";
import { dropdownStyle } from "./style";

export const FilteringOptionsDropdown = ({
  dropdownOptions = [],
  isInModal,
  onPass,
  selected,
  defaultSelected = {},
  onClearSelected,
}) => {
  const [dropdownIsShown, setDropdownIsShown] = useState(false);

  const classes = dropdownStyle();

  const chooseOptionHandler = (option) => {
    onPass(option);
    setDropdownIsShown(false);
  };

  return (
    <>
      {dropdownIsShown && (
        <div
          className={`${classes.dropdownOverlayStyle} ${
            isInModal ? "overlayInModal" : ""
          }`}
          onClick={() => setDropdownIsShown(false)}
        />
      )}
      <div
        className={`${classes.dropdownBoxStyle} ${
          isInModal ? "boxInModal" : "newestInModal"
        }`}
      >
        <div
          className={`${classes.showDropdownBoxStyle} ${
            selected ? "selected" : ""
          } ${isInModal ? "showBoxInModal" : ""}`}
          onClick={() => setDropdownIsShown((prevState) => !prevState)}
        >
          {selected ? (
            <div className={classes.dropTitleBoxClassName}>
              <h6 className={classes.dropdownSelectedStyle}>
                {selected.label}
              </h6>
              {selected.id !== "0" && selected.id !== "1" && (
                <div className={classes.dropdownOptionIconClassName}>
                  <img src={selected.icon} alt="down up arrows" />
                </div>
              )}
            </div>
          ) : (
            <h6 className={classes.dropdownSelectedStyle}>
              {defaultSelected.label}
            </h6>
          )}
          {selected ? (
            <button
              type="button"
              onClick={(evt) => {
                evt.stopPropagation();
                onClearSelected();
                setDropdownIsShown(false);
              }}
              className={`${classes.clearSelectedButnStyle} dropClear`}
            >
              <img src={Images.clear} alt="clear" />
            </button>
          ) : dropdownIsShown ? (
            <img
              src={Images.dropdownArrowBlack}
              alt="dropdown arrow black"
              className={"dropArrow"}
            />
          ) : (
            <img
              src={Images.dropdownArrow}
              alt="dropdown arrow"
              className={"dropArrow"}
            />
          )}
        </div>
        {dropdownIsShown && (
          <div
            className={`${classes.dropdownStyle} ${
              isInModal ? "dropdownInModal" : ""
            }`}
          >
            {dropdownOptions.map((option, index) => {
              return (
                <div
                  key={index}
                  className={classes.dropdownOptionsStyle}
                  onClick={() => chooseOptionHandler(option)}
                >
                  <span
                    className={`${classes.dropdownOptionTitleStyle} ${
                      option.id === selected.id && "selectedTitle"
                    }`}
                  >
                    {option.label}
                  </span>
                  {index >= 2 && (
                    <div className={classes.dropdownOptionIconClassName}>
                      {option.id === selected.id ? (
                        <img
                          src={option.iconSelected}
                          alt="down up arrows selected"
                        />
                      ) : (
                        <img src={option.icon} alt="down up arrows" />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
