import axios from "axios";

export const authService = {
  logInService: (body) => axios.post("/authn/signin", body),

  logOutService: () => axios.post("/authn/logout", {}, { auth: true }),

  getLinkService: (email) => axios.get(`/authn/forgotPassword/${email}`),

  getMyProfileByTokenService: (about ) => axios.get( about === 'BROKER' ? '/brokers/myProfile' : `/user/myProfile/byToken`, { auth: true }),

  resetPassService: (passwords) => {
    let endpoint = `/authn/resetPassword`;
    const res = axios.post(
      endpoint,
      {
        newPassword: passwords.newPassword,
        confirmation: passwords.confirmation,
      },
      { headers: { "reset-token": passwords.token } }
    );
    return res;
  },

  changePasswordService: (passwords) => axios.post("/authn/changePassword", passwords, { auth: true }),

    /** Contact Form */

    sendMessageService: (info) => axios.post('/mailer/contactForm', { ...info }, { auth: false }),

    /** End */

};
