import {BrokersFragmentStyles} from "./core/styles";
import {WhiteSpace} from "./core/whiteSpace";
import {Images} from "utils";

export const BrokerInfoFragment = ({info}) => {
    const classes = BrokersFragmentStyles()
    return (
        <div className={classes.grayBack} style={{background: '#F4F4F4'}}>
            <div className={classes.brokerInfoFragmentWrapper}>

                <div style={{margin: '49px 0 38px 0'}} className={classes.breadcrumb}>
                    <a href={'/'}>Homepage &nbsp;/ </a>
                    <a href={'/brokers'}> &nbsp;Broker  &nbsp;/ </a>
                    <p>&nbsp;Broker Details</p>
                </div>

                <div className={classes.whiteSpaces}>
                    <WhiteSpace>
                        <div className={classes.brokerInfoImage}>
                            <div className={classes.imageAndInfo}>
                                <img
                                    className={classes.brokerAvatar}
                                    src={info?.avatar?.url ? info?.avatar?.url : Images.userProfile} alt="icons"
                                />
                                <div className={classes.nameInfo}>
                                    <p className={classes.name}>{`${info?.firstName} ${info?.lastName}`}</p>
                                    <p className={classes.license}>{info?.licenseNumber}</p>
                                    <p className={classes.company}>Company: <span
                                        style={{fontWeight: '400'}}>{info?.company}</span>
                                    </p>
                                </div>
                            </div>
                            <div className={classes.contactMeWrapper}>
                                <a href={`tel:${info?.phone}`}>
                                    <button className={classes.contactMe}>Contact me</button>
                                </a>
                            </div>
                        </div>
                    </WhiteSpace>

                    <WhiteSpace>
                        <p className={classes.cardTitle}>Contacts Info</p>
                        <div className={classes.contactItem}>
                            <img src={Images.callCallingBlack} alt="icon"/>
                            <a href={`tel:${info?.phone}`}>Phone Number:
                                <span style={{fontWeight: 400}}>
                                    {info?.phone}
                                </span>
                            </a>
                        </div>
                        <div className={classes.contactItem}>
                            <img src={Images.sms} alt="icon"/>
                            <a href={`mailto:${info?.email}`}>Email: <span
                                style={{fontWeight: 400}}>{info?.email}</span></a>
                        </div>
                        <div className={classes.contactItem}>
                            <img src={Images.location} alt="icon"/>
                            <p>County of Service: <span style={{fontWeight: 400}}>{info?.countryOfService}</span></p>
                        </div>
                        <div className={classes.contactItem}>
                            <img src={Images.medalStar} alt="icon"/>
                            <p>License Number:<span style={{fontWeight: 400}}> {info?.licenseNumber} </span></p>
                        </div>
                    </WhiteSpace>

                        <div className={classes.aboutMeWrapper}>
                            <WhiteSpace>
                                <p className={classes.cardTitle}>About</p>
                                <p className={classes.aboutBrokerInfo}>
                                    {info?.aboutMe ? info?.aboutMe : 'No Information Yet'}
                                </p>
                            </WhiteSpace>
                        </div>
                </div>
            </div>
        </div>
    )
}