/** Types */

// Log In
export const LOG_IN = "LOG_IN";

// Log out
export const LOG_OUT = "LOG_OUT";

// Get Recovery Link
export const GET_RECOVERY_LINK = "GET_RECOVERY_LINK";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";

// Change password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";

/** Contact Form */
export const SEND_MESSAGE = "SEND_MESSAGE"