import React, {useState} from "react";
import {billingStyles} from "./styles";
import {globalStyles, SimpleModal} from "components";
import {Images} from "utils";
import {DeleteCard} from "./deleteCard";
import {EditCard} from "./editCard";

export const PaymentMethod = ({payment}) => {
    const classes = billingStyles()
    const globalClasses = globalStyles()
    const [open, setOpen] = useState('')

    const handleClose = () => {
        setOpen('')
    }

    return (
        <>
            <div className={classes.whiteWrapper}>
                <p className={globalClasses.title}>Payment method</p>
                <p className={classes.changePayment}>Change how you pay your promotions.</p>

                {payment ?
                    <div className={classes.paymentCardWrapper}>
                        <div className={classes.cardInfo}>
                            <img
                                src={payment?.card === "mastercard" ? Images.master : Images.visa}
                                alt="icon"
                            />

                            <div className={classes.cardNumberYear}>
                                <p>{`**** **** **** ${payment?.last4}`}</p>
                                <p style={{marginTop: '7px'}}>{`Expiry ${payment?.exp_month}/${payment?.exp_year}`}</p>
                            </div>
                        </div>
                        <div className={classes.buttonsWrapper}>
                            <button onClick={() => setOpen('edit')} className={classes.edit}>
                                <img src={Images.edit} alt={'edit'}/>
                                <p>Edit</p>
                            </button>
                            <button onClick={() => setOpen('delete')} className={classes.delete}>
                                <img src={Images.deletee} alt={'edit'}/>
                                <p>Delete</p>
                            </button>
                        </div>
                    </div>
                    :
                    <div className={classes.whiteWrapper}>
                        <div className={classes.addCardWrapper}>
                            <button onClick={() => setOpen('edit')} className={classes.addButton}>
                                <img src={Images.add} alt='icon'/>
                                Add Card
                            </button>
                        </div>
                    </div>
                }
            </div>

            <SimpleModal
                openDefault={!!open}
                handleOpenClose={handleClose}
                content={
                    <div className={classes.paymentModal}>
                        <div className={classes.flexEnd}>
                            <button onClick={handleClose}>
                                <img src={Images.close} alt=""/>
                            </button>
                        </div>
                        {open === 'delete' ?
                            <DeleteCard
                                onClose={handleClose}
                            />
                            :
                            <EditCard/>
                        }
                    </div>
                }
            />
        </>
    )
}