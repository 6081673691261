import React, { useEffect, useState } from 'react';
import { FindLoad, Images } from 'utils';
import { listingDetailsCoreStyle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { listingActions } from 'store';
import { CloseModalButton, CustomBreadcrumbs, Loader } from 'components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { SET_LISTING_BY_ID } from 'store/listings/listings.types';

export const ListingGallery = () => {
    const classes = listingDetailsCoreStyle();

    const params = useParams();
    const _id = params.id;

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(1);

    const arrowsAreShown = window.innerWidth > 1200;

    const listingById = useSelector((state) => state.listings.listingById);
    const loader = !!FindLoad('GET_LISTING_BY_ID').length;

    useEffect(() => {
        dispatch(listingActions.getListingById(_id));
        return () => {
            dispatch({
                type: SET_LISTING_BY_ID,
                payload: { listingById: null },
            });
        };
    }, [dispatch, _id]);

    return loader ? (
        <Loader />
    ) : (
        <>
            <CustomBreadcrumbs />
            <div className={classes.listingGalleryBoxStyle}>
                <div className={classes.listingGalleryBoxContainerStyle}>
                    <div className={classes.galleryStyle}>
                        {listingById?.images.map((image, index) => (
                            <div className={classes.photoContainerStyle} key={index}>
                                <img src={image.url} alt="listing" />
                                <div
                                    className={classes.photoOverlayStyle}
                                    onClick={() => {
                                        setIsOpen(true);
                                        setSelectedPhoto(index);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    {isOpen && (
                        <div className={classes.photoModalStyle}>
                            <div className={classes.photoModalContentStyle}>
                                <CloseModalButton
                                    butnClassName={classes.closeButnStyle}
                                    onCloseModal={() => setIsOpen(false)}
                                    butnLabel={
                                        <>
                                            <img src={Images.closeWhite} alt="" />
                                            Close
                                        </>
                                    }
                                />
                                <div className={classes.carouselContainerStyle}>
                                    <p className={classes.carouselStatusStyle}>{`${selectedPhoto + 1}/${listingById?.images.length}`}</p>
                                    <Carousel
                                        className={classes.carouselStyle}
                                        showStatus={false}
                                        showIndicators={false}
                                        showThumbs={false}
                                        showArrows={arrowsAreShown}
                                        selectedItem={selectedPhoto}
                                        onChange={(index) => setSelectedPhoto(index)}>
                                        {listingById?.images.map((image, index) => (
                                            <div key={index} className={classes.photoBoxInModalStyle}>
                                                <img src={image.url} alt="" />
                                            </div>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
