import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {globalStyles, Loader} from "components";
import {BillingFragments} from "fragments";
import {paymentActions} from "store";
import {FindLoad} from "utils";

export const Billing = ({}) => {
    const globalClasses = globalStyles()
    const dispatch = useDispatch()
    const loader = FindLoad('GET_PAYMENT')

    useEffect(() => {
        dispatch(paymentActions.getPayment())
        dispatch(paymentActions.getInvoices())
    }, [])

    return (
        <div className={globalClasses.promotionWrapperBackground}>
            <div className={globalClasses.promotionWrapper}>
                <p className={globalClasses.title}>Billing</p>
                <div>
                    {loader?.length ?
                        <Loader/>
                        :
                        <BillingFragments/>
                    }
                </div>
            </div>
        </div>
    )
}