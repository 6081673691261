import React from "react";
import { buttonsStyle } from "./style";
import { CircularProgress } from "@mui/material";

export const SendButton = ({
  butnClassName,
  butnType,
  butnSendingText,
  butnDisabled,
  onClickButn,
  loader = false,
}) => {
  const classes = buttonsStyle();

  return (
    <button
      type={butnType}
      className={`${classes.sendButnStyle} ${butnClassName}`}
      disabled={butnDisabled}
      onClick={onClickButn}
    >
      {loader ? <CircularProgress size={17} color={"inherit"} /> : butnSendingText}
    </button>
  );
};
