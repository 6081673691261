import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {Loader, PaymentStartEnd} from "components";
import {httpRequestsOnSuccessActions} from "store";
import {FindLoad, FindSuccess, Images} from "utils";
import {promotionStyle} from "../styles";
import CheckoutForm from "./checkoutForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PKEY}`);
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);
const ACTION_TYPE = 'PROMOTE_LISTING'


export const PaymentMethod = ({handleClose, handleOpenSuccess, payment, handlePay, selectedItem}) => {
    const classes = promotionStyle()
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)
    const dispatch = useDispatch()


    useEffect(() => {
        if (success?.length) {
            handleClose()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
        }
    }, [success])

    return (
        <div>

            <p className={classes.modalTitle}>{payment && !selectedItem ? 'Add promotion?' : 'Payment Method'}</p>

            {payment && !selectedItem ?
                <div className='form-wrapper'>
                    <p className={classes.modalSubTitle}>Add promotion we will add 30 Days to your listing item.</p>
                    <PaymentStartEnd
                        startDate={selectedItem?.promotion?.startDate}
                        endDate={selectedItem?.promotion?.endDate}
                        renewPromotion={selectedItem?.promotion?.status === "PROMOTED"}
                    />
                    <div className={classes.whiteWrapper}>
                        <div className={classes.alignCenter}>
                            {payment?.card &&
                                <img
                                    src={payment?.card === "mastercard" ? Images.master : Images.visa}
                                    alt="icon"
                                />
                            }
                            <p className={classes.cardNumber}>{`**** **** **** ${payment?.last4}`}</p>
                        </div>
                    </div>
                    <div className={classes.spaceBetweenRenew}>
                        <button className={classes.cancel} onClick={handleClose}>Cancel</button>
                        <button onClick={handlePay} className={classes.payButton}>
                            {loader?.length ?
                                <Loader type={'mini'} color={'white'}/>
                                :
                                'Pay'
                            }
                        </button>
                    </div>
                </div>
                :
                <div className="checkout-page">
                    <div className="checkout-container-wrapper">
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                selectedItem={selectedItem}
                                handleOpenSuccess={handleOpenSuccess}
                                handlePay={handlePay}
                                handleClose={handleClose}
                            />
                        </Elements>
                    </div>
                </div>
            }
        </div>
    )
}