import React from 'react';
import {CardRed, CardWithSvg} from "fragments";
import {Images} from "assets/images";
import {aboutUsStyles} from "./styles";

export const AboutUs = () => {
    const classes = aboutUsStyles()


    const items = [

        {
            title: 'Our Mission',
            description: "Simplify the commercial real estate leasing journey, ensuring a smooth and accessible" +
                " experience for both property owners and lessees. We strive to provide users with the necessary " +
                "tools to effortlessly list, explore, and manage commercial properties with precision and efficiency."
        },
        {
            title: 'Our Vision',
            description: 'To reshape the commercial real estate sector into a more inclusive and streamlined ' +
                'marketplace. We strive to make finding a rental space easy, adapting to the dynamic needs of ' +
                'the real estate industry.'
        },
    ]

    const whyChooseCards = [
        {
            description: 'Streamlined Experience: Seamlessly create your commercial property listings in just' +
                ' three straightforward steps – Create an account, list your property and manage your property listing.'
        },
        {
            description: 'Free Listing Creation: Create commercial real estate listings for free whenever you want.'
        },
        {
            description: 'Wide Visibility: Your listings gain maximum exposure through our network of industry-leading ' +
                'partners and platforms.'
        },
    ]
    return (
        <div className={classes.aboutUsPage}>
            <div className={classes.aboutUsContainer}>
                <div className={classes.aboutSomethingWrapper}>
                    <div className={classes.aboutSomethingTextAndDescription}>
                        <h2 className={classes.aboutSomethingTitle}>
                            About Us
                        </h2>

                        <p className={classes.aboutSomethingDescription}>
                            Croill is your top choice for finding commercial spaces in the US. We focus on industrial,
                            commercial, and residential areas to help you succeed in any industry. From small
                            retail spaces to expansive office complexes, we ensure seamless
                            leasing experiences to propel your business forward.
                        </p>
                    </div>

                    <div className={classes.aboutSomethingImageBlock}>
                        <img
                            src={Images.aboutUs} alt="img"
                            className={classes.aboutSomethingImage}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.whoWeAreAndCardsWrapper}>
                <div className={classes.aboutUsContainer}>
                    <div className={`${classes.aboutSomethingWrapper} ${classes.WhoWeAreSection}`}>
                        <div
                            className={`${classes.aboutSomethingTextAndDescription} ${classes.WhoWeAreContent}`}>
                            <h2 className={classes.aboutSomethingTitle}>
                                Who We Are
                            </h2>

                            <p className={classes.aboutSomethingDescription}>
                                We are a team of innovators and real estate professionals dedicated to
                                revolutionizing the commercial property leasing landscape. With a focus on technology
                                -driven solutions, we are committed to providing a platform that simplifies the leasing
                                process, making it more accessible and efficient for everyone involved.
                            </p>
                        </div>

                        <div className={`${classes.aboutSomethingImageBlock} ${classes.WhoWeAreImageBlock}`}>
                            <img
                                src={Images.whoWeAre} alt="img"
                                className={classes.aboutSomethingImage}
                            />
                        </div>
                    </div>

                    <div className={classes.redCardsWrapper}>
                        {items.map(({title, description}, i) => (
                            <CardRed
                                title={title}
                                description={description}
                                key={i}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className={classes.aboutUsContainer}>
                <div className={classes.whyChooseUsWrapper}>

                    <div className={classes.whyChooseUsBox}>
                        <h2 className={classes.whyChooseUsTitle}>
                            Why Choose Us
                        </h2>

                        <p className={classes.whyChooseUsDesc}>
                            We are your top choice for managing commercial real estate listings. We do it with
                            simplicity, efficiency, and effectiveness. Here's why our platform excels:
                        </p>

                        <div className={classes.whyChooseUsContent}>
                            {whyChooseCards.map(({description}, i) => (
                                <CardWithSvg
                                    key={i}
                                    description={description}
                                />
                            ))}
                        </div>
                    </div>

                    <div className={classes.whyChooseUsImageBlock}>
                        <img
                            src={Images.whyChooseImg} alt="img"
                            className={classes.whyChooseUsImage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

