import {createBroker, editBroker, getBrokers, deleteBroker, getBrokerById, getBrokerProfile} from "./brokers.action";

export {brokerReducer} from "./brokers.reducer";
export {watchBroker} from "./brokers.saga";

export const brokerActions = {
    createBroker,
    getBrokers,
    editBroker,
    deleteBroker,
    getBrokerById,
    getBrokerProfile,
};
