import React, { useState } from 'react';
import { wrappersStyle } from './style';
import { Images } from 'utils';

export const ListingInputsWrapper = ({ inputsTitle, children }) => {
    const classes = wrappersStyle();
    const [inputsAreShown, setInputsAreShown] = useState(false);

    return (
        <div className={classes.listingInputWrapperStyle}>
            <div className={`${classes.wrapperContainerStyle} tabletView`} onClick={() => setInputsAreShown((prevState) => !prevState)}>
                <h2 className={classes.wrapperTitleStyle}> {inputsTitle} </h2>
                <img src={inputsAreShown ? Images.arrowBlueUp : Images.arrowBlueDown} alt="" />
            </div>
            <div className={`${classes.childrenContainerStyle} tabletView ${inputsAreShown ? 'active' : ''}`}>
                {inputsAreShown && children}
            </div>
            <div className={classes.wrapperContainerStyle}>
                <h2 className={classes.wrapperTitleStyle}> {inputsTitle} </h2>
            </div>
            <div className={classes.childrenContainerStyle}>{children}</div>
        </div>
    );
};
