import {makeStyles} from "@material-ui/core/styles";

export const promotedStyles = makeStyles(() => ({
    cardsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media(max-width: 1180px)': {
            justifyContent: 'center',
        },
    },
    notPromotedCardWrapper: {
        maxWidth: '628px',
        width: '100%',
        padding: '8px 16px 8px 8px',
        height: '118px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px',
        '@media(max-width: 1440px)': {
            maxWidth: '608px',
        },
        '@media(max-width: 1400px)': {
            maxWidth: '588px',
        },
        '@media(max-width: 1360px)': {
            maxWidth: '548px',
        },
        '@media(max-width: 1280px)': {
            maxWidth: '508px',
        },
        '@media(max-width: 1180px)': {
            maxWidth: '628px',
        },
    },
    cardIcon: {
        width: '102px',
        height: '102px',
        objectFit: 'cover',
        borderRadius: '8px',
    },
    iconAntText: {
        display: 'flex',
    },
    addressWrapper: {
        marginLeft: '16px',
        width:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
    },
    address: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#0073BB',
        marginTop: '5px',
    },
    country: {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#0073BB',
        marginTop: '8px',
    },
    sqStyle: {
        padding: '4px 8px',
        background: '#F4F4F4',
        borderRadius: '4px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2B273C',
        marginBottom: '4px',
        width: 'auto',
        maxWidth: '120px',
    },
    priceAndButton: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    priceStyle: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        marginTop: '5px',
        '& span': {
            fontSize: '14px',
            color: '#757280',
        },
    },
    promoteButton: {
        width: '124px',
        background: 'transparent',
        color: '#FE385C',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
    },
    promotedCardWrapper: {
        width: '100%',
        height: '414px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '12px',
        padding: '30px',
        '@media(min-width: 320px)': {
            display: 'none',
        },
        '@media(min-width: 768px)': {
            display: 'block',
            maxHeight: '982px',
            height: 'auto',
        },
        '@media(min-width: 1280px)': {
            height: '414px',
        },
    },
    promotedHead: {
        width: '100%',
        display: 'flex',
        padding: '15px 16px',
        marginBottom: '14px',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#41586D',
    },

    promotedCard: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        height: '64px',
        padding: '14px 18px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    promotedCardIcon: {
        height: '36px',
        width: '36px',
        objectFit: 'cover',
        borderRadius: '4px',
    },
    promotedCardIconTitle: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#757280',
        marginLeft: '10px',
        width:'100%',
    },
    alignItem: {
        display: 'flex',
        alignItems: 'center',
    },
    promotedCardBody: {
        height: '310px',
        overflow: 'auto',
        padding: '6px'
    },
    repromote: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#0073BB',
        background: 'transparent',
        border: 'none',
    },
    promotedDesktop: {
        display: 'none',
        '@media(min-width: 1280px)': {
            display: 'block',
        },
    },
    promotedTablet: {
        display: 'none',
        '@media(min-width: 768px)': {
            display: 'block',
        },
        '@media(min-width: 1280px)': {
            display: 'none',
        },
    },
    promotedCardTablet: {
        width: '100%',
        height: '170px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        padding: '12px 16px',
        marginBottom: '10px',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    boldTitle: {
        fontWeight: '600',
        color: '#2B273C'
    },
    promotedCardTabletBody: {
        maxHeight: '860px',
        height: 'auto',
        overflow: 'auto',
        padding: '6px'
    },
    promotedCardMobile: {
        '@media(min-width: 320px)': {
            display: 'block',
        },
        '@media(min-width: 768px)': {
            display: 'none',
        },
    },
    promotedCardMobileWrapper: {
        width: '100%',
        minHeight: '284px',
        height:'auto',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        padding: '18px 16px',
        marginBottom: '10px',
    },
    cardAddressStyle:{
      fontSize:'12px',
    },
    repromoteMobile: {
        width: '100%',
        height: '36px',
        border: '1px solid #0073BB',
        borderRadius: '8px',
        background: 'transparent',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#0073BB',
        marginTop: '18px'
    },

}))