import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils"

export const aboutUsFragments = makeStyles(() => ({
    cardRedWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        borderRadius: '16px',
        backgroundColor: `${Colors.ThemePink}`,
        padding: '24px'
    },
    cardRedTitle: {
        color: `${Colors.TextWhite}`,
        fontSize: '24px',
        fontWeight: '800',
        lineHeight: '36px'
    },
    cardRedDescription: {
        color: `${Colors.TextWhite}`,
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px'
    },

    cardWithSvgWrapper: {
        width: '100%',
        display: 'flex',
        backgroundColor: `${Colors.BackgroundWhite}`,
        gap: '16px',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.10)',

        '@media(max-width: 768px)': {
            flexDirection: 'column'
        },
    },
    cardWithSvgSvg:{
        minWidth:32,
        minHeight:32
    },

    cardWithSvgDescription:{
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px'
    }
}))