import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "utils";

export const logoStyle = makeStyles(
  () => ({
    logoStyle: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& > h1": {
        fontSize: "24px",
        fontWeight: "800",
        color: Colors.TextBlack,
        textTransform: "uppercase",
      },
      "&::after": {
        content: "''",
        display: "block",
        width: "10px",
        height: "10px",
        backgroundColor: Colors.ThemePink,
        borderRadius: "2px",
        marginLeft: "6px",
        transform: "rotate(45deg)",
      },
    },
  }),
  { index: 1 }
);
