import {promotionStyle} from "../styles";
import {Images} from "utils";

export const DoneModal = ({handleClose}) => {
    const classes = promotionStyle()

    return (
        <div className={classes.doneWrapper}>
            <img src={Images.done} alt={'done'}/>
            <p className={classes.doneTitle}>Done</p>
            <p className={classes.doneSubtitle}>You can see your promotion <br/> on listing page.</p>
            <button onClick={handleClose} className={classes.doneButton}>Done</button>
        </div>
    )
}