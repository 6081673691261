import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";


export const aboutUsStyles = makeStyles(() => ({
    aboutUsPage: {
        width: '100%',
        padding: '116px 0 138px',

        '@media(max-width: 1279px)': {
            padding: '56px 0 96px',
        },
        '@media(max-width: 768px)': {
            padding: '56px 0 48px',
        },
    },
    aboutUsContainer: {
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',
        padding: '0px 80px 0',
        '@media(max-width: 1279px)': {
            padding: '0px 33px 0',
        },
        '@media(max-width: 768px)': {
            padding: '0px 14px 0',
        },
    },

    aboutSomethingWrapper: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '77px',
        gap: '154px',

        '@media(max-width: 1279px)': {
            flexDirection: 'column',
            paddingBottom: '89px',
            gap: '24px',
        },
        '@media(max-width: 768px)': {
            paddingBottom: '72px',
        },
    },
    aboutSomethingTextAndDescription: {
        width: '100%',
        maxWidth: '537px',

        '@media(max-width: 1279px)': {
            maxWidth: '100%',
        },
        '@media(max-width: 768px)': {},
        '@media(max-width: 400px)': {}
    },
    aboutSomethingTitle: {
        color: `${Colors.TextBlack2}`,
        fontSize: '36px',
        fontWeight: '800',
        lineHeight: '36px',
        marginBottom: '24px'
    },
    aboutSomethingDescription: {
        color: Colors.TextBlack2,
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px'
    },
    aboutSomethingImageBlock: {
        width: '100%',
        maxWidth: '568px',
        height: '379px',
        borderRadius: '24px',
        overflow: "hidden",

        '@media(max-width: 1279px)': {
            maxWidth: '703px',
            height: 'auto',
            aspectRatio: '3.7/2'
        },
        '@media(max-width: 768px)': {
            maxWidth: '100%',
        },
    },
    aboutSomethingImage: {
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    },
    CardRedWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        borderRadius: '16px',
        backgroundColor: `${Colors.ThemePink}`,
        padding: '24px'
    },
    CardRedTitle: {
        color: `${Colors.TextWhite}`,
        fontSize: '24px',
        fontWeight: '800',
        lineHeight: '36px'
    },
    CardRedDescription: {
        color: `${Colors.TextWhite}`,
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px'
    },

    cardWithSvgWrapper: {
        width: '100%',
        display: 'flex',
        backgroundColor: `${Colors.BackgroundWhite}`,
        gap: '16px',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.10)',

        '@media(max-width: 768px)': {
            flexDirection: 'column'
        },
    },
    cardWithSvgSvg: {
        minWidth: 32,
        minHeight: 32
    },

    cardWithSvgDescription: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px'
    },

    whoWeAreAndCardsWrapper: {
        padding: '60px 0 91px',
        background: `${Colors.BackgroundLightGray}`,

        '@media(max-width: 1279px)': {
            padding: '60px 0 80px',
        },
        '@media(max-width: 768px)': {
            padding: '60px 0 60px',
        },

    },
    WhoWeAreSection: {
        flexDirection: 'row-reverse',
        paddingBottom: '0px',

        '@media(max-width: 1279px)': {
            flexDirection: 'column',
        },
    },

    WhoWeAreContent: {
        maxWidth: '579px',

        '@media(max-width: 1279px)': {
            maxWidth: '100%',
        },
    },

    WhoWeAreImageBlock: {
        maxWidth: '538px',
        height: '359px',

        '@media(max-width: 1279px)': {
            maxWidth: '648px',
            aspectRatio: '3.61/2',
            height: 'auto'
        },
    },

    redCardsWrapper: {
        display: 'flex',
        gap: '28px',
        marginTop: '79px',

        '@media(max-width: 1279px)': {
            flexDirection: 'column'
        },
    },
    whyChooseUsWrapper: {
        paddingTop: '104px',
        display: 'flex',
        gap: '46px',

        '@media(max-width: 1279px)': {
            paddingTop: '96px',
            flexDirection:'column',
            alignItems:'center'
        },
        '@media(max-width: 768px)': {
            paddingTop: '72px',
        },
    },
    whyChooseUsTitle: {
        color: `${Colors.TextBlack}`,
        fontSize: '36px',
        fontWeight: '800',
        lineHeight: '36px',
    },
    whyChooseUsBox: {
        width:'100%',
        maxWidth:'628px',

        '@media(max-width: 1279px)': {
            maxWidth:'100%',
        },

    },
    whyChooseUsContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',

        '@media(max-width: 1279px)': {
            maxWidth: '100%',
        },
    },

    whyChooseUsDesc: {
        fontSize: '16px',
        color: `${Colors.TextBlack2}`,
        fontWeight: '400',
        lineHeight: '24px',
        margin:'44px 0',
    },

    whyChooseUsImageBlock: {
        width: '100%',
        maxWidth: '562px',
        height: 'auto',
        borderRadius: '24px',
        overflow: 'hidden',
        aspectRatio: '1.16/1',

        '@media(max-width: 1279px)': {
            maxWidth: '648px',
            aspectRatio: '1.16/1'
        },
    },
    whyChooseUsImage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}))