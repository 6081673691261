import axios from 'axios';

export const authService = {
    createListingService: (body) => axios.post('/listing', body, { auth: true }),

    getListingsService: () => axios.get('/listing'),

    editListingService: (id, body) => axios.patch(`/listing/${id}`, body, { auth: true }),

    editListingImagesService: (id, body) => axios.patch(`/listing/${id}/manageImages`, body, { auth: true }),

    removeListingSevice: (id) => axios.delete(`/listing/${id}`, { auth: true }),

    getListingByIdService: (id) => axios.get(`/listing/${id}`),

    getListingsByUserIdService: () => axios.get(`/listing/user`, { auth: true }),

    getListingsByUserIdServiceFilter: (filters) => axios.get(`/listing/user`, { params: { ...filters }, auth: true }),

    getListingsByFiltersService: (filters) => axios.get('/listing', { params: { ...filters } }),

    /** Promote */
    promoteService: (id, body) => axios.post(`/listing/${id}/promote`, { ...body }, { auth: true }),
    /** End */

};
