import {promotedStyles} from "./styles";
import {getAddressText, getValDependsOnLength, Images, TextRow} from "utils";
import {ViewMoreButton} from "components";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

export const NotPromotedCard = ({item, handlePromote}) => {
    const classes = promotedStyles()
    const {address, price, mainImage} = item;
    const {street} = address;
    const listingPrice = getValDependsOnLength(price);
    const listingMinSize = getValDependsOnLength(item?.size?.start ? item?.size?.start : '');
    const listingMaxSize = getValDependsOnLength(item?.size?.end ? item?.size?.end : '');
    const size = {minSize: listingMinSize, maxSize: listingMaxSize}
    const addressDisplay = getAddressText(address);
    const sizeDisplay = size.minSize && size.maxSize ? `${size.minSize} - ${size.maxSize} sq.ft.` : size.minSize ? `Over ${size.minSize} sq.ft.` : size.maxSize ? `Under ${size.maxSize} sq.ft.` : '';

    return (
        <div className={classes.notPromotedCardWrapper}>
            <div style={{width: '100%'}} className={classes.iconAntText}>
                <img className={classes.cardIcon} src={
                    item?.mainImage?.url ? item?.mainImage?.url :  Images.building} alt="icon"/>
                <div className={classes.addressWrapper}>
                    <Tooltip title={`${street} ${addressDisplay}`} placement="top-start">
                        <div className={classes.address}>
                            {street ? (
                                <>
                                    <h6>
                                        <TextRow name={street}/>
                                    </h6>
                                    <p className={classes.cardAddressStyle}>
                                        <TextRow name={addressDisplay}/>
                                    </p>
                                </>
                            ) : (
                                <h6>
                                    <TextRow name={addressDisplay}/>
                                </h6>
                            )}
                        </div>
                    </Tooltip>
                    {sizeDisplay &&
                        <div className={classes.sqStyle}>{sizeDisplay}</div>
                    }
                </div>
            </div>
            <div className={classes.priceAndButton}>
                <p className={classes.priceStyle}>
                    {`$${price ? getValDependsOnLength(price) : 0}`} <em> {'SF/YR'} </em>
                </p>

                <ViewMoreButton
                    butnClassName={classes.promoteButton}
                    butnViewingText={'Promote'}
                    onView={() => handlePromote(item?._id)}
                />
            </div>
        </div>
    )
}