export const CREATE_LISTING = 'CREATE_LISTING';

export const GET_LISTINGS = 'GET_LISTINGS';
export const SET_LISTINGS = 'SET_LISTINGS';

export const EDIT_LISTING = 'EDIT_LISTING';

export const REMOVE_LISTING = 'REMOVE_LISTING';

export const GET_LISTING_BY_ID = 'GET_LISTING_BY_ID';
export const SET_LISTING_BY_ID = 'SET_LISTING_BY_ID';

export const GET_LISTINGS_BY_USER_ID = 'GET_LISTINGS_BY_USER_ID';
export const SET_LISTINGS_BY_USER_ID_SUCCESS = 'SET_LISTINGS_BY_USER_ID_SUCCESS';

export const GET_LISTINGS_BY_FILTERS = 'GET_LISTINGS_BY_FILTERS';

export const GET_LISTINGS_BY_USER_ID_FILTER = 'GET_LISTINGS_BY_USER_ID_FILTER';
export const GET_LISTINGS_BY_USER_ID_FILTER_SUCCESS = 'GET_LISTINGS_BY_USER_ID_FILTER_SUCCESS';


export const PROMOTE_LISTING = 'PROMOTE_LISTING';
export const PROMOTE_LISTING_SUCCESS = 'PROMOTE_LISTING_SUCCESS';
