import React from 'react';
import {Svg} from "assets/images";
import {howItWorksFragmentsStyles} from "./styles";

export const Step1 = () => {
    const classes = howItWorksFragmentsStyles();

    return (
        <div className={classes.stepsWrapper}>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        1
                    </div>
                    <div className={classes.stepDot}/>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 1
                    </h4>

                    <p className={classes.stepDesc}>
                        Go to the
                        <span className={classes.stepItemsTextBold}>“Homepage”</span>
                        and click the <span className={classes.stepItemsTextBold}>“Sign In”</span> button, then click
                        <span className={classes.stepItemsTextBold}>"Sign Up"</span>
                    </p>
                </div>
            </div>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        2
                    </div>
                    <div className={classes.stepDot}/>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 2
                    </h4>

                    <p className={classes.stepSubTitle}>
                        Choose an account type
                    </p>

                    <div style={{marginTop: '20px'}} className={classes.stepItemsDescWrapper}>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                                Basic Account:
                                            </span>
                                Allows you to post and sell your property.
                            </p>
                        </div>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                            <span style={{marginRight: '5px'}} className={classes.stepItemsTextBold}>
                                                Broker Account:
                                            </span>
                                Allows you to post and sell your property, as well as be listed on a broker
                                list for potential clients to easily connect with you.
                            </p>
                        </div>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>

                            <p className={classes.stepItemsText}>
                                Click
                                <span style={{margin: '0 5px'}} className={classes.stepItemsTextBold}>
                                               “Next Step”
                                            </span>
                                to proceed.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        3
                    </div>
                    <div className={classes.stepDot}/>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 3
                    </h4>

                    <p className={classes.stepSubTitle}>
                        Enter your personal information
                    </p>

                    <p className={classes.stepDesc}>
                        <span className={classes.stepItemsTextBold}>Note: </span>If you're a broker, please include your license number.
                        Ensure all your information is correct for smooth account operation.
                    </p>
                </div>
            </div>
            <div className={classes.singleStepWrapper}>
                <div className={classes.stepCountAndDot}>
                    <div className={classes.stepCount}>
                        4
                    </div>
                </div>

                <div className={classes.stepTitleAndDesc}>
                    <h4 className={classes.stepTitle}>
                        Step 4
                    </h4>

                    <p className={classes.stepSubTitle}>
                        Complete registration
                    </p>

                    <div style={{marginTop: '20px'}} className={classes.stepItemsDescWrapper}>
                        <div className={classes.stepItemsDesc}>
                            <Svg.DownRightArrow className={classes.stepItemsSvg}/>
                            <p className={classes.stepItemsText}>
                                After entering your details, click the <span className={classes.stepItemsTextBold}>“Sign Up”</span> button to finalize your account
                                creation.
                            </p>
                        </div>
                    </div>

                    <p className={classes.stepDesc}>
                        As soon as you register, you'll see a confirmation message pop up on your
                        screen.You're officially part of Croill.com! You're all set to start listing
                        properties and exploring opportunities.
                    </p>
                </div>
            </div>
        </div>
    );
};

