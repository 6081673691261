import React, {useEffect} from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ListingsCard, Nav } from 'fragments';
import { Loader, ViewMoreButton } from 'components';
import { listingsStyle } from './styles';
import { FindLoad } from 'utils';

export const UserListings = () => {
    const classes = listingsStyle();
    const history = useHistory();
    const userListings = useSelector((state) => state.listings.listingsByUserId);
    const isLoading = !!FindLoad('GET_LISTINGS_BY_USER_ID').length;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    return isLoading ? (
        <Loader />
    ) : (
        <div>
            <div className={classes.userListingsBoxStyle}>
                <div className={classes.userListingsBoxContainerStyle}>
                    <h2 className={classes.myListingsTitleStyle}> My Listings </h2>
                    <ViewMoreButton
                        butnClassName={classes.createListingButnStyle}
                        butnViewingText={'Create Listing'}
                        onView={() => history.push('/my_listings/create_listing')}
                    />
                </div>
            </div>
            <Nav />
            <ListingsCard listingList={userListings} />
        </div>
    );
};
