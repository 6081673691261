import React, {useState} from "react";
import {Link} from "react-router-dom";
import {footerStyle} from "./style";
import {Logo, SimpleModal} from "components";
import {Images} from "utils";
import {SendMessage} from "./core.js";

export const Footer = () => {
    const classes = footerStyle();
    const isResetPasswordPage = window.location.pathname === "/resetPassword";
    const [open, setOpen] = useState(false)
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const d = new Date();
    let year = d.getFullYear();

    const search = userInfo ?
        [
            {title: 'Properties For Sale', link: '/listings'},
            {title: 'Find a broker', link: '/brokers'},
            {title: 'Create Listing', link: '/create_listing'},
        ] :
        [
            {title: 'Properties For Sale', link: '/listings'},
            {title: 'Find a broker', link: '/brokers'},
        ]

    const company = [
        {title: 'About Us', link: '/about-us'},
        {title: 'Terms of Use', link: '/termsAndConditions'},
        {title: 'Privacy Policy', link: '/privacyPolicy'},
    ]

    return (
        <footer className={`${classes.footerStyle} ${isResetPasswordPage ? "margin" : ""}`}>
            <section className={classes.footerContainerStyle}>
                <div className={classes.rowFirstWrapper}>
                    <Logo logoClassName={classes.logoStyle}/>
                    <div className={classes.followUs}>
                        <p className={classes.followTitle}>Follow us</p>
                        <div className={classes.socialWrapper}>
                            <a
                                href="https://www.linkedin.com/feed/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <div className={classes.socialItem}><img src={Images.linkedinFooter} alt=""/></div>
                            </a>
                            <a
                                href="https://www.facebook.com/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <div className={classes.socialItem}><img src={Images.facebookFooter} alt=""/></div>
                            </a>
                            <a
                                href="https://www.instagram.com/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <div className={classes.socialItem}><img src={Images.twitterFooter} alt=""/></div>
                            </a>
                        </div>
                    </div>
                    <p className={classes.copyrightTextStyle}>
                        {`©${year} Croil. All Rights Reserved.`}
                    </p>
                </div>
                <div className={classes.rowWrapper}>
                    <p className={classes.columnTitle}>Search</p>
                    <ul>
                        {search?.map((i, k) => (
                            <li className={classes.liStyle} key={k}>
                                <Link to={i?.link}>{i?.title} </Link>
                            </li>
                        ))
                        }
                    </ul>
                </div>
                <div className={classes.rowWrapper}>
                    <p className={classes.columnTitle}>Company</p>
                    <ul>
                        {company?.map((i, k) => (
                            <li className={classes.liStyle} key={k}>
                                <Link to={i?.link}>{i?.title} </Link>
                            </li>
                        ))
                        }
                    </ul>
                </div>
                <div className={classes.rowWrapper}>
                    <p className={classes.columnTitle}>Contact Us</p>
                    <ul>
                        <li className={classes.liStyleSms}>
                            <a  href={`mailto:info@croill.com`}>
                                <img src={Images.sms} alt=""/>
                                <p>info@croill.com</p>
                            </a>
                        </li>
                        <li className={classes.liStyle}>
                            <button
                                onClick={() => setOpen(true)}
                                className={classes.sendUsMessage}
                            >Send Us Message
                            </button>
                        </li>
                    </ul>
                </div>
            </section>

            <section className={classes.footerContainerStyleMobile}>

                <div>
                    <Logo logoClassName={classes.logoStyle}/>
                </div>

                <div className={classes.mobileFirstSection}>
                    <div className={classes.rowWrapper}>
                        <p className={classes.columnTitle}>Search</p>
                        <ul>
                            {search?.map((i, k) => (
                                <li className={classes.liStyle} key={k}>
                                    <Link to={i?.link}>{i?.title} </Link>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                    <div className={classes.rowCompanyWrapper}>
                        <p className={classes.columnTitle}>Company</p>
                        <ul>
                            {company?.map((i, k) => (
                                <li className={classes.liStyle} key={k}>
                                    <Link to={i?.link}>{i?.title} </Link>
                                </li>
                            ))
                            }
                        </ul>
                    </div>
                </div>


                <div className={classes.mobileLastSection}>

                    <div className={classes.rowWrapper}>
                        <p className={classes.columnTitle}>Contact Us</p>
                        <ul>
                            <li className={classes.liStyleSms}>
                                <a  href={`mailto:info@croill.com`}>
                                    <img src={Images.sms} alt=""/>
                                    <p>info@croill.com</p>
                                </a>
                            </li>
                            <li className={classes.liStyle}>
                                <button
                                    onClick={() => setOpen(true)}
                                    className={classes.sendUsMessage}
                                >
                                    Send Us Message
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div style={{width: '270px'}} className={classes.rowFirstWrapper}>

                        <div className={classes.followUs}>
                            <p className={classes.followTitle}>Follow us</p>
                            <div className={classes.socialWrapper}>
                                <a
                                    href="https://www.linkedin.com/feed/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <div className={classes.socialItem}><img src={Images.linkedinFooter} alt=""/></div>
                                </a>
                                <a
                                    href="https://www.facebook.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <div className={classes.socialItem}><img src={Images.facebookFooter} alt=""/></div>
                                </a>
                                <a
                                    href="https://www.instagram.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <div className={classes.socialItem}><img src={Images.twitterFooter} alt=""/></div>
                                </a>
                            </div>
                        </div>
                        <p className={classes.copyrightTextStyle}>
                            {`©${year} Croil. All Rights Reserved.`}
                        </p>
                    </div>
                </div>
            </section>

            <SimpleModal
                openDefault={open}
                handleOpenClose={() => setOpen(false)}
                content={
                    <SendMessage
                        onClose={() => setOpen(false)}
                    />
                }
            />

        </footer>
    );
};
