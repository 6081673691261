import React from "react";
import { Modal } from "@mui/material";
import { Backdrop } from "@mui/material";

export const SimpleModal = ({
  openDefault,
  handleOpenClose,
  content,
  backdropCustom,
  disableScrollLock,
}) => {
  const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    if (handleOpenClose) {
      handleOpenClose();
    } else {
      setOpen(false);
    }
  };

  const body = <div>{content}</div>;

  return (
    <div>
      {/* <button type="button" onClick={handleOpen}>
        Open Modal
      </button> */}
      <Modal
        disableScrollLock={disableScrollLock}
        open={openDefault ? openDefault : open}
        onClose={handleClose}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={backdropCustom ? backdropCustom : ""}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {body}
      </Modal>
    </div>
  );
};
