import React, {useContext, useState} from 'react';
import {useHistory} from 'react-router';
import {RegisterModalWrapper, SimpleModal, UserRegistrationWrapper, ViewMoreButton} from 'components';
import {
    getAddressText,
    Images,
    PROMOTED,
    RegisterContext,
    TextRow,
    useWidth,
    ViewAsContext
} from 'utils';
import {listingsCardItemStyle} from './style';

export const ListingsCardItem = ({cardClassName, listingId, mainImage = '', street, price, size, address, item}) => {
    const classes = listingsCardItemStyle();
    const history = useHistory();

    const screenWidth = useWidth();

    const showMoreDetails = () => {
        if (isMyListingsPage) {
            history.push(`/my_listings/listing_details-${listingId}`);
        } else {

            const pageEvent = {
                event: "VIEW_DETAILS",
                list_id: listingId,
            };
            window?.dataLayer.push(pageEvent);

            history.push(`/listings/listing_details-${listingId}`);
        }
    };

    const onViewDetails = () => {
        if (view !== 'gallery' || (!isHomePage && screenWidth > 768)) return;
        showMoreDetails();
    };

    const [isShownMore, setIsShownMore] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const {view} = useContext(ViewAsContext);
    const registerCtx = useContext(RegisterContext);
    const {handleModalContent} = registerCtx;

    const isHomePage = window.location.pathname === '/';
    const isMyListingsPage = window.location.pathname === '/my_listings';

    const cardItemClassName = view === 'gallery' || isHomePage ? classes.cardItemStyle : view === 'list' ? classes.cardItemListViewStyle : '';
    const cardImageBoxClassName = view === 'gallery' || isHomePage ? classes.cardImageBoxStyle : view === 'list' ? classes.cardImageBoxListViewStyle : '';
    const cardItemOverlayClassName = view === 'gallery' || isHomePage ? `${classes.cardItemOverlayStyle} cardOverlay` : view === 'list' ? classes.cardItemOverlayListViewStyle : '';
    const cardItemInfoClassName = view === 'gallery' || isHomePage ? `${classes.cardItemInfoStyle} cardInfo` : view === 'list' ? classes.cardItemInfoListViewStyle : '';
    const infoBoxClassName = view === 'gallery' || isHomePage ? classes.infoBoxStyle : view === 'list' ? classes.infoBoxListViewStyle : '';
    const formattedAddressBoxClassName = view === 'gallery' || isHomePage ? classes.formattedAddressBoxStyle : view === 'list' ? classes.formattedAddressBoxListViewStyle : '';
    const cardTitleClassName = view === 'gallery' || isHomePage ? classes.cardTitleStyle : view === 'list' ? classes.cardTitleListViewStyle : '';
    const cardPriceClassName = view === 'gallery' || isHomePage ? classes.cardPriceStyle : view === 'list' ? `${classes.cardPriceListViewStyle} ${isMyListingsPage ? 'margin' : ''}` : '';
    const cardSizeClassName = view === 'gallery' || isHomePage ? classes.cardSizeStyle : view === 'list' ? `${classes.cardSizeStyle} ${classes.cardSizeListViewStyle}` : '';
    const viewCardDetailsButnClassName = view === 'gallery' || isHomePage ? `${classes.viewCardDetailsButnStyle} viewDetailsButn` : view === 'list' ? `${classes.viewCardDetailsButnListViewStyle} viewDetailsButnListView` : '';
    const moreBoxClassName = view === 'gallery' ? classes.moreBoxStyle : view === 'list' ? classes.moreBoxListViewStyle : '';
    const moreClassName = view === 'gallery' ? classes.moreStyle : view === 'list' ? classes.moreListViewStyle : '';
    const buildingClassName = view === 'gallery' || isHomePage ? classes.buildingStyle : view === 'list' ? classes.buildingListViewStyle : '';
    const addressDisplay = getAddressText(address);
    const sizeDisplay = size.minSize && size.maxSize ? `${size.minSize} - ${size.maxSize} sq.ft.` : size.minSize ? `Over ${size.minSize} sq.ft.` : size.maxSize ? `Under ${size.maxSize} sq.ft.` : '';

    return (
        <>
            <div className={`${cardItemClassName} ${cardClassName}`} onClick={onViewDetails}>
                {isMyListingsPage && (
                    <div className={moreBoxClassName} onClick={(e) => e.stopPropagation()}>
                        <div className={moreClassName} onClick={() => setIsShownMore((prevState) => !prevState)}>
                            <img src={Images.more} alt=""/>
                        </div>
                        {isShownMore && (
                            <>
                                <div className={classes.moreOverlayStyle} onClick={() => setIsShownMore(false)}/>
                                <div className={classes.moreActionsBoxStyle}>
                                    <ul className={classes.moreActionsListStyle}>
                                        <li onClick={() => history.push(`/my_listings/edit_listing-${listingId}`)}>
                                            <span>Edit</span>
                                        </li>
                                        <li
                                            onClick={() => {
                                                setModalIsOpen(true);
                                                handleModalContent('deleteListing');
                                            }}>
                                            <span>Delete</span>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                )}
                {item?.promotion?.status === PROMOTED && view &&
                    <div
                        className={view === 'list' ? classes.promotedList : classes.promoted}>
                        <img src={Images.promotedFill} alt="icon"/>
                        {view === 'gallery' && <p>Promoted</p>}
                    </div>
                }
                {mainImage ? (
                    <div className={cardImageBoxClassName}>
                        <img src={mainImage} alt="main listing"/>
                    </div>
                ) : (
                    <div className={buildingClassName}>
                        <img src={Images.building} alt=""/>
                    </div>
                )}
                <div className={cardItemOverlayClassName}/>
                <div className={cardItemInfoClassName}>
                    <div className={infoBoxClassName}>
                        {/*<Tooltip title={`${street} ${addressDisplay}`} placement="top-start">*/}
                        <div style={{width: '100%'}} className={formattedAddressBoxClassName}>
                            {street ? (
                                <>
                                    <h6 className={cardTitleClassName}>
                                        <TextRow name={street} textWidth={10}/>
                                        {/*{getLimitedVal(street, 15)}*/}
                                    </h6>
                                    <p className={classes.cardAddressStyle}>
                                        <TextRow name={addressDisplay} textWidth={10}/>
                                        {/*{getLimitedVal(addressDisplay, 19)}*/}
                                    </p>
                                </>
                            ) : (
                                <h6 className={cardTitleClassName}>
                                    <TextRow name={addressDisplay} textWidth={10}/>
                                    {/*{getLimitedVal(addressDisplay, 15)}*/}
                                </h6>
                            )}
                        </div>
                        {/*</Tooltip>*/}
                        {!!price && price !== '0' && (
                            <div style={{width: '100%'}}>
                                <div className={classes.priceAndMoreStyle}>
                                    <p className={cardPriceClassName}>
                                        {`$${price}`} <em> {'SF/YR'} </em>
                                    </p>
                                </div>
                                {sizeDisplay && <p className={cardSizeClassName}> {sizeDisplay} </p>}
                            </div>
                        )
                        }
                    </div>
                    <ViewMoreButton
                        butnClassName={viewCardDetailsButnClassName}
                        onView={showMoreDetails}
                        butnViewingText={'View Details'}
                    />
                </div>
            </div>
            <SimpleModal
                openDefault={modalIsOpen}
                handleOpenClose={() => setModalIsOpen(false)}
                content={
                    <RegisterModalWrapper onClose={() => setModalIsOpen(false)}>
                        <UserRegistrationWrapper
                            title={'Want to delete?'}
                            onClose={() => setModalIsOpen(false)}
                            listingId={listingId}
                        />
                    </RegisterModalWrapper>
                }
            />
        </>
    );
};
