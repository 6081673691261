import React, {useState, useEffect, useContext} from 'react';
import {
    FilteringOptionsDropdown,
    FilteringCheckBoxDropdown,
    FilteringFromToDropdown,
    FilterButton,
    SimpleModal,
} from 'components';
import {FindSuccess, getValTillTenDig, initialCoordinates, isNotEmpty, ListingFiltersContext} from 'utils';
import {enumValues} from 'utils';
import {navBarCoresStyle} from './style';
import {useDispatch} from 'react-redux';
import {httpRequestsOnSuccessActions} from 'store';
import {AdvancedSearch} from "../../../home/core";

export const NavBarFilteringBox = ({onRequest, view}) => {
    const listingFiltersCtx = useContext(ListingFiltersContext);
    const {selectedPropType, handleFiltersRequest, filters, propTypes, setPropTypes,

        filteredMinPrice, setFilteredMinPrice,
        filteredMaxPrice, setFilteredMaxPrice,
        filteredMinSize, setFilteredMinSize,
        filteredMaxSize, setFilteredMaxSize,
    } = listingFiltersCtx;
    const classes = navBarCoresStyle();
    const dispatch = useDispatch();
    const [filteredNewest, setFilteredNewest] = useState('');
    const [open, setOpen] = useState(false);
    const [hasBeenUsed, setHasBeenUsed] = useState(false);
    const [selectedFilters, setFilters] = useState({});
    const filteredPropTypes = propTypes.filter((propType) => propType?.isChecked).map((propType) => propType.label.toUpperCase());
    const listingFilterSuccess = FindSuccess('GET_LISTINGS_BY_FILTERS');
    const userListingFilterSuccess = FindSuccess('GET_LISTINGS_BY_USER_ID_FILTER');

    useEffect(() => {
        if (filters?.propertyType?.length) {
            let newPropTypes = enumValues.PROPERTY_TYPES
            enumValues.PROPERTY_TYPES.filter((i, j) => (
                // eslint-disable-next-line array-callback-return
                filters?.propertyType.filter((k) => {
                        if (k === i.value) {
                            newPropTypes[j].isChecked = true
                        }
                    }
                )))
            setPropTypes(newPropTypes)
        }else{
            setPropTypes(enumValues.DEFAULT_PROPERTY_TYPES)
        }
    }, [])

    const handleClearAll= () => {
        setFilteredNewest('');
        setFilteredMinPrice('');
        setFilteredMaxPrice('');
        setFilteredMinSize('');
        setFilteredMaxSize('')
    }

    const handleSelectedFilterSort = (selectedNewest, resetFilters) => {
        setFilteredNewest(selectedNewest);
        resetFilters((prevState) => ({
            ...prevState,
            filterSort: selectedNewest === '' ? selectedNewest : selectedNewest.filterSort,
        }));
    };

    const passSelectedPropTypes = (selectedOption) => {
        setPropTypes(
            propTypes.map((propType) => {
                if (propType.label === selectedOption.label) {
                    return selectedOption;
                }
                return propType;
            })
        );
    };

    useEffect(() => {
        if (selectedPropType) {
            const selPropType = {label: selectedPropType, isChecked: true};
            handleSelectedPropType(selPropType, setFilters);
        }
    }, [selectedPropType]);

    const handleSelectedPropType = (selectedOption, resetFilters) => {
        passSelectedPropTypes(selectedOption);
        resetFilters((prevState) => ({...prevState}));
    };

    const handleSelectedMinPrice = (minPrice, resetFilters) => {
        setFilteredMinPrice(getValTillTenDig(minPrice));
        resetFilters((prevState) => ({
            ...prevState,
            priceStart: getValTillTenDig(minPrice),
        }));
    };

    const handleSelectedMaxPrice = (maxPrice, resetFilters) => {
        setFilteredMaxPrice(getValTillTenDig(maxPrice));
        resetFilters((prevState) => ({
            ...prevState,
            priceEnd: getValTillTenDig(maxPrice),
        }));
    };

    const handleSelectedMinSize = (minSize, resetFilters) => {
        setFilteredMinSize(getValTillTenDig(minSize));
        resetFilters((prevState) => ({
            ...prevState,
            sizeStart: getValTillTenDig(minSize),
        }));
    };

    const handleSelectedMaxSize = (maxSize, resetFilters) => {
        setFilteredMaxSize(getValTillTenDig(maxSize));
        resetFilters((prevState) => ({
            ...prevState,
            sizeEnd: getValTillTenDig(maxSize),
        }));
    };

    const clearSelectedFilterSort = (resetFilters) => {
        setFilteredNewest('');
        resetFilters((prevState) => ({
            ...prevState,
            filterSort: filteredNewest === '' ? filteredNewest : filteredNewest.filterSort,
        }));
    };

    const clearSelectedPrice = (resetFilters) => {
        setFilteredMinPrice('');
        setFilteredMaxPrice('');
        resetFilters((prevState) => ({
            ...prevState,
            priceStart: getValTillTenDig(filteredMinPrice),
            priceEnd: getValTillTenDig(filteredMaxPrice),
        }));
    };

    const clearSelectedSize = (resetFilters) => {
        setFilteredMinSize('');
        setFilteredMaxSize('');
        resetFilters((prevState) => ({
            ...prevState,
            sizeStart: getValTillTenDig(filteredMinSize),
            sizeEnd: getValTillTenDig(filteredMaxSize),
        }));
    };

    const handleSelectedFilters = (selFilters) => {
        delete selFilters[isNotEmpty(filteredNewest.label) ? '' : 'filterSort'];
        delete selFilters[filteredPropTypes.length !== 0 ? '' : 'propertyType'];
        delete selFilters[isNotEmpty(filteredMinPrice) ? '' : 'priceStart'];
        delete selFilters[isNotEmpty(filteredMaxPrice) ? '' : 'priceEnd'];
        delete selFilters[isNotEmpty(filteredMinSize) ? '' : 'sizeStart'];
        delete selFilters[isNotEmpty(filteredMaxSize) ? '' : 'sizeEnd'];
        handleFiltersRequest(selFilters);
    };

    useEffect(() => {
        if (listingFilterSuccess.length) {
            setOpen(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess('GET_LISTINGS_BY_FILTERS'));
        }
        if (userListingFilterSuccess.length) {
            setOpen(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess('GET_LISTINGS_BY_USER_ID_FILTER'));
        }
    }, [listingFilterSuccess, userListingFilterSuccess]);

    useEffect(() => {
        if (selectedFilters) {
            const chosenFilters = {
                ...selectedFilters,
                propertyType: filteredPropTypes,
            };
            handleSelectedFilters(chosenFilters);
        }
    }, [selectedFilters]);

    const handleSubmitFilters = () => {
        const chosenFiltersInModal = {
            ...selectedFilters,
            propertyType: filteredPropTypes,
        };
        handleSelectedFilters(chosenFiltersInModal);
    };

    const handleModalClose = () => {
        if (hasBeenUsed) {
            handleSubmitFilters();
            setOpen(false);
        } else {
            setOpen(false);
        }
    };

    const FILTERS = [
        {
            filterTitle: 'newestOptionsFilter',
            hasBeenUsed: isNotEmpty(filteredNewest.label),
        },
        {
            filterTitle: 'propertyTypesFilter',
            hasBeenUsed: filteredPropTypes.length !== 0,
        },
        {
            filterTitle: 'fromToFilter',
            hasBeenUsed: isNotEmpty(filteredMinPrice) || isNotEmpty(filteredMaxPrice),
        },
        {
            filterTitle: 'fromToFilter',
            hasBeenUsed: isNotEmpty(filteredMinSize) || isNotEmpty(filteredMaxSize),
        },
    ];

    const FROM_TO_FILTERS = FILTERS.filter((filter) => filter.filterTitle === 'fromToFilter');
    const usedFilters = FILTERS.filter((filter) => filter.hasBeenUsed);
    const usedFromToFilters = FROM_TO_FILTERS.filter((filter) => filter.hasBeenUsed);

    return (
        <div className={classes.navBarFilteringBoxStyle}>
            <FilteringOptionsDropdown
                dropdownOptions={enumValues.NEWEST_OPTIONS}
                onPass={(filterSort) => handleSelectedFilterSort(filterSort, setFilters)}
                selected={filteredNewest}
                defaultSelected={enumValues.NEWEST_OPTIONS[0]}
                onClearSelected={() => clearSelectedFilterSort(setFilters)}
            />
            <FilteringCheckBoxDropdown
                dropdownOptions={propTypes}
                selectedCheckBoxes={filteredPropTypes}
                dropdownTitle={'Property Type'}
                onPass={(propType) => handleSelectedPropType(propType, setFilters)}
            />
            <>
                <FilteringFromToDropdown
                    passSelectedMin={(minPrice) => handleSelectedMinPrice(minPrice, setFilters)}
                    passSelectedMax={(maxPrice) => handleSelectedMaxPrice(maxPrice, setFilters)}
                    selectedMin={filteredMinPrice}
                    selectedMax={filteredMaxPrice}
                    dropdownTitle={'Price'}
                    onClearSelected={() => clearSelectedPrice(setFilters)}
                    measureUnit={'$'}
                    minInputPlaceholder={'Min $/SF/YR'}
                    maxInputPlaceholder={'Max $/SF/YR'}
                />
                <FilteringFromToDropdown
                    passSelectedMin={(minSize) => handleSelectedMinSize(minSize, setFilters)}
                    passSelectedMax={(maxSize) => handleSelectedMaxSize(maxSize, setFilters)}
                    selectedMin={filteredMinSize}
                    selectedMax={filteredMaxSize}
                    dropdownTitle={'Size'}
                    onClearSelected={() => clearSelectedSize(setFilters)}
                    measureUnit={'SF'}
                    minInputPlaceholder={'Min SF'}
                    maxInputPlaceholder={'Max SF'}
                />
            </>
            <FilterButton
                butnClassName={classes.filterMoreButnStyle}
                butnLabelClassName={classes.butnLabelStyle}
                onOpen={() => {
                    setOpen(true);
                }}
                filterButnLabel={'More Filters'}
                filteredItemsQuantity={usedFromToFilters.length}
            />
            <FilterButton
                butnClassName={classes.filterButnStyle}
                butnLabelClassName={classes.butnLabelStyle}
                onOpen={() => {
                    setOpen(true);
                }}
                filteredItemsQuantity={usedFilters.length}
            />
            <SimpleModal
                backdropCustom={classes.filterOverlayStyle}
                content={
                    <AdvancedSearch
                        handleClearAll={handleClearAll}
                        handleModalClose={handleModalClose}
                    />
                }
                openDefault={open}
                handleOpenClose={handleModalClose}
            />
        </div>
    );
};
