import {Images, Colors} from "utils";
import {makeStyles} from "@material-ui/core/styles";

export const footerStyle = makeStyles(
    () => ({
        footerStyle: {
            width: "100%",
            backgroundColor: Colors.BackgroundWhiteOff,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 0px 8px #00000029",
        },
        footerContainerStyle: {
            flexShrink: 0,
            maxWidth: "1440px",
            width: "100%",
            display: "none",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            padding: "13px 80px",
            height: '313px',
            "@media(min-width: 1279px)": {
                display: 'flex'
            },
        },
        footerContainerStyleMobile: {
            flexShrink: 0,
            maxWidth: "1440px",
            width: "100%",
            flexDirection: 'column',
            // justifyContent: "space-between",
            alignItems: "start",
            position: "relative",
            padding: "40px 16px",
            height: 'auto',
            display: 'flex',
            "@media(min-width: 1279px)": {
                display: "none",
            },
            "@media(min-width: 768px)": {
                padding: "40px 70px 81px",
            },
        },
        mobileFirstSection: {
            display: 'flex',
            margin: '24px 0 28px',
            justifyContent: 'space-between',
            width:'100%'
        },
        mobileLastSection: {
            display: 'flex',
            "@media(min-width: 320px)": {
                flexDirection: 'column'
            },
            "@media(min-width: 768px)": {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width:'100%'
            },
        },
        footerExtraInfoStyle: {
            display: "flex",
            alignItems: "center",
        },
        logoStyle: {
            cursor: "default",
            "& > h1": {fontSize: "15px"},
            "&::after": {
                width: "6px",
                height: "6px",
            },
        },
        copyrightTextStyle: {
            fontSize: "16px",
            fontWeight: 400,
            color: Colors.TextBlack,
            marginTop: "8px",
            textTransform: "capitalize",
        },
        privacyAndTermsStyle: {
            display: "flex",
            alignItems: "center",
            "& li": {
                fontSize: "14px",
                fontWeight: 500,
                color: Colors.TextGray,
                borderRight: "1px solid #75728080",
                textTransform: "capitalize",
                transition: "color 0.2s linear",
                cursor: "pointer",
                "&:hover": {color: Colors.TextBlack},

                // ** TEMPORARILY HIDDEN **
                // marginRight: "24px",
                // paddingRight: "24px",
                // ** end **

                // ** TEMPORARILY ADDED **
                "&:first-of-type": {
                    marginRight: "24px",
                    paddingRight: "24px",
                },
                // ** end **

                // ** TEMPORARILY ADDED **
                "&:last-of-type": {
                    borderRight: "none",
                },
                // ** end **

                "@media(max-width: 700px)": {
                    marginRight: "16px",
                    paddingRight: "16px",
                    "&:last-of-type": {
                        marginRight: "0px",
                        paddingRight: "0px",

                        // ** TEMPORARILY HIDDEN **
                        // borderRight: "none",
                        // ** end **

                    },
                },
                "& a": {
                    textDecoration: "none",
                    color: "inherit",
                },
            },
            "@media(max-width: 700px)": {
                position: "absolute",
                top: "18px",
                left: "16px",
            },
        },
        socialServicesListStyle: {

            // ** TEMPORARILY HIDDEN **
            // display: "flex",
            display: "none",
            // ** end **

            alignItems: "center",
            "& li:not(:last-of-type)": {marginRight: "16px"},
            "& li a i": {
                display: "block",
                width: "24px",
                height: "24px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                transition: "background-image 0.2s linear",
            },
        },
        facebookStyle: {
            backgroundImage: `url(${Images.facebook})`,
            "&:hover": {backgroundImage: `url(${Images.facebookHover})`},
        },
        twitterStyle: {
            backgroundImage: `url(${Images.twitter})`,
            "&:hover": {backgroundImage: `url(${Images.twitterHover})`},
        },
        instagramStyle: {
            backgroundImage: `url(${Images.instagram})`,
            "&:hover": {backgroundImage: `url(${Images.instagramHover})`},
        },
        followUs: {
            display: 'flex',
            alignItems: 'center',
            margin: '25px 0'
        },
        followTitle: {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
            marginRight: '28px',
        },
        rowFirstWrapper: {
            height: '152px',
            width: '270px',
        },
        rowWrapper: {
            width: '148px',
            height: '152px',
        },
        rowCompanyWrapper: {
            width: '148px',
            height: '152px',
            "@media(min-width: 320px)": {
                width: '148px',
            },
            "@media(min-width: 768px)": {
                width: '270px',
            },


        },
        liStyle: {
            marginBottom: '16px',
            '& a': {
                textDecoration: 'none',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#2B273C',
            }
        },
        liStyleSms: {
            marginBottom: '16px',

            '& a': {
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#2B273C',
                '& img': {
                    marginRight: '16px',
                }
            }
        },
        columnTitle: {
            marginBottom: '16px',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '27px',
            color: '#2B273C',
        },
        socialWrapper: {
            display: 'flex',
        },
        socialItem: {
            width: '32px',
            height: '32px',
            background: '#CECCCC',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '24px',
        },
        sendUsMessage:{
            background: "transparent",
            border: 'none',
            textDecoration:'underline',
            padding: 0,
            fontSize: '16px'
        }
    }),
    {index: 1}
);
