import {CREATE_PAYMENT, DELETE_PAYMENT, GET_INVOICES, GET_PAYMENT, IMPRESSION_REQUEST} from "./payment.types";

/** Payment Actions  */

export const createPayment = (body) => {
    return {
        type: CREATE_PAYMENT,
        payload: {body},
    };
};

export const getPayment = () => {
    return {
        type: GET_PAYMENT,
    };
};

export const getInvoices = () => {
    return {
        type: GET_INVOICES,
    };
};

export const deletePayment = (id) => {
    return {
        type: DELETE_PAYMENT,
        payload: {id},
    };
};

export const impression = (id) => {
    return {
        type: IMPRESSION_REQUEST,
        payload: {id},
    };
};

/** End  */