import axios from 'axios';
import { forEach } from 'lodash';

export const handleFileUpload = async (givenFile) => {
    if (!givenFile) return;

    const formData = new FormData();
    let endpoint = '';

    if (Array.isArray(givenFile)) {
        forEach(givenFile, (file) => formData.append('files', file));
        endpoint = '/files/uploadMany';
    } else {
        formData.append('files', givenFile);
        endpoint = '/files/upload';
    }

    return await axios.post(endpoint, formData, { auth: true });
};
