import {
    GET_LISTINGS_BY_USER_ID_FILTER_SUCCESS,
    PROMOTE_LISTING_SUCCESS,
    SET_LISTING_BY_ID,
    SET_LISTINGS,
    SET_LISTINGS_BY_USER_ID_SUCCESS
} from './listings.types';

const initialState = {
    listingList: [],
    listingById: null,
    listingsByUserId: [],
    creatorInfo: null,
};

const renderNewList = (list, payload) => {
        return list.map((i) => i?._id === payload?._id ? payload : i)
}

export const listingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LISTINGS:
            return {
                ...state,
                listingList: action.payload.listings,
            };
        case SET_LISTING_BY_ID:
            return {
                ...state,
                listingById: action.payload.listingById,
            };
        case SET_LISTINGS_BY_USER_ID_SUCCESS:
            return {
                ...state,
                listingsByUserId: action.payload.listingsByUserId,
            };
        case GET_LISTINGS_BY_USER_ID_FILTER_SUCCESS:
            return {
                ...state,
                listingsByUserId: action.payload,
            };


        case PROMOTE_LISTING_SUCCESS:
            return {
                ...state,
                listingsByUserId: renderNewList(state.listingsByUserId, action.payload) ,
            };

        default:
            return state;
    }
};
