import { makeStyles } from '@material-ui/core/styles';
import { Images, Colors } from 'utils';

export const navBarCoresStyle = makeStyles(
    () => ({
        navBarFilteringBoxStyle: {
            display: 'flex',
            alignItems: 'center',
            '& > div': { marginRight: '16px' },
        },
        sortByTitleStyle: {
            display: 'block',
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextBlack,
        },
        navBarActionsBoxStyle: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '24px',
            '& button': {
                marginLeft: '16px',
                outline: 'none',
                border: 'none',
                padding: '10px',
                borderRadius: '6px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'all 0.2s linear',
                backgroundColor: Colors.BackgroundWhiteOff,
                '&.active': {
                    backgroundColor: '#FF385C',
                },
                '& img': {
                    width: '100%',
                },
                '@media(max-width: 650px)': {
                    padding: '8px',
                },
            },
            '@media(max-width: 650px)': {
                marginRight: '0px',
            },
        },
        filterMoreButnStyle: {
            display: 'none',
            position: 'relative',
            border: '1px solid #757280',
            borderRadius: '20px',
            padding: '8px 24px 8px 16px',
            backgroundColor: 'inherit',
            '@media(max-width: 1440px)': {
                display: 'block',
            },
            '@media(max-width: 1200px)': {
                display: 'none',
            },
        },
        filterButnStyle: {
            display: 'none',
            position: 'relative',
            width: '40px',
            height: '40px',
            border: '1px solid #757280',
            backgroundColor: 'inherit',
            borderRadius: '6px',
            backgroundImage: `url(${Images.filter})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '24px 24px',
            backgroundPosition: 'center',
            '& > div': {
                top: '-8px',
            },
            '@media(max-width: 1200px)': {
                display: 'block',
            },
            '@media(max-width: 650px)': {
                width: '36px',
                height: '36px',
            },
        },
        sortByBoxStyle: {
            display: 'none',
            width: '100%',
            borderBottom: '1px solid #7572804D',
            paddingLeft: '24px',
            paddingRight: '24px',
            '&.sortByBox': { borderBottom: 'none', paddingTop: '32px' },
            '&.newestBox, &.checkBox': {
                '@media(max-width: 1200px)': {
                    display: 'block',
                },
            },
            '&.fromToBox': {
                '@media(max-width: 1440px)': {
                    display: 'block',
                },
            },
        },
        newestBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px 0',
        },
        filterOverlayStyle: {
            backgroundColor: 'rgba(100, 100, 100, 0.8)',
        },
        forLeaseButton: {
            marginLeft:'25px',
            marginTop:'25px',
            padding: '0 24px 0 16px',
            width: '129px',
            height: '48px',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: Colors.ThemePink,
            border: 'none',
            transition: 'background-color 0.2s linear',
            // '&:hover': {backgroundColor: '#d52f4c'},
            // '@media(max-width: 1024px)': {width: '56px'},
            '& span': {
                fontSize: '16px',
                fontWeight: 500,
                color: Colors.TextWhite,
            },
            '@media(max-width: 1279px)': {
                marginBottom: '12px',

            },
        },

    }),
    { index: 1 }
);
