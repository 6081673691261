import React, {useState} from 'react';
import {Step1, Step2, Step3, Step4} from "fragments";
import {Svg} from "assets/images";
import {howItWorksStyles} from "./styles";
import {useWidth} from "../../utils";
import {UserInputsDropdown} from "../../components";

export const HowItWorks = () => {
    const width = useWidth()
    const classes = howItWorksStyles();
    const [currentTab, setTabs] = useState('How to Create An Account Profile')

    const tabs = [
        {
            title: 'How to Create An Account Profile',
            Svg: Svg.ProfileSvg,
            steps: [
                {
                    title: 'Step   1',
                    description: 'Go to the homepage and click the “Sign In” button, then click "Sign Up"'
                },

                {
                    title: 'Step   2',
                    subTitle: 'Choose an account type',
                    description: 'Go to the homepage and click the “Sign In” button, then click "Sign Up"',
                },

            ]
        },
        {
            title: 'How to Create a Property Listing',
            Svg: Svg.ListSvg
        },
        {
            title: 'How to Quickly Find a Broker',
            Svg: Svg.AgentSvg
        },
        {
            title: 'How to Promote a Listing',
            Svg: Svg.MegaphoneSvg
        },
    ]

    const dropdownOptions = tabs.map(({title})=> title)

    const handleClickTabs = (value) => {
        setTabs(value)
    }

    return (
        <div className={classes.howItWorksPage}>
            <div className={classes.howItWorksContainer}>
                <h2 className={classes.howItWorksTitle}>
                    How It Works
                </h2>
                    <div className={classes.howItWorksContentWrapper}>
                        {width > 1279 &&
                            <div className={classes.tabsWrapper}>
                                {tabs.map(({Svg, title}, index) => (
                                    <div
                                        key={index}
                                        className={`${classes.tab} ${currentTab === title ? classes.activeTab : ''}`}
                                        onClick={() => handleClickTabs(title)}
                                    >
                                        <div className={classes.svgWrapper}>
                                            <Svg className={classes.tabSvg}/>
                                        </div>
                                        <p className={classes.text}>
                                            {title}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        }
                        {
                            width <= 1279 &&

                            <UserInputsDropdown
                                dropdownOptions={dropdownOptions}
                                onPass={setTabs}
                                selected={currentTab}
                            />
                        }

                        <div>
                            {currentTab === tabs[0].title
                                &&
                                <Step1/>
                            }

                            {currentTab === tabs[1].title
                                &&
                                <Step2/>
                            }

                            {currentTab === tabs[2].title
                                &&
                                <Step3/>
                            }

                            {currentTab === tabs[3].title
                                &&
                                <Step4/>
                            }

                        </div>
                    </div>

            </div>
        </div>
    );
};

