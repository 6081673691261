import React from 'react';
import { useHistory } from 'react-router';
import { formatPhoneNumber, handleCreatedAtDate, Images, makeCapitalize } from 'utils';
import { ViewMoreButton } from 'components';
import { listingDetailsCoreStyle } from './styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export const PhotosAndInfoBox = ({
    listingId,
    addressDisplay,
    price,
    size,
    user,
    createdAt,
    listingLocationUrl,
    listingImages = [],
    mainImage,

}) => {
    const classes = listingDetailsCoreStyle();
    const history = useHistory();

    const goToListingGalleryPage = () => {
        if (isMyListingsDetailsPage) {
            history.push(`/my_listings/listing_details-${listingId}/gallery`);
        } else {

            const pageEvent = {
                event: "VIEW_DETAILS",
                list_id: listingId,
            };
            window?.dataLayer.push(pageEvent);

            history.push(`/listings/listing_details-${listingId}/gallery`);
        }
    };

    const priceDisplay =
        price && price !== '0' ? (
            <>
                {`$${price}`} <em> SQ/YR </em>
            </>
        ) : (
            'Not Set'
        );
    const sizeDisplay =
        size.sizeStart && size.sizeEnd ? (
            <>
                {`${size.sizeStart} - ${size.sizeEnd}`} <em>sq.ft.</em>
            </>
        ) : size.sizeStart ? (
            <>
                {`Over ${size.sizeStart}`} <em>sq.ft.</em>
            </>
        ) : size.sizeEnd ? (
            <>
                {`Under ${size.sizeEnd}`} <em>sq.ft.</em>
            </>
        ) : (
            'Not Set'
        );

    const userFullNameDisplay = `${makeCapitalize(`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`)}`;
    const createdAtDisplay = handleCreatedAtDate(createdAt, 10, '/');

    const indicatorsAreShown = window.innerWidth <= 768;
    const isMyListingsDetailsPage = window.location.pathname === `/my_listings/listing_details-${listingId}`;

    const modifiedListingImages = listingImages.filter((image) => image.url !== mainImage?.url);
    modifiedListingImages.unshift(mainImage);

    return (
        <div className={classes.listingDetailsStyle}>
            <div className={`${classes.listingPhotosStyle} reactCarousel`}>
                {!listingImages.length ? (
                    <>
                        <div className={classes.listingDetailsBuildingStyle}>
                            <img src={Images.building} alt="" />
                        </div>
                        <div className={classes.smallInfoBoxStyle}>
                            <span className={classes.createdAtStyle}>{`Created: ${createdAtDisplay}`}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <Carousel
                            className={classes.carouselStyle}
                            showStatus={false}
                            showIndicators={indicatorsAreShown}
                            showThumbs={false}
                            showArrows={!indicatorsAreShown}>
                            {modifiedListingImages.map((image, index) => (
                                <div key={index} className={classes.photoStyle}>
                                    <img src={image.url} alt="listing" />
                                </div>
                            ))}
                        </Carousel>
                        <ViewMoreButton
                            butnClassName={classes.viewAllPhotosButnStyle}
                            onView={goToListingGalleryPage}
                            butnViewingText={'View all photos'}
                        />
                        <div className={classes.smallInfoBoxStyle}>
                            <span className={classes.createdAtStyle}>{`Created: ${createdAtDisplay}`}</span>
                            <ViewMoreButton
                                butnClassName={`${classes.viewAllPhotosButnStyle} smallDevice`}
                                onView={goToListingGalleryPage}
                                butnViewingText={'View all photos'}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className={classes.lisingInfoStyle}>
                <h2 className={classes.titleAndAddressStyle}>{addressDisplay}</h2>
                <ul className={classes.priceAndSizeStyle}>
                    <li>
                        <p className={classes.priceStyle}> Price </p>
                        <span>{priceDisplay}</span>
                    </li>
                    <li>
                        <p className={classes.sizeStyle}> Size </p>
                        <span>{sizeDisplay}</span>
                    </li>
                </ul>
                <ViewMoreButton
                    butnClassName={classes.viewOnMapButnStyle}
                    onView={() => window.open(listingLocationUrl, '_blank')}
                    butnViewingText={'View on Map'}
                />
                <hr className={classes.underlineStyle} />

                {user?.firstName &&
                    <div className={classes.userInfoStyle}>
                        <div className={classes.userImageStyle}>
                            <img src={user?.avatar?.url ? user?.avatar?.url : Images.userProfile} alt="user profile"/>
                        </div>
                        <h3 className={classes.userFullNameStyle}>{userFullNameDisplay}</h3>
                    </div>
                }

                <ul className={classes.userContactsListStyle}>
                    {user?.phone &&
                        <li>
                            <p>
                                Tell:
                                <a href={`tel:${user?.phone}`} target="_blank" rel="noreferrer noopener"
                                   className={classes.contactLinkStyle}>
                                    {formatPhoneNumber(user?.phone)}
                                </a>
                            </p>
                        </li>
                    }
                    {user?.email &&
                        <li>
                            <p>
                                Email:
                                <a href={`mailto:${user?.email}`} target="_blank" rel="noreferrer noopener"
                                   className={classes.contactLinkStyle}>
                                    {user?.email}
                                </a>
                            </p>
                        </li>
                    }
                </ul>
            </div>
        </div>
    );
};
