import {makeStyles} from "@material-ui/core/styles";


export const BrokersFragmentStyles = makeStyles(() => ({

    brokersFragmentWrapper: {
        padding: '50px 80px',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1440px',
        margin: '0 auto',
        minHeight: '100vh',
        '@media(min-width: 320px)': {
            padding: '24px 16px',
        },
        '@media(min-width: 768px)': {
            padding: '42px 70px',
        },
        '@media(min-width: 1280px)': {
            padding: '50px 80px',
        }
    },

    brokersWrapper: {
        height: '70vh',
        overflow: 'auto',
        width: '100%',
        padding: '5px',
    },

    breadcrumb: {
        display: 'flex',

        '& a': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#757280',
            textDecoration: 'none'
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
        }
    },

    title: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2B273C',
        marginBottom: '32px',
    },

    findWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '204px',
        height: '40px',
        background: '#F4F4F4',
        borderRadius: '24px',
        padding: '0 12px',
        marginBottom: '31px',
        '@media(min-width: 320px)': {
            width:'100%',
            maxWidth:'626px',
        },
        '@media(min-width: 1280px)': {
            width: '204px',
        }
    },

    searchInput: {
        height: '40px',
        width: '100%',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        marginLeft: '8px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#757280',
    },

    brokerItem: {
        height: 'auto',
        background: '#FFFFFF',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        padding: '16px',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',

        '@media(min-width: 320px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '@media(min-width: 1280px)': {
            flexDirection: 'row',
            alignItems: 'center',
        },

    },

    brokerInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '@media(min-width: 320px)': {
            marginBottom: '16px',
        },
        '@media(min-width: 1280px)': {
            marginBottom: '0',
            width: '310px'
        },


        '& p': {
            // fontWeight: '700',
            // fontSize: '18px',
            // lineHeight: '24px',
            // color: '#2B273C',
            // marginLeft: '16px',
        },
    },

    textRow: {
        width: '100%',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#2B273C',
        marginLeft: '16px',
    },

    grayItem: {
        display: 'flex',
        alignItems: 'center',
        height: '44px',
        width: '100%',
        background: '#F9F9F9',
        borderRadius: '4px',
        padding: '10px 12px',
        marginRight: '16px',

        '@media(min-width: 320px)': {
            marginBottom: '8px',
            marginRight: 0
        },
        '@media(min-width: 1280px)': {
            marginBottom: '0',
            marginRight: '16px',
        },
    },

    grayItemTitle: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        marginLeft: '10px'
    },

    grayItemSbTitle: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        marginLeft: '4px',

    },

    grayItemsWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '@media(min-width: 320px)': {
            flexDirection: 'column'
        },
        '@media(min-width: 1280px)': {
            flexDirection: 'row'
        },


        '& a': {
            textDecoration: 'none',
            color: '#2B273C',
            width: '100%',
            '@media(min-width: 1280px)': {
                marginRight: '16px',
            },

        },
    },

    brokerInfoFragmentWrapper: {
        maxWidth: '1440px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        '@media(min-width: 320px)': {
            padding: '0 16px',
        },
        '@media(min-width: 768px)': {
            padding: '0 32px',
        },
        '@media(min-width: 1280px)': {
            padding: '0 80px',
        },
    },

    grayBack: {
        background: '#F4F4F4',
        height: '100%',
    },

    whiteSpaces: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },

    whiteSpaceWrapper: {
        padding: '24px',
        width: '100%',
        maxWidth: '896px',
        height: 'auto',
        marginBottom: '24px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '12px',
        "@media(min-width: 320px)": {
            padding: '16px',
        },
        "@media(min-width: 768px)": {
            padding: '24px',
        },
        "@media(min-width: 1280px)": {
            marginBottom: '16px',
        },
    },

    contactMeWrapper: {
        '@media(min-width: 320px)': {
            width: '100%',
        },
        '@media(min-width: 768px)': {
            width: 'auto',
        },
    },

    brokerInfoImage: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',

        '@media(min-width: 320px)': {
            flexDirection: 'column'
        },
        '@media(min-width: 768px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',

        },
    },

    imageAndInfo: {
        display: 'flex',
        alignItems: 'center',

        '@media(min-width: 320px)': {
            flexDirection: 'column',
            textAlign: 'center',
        },
        '@media(min-width: 768px)': {
            flexDirection: 'row',
            textAlign: 'start',
        },

    },

    nameInfo: {
        marginLeft: '16px',
        '@media(min-width: 320px)': {
            marginLeft: 0,
        },
        '@media(min-width: 768px)': {
            marginLeft: '16px',
        }
    },

    name: {
        fontWeight: '800',
        fontSize: '24px',
        lineHeight: '36px',
        color: '#2B273C',
        '@media(min-width: 320px)': {
            marginTop: '16px'
        },
        '@media(min-width: 768px)': {
            marginTop: '0'
        },
    },

    license: {
        margin: '12px 0',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#757280',
    },

    company: {
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '24px',
        color: '#2B273C',
    },

    contactMe: {
        width: '128px',
        height: '40px',
        background: '#FE385C',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#FFFFFF',
        border: 'none',

        '@media(min-width: 320px)': {
            width: '100%',
            marginTop: '16px'
        },
        '@media(min-width: 768px)': {
            width: '128px',
            marginTop: '0'
        },
    },

    cardTitle: {
        fontWeight: '800',
        fontSize: '18px',
        lineHeight: '28px',
        color: '#2B273C',
        marginBottom: '26px',
    },

    contactItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '24px',

        '@media(min-width: 320px)': {
           marginLeft:'6px',
        },
        // '@media(min-width: 768px)': {
        //     width: '128px',
        //     marginTop: '0'
        // },

        '& img': {
            marginRight: '10px',
        },

        '& a': {
            textDecoration: 'none',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
        },
        '& p': {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2B273C',
        },
    },
    aboutBrokerInfo: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B273C',
        marginTop: '16px'
    },
    aboutMeWrapper: {
        marginBottom: '110px',
        width: '100%',
        maxWidth: '896px',
    },
    brokerAvatar: {
        width: '130px',
        height: '130px',
        borderRadius: '80px',
        objectFit: 'cover',
        '@media(min-width: 320px)': {
            width: '80px',
            height: '80px',
        },
        '@media(min-width: 768px)': {
            width: '102px',
            height: '102px',
        },
        '@media(min-width: 1280px)': {
            width: '130px',
            height: '130px',
        },
    },
    brokerListAvatar: {
        width: '50px',
        height: '50px',
        borderRadius: '40px',
        objectFit: 'cover',
    },
    noBrokersYet: {
        marginTop:'40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',

        '& p': {
            fontWeight: '800',
            fontSize: '24px',
            lineHeight: '36px',
            textAlign: 'center',
            color: '#2B273C',
            marginTop:'30px',
        }
    }
}))
