import { makeStyles } from "@material-ui/core/styles";

export const navStyle = makeStyles(
  () => ({
    navBarStyle: {
      width: "100%",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      marginBottom: "48px",
      "@media(max-width: 1200px)": { marginBottom: "32px" },
      "@media(max-width: 705px)": { marginBottom: "24px" },
      "@media(max-width: 650px)": { marginBottom: "84px" },
    },
    navBarContainerStyle: {
      maxWidth: "1440px",
      width: "100%",
      padding: "0px 80px",
      "@media(max-width: 1200px)": {
        paddingLeft: "70px",
        paddingRight: "70px",
      },
      "@media(max-width: 768px)": {
        paddingLeft: "37px",
        paddingRight: "37px",
      },
      "@media(max-width: 400px)": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    navBarUserActionBoxStyle: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "@media(max-width: 650px)": {
        flexDirection: "column",
      },
    },
    filterAndActionsBoxStyle: {
      width: "100%",
      flex: "1 1 auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }),
  { index: 1 }
);
