import {Loader} from "../loader";
import {buttonsStyle} from "./style";

export const CreateCancelButtons = ({cancelText, handleCancel, createText, handleCreate, loader}) => {
    const classes = buttonsStyle()

    return (
        <div className={classes.alignCenter}>
            <button className={classes.cancelButton} onClick={handleCancel}>{cancelText}</button>
            <button className={classes.createButton} onClick={handleCreate}>{
                loader?.length ?
                    <Loader type={'mini'} color={'white'}/>
                    :
                    createText
            }
            </button>
        </div>
    )
}