import React, { useContext } from 'react';
import {FindLoad, RegisterContext} from 'utils';
import { wrappersStyle } from './style';
import {Loader, SaveOrCancelButton, SendButton} from 'components';
import { useDispatch } from 'react-redux';
import { listingActions, userActions } from 'store';
import ReactFileReader from 'react-file-reader';

export const UserRegistrationWrapper = ({ title, children, avatarURL, user, onClose, onChangeAvatar, onRemoveAvatar, listingId, loader }) => {
    const classes = wrappersStyle();
    const dispatch = useDispatch();
    const delLoader = FindLoad('REMOVE_USER')
    const delListingLoader = FindLoad('REMOVE_LISTING')

    const registerCtx = useContext(RegisterContext);
    const { modalContent } = registerCtx;

    return (
        <div className={classes.userRegistrationStyle}>
            {modalContent === 'profilePicture' && (
                <div className={classes.profilePictureStyle}>
                    <img src={avatarURL} alt="avatar" />
                </div>
            )}
            <h2
                className={`${classes.registerTitleStyle} ${
                    modalContent === 'welcome' || modalContent === 'checkEmail'
                        ? 'welcome'
                        : modalContent === 'profilePicture'
                        ? 'profilePicture'
                        : modalContent === 'changePassword' || modalContent === 'deleteAccount'
                        ? 'changeDelete'
                        : ''
                }`}>
                {title}
            </h2>
            {children}
            <div>
                {modalContent === 'profilePicture' ? (
                    <div className={classes.pictureActionsStyle}>
                        <SendButton
                            butnClassName={classes.removeButnStyle}
                            butnType={'button'}
                            butnSendingText={'Remove'}
                            onClickButn={onRemoveAvatar}
                        />
                        <label className={classes.changeAvatarStyle}>
                            {loader ?
                                <Loader type={'mini'} color={'white'}/>
                                :
                            'Change'
                            }
                            <ReactFileReader handleFiles={onChangeAvatar}>
                                <></>
                            </ReactFileReader>

                        </label>
                    </div>
                ) : modalContent === 'deleteAccount' ? (
                    <SaveOrCancelButton
                        butnSavingText={'Delete'}
                        butnCancelling={'Cancel'}
                        onClearAll={onClose}
                        onSaveData={() => dispatch(userActions.removeUser(user?.id))}
                        loader={delLoader?.length}
                    />
                ) : modalContent === 'deleteListing' ? (
                    <SaveOrCancelButton
                        butnSavingText={'Delete'}
                        butnCancelling={'Cancel'}
                        onClearAll={onClose}
                        onSaveData={() => dispatch(listingActions.removeListing(listingId))}
                        loader={delListingLoader?.length}
                    />
                ) : null}
            </div>
        </div>
    );
};
