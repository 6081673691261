import React from 'react';
import { SendButton } from 'components';
import { userRegistrationCoreStyles } from './styles';

export const CheckEmail = ({ userEmail, closeModal }) => {
    const classes = userRegistrationCoreStyles();

    return (
        <>
            <h6 className={classes.registerInfoStyle}>
                We've sent a recovery email to
                <a href={`mailto:${userEmail}`} target="_blank" rel="noreferrer noopener" className={classes.userEmailStyle}>
                    {userEmail}
                </a>
                reset your password.
            </h6>
            <SendButton
                butnClassName={classes.doneButnStyle}
                butnType={'button'}
                butnDisabled={false}
                butnSendingText={'Ok'}
                onClickButn={closeModal}
            />
        </>
    );
};
