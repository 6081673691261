import React, { useContext } from 'react';
import { ViewAsButton } from 'components';
import { Images, ViewAsContext } from 'utils';
import { navBarCoresStyle } from './style';

export const NavBarActionsBox = () => {
    const classes = navBarCoresStyle();

    const { view, handleViewChange } = useContext(ViewAsContext);

    const galleryViewImgSrc = view === 'gallery' ? Images.galleryViewWhite : Images.galleryView;
    const galleryViewLabel = <img src={galleryViewImgSrc} alt="" />;

    const listViewImgSrc = view === 'list' ? Images.listViewWhite : Images.listView;
    const listViewLabel = <img src={listViewImgSrc} alt="" />;

    const mapViewImgSrc = view === 'map' ? Images.mapActive : Images.map;
    const mapViewLabel = <img src={mapViewImgSrc} alt="" />;

    return (
        <div className={classes.navBarActionsBoxStyle}>
            <ViewAsButton
                butnClassName={view === 'gallery' ? 'active' : ''}
                viewAsHandler={() => handleViewChange('gallery')}
                butnViewAsLabel={galleryViewLabel}
            />
            <ViewAsButton
                butnClassName={view === 'list' ? 'active' : ''}
                viewAsHandler={() => handleViewChange('list')}
                butnViewAsLabel={listViewLabel}
            />
            <ViewAsButton
                butnClassName={view === 'map' ? 'active' : ''}
                viewAsHandler={() => handleViewChange('map')}
                butnViewAsLabel={mapViewLabel}
            />
        </div>
    );
};
