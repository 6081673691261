

export const Colors ={

  // Backgrounds colors

  BackgroundDark :'#1C2331',
  BackgroundLight :'#F7F9FC',
  BackgroundLightGray :'#F5F5F5',
  BackgroundPrimary :'#E6ECF3',
  BackgroundWhite :'#FFFFFF',
  BackgroundWhiteOff: '#F4F4F4',
  BackgroundDarkWhite: '#F7F7F7', 

  // Text colors

  TextPrimary:'#51566D',
  TextSecondary:'#2A374E',
  TextWhite:'#FFFFFF',
  TextLight:'#51566D80',
  TextGray:'#757280',
  TextDarkGrey:'#2A374E80',
  TextWhiteBlue: '#438AFE4D',
  TextBlack: '#2B273C',
  TextBlack2: '#000',

  // Theme colors

  ThemeError:'#F07379',
  ThemeBlue:'#0073BB',
  ThemeWhiteBlue:'#9cb9f3',
  ThemeBorderBlue: '#438AFE80',
  ThemeYellow:'#ECC711',
  ThemeGreen:'#00C851',
  ThemeRed:'#D72525',
  ThemePurple:'#7B61EA',
  ThemeOrange:'#FFA330',
  ThemeGray:'#E6ECF380',
  ThemeDarkGray:'#E6ECF3',
  ThemeBorder:'#51566DB3',
  ThemeDarkBlue:'#3F51B5',
  ThemeTurquoise:'#00BCD4',
  ThemePink: '#FE385C',

}

export const Shadow ={
  normal: '0px 0px 12px #0052E01A',
  menuShadow: '0px 3px 6px #8A8A8A29',
  modalShadow: '0px 0px 6px #8A8A8A29',
  changeShadow: '0px 0px 6px #8A8A8A3D',
  blueButton: '0px 3px 6px #438AFE4D'
}

export const Backgrounds ={
  white: '#F7F9FC 0% 0% no-repeat padding-box',
  green: '#00C851 0% 0% no-repeat padding-box',
  gray:  '#E6ECF3 0% 0% no-repeat padding-box',
  hover: '#26b85d 0% 0% no-repeat padding-box',
  whiteModal: '#FFFFFF 0% 0% no-repeat padding-box',
  tableActive: '#E6ECF380 0% 0% no-repeat padding-box',
  tableActiveDark: '#E6ECF3 0% 0% no-repeat padding-box;',
  info: '#438AFE1A 0% 0% no-repeat padding-box',
  theme: '#438AFE 0% 0% no-repeat padding-box',
  themeDark: '#0000001A 0% 0% no-repeat padding-box',
  darkBlue : '#337BEFFF',

}

