import React, {useContext, useEffect, useRef, useState} from 'react';
import {dropdownStyle} from './style';
import {getLimitedVal, Images, initialCoordinates, isNotEmpty, ListingFiltersContext, ViewAsContext} from 'utils';
import axios from 'axios';
import {AddressInput} from '../address/address';

export const FilteringWithSearchBoxDropdown = ({
                                                   searchBoxClassName,
                                                   dropdownClassName,
                                                   defaultSearchText,
                                                   searchIcon,
                                                   searchedAddress,
                                                   onRequest,
                                                   customClass,
                                                   error,
                                                   searchType,
                                                   disabled,
                                               }) => {
    const classes = dropdownStyle();

    const addressInputRef = useRef();

    const [address, setAddress] = useState('');
    const [noAddress, setNoAddress] = useState(false);
    const [searchInputIsTouched, setSearchInputIsTouched] = useState(false);
    const [status, setStatus] = useState('');

    const isHomePage = window.location.pathname === '/';

    const {handleLatLngRequest, handleZoomRequest} = useContext(ListingFiltersContext);

    useEffect(() => {
        setAddress(searchedAddress);
    }, [searchedAddress]);

    const handleSearchAddressInput = (evt) => {
        if (evt.currentTarget.value === '') {
            setNoAddress(true);
        } else {
            setNoAddress(false);
        }
    };

    const handleSearchInputFocus = () => setSearchInputIsTouched(true);

    const handleSearchInputBlur = () => {
        if (noAddress && !isHomePage) {
            onRequest(address);
            handleLatLngRequest(initialCoordinates.lat, initialCoordinates.lng);
            handleZoomRequest(initialCoordinates.zoom);
        }

        setSearchInputIsTouched(false);
    };

    const handleChangeAddress = (value) => {
        if (isHomePage) {
            onRequest(value);
        }

        setAddress(value);
        setStatus('');
    };

    const handleSearchingStatus = (status) => setStatus(status);

    const handleSelectAddress = async (value) => {
        if (!isNotEmpty(value)) return;

        addressInputRef.current.blur();

        await axios.post(`/address`, {address: value})
            .then(function (response) {
                handleLatLngRequest(response.data.lat.toString(), response.data.lng.toString());

                handleZoomRequest(initialCoordinates.searchingZoom);

                setAddress(response.data.formattedAddress);

                onRequest(response.data.formattedAddress);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const dropdownIsShown = searchInputIsTouched && isNotEmpty(address);

    const addressDisplay = searchInputIsTouched || isHomePage ? address : getLimitedVal(address, 15);


    const { view, handleViewChange } = useContext(ViewAsContext);

    useEffect(() =>{
        if(!address) {
            handleLatLngRequest(initialCoordinates.lat, initialCoordinates.lng);
            handleZoomRequest(initialCoordinates.zoom);
        }
    },[view])

    return (
        <div className={customClass ? customClass : `${classes.searchContainerStyle} ${searchBoxClassName}`}>
            <div>
                <div
                    className={`${customClass ?
                        error ? classes.searchBoxStyleCustomError : classes.searchBoxStyleCustom 
                        :
                        classes.searchBoxStyle} ${searchInputIsTouched ? 'focused' : ''}`}
                >
                    {searchIcon && (
                        <div className={classes.searchIconStyle} onClick={() => addressInputRef.current.focus()}>
                            <img src={searchInputIsTouched ? Images.search : Images.searchRed} alt=""/>
                        </div>
                    )}
                    <AddressInput
                        disabled={disabled}
                        dropdownClassName={dropdownClassName}
                        inputRef={addressInputRef}
                        inputType="search"
                        inputPlaceholder={defaultSearchText}
                        onInputAddress={handleSearchAddressInput}
                        onFocusInput={handleSearchInputFocus}
                        onBlurInput={handleSearchInputBlur}
                        address={addressDisplay}
                        onChangeAddress={handleChangeAddress}
                        triggerStatus={handleSearchingStatus}
                        searchingStatus={status}
                        onSelectAddress={handleSelectAddress}
                        dropdownIsShown={dropdownIsShown}
                        searchType={searchType}
                    />
                </div>
            </div>
        </div>
    );
};
