import React from 'react';
import { FromToInput } from 'components';
import { modalContentsStyle } from './styles';

export const FilteringFromToInModal = ({
    passSelectedMin,
    passSelectedMax,
    selectedMin,
    selectedMax,
    minInputPlaceholder,
    maxInputPlaceholder,
}) => {
    const classes = modalContentsStyle();

    return (
        <div className={classes.filteringFromToInModalStyle}>
            <FromToInput
                inputValue={selectedMin ? selectedMin : ''}
                onInputChange={(evt) => passSelectedMin(evt.target.value)}
                inputPlaceholder={minInputPlaceholder}
            />
            <span> to </span>
            <FromToInput
                inputValue={selectedMax ? selectedMax : ''}
                onInputChange={(evt) => passSelectedMax(evt.target.value)}
                inputPlaceholder={maxInputPlaceholder}
            />
        </div>
    );
};
