import {promotionStyle} from "../styles";
import {FindLoad, Images} from "utils";
import {Loader, PaymentStartEnd} from "components";

export const RenewPromotion = ({handleClose, payment, handlePay, item}) => {
    const classes = promotionStyle()
    const loader = FindLoad('PROMOTE_LISTING')

    return (
        <div>
            <p className={classes.modalTitle}> Renew promotion?</p>
            <p className={classes.modalSubTitle}> Renew promotion we will add another 30 Days to your listing item.</p>
            <PaymentStartEnd
                startDate={item?.promotion?.startDate}
                endDate={item?.promotion?.endDate}
                renewPromotion={true}
            />
            <div className={classes.whiteWrapper}>
                <div className={classes.alignCenter}>
                    {payment?.card &&
                        <img
                            src={payment?.card === "mastercard" ? Images.master : Images.visa}
                            alt="icon"
                        />
                    }
                    <p className={classes.cardNumber}>{`**** **** **** ${payment?.last4}`}</p>
                </div>
            </div>
            <div className={classes.spaceBetweenRenew}>
                <button className={classes.cancel} onClick={handleClose}>Cancel</button>
                <button className={classes.payButton} onClick={handlePay}>
                    {loader?.length ?
                        <Loader type={'mini'} color={'white'}/>
                        :
                        'Pay'
                    }</button>
            </div>
        </div>
    )
}