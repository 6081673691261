import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const headerStyle = makeStyles(
    () => ({
        headerStyle: {
            width: "100%",
            backgroundColor: "#FAFAFA",
            boxShadow: "0px 2px 8px #0000001A",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            zIndex: 999,
        },
        headerContainerStyle: {
            maxWidth: "1440px",
            width: "100%",
            padding: "24px 80px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "@media(max-width: 1279px)": {
                padding: "24px 32px",
            },
            "@media(max-width: 768px)": {
                padding: "18px 16px",
            },
        },
        headerLogoStyle: {
            "@media(max-width: 400px)": {
                "&::after": {
                    width: "8px",
                    height: "8px",
                },
                "& > h1": {
                    fontSize: "18px",
                },
            },
        },
        menuOverlayStyle: {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            position: "fixed",
        },
        userProfileActionsBoxStyle: {
            display: "flex",
            alignItems: "center",

            "@media(max-width: 1279px)": {
                display: "none",
                height: "100vh",
                flexDirection: "column",
                position: "absolute",
                right: "0",
                transition: "width 0.4s ease-in-out",
                top: "94px",
                boxShadow: "-4px 0px 8px #0000001A",
                backgroundColor: "#FAFAFA",
                alignItems: "flex-start",
                padding: "32px",

                "@media(max-width: 767px)": {
                    top: "82px",
                },

                "&.show": {
                    width: "fit-content",
                    display: "flex",
                    marginTop: '-4px'
                },
            },

        },
        menuList: {
            display: 'flex',
            gap: '24px',
            paddingRight: '24px',
            marginRight: '24px',
            borderRight: `1px solid ${Colors.TextGray}`,

            "@media(max-width: 1279px)": {
                width: '100%',
                flexDirection: 'column',
                borderRight: 'none',
                borderBottom: `1px solid ${Colors.TextGray}`,
                paddingBottom: '24px',
            }
        },
        menuItem: {
            color: `${Colors.TextGray}`,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            cursor: 'pointer',
            '&:hover': {
                color: `${Colors.ThemePink}`,
            },
            '&.active-page': {
                color: `${Colors.ThemePink}`,
            }
        },
        buttonsInfoBlock: {
            display: 'flex',
            "@media(min-width: 1279px)": {
                alignItems: 'center',
            },
            '&.row-revers': {
                flexDirection: 'row-reverse',
                "@media(max-width: 1279px)": {
                    flexDirection: 'column',
                    gap: '24px',
                },
            },
            "@media(max-width: 1279px)": {
                flexDirection: 'column',
                gap: '24px',
            },

        },

        userName: {
            fontSize: "16px",
            fontWeight: "500",
            color: Colors.TextGray,
            marginRight: "30px",
        },
        createListingButnStyle: {
            width: "161px",
            color: `${Colors.ThemePink}`,
            backgroundColor: "inherit",
            marginRight: '16px',
            transition: "all 0.2s linear",
            "&:hover": {
                color: Colors.TextWhite,
                backgroundColor: Colors.ThemePink,
            },

            "@media(max-width: 1279px)": {
                width: "100%",
                maxWidth: '100%',
                margin: "21px 0 16px",
            },
        },

        findListingButnStyle: {
            width: "161px",
            color: `${Colors.ThemePink}`,
            backgroundColor: "inherit",
            transition: "all 0.2s linear",

            "&:hover": {
                color: Colors.TextWhite,
                backgroundColor: Colors.ThemePink,
            },
            "@media(max-width: 1279px)": {
                width: "100%",
                maxWidth: "100%",
                margin: "0",
            },
        },

        menuIconStyle: {
            display: "none",
            position: "relative",
            width: "42px",
            height: "42px",
            cursor: "pointer",
            "& div": {
                margin: "auto",
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                width: "32px",
                height: "16px",
                "@media(max-width: 400px)": {
                    width: "22px",
                    height: "12px",
                },
            },
            "& span": {
                borderRadius: "4px",
                position: "absolute",
                display: "block",
                width: "100%",
                height: "3px",
                backgroundColor: "#FF385C",
                transition: "all 0.3s ease-in-out",
                "&:first-of-type": {top: 0},
                "&:last-of-type": {bottom: 0},
            },

            "@media(max-width: 1279px)": {
                display: "block",
            },

            "&.active span:first-of-type": {
                transform: "rotate(45deg)",
                top: "8px",
                "@media(max-width: 400px)": {top: "4px"},
            },
            "&.active span:last-of-type": {
                transform: "rotate(-45deg)",
                bottom: "5px",
            },
        },
        checkboxStyle: {
            display: "block",
            width: "100%",
            height: "100%",
            cursor: "pointer",
            zIndex: 2,
            "-webkit-touch-callout": "none",
            position: "absolute",
            opacity: 0,
        },
    }),
    {index: 1}
);
