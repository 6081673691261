import user from '../assets/images/icons/user.svg';
import dropdownArrow from '../assets/images/icons/dropdownArrow.svg';
import galleryView from '../assets/images/icons/grid.svg';
import galleryViewWhite from '../assets/images/icons/gridWhite.svg';
import listView from '../assets/images/icons/list.svg';
import listViewWhite from '../assets/images/icons/listWhite.svg';
import checkedCheckmark from '../assets/images/icons/checkedCheckmark.svg';
import uncheckedCheckmark from '../assets/images/icons/uncheckedCheckmark.svg';
import clear from '../assets/images/icons/clear.svg';
import dropdownArrowBlack from '../assets/images/icons/dropdownArrowBlack.svg';
import clearSearch from '../assets/images/icons/clearSearch.svg';
import filterSmall from '../assets/images/icons/filterSmall.svg';

// import upDownArrows from '../assets/images/icons/upDownArrows.svg';
// import upDownArrowsBlue from '../assets/images/icons/upDownArrowsBlue.svg';
// import downUpArrows from '../assets/images/icons/downUpArrows.svg';
// import downUpArrowsBlue from '../assets/images/icons/downUpArrowsBlue.svg';
import upDownArrows from '../assets/images/icons/upDownArrows2.svg';
import upDownArrowsBlue from '../assets/images/icons/upDownArrowsBlue2.svg';
import downUpArrows from '../assets/images/icons/downUpArrows2.svg';
import downUpArrowsBlue from '../assets/images/icons/downUpArrowsBlue2.svg';

import close from '../assets/images/icons/close.svg';
import filter from '../assets/images/icons/filter.svg';
import facebook from '../assets/images/icons/facebook.svg';
import instagram from '../assets/images/icons/instagram.svg';
import twitter from '../assets/images/icons/twitter.svg';
import facebookHover from '../assets/images/icons/facebookHover.svg';
import instagramHover from '../assets/images/icons/instagramHover.svg';
import twitterHover from '../assets/images/icons/twitterHover.svg';
import address from '../assets/images/icons/address.svg';
import arrowLeft from '../assets/images/icons/arrowLeft.svg';
import arrowRight from '../assets/images/icons/arrowRight.svg';
import price from '../assets/images/icons/price.svg';
import size from '../assets/images/icons/size.svg';
import checkmark from '../assets/images/icons/checkmark.svg';
import userProfile from '../assets/images/icons/userProfile.svg';
import pin from '../assets/images/icons/pin.svg';
import arrowWhiteLeft from '../assets/images/icons/arrowWhiteLeft.svg';
import arrowWhiteRight from '../assets/images/icons/arrowWhiteRight.svg';
import closeWhite from '../assets/images/icons/closeWhite.svg';
import noListingsFound from '../assets/images/noListingsFound.svg';
import registerModalClose from '../assets/images/icons/registerModalClose.svg';
import registerModalIMage from '../assets/images/registerModalImage.svg';
import eyeOnIcon from '../assets/images/icons/eyeOnIcon.svg';
import eyeOffIcon from '../assets/images/icons/eyeOffIcon.svg';
import validCheckmark from '../assets/images/icons/validCheckmark.svg';
import invalidCheckmark from '../assets/images/icons/invalidCheckmark.svg';
import success from '../assets/images/icons/success.svg';
import error from '../assets/images/icons/error.svg';
import profile from '../assets/images/icons/profile.svg';
import listing from '../assets/images/icons/listing.svg';
import logOut from '../assets/images/icons/logOut.svg';
import arrowBlueUp from '../assets/images/icons/arrowBlueUp.svg';
import arrowBlueDown from '../assets/images/icons/arrowBlueDown.svg';
import emptyImage from '../assets/images/icons/emptyImage.svg';
import closeImage from '../assets/images/icons/closeImage.svg';
import password from '../assets/images/icons/password.svg';
import deletee from '../assets/images/icons/delete.svg';
import addPhoto from '../assets/images/icons/addPhoto.svg';
import edit from '../assets/images/icons/edit.svg';
import heroImage from '../assets/images/heroImage.svg';
import search from '../assets/images/icons/search.svg';
import searchCity from '../assets/images/icons/searchCity.svg';
import searchRed from '../assets/images/icons/searchRed.svg';
import diamond from '../assets/images/icons/diamond.svg';
import users from '../assets/images/icons/users.svg';
import listingList from '../assets/images/icons/listingList.svg';
import usersImage from '../assets/images/usersImage.svg';
import freeAccess from '../assets/images/icons/freeAccess.svg';
import management from '../assets/images/icons/management.svg';
import property from '../assets/images/icons/property.svg';
import account from '../assets/images/icons/account.svg';
import industrial from '../assets/images/industrial.png';
import retail from '../assets/images/retail.png';
import office from '../assets/images/office.png';
import more from '../assets/images/icons/more.svg';
import building from '../assets/images/icons/building.svg';
import pageNotFound from '../assets/images/pageNotFound.svg';
import smthWentWrong from '../assets/images/smthWentWrong.svg';
import map from '../assets/images/icons/map.svg';
import mapActive from '../assets/images/icons/mapActive.svg';
import zoomInIcon from '../assets/images/icons/zoomInIcon.svg';
import zoomOutIcon from '../assets/images/icons/zoomOutIcon.svg';
import homePageHero from '../assets/images/homePageHero.png';
import forLease from '../assets/images/icons/forLease.svg';
import arrowRightRed from '../assets/images/icons/arrowRightRed.svg';
import officeIcon from '../assets/images/officeIcon.png';
import rectangleIcon from '../assets/images/rectangleIcon.png';
import arrowBlackRight from '../assets/images/icons/arrowBlackRight.svg';
import signUpRectangle from '../assets/images/signUpRectangle.png';
import basicUser from '../assets/images/icons/basicUser.svg';
import brokerIcon from '../assets/images/icons/brokerIcon.svg';

import facebookFooter from '../assets/images/icons/facebookFooter.svg';
import linkedinFooter from '../assets/images/icons/linkedinFooter.svg';
import twitterFooter from '../assets/images/icons/twitterFooter.svg';
import sms from '../assets/images/icons/sms.svg';
import promotions from '../assets/images/icons/promotions.svg';

import statusUp from '../assets/images/icons/statusUp.svg';
import timerPause from '../assets/images/icons/timerPause.svg';
import timerStart from '../assets/images/icons/timerStart.svg';
import dollarSquare from '../assets/images/icons/dollarSquare.svg';
import visa from '../assets/images/icons/visa.svg';
import done from '../assets/images/icons/done.svg';
import showInvoice from '../assets/images/icons/showInvoice.svg';
import download from '../assets/images/icons/download.svg';
import noPromoted from '../assets/images/noPromoted.svg';
import cards from '../assets/images/icons/cards.svg';
import add from '../assets/images/icons/add.svg';
import promotedFill from '../assets/images/icons/promotedFill.svg';
import master from '../assets/images/icons/master.svg';
import userIcon from '../assets/images/icons/userIcon.svg';
import buildings from '../assets/images/icons/buildings.svg';
import callCalling from '../assets/images/icons/callCalling.svg';
import redSms from '../assets/images/icons/redSms.svg';
import brokerAvatar from '../assets/images/icons/brokerAvatar.svg';
import callCallingBlack from '../assets/images/icons/callCallingBlack.svg';
import medalStar from '../assets/images/icons/medalStar.svg';
import location from '../assets/images/icons/location.svg';
import searchBroker from '../assets/images/icons/searchBroker.svg';

export const Images = {
    searchBroker,
    master,
    promotedFill,
    add,
    cards,
    noPromoted,
    showInvoice,
    download,
    done,
    visa,
    dollarSquare,
    statusUp,
    timerPause,
    timerStart,
    promotions,
    location,
    medalStar,
    callCallingBlack,
    brokerAvatar,
    redSms,
    callCalling,
    buildings,
    userIcon,
    sms,
    facebookFooter,
    linkedinFooter,
    twitterFooter,
    brokerIcon,
    basicUser,
    signUpRectangle,
    arrowBlackRight,
    rectangleIcon,
    officeIcon,
    arrowRightRed,
    forLease,
    user,
    dropdownArrow,
    galleryView,
    galleryViewWhite,
    listView,
    listViewWhite,
    checkedCheckmark,
    uncheckedCheckmark,
    dropdownArrowBlack,
    clear,
    clearSearch,
    filterSmall,
    upDownArrows,
    upDownArrowsBlue,
    downUpArrows,
    downUpArrowsBlue,
    close,
    filter,
    facebook,
    instagram,
    twitter,
    facebookHover,
    instagramHover,
    twitterHover,
    address,
    arrowLeft,
    arrowRight,
    price,
    size,
    checkmark,
    userProfile,
    pin,
    arrowWhiteLeft,
    arrowWhiteRight,
    closeWhite,
    noListingsFound,
    registerModalClose,
    registerModalIMage,
    eyeOffIcon,
    eyeOnIcon,
    validCheckmark,
    invalidCheckmark,
    success,
    error,
    profile,
    listing,
    logOut,
    arrowBlueUp,
    arrowBlueDown,
    emptyImage,
    closeImage,
    password,
    deletee,
    addPhoto,
    edit,
    heroImage,
    search,
    searchCity,
    searchRed,
    diamond,
    users,
    listingList,
    freeAccess,
    usersImage,
    management,
    account,
    property,
    industrial,
    retail,
    office,
    more,
    building,
    pageNotFound,
    smthWentWrong,
    map,
    mapActive,
    zoomInIcon,
    zoomOutIcon,
    homePageHero
};
