/** Payment Actions  */
export const CREATE_PAYMENT = 'CREATE_PAYMENT'

export const GET_PAYMENT = 'GET_PAYMENT'
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS'

export const GET_INVOICES = 'GET_INVOICES'
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS'

export const DELETE_PAYMENT = 'DELETE_PAYMENT'

export const IMPRESSION_REQUEST = 'IMPRESSION_REQUEST'