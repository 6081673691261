import {call, put, takeLatest} from 'redux-saga/effects';
import {httpRequestsOnLoadActions} from '../http_requests_on_load';
import {httpRequestsOnErrorsActions} from '../http_requests_on_errors';
import {httpRequestsOnSuccessActions} from '../http_requests_on_success';
import {authService} from './listings.service';
import {
    CREATE_LISTING,
    EDIT_LISTING,
    GET_LISTINGS,
    GET_LISTING_BY_ID,
    REMOVE_LISTING,
    SET_LISTINGS,
    SET_LISTING_BY_ID,
    GET_LISTINGS_BY_USER_ID,
    GET_LISTINGS_BY_FILTERS,
    SET_LISTINGS_BY_USER_ID_SUCCESS,
    GET_LISTINGS_BY_USER_ID_FILTER,
    GET_LISTINGS_BY_USER_ID_FILTER_SUCCESS, PROMOTE_LISTING, PROMOTE_LISTING_SUCCESS,
} from './listings.types';

const user = JSON.parse(localStorage.getItem('userInfo'));

function* createListing({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.createListingService, payload.body);


        const pageEvent = {
            event: "CREATE_LISTING",
            user_id: user?.id,
        };
        window?.dataLayer.push(pageEvent);

        window.location.replace(`/my_listings`);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getListings({type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const {data} = yield call(authService.getListingsService);
        yield put({
            type: SET_LISTINGS,
            payload: {listings: data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* editListing({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        const _listingDetails = payload.body;

        const _listingImages = {
            mainImage: _listingDetails.mainImage,
            imagesToAdd: _listingDetails.imagesToAdd,
            imagesToRemove: _listingDetails.imagesToRemove,
        };

        delete _listingDetails.mainImage;
        delete _listingDetails.imagesToAdd;
        delete _listingDetails.imagesToRemove;

        yield call(authService.editListingService, payload.id, _listingDetails);
        yield call(authService.editListingImagesService, payload.id, _listingImages);
        window.location.replace('/my_listings');
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* removeListing({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.removeListingSevice, payload.id);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));

        const pageEvent = {
            event: "DELETE_LISTING",
            user_id: user?.id,
        };
        window?.dataLayer.push(pageEvent);

        window.location.replace('/my_listings');
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getListingById({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const {data} = yield call(authService.getListingByIdService, payload.id);
        yield put({
            type: SET_LISTING_BY_ID,
            payload: {listingById: data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getListingsByUserId({type}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const {data} = yield call(authService.getListingsByUserIdService);
        yield put({
            type: SET_LISTINGS_BY_USER_ID_SUCCESS,
            payload: {listingsByUserId: data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getListingsByUserIdFilter({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    if (payload?.loader !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    try {
        const res = yield call(authService.getListingsByUserIdServiceFilter, payload.filters);
        yield put({
            type: GET_LISTINGS_BY_USER_ID_FILTER_SUCCESS,
            payload: res.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* getListingsByFilters({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const {data} = yield call(authService.getListingsByFiltersService, payload.filters);

        yield put({
            type: SET_LISTINGS,
            payload: {listings: data},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put({
            type: SET_LISTINGS,
            payload: {listings: []},
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/** Promote */

function* promoteSaga({type, payload}) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.promoteService, payload.id, payload.body);
        const user = JSON.parse(localStorage.getItem('userInfo'));

        if (payload?.type === 'NON_PROMOTED'){
            const pageEvent = {
                event: "PROMOTE",
                user_id: user?.id,
                action: "PROMOTE"
            };
            window?.dataLayer.push(pageEvent);
        }else{
            const pageEvent = {
                event: "RE_PROMOTE",
                user_id: user?.id,
                action: "RE_PROMOTE"
            };
            window?.dataLayer.push(pageEvent);
        }

        yield put({
            type: PROMOTE_LISTING_SUCCESS,
            payload: res?.data?.listingDto,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

/** End */

export const watchListing = function* watchListingSaga() {
    yield takeLatest(CREATE_LISTING, createListing);
    yield takeLatest(GET_LISTINGS, getListings);
    yield takeLatest(EDIT_LISTING, editListing);
    yield takeLatest(REMOVE_LISTING, removeListing);
    yield takeLatest(GET_LISTING_BY_ID, getListingById);
    yield takeLatest(GET_LISTINGS_BY_USER_ID, getListingsByUserId);
    yield takeLatest(GET_LISTINGS_BY_USER_ID_FILTER, getListingsByUserIdFilter);
    yield takeLatest(GET_LISTINGS_BY_FILTERS, getListingsByFilters);
    /** Promote */
    yield takeLatest(PROMOTE_LISTING, promoteSaga);
    /** End */
};
