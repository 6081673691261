import React, { useState } from 'react';
import {enumValues, initialCoordinates} from '..';

export const ListingFiltersContext = React.createContext({
    filters: {},
    mapCenter: { lat: '', lng: '' },
    zoom: 0,
    selectedPropType: '',
    address: '',
    handleFiltersRequest: () => {},
    handleLatLngRequest: () => {},
    handleZoomRequest: () => {},
    handlePropTypeSelected: () => {},
    handleAddressRequest: () => {},
    reselAllFilters: () => {},
    propTypes: enumValues.PROPERTY_TYPES,
    setPropTypes: '',

    filteredMinPrice: '', setFilteredMinPrice: '',
    filteredMaxPrice: '', setFilteredMaxPrice: '',
    filteredMinSize: '', setFilteredMinSize: '',
    filteredMaxSize: '', setFilteredMaxSize: ''
});

export const ListingFiltersContextProvider = ({ children }) => {
    const [filters, setFilters] = useState({});
    const [mapCenter, setMapCenter] = useState({ lat: initialCoordinates.lat, lng: initialCoordinates.lng });
    const [zoom, setZoom] = useState(initialCoordinates.zoom);
    const [selectedPropType, setSelectedProptype] = useState('');
    const [address, setAddress] = useState('');
    const [propTypes, setPropTypes] = useState(enumValues.PROPERTY_TYPES)

    const [filteredMinPrice, setFilteredMinPrice] = useState();
    const [filteredMaxPrice, setFilteredMaxPrice] = useState();
    const [filteredMinSize, setFilteredMinSize] = useState();
    const [filteredMaxSize, setFilteredMaxSize] = useState();

    const handleFiltersRequest = (filters) => setFilters(filters);
    const handleLatLngRequest = (lat, lng) => setMapCenter({ lat, lng });
    const handleZoomRequest = (zoom) => setZoom(zoom);
    const handlePropTypeSelected = (propType) => setSelectedProptype(propType);
    const handleAddressRequest = (address) => setAddress(address);
    const reselAllFilters= () => {
        setFilters({ });
        setAddress('');
        setSelectedProptype('')
        // setSelectedProptype('')
    }


    const context = {
        filters,
        mapCenter,
        zoom,
        selectedPropType,
        address,
        handleFiltersRequest,
        handleLatLngRequest,
        handleZoomRequest,
        handlePropTypeSelected,
        handleAddressRequest,
        reselAllFilters,
        propTypes,
        setPropTypes,

        filteredMinPrice, setFilteredMinPrice,
        filteredMaxPrice, setFilteredMaxPrice,
        filteredMinSize, setFilteredMinSize,
        filteredMaxSize, setFilteredMaxSize
    };

    return <ListingFiltersContext.Provider value={context}>{children}</ListingFiltersContext.Provider>;
};
