import { makeStyles } from "@material-ui/core/styles";
import { Colors, Images } from "utils";

export const addressStyle = makeStyles(
  () => ({
    addressInputStyle: {
      outline: "none",
      border: "none",
      backgroundColor: "inherit",
      fontSize: "14px",
      fontWeight: 500,
      color: Colors.TextGray,
      width: "100%",
      "&::-webkit-search-cancel-button": {
        position: "relative",
        right: 0,
        appearence: "none",
        "-webkit-appearance": "none",
        height: "24px",
        width: "24px",
        borderRadius: "50%",
        backgroundImage: `url(${Images.clearSearch})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        cursor: "pointer",
      },
    },
    addressDropdownStyle: {
      position: "absolute",
      left: 0,
      width: "250px",
      backgroundColor: Colors.BackgroundWhite,
      boxShadow: "0px 0px 6px #00000029",
      borderRadius: "12px",
      paddingTop: "12px",
      paddingBottom: "12px",
      top: "48px",
      zIndex: 999,
      "&.dropdownInModal": {
        width: "372px",
        "@media(max-width: 768px)": {
          maxWidth: "247px",
        },
      },
      "&.filteringFromTo": {
        width: "376px",
        padding: "24px",
        top: "48px",
        transition: "all 0.1s linear",
        display: "flex",
        alignItems: "center",
        "& span": {
          fontSize: "16px",
          fontWeight: 500,
          color: Colors.TextBlack,
          marginLeft: "16px",
          marginRight: "16px",
        },
        "& input": {
          maxWidth: "140px",
          width: "100%",
        },
      },
      "&.filteringAddress": {
        width: "380px",
        backgroundColor: Colors.BackgroundWhite,
        boxShadow: "0px 0px 6px #00000029",
        borderRadius: "12px",
        paddingTop: "12px",
        paddingBottom: "12px",
        top: "48px",
        zIndex: 999,
        "@media(max-width: 650px)": {
          width: "100%",
        },
      },
    },
    addressOptionsStyle: {
      transition: "all 0.1s linear",
      cursor: "default",
      padding: "10px 16px",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        backgroundColor: Colors.BackgroundWhiteOff,
      },
      zIndex: 999,
    },
    addressOptionTitleStyle: {
      fontSize: "14px",
      fontWeight: 500,
      color: Colors.TextGray,
      "&.selectedTitle": {
        color: "#3377CC",
      },
    },
  }),
  { index: 1 }
);
