import { Switch } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { Backgrounds, Colors } from "./globalColors";

export const useGlobalStyles = makeStyles(
  () => ({
    tableStyle: {
      background: "red",
      height: "100%",
      minHeight: "100vh",
      width: "100%",
      boxShadow: "0px 0px 12px #0052E01A",
      borderRadius: "8px",
      marginTop: "16px",
      marginBottom: "24px",
      "& .MuiTableContainer-root": {
        boxShadow: "none",
      },
    },
    tableWrapper: {
      height: "100%",
      minHeight: "80vh",
      width: "100%",
      boxShadow: "0px 0px 12px #0052E01A",
      borderRadius: "8px",
      marginTop: "16px",
      marginBottom: "24px",
      "& .MuiTableContainer-root": {
        boxShadow: "none",
      },
    },
    tableWrapperSmallHeight: {
      height: "100%",
      minHeight: "60vh",
      width: "100%",
      boxShadow: "0px 0px 12px #0052E01A",
      borderRadius: "8px",
      marginTop: "24px",
      marginBottom: "24px",
      "& .MuiTableContainer-root": {
        boxShadow: "none",
      },
    },
    tableWrapperSmall: {
      height: "100%",
      width: "100%",
      boxShadow: "0px 0px 12px #0052E01A",
      borderRadius: "8px",
      marginTop: "16px",
      marginBottom: "24px",
      "& .MuiTableContainer-root": {
        boxShadow: "none",
      },
    },

    simpleInput: {
      marginRight: "30px",
      width: "100%",
      "@media (min-width: 1919px)": {
        marginRight: "56px",
      },
    },
    simpleInputFull: {
      width: "100%",
    },

    InfoAndImage: {
      display: "flex",
      alignItems: "center",
      "& p": {
        fontSize: "14px",
        lineHeight: "19px",
        fontWeight: "bold",
        color: Colors.TextSecondary,
      },
      "& img": {
        width: "24px",
        height: "24px",
        marginRight: "4px",
        "@media (min-width: 1920px)": {
          marginRight: "8px",
        },
      },
    },

    buttonsStyle: {
      marginTop: "14px",
    },

    createOfficeTableHead: {
      display: "flex",
      alignItems: "center",

      "& p": {
        fontSize: "16px",
        fontWeight: "bold",
        color: Colors.TextSecondary,

        "@media (max-width: 1919px)": {
          marginLeft: "4px",
        },
        "@media (min-width: 1920px)": {
          marginLeft: "8px",
        },
      },
    },

    spaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },

    centerItem: {
      display: "flex",
      alignItems: "center",
    },

    fullScreen: {
      display: "flex",
      width: "100%",
      height: "100%",
    },

    flexAble: {
      display: "flex",
    },

    fullWidth: {
      width: "100%",
    },

    deleteModal: {
      width: "500px",
      height: "auto",
      background: Backgrounds.whiteModal,
      borderRadius: "8px",
      padding: "8px 0 40px 0",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },

    tableContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "110vh",
      "@media (min-width: 1919px)": {
        height: "85vh",
      },
    },

    item: {
      background: Backgrounds.tableActive,
      borderRadius: "4px",
      height: "48px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 16px",
      marginBottom: "8px",
      cursor: "pointer",
    },
    activeItem: {
      background: Backgrounds.tableActiveDark,
      borderRadius: "4px",
      height: "48px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 16px",
      marginBottom: "8px",
      cursor: "pointer",
    },
    inputTextFieldBlue: {
      width: "100%",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: Colors.ThemeBlue,
      },
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        {
          borderColor: Colors.ThemeWhiteBlue,
        },
      "& .MuiFormLabel-root.Mui-disabled": {
        color: `${Colors.ThemeWhiteBlue}!important`,
      },
      "& .MuiOutlinedInput-root": {
        height: "48px",
      },
      "& .MuiInputLabel-outlined": {
        marginTop: "-3px",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(14px, -2px) scale(0.75)",
        color: Colors.ThemeBlue,
      },
    },
  }),
  { index: 1 }
);

export const useGlobalTextStyles = makeStyles(
  () => ({
    modalTitle: {
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "43px",
      color: Colors.TextSecondary,
    },

    modalText: {
      color: Colors.TextPrimary,
      fontSize: "16px",
      lineHeight: "24px",
      margin: "16px 0 20px 0",
      "@media (min-width: 1920px)": {
        margin: "16px 0 40px 0",
      },
    },

    orText: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "600",
      textAlign: "center",
      margin: "16px 0",
      "@media (min-width: 1920px)": {
        margin: "24px 0",
      },
    },
  }),
  { index: 1 }
);

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 16,
    padding: 0,
    display: "flex",
    marginTop: 0,
    marginLeft: "8px",
  },
  switchBase: {
    paddingTop: "2.2px",
    padding: 3,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(14px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: Colors.ThemeBlue,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    marginTop: 0,
    boxShadow: "none",
  },
  track: {
    border: "none",
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
  },
  checked: {},
}))(Switch);
