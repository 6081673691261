import React, { useState } from "react";

export const RegisterContext = React.createContext({
  modalContent: "",
  handleModalContent: () => {},
});

export const RegisterContextProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState("signIn");

  const handleModalContent = (content) => setModalContent(content);

  return (
    <RegisterContext.Provider value={{modalContent, handleModalContent}}>
      {children}
    </RegisterContext.Provider>
  );
};
