import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {Svg} from "assets/images";
import {homeFragmentStyles} from "../styles";

export const Faqs = () => {
    const [expanded, setExpanded] = useState(null);

    const classes = homeFragmentStyles();

    const items = [
        {
            title: 'What types of properties can I list on Croill?',
            description: 'You can list various types of commercial properties including retail spaces, office spaces,' +
                ' and industrial estates. Our platform is designed to cater to a wide range of commercial real estate ' +
                'needs.'
        },
        {
            title: 'How long does it take to create an account on Croill.com?',
            description: 'Creating an account on Croill.com is quick and easy, taking just two minutes. This account' +
                ' will link directly to your property listings, providing a seamless management experience.',
        },
        {
            title: 'Are there any fees associated with listing or promoting my property on Croill.com?',
            description: 'Listing your property on Croill.com is completely free. However, additional promotional' +
                ' features to improve the visibility of your listings are not free, see the Promotions section.',
        },
        {
            title: 'What types of commercial spaces are available for lease on Croill.com?',
            description: 'We offer a variety of commercial spaces including industrial, office, and retail options,' +
                ' each customized to meet specific business needs and designed to enhance the growth and efficiency ' +
                'of diverse business models.',
        },
        {
            title: 'What kind of support does Croill.com offer?',
            description: 'We offers extensive support to ensure you can manage your listings and accounts ' +
                'effectively. For immediate assistance, you can reach our customer service team via email at' +
                ' info@croill.com. ',
        },
        {
            title: 'Can I list multiple properties under one account on Croill.com?',
            description: 'Yes, you can list multiple properties under a single account.',
        },
        {
            title: 'How do I update or edit a listing on Croill.com?',
            description: 'To update or edit a listing, log in to your account, navigate to "My Listings," select the ' +
                'listing you want to update, and click "Edit." Make the necessary changes and save them to ' +
                'update your listing immediately.',
        },
    ]

    const handleChange = (id) => {
        const checkId = expanded === id ? null : id;

        if (id) {
            setExpanded(checkId);
        }
    }
    return (
        <div className={classes.thirdBoxStyle}>
            <h2 className={classes.fAQTitle}>
                FAQs
            </h2>

            <div className={classes.accordionWrapper}>
                {items?.map(({title, description}, i) => (
                    <Accordion
                        className={classes.accordion}
                        key={i}
                        onChange={() => handleChange(i+1)}
                        expanded={expanded === i+1}
                    >
                        <AccordionSummary
                            className={classes.accordionSummary}
                            expandIcon={<Svg.ArrowRightSvg/>}
                            aria-controls="panel-content"
                            id="panel-header"
                        >
                            <p className={classes.accordionTitle}>
                                {title}
                            </p>
                        </AccordionSummary>

                        <AccordionDetails>
                            <p className={classes.accordionDescription}>
                                {description}
                            </p>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    );
};

