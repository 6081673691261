import React from 'react';
import { ListingInputsWrapper } from 'components';
import { commonStyle } from './styles';
import { Images } from 'utils';

export const ContactInfo = () => {
    const classes = commonStyle();

    const user = JSON.parse(localStorage.getItem('userInfo'));
    const userFullName = user && `${user.firstName} ${user.lastName}`;

    const userProfileInputs = [
        { profileLabel: 'Email Address', profileInput: user && user.email },
        { profileLabel: 'Phone Number', profileInput: user && user.phone },
    ];

    return (
        <ListingInputsWrapper inputsTitle={'Contact Info'}>
            <div className={classes.userInfoStyle}>
                <img
                    className={classes.userProfileImageStyle}
                    src={user && user.avatar ? user.avatar.url : Images.userProfile}
                    alt="user profile"
                />
                <h3 className={classes.userFullNameStyle}> {userFullName} </h3>
            </div>
            <div>
                {userProfileInputs.map((inputs, index) => {
                    return (
                        <div key={index} className={classes.profileInfoBox}>
                            <h6 className={classes.profileLabelStyle}>{inputs.profileLabel}</h6>
                            <div className={classes.profileInputStyle}>
                                <span className={classes.infoStyle}>{inputs.profileInput}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </ListingInputsWrapper>
    );
};
