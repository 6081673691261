import { SET_USERS, SET_USER_BY_ID } from "./users.types";

const initialState = {
  userList: [],
  userById: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        userList: action.payload.users,
      };
    case SET_USER_BY_ID:
      return {
        ...state,
        userById: action.payload.userById,
      };
    default:
      return state;
  }
};
