import {Images, Colors} from 'utils';
import {makeStyles} from '@material-ui/core/styles';

export const listingDetailsCoreStyle = makeStyles(() => ({
        listingGalleryBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        listingGalleryBoxContainerStyle: {
            maxWidth: '1440px',
            width: '100%',
            padding: '0px 80px 128px',
            '@media(max-width: 1200px)': {
                paddingLeft: '70px',
                paddingRight: '70px',
            },
            '@media(max-width: 768px)': {
                paddingLeft: '37px',
                paddingRight: '37px',
            },
            '@media(max-width: 400px)': {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        listingDetailsStyle: {
            width: '100%',
            display: 'flex',
            '@media(max-width: 1130px)': {
                flexWrap: 'wrap',
                justifyContent: 'center',
            },
        },
        listingPhotosStyle: {
            width: '100%',
            position: 'relative',
            '&.reactCarousel .slider-wrapper.axis-horizontal .slider .slide': {
                maxWidth: '628px',
                height: '420px',
                '& > div > img': {
                    width: '100%',
                    height: '420px',
                    objectFit: 'cover',
                },
            },
            '&.reactCarousel .carousel.carousel-slider .control-arrow': {
                transition: 'background-color 0.1s linear',
                top: '50%',
                opacity: 1,
                transform: 'translateY(-50%)',
                width: '40px',
                height: '40px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '20px',
                backgroundPosition: 'center',
                backgroundColor: Colors.BackgroundWhite,
                borderRadius: '50%',
                '&:first-of-type': {
                    backgroundImage: `url(${Images.arrowLeft})`,
                    left: '8px',
                },
                '&:last-of-type': {
                    backgroundImage: `url(${Images.arrowRight})`,
                    right: '8px',
                },
                '&:hover': {backgroundColor: Colors.BackgroundWhiteOff},
                '&:before': {display: 'none'},
            },
        },
        carouselStyle: {width: '100%'},
        photoStyle: {
            width: '100%',
            borderRadius: '16px',
            overflow: 'hidden',
            '& img': {width: '100%'},
        },
        viewAllPhotosButnStyle: {
            position: 'absolute',
            top: '16px',
            left: '15px',
            backgroundColor: Colors.BackgroundWhite,
            color: '#0273BB',
            maxWidth: '155px',
            border: 'none',
            textAlign: 'center',
            transition: 'background-color 0.1s linear',
            '&:hover': {backgroundColor: Colors.BackgroundWhiteOff},
            '@media(max-width: 768px)': {
                display: 'none',
            },
            '&.smallDevice': {
                position: 'unset',
                opacity: 1,
                textDecoration: 'underline',
                padding: 0,
                textAlign: 'right',
                display: 'none',
                '@media(max-width: 768px)': {
                    display: 'block',
                },
            },
        },
        smallInfoBoxStyle: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
        },
        createdAtStyle: {
            display: 'inline-block',
            fontSize: '14px',
            fontWeight: 500,
            color: Colors.TextGray,
        },
        lisingInfoStyle: {
            width: '100%',
            marginLeft: '24px',
            paddingBottom: '48px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '@media(max-width: 1130px)': {
                marginLeft: 0,
                marginTop: '28px',
                paddingBottom: 0,
            },
            '@media(max-width: 500px)': {
                marginTop: '20px',
            },
        },
        titleAndAddressStyle: {
            fontSize: '24px',
            fontWeight: 600,
            color: '#0273BB',
        },
        priceAndSizeStyle: {
            margin: '24px 0',
            '& li:first-of-type': {marginBottom: '16px'},
            '& li p': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontWeight: 600,
                color: Colors.TextBlack,
                '&:before': {
                    content: "''",
                    display: 'block',
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                },
            },
            '& li span': {
                display: 'inline-block',
                margin: '4px 32px',
                fontSize: '16px',
                color: Colors.TextGray,
                fontWeight: 600,
                '& em': {
                    fontSize: '14px',
                    fontWeight: 500,
                },
            },
            '& li:last-of-type span': {fontWeight: 500},
        },
        priceStyle: {
            '&:before': {backgroundImage: `url(${Images.price})`},
        },
        sizeStyle: {
            '&:before': {backgroundImage: `url(${Images.size})`},
        },
        viewOnMapButnStyle: {
            backgroundColor: Colors.ThemePink,
            color: Colors.TextWhite,
            maxWidth: '300px',
            transition: 'background-color 0.1s linear',
            '&:hover': {backgroundColor: '#d52f4c'},
            '@media(max-width: 500px)': {
                maxWidth: '100%',
            },
        },
        underlineStyle: {margin: '24px 0'},
        userInfoStyle: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '26px'
        },
        userImageStyle: {
            width: '52px',
            height: '52px',
            border: '2px solid #FFFFFF',
            borderRadius: '40px',
            filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161))',
            '& img': {
                width: '48px',
                height: '48px',
                borderRadius: '40px',
                objectFit: 'cover',
            },
        },
        userFullNameStyle: {
            fontSize: '20px',
            fontWeight: 600,
            color: '#0273BB',
            marginLeft: '26px',
        },
        userContactsListStyle: {
            '& li': {
                fontSize: '16px',
                fontWeight: 500,
                color: Colors.TextBlack,
                marginBottom: '8px',
            },
        },
        contactLinkStyle: {
            color: Colors.TextGray,
            textDecoration: 'none',
            marginLeft: '8px',
        },
        galleryStyle: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(302px, 1fr))',
            gridAutoRows: '302px',
            gridGap: '24px',
            '@media(max-width: 701px)': {gridAutoRows: '502px'},
            '@media(max-width: 601px)': {gridAutoRows: '402px'},
            '@media(max-width: 501px)': {gridAutoRows: '302px'},
        },
        photoContainerStyle: {
            position: 'relative',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            },
        },
        photoOverlayStyle: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 9,
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            backgroundColor: '#0000001A',
            transition: 'background-color 0.2s linear',
            '&:hover': {backgroundColor: '#00000066'},
        },
        photoModalStyle: {
            backgroundColor: '#2B273C',
            maxWidth: '100%',
            width: '100%',
            height: '100%',
            position: 'fixed',
            padding: '80px',
            top: 0,
            left: 0,
            zIndex: 9999,
            '@media(max-width: 860px)': {
                padding: '24px',
            },
            '@media(max-width: 500px)': {
                padding: ' 24px 0',
            },
        },
        photoModalContentStyle: {
            backgroundColor: '#2B273C',
            position: 'relative',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 99999,
            maxWidth: '1200px',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .carousel .slider-wrapper': {
                width: '628px',
                height: '414px',
                '@media(max-width: 1200px)': {
                    width: '628px',
                    height: '414px',
                },
                '@media(max-width: 600px)': {
                    width: '343px',
                    height: '224px',
                },
            },
            '& .carousel.carousel-slider .control-arrow': {
                transition: 'opacity 0.1s linear',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '36px',
                height: '36px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '20px',
                backgroundPosition: 'center',
                border: '2px solid #FFFFFF',
                borderRadius: '50%',
                '&:first-of-type': {
                    backgroundImage: `url(${Images.arrowWhiteLeft})`,
                },
                '&:last-of-type': {
                    backgroundImage: `url(${Images.arrowWhiteRight})`,
                },
                '&:before': {display: 'none'},
            },
        },
        closeButnStyle: {
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'inherit',
            border: 'none',
            outline: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextWhite,
            '& img': {marginRight: '8px'},
            '@media(max-width: 860px)': {left: '50px'},
        },
        carouselContainerStyle: {
            paddingTop: '47px',
            position: 'relative',
            maxWidth: '800px',
            width: '100%',
            maxHeight: '656px',
            '& .carousel .slider-wrapper.axis-horizontal .slider .slide': {
                height: '420px',
                '& > div': {maxHeight: '100%', height: '100%'},
                '@media(max-width: 600px)': {
                    width: '343px',
                    height: '224px',
                },
            },
        },
        photoBoxInModalStyle: {
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            },
        },
        carouselStatusStyle: {
            position: 'absolute',
            top: 0,
            right: '50%',
            transform: 'translateX(50%)',
            fontSize: '16px',
            fontWeight: 500,
            color: Colors.TextWhite,
        },
        listingDetailsBuildingStyle: {
            width: '100%',
            height: '420px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors.BackgroundWhiteOff,
            borderRadius: '16px',
        },
    }),
    {index: 1}
);
