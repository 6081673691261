import { Images, Colors } from 'utils';
import { makeStyles } from '@material-ui/core/styles';

export const headerCoresStyle = makeStyles(
    () => ({
        userProfileMenuDropdownOverlayStyle: {
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 99,
            top: 0,
            left: 0,
            display: 'block',
            '&.hide': { display: 'none' },
            '@media(max-width: 768px)': {
                display: 'none',
            },
        },
        userProfileStyle: {
            width: '30px',
            height: '30px',
            marginRight: '16px',
            '& img': { width: '100%' },

            '@media(max-width: 400px)': {
                marginRight: '8px',
                width: '24px',
                height: '24px',
            },
        },
        authButnStyle: {
            outline: 'none',
            border: 'none',
            backgroundColor: 'inherit',
            textTransform: 'capitalize',
            position: 'relative',
            fontSize: '16px',
            fontWeight: '500',
            color: Colors.TextGray,
            marginRight: '19px',
            cursor: 'pointer',

            '&.authButnDontUser':{
                marginRight: 0,
                marginLeft: 0,
                '@media(min-width: 1279px)': {
                    marginLeft: '31px',
                },
            },
            '@media(max-width: 768px)': {
                color: Colors.TextBlack,
                margin: '0',
            },
        },
        userSignInStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        signInTextStyle: { color: Colors.TextGray },
        myDropdownContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& > span': {
                marginRight: '16px',
                '&.active': {
                },
            },
        },
        menuDropdownStyle: {
            position: 'absolute',
            top: '62px',
            left: '2px',
            zIndex: 9999999,
            backgroundColor: Colors.BackgroundWhite,
            boxShadow: '0px 0px 6px #00000029',
            borderRadius: '12px',
            padding: '12px 0',
            minWidth:'180px',
            width: '100%',
            cursor: 'default',
            display: 'none',
            '&.show': { display: 'block' },
            '@media(max-width: 1279px)': {
                top: '50px',
                left: '-32px',
                backgroundColor: Colors.BackgroundWhiteOff,
                width: '326px',
                boxShadow: 'none',
                borderRadius: 0,
            },
            '@media(max-width: 400px)': {
                width: '248px',
            },
        },
        userMenuListStyle: {
            width: '100%',
            backgroundColor: 'inherit',
            '& li': {
                backgroundColor: 'inherit',
                transition: 'background-color 0.2s linear',
                fontSize: '14px',
                fontWeight: 500,
                color: Colors.TextGray,

                '@media(max-width: 1279px)': {
                    color: Colors.TextBlack,
                    fontSize: '16px',
                    textAlign:'center'
                },
                '@media(max-width: 400px)': {
                    fontSize: '14px',
                    marginLeft: '60px',
                },
                '&:hover': { backgroundColor: Colors.BackgroundWhiteOff },
                '& a': {
                    cursor: 'pointer',
                    color: 'inherit',
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '11px 16px 11px 78px',
                    '@media(min-width: 1279px)': {
                        padding: '11px 16px 11px 16px',
                    },

                    '&::before': {
                        content: "''",
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        width: '18px',
                        height: '18px',
                        marginRight: '8px',
                        '@media(max-width: 1279px)': { display: 'none' },
                    },
                    '&.myProfile::before': { backgroundImage: `url(${Images.profile})` },
                    '&.myListings::before': { backgroundImage: `url(${Images.listing})` },
                    '&.promotions::before': { backgroundImage: `url(${Images.promotions})` },
                    '&.billings::before': { backgroundImage: `url(${Images.cards})` },
                    '&.logOut::before': { backgroundImage: `url(${Images.logOut})` },
                },
            },
        },
        dropdownArrowStyle: {
            width: '18px',
            height: '18px',
            '& img': { width: '100%' },
        },
    }),
    { index: 1 }
);
