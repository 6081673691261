import React from "react";
import {buttonsStyle} from "./style";

export const ViewMoreButton = ({butnClassName, onView, butnViewingText, styles}) => {
    const classes = buttonsStyle();
    return (
        <button
            style={{...styles}}
            type="button"
            className={`${classes.viewMoreButnStyle} ${butnClassName}`}
            onClick={onView}
        >
            {butnViewingText}
        </button>
    );
};
