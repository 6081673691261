import React from "react";
import { dropdownStyle } from "../dropdowns/style";

export const FilterButton = ({
  butnClassName,
  butnLabelClassName,
  onOpen,
  filterButnLabel,
  filteredItemsQuantity,
}) => {
  const classes = dropdownStyle();

  return (
    <button type="button" className={butnClassName} onClick={onOpen}>
      {filteredItemsQuantity !== 0 && (
        <div className={classes.checkBoxesQuantityStyle}>
          <span> {filteredItemsQuantity} </span>
        </div>
      )}
      <span className={butnLabelClassName}> {filterButnLabel} </span>
    </button>
  );
};
