import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import {addressStyle} from './styles';

export const AddressInput = ({
                                 dropdownClassName,
                                 inputRef,
                                 inputType,
                                 inputPlaceholder,
                                 onInputAddress,
                                 onFocusInput,
                                 onBlurInput,
                                 address,
                                 onChangeAddress,
                                 triggerStatus,
                                 searchingStatus,
                                 onSelectAddress,
                                 dropdownIsShown,
                                 searchType,
                                 disabled
                             }) => {
    const classes = addressStyle();

    return (
        <PlacesAutocomplete
            searchOptions={{types: searchType === 'regions' ? ['(regions)'] : ['(cities)'], country: ['ca', 'us']}}
            value={address}
            onChange={onChangeAddress}
            onSelect={onSelectAddress}
            onError={(status) => triggerStatus(status)}>
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <>
                    <input
                        {...getInputProps({
                            ref: inputRef,
                            type: inputType,
                            className: classes.addressInputStyle,
                            placeholder: inputPlaceholder,
                        })}
                        disabled={disabled}
                        onInput={onInputAddress}
                        onFocus={onFocusInput}
                        onBlur={onBlurInput}
                    />
                    {suggestions.length !== 0 && dropdownIsShown && (
                        <div className={`${classes.addressDropdownStyle} filteringAddress ${dropdownClassName}`}>
                            {/* {loading && <div>Loading...</div>} */}
                            {searchingStatus === 'ZERO_RESULTS' ? (
                                <div className={classes.addressOptionsStyle}>
                                    <span className={classes.addressOptionTitleStyle}>No Results</span>
                                </div>
                            ) : (
                                suggestions.map((suggestion, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={classes.addressOptionsStyle}
                                            {...getSuggestionItemProps(suggestion, {})}>
                                            <span
                                                className={classes.addressOptionTitleStyle}>{suggestion.description}</span>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}
                </>
            )}
        </PlacesAutocomplete>
    );
};
